import { Input, Output, EventEmitter } from '@angular/core';
import { CmdScene, CmdWidget } from '../models';
import { FormGroup } from '@angular/forms';
export class WidgetEditor {
    appId: string;
    sceneList: Array<any>;
    isCollapsed: boolean = true;
    form: FormGroup;
    hasChanges: boolean = false;
    @Input() set scenes(data: Array<CmdScene>) {
        this.sceneList = data;
    }

    @Input() set applicationId(id: string) {
        this.appId = id;
    }

    @Output() remove = new EventEmitter<any>();
    @Output() change = new EventEmitter<any>();
    @Output() duplicate = new EventEmitter<any>();

    doRemove(widget) {
        this.remove.emit(widget);
    } 

    clone(widget) {
        this.duplicate.emit(widget);
    }

    notifyChange() {
        this.change.emit("change");
        this.hasChanges = true;
    }

    @Input() set sceneHasChanges(hasChanges: boolean) {
        if (!hasChanges) {
            this.hasChanges = false;
        }
    }
}
