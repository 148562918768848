import { CmdAsset } from './';
export class CmdUser {
    
    id:string;
    organizationId:string;
    firstName:string;
    lastName:string;
    email:string;
    status:string;
    businessRole:string;
    role:string;
    password:string;
    passwordConfirm:string;
    appIds:Array<string> = [];
    profilePicture:CmdAsset;
    analyticsId?: string = '';
}