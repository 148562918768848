import { CmdApplication, CmdOrganization } from '../../../models';
import { AnalyticsService } from "../../../services/analytics.service";
import { OrganizationService } from '../../../services/organization.service';
import { UserService } from '../../../services/user.service';
import { DatePipe } from "@angular/common";
import { Component, OnInit, NgZone, ViewChild, AfterViewInit, AfterContentInit } from '@angular/core';
import { ApplicationService } from '../../../services/application.service';
import { chartp } from 'chart.piecelabel.js';
import { dateType } from 'aws-sdk/clients/iam';
import { Chart } from 'chart.js'
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { jsPDF } from "jspdf";
import 'chart.piecelabel.js';
import * as fs from 'file-saver';
import * as CanvasJS from 'canvasjs/dist/canvasjs.js';
import html2canvas from 'html2canvas';
import { SpinService } from '../../../services/spin.service';
import { Color } from 'ng2-charts';
import { Workbook } from 'exceljs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare var gapi;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements AfterViewInit, OnInit {

  @ViewChild("authButton", { static: false }) button: any;
  @ViewChild("viewSelector", { static: false }) selector: any;
  currentOrganization: CmdOrganization;
  applicationsList: Array<CmdApplication>;
  startDate: string;
  selectedApp: any = 'AllApps';
  endDate: string;
  appModuleCount: number = 0;
  userCount: number;
  sessionsCount: number;
  shareCount: number;
  iPhoneDeviceCount: number;
  iPadDeviceCount: number;
  phoneDevicesCount: number;
  tabletDevicesCount: number;
  appleDevicesCount: number;
  androidDevicesCount: number;
  windowsDevicesCount: number;
  collectionsShownTotalCount: any;
  collectionsShownTotalChange: any;
  sharedContentTotalCount: any;
  sharedContentTotalChange: any;
  collectionsShownDateviseCount: Array<any> = [];
  sharedContentDateviseCount: Array<any> = [];
  collectionsDate: Array<any> = [];
  sharedContentDate: Array<any> = [];
  date: dateType;
  dateFrom: string;
  dateTo: string;
  indTtlData: any = [];
  direction: number;
  isDesc = false;
  column = 'label';
  menuScenes: Array<any> = [];
  menuScenceAPIData: any;
  contentOverview: any = [];
  conetentOverviewData: any = [];
  rowData2: any = [];
  rowData4: any = [];
  userData: any = [];
  hasChanged: boolean = false;
  recentActivities: any = [];
  p: number = 1;

  recentActivity: any = {
    results: []
  };

  // loader properties
  isLoadingMenu:boolean = false;
  isLoadingCollectionsShown:boolean = false;
  isLoadingSharedContents:boolean = false;
  isLoadingDeviceCount:boolean = false;
  isLoadingUsers:boolean = false;
  isLoadingTotalShares:boolean = false;
  isLoadingSessions:boolean = false;
  isLoadingRecentActivity:boolean = false;
  isLoadingContentOverviews:boolean = false;
  isLoadingAppModules:boolean = false;
  ////////////////////////////////////

  constructor(
    private orgService: OrganizationService,
    private analyticsService: AnalyticsService,
    private appService: ApplicationService,
    private datepipe: DatePipe,
    private alertService: StickyPopUpService,
    private router: Router,
    private spinnerService: NgxSpinnerService
  ) {
    this.currentOrganization = this.orgService.getCurrentOrganization();
    this.isLoadingAppModules = true;
    this.appService.getOrgApplications(this.currentOrganization.id).subscribe(list => {
      this.applicationsList = list;
      this.appModuleCount = this.applicationsList.length;
      this.isLoadingAppModules = false;
    });
  }

  ngOnInit() {
    this.date = new Date();
    let oneMonthBack = this.date.getFullYear() + '-' + this.date.getMonth() + '-' + (this.date.getDate() - 1);
    this.dateFrom = this.datepipe.transform(oneMonthBack, 'yyyy-MM-dd');
    this.dateTo = this.datepipe.transform(this.date, "yyyy-MM-dd");
    this.selectDateRange(this.dateFrom, this.dateTo);
    this.loadAll();
  }

  ngAfterViewInit() {
    var that = this;
  }

  loadOnChange() {
    this.getScenes();
    this.getDeviceCount();
    this.getCollectionsShown();
    this.getSharedContent();
    this.getShareCount();
    this.getContentOverviewForOrg();
  }

  selectDateRange(dateFrom: string, dateTo: string) {
    this.startDate = this.datepipe.transform(dateFrom, 'yyyy-MM-ddT00:00:00');
    this.endDate = this.datepipe.transform(dateTo, 'yyyy-MM-ddT23:59:59');

    if ((Date.parse(this.startDate) >= Date.parse(this.endDate))) {
      this.alertService.info(" Select Range : End date should be greater than Start date.");
      (document.getElementById("dateFrom1") as HTMLInputElement).value = this.dateFrom;
      (document.getElementById("dateTo1") as HTMLInputElement).value = this.dateTo;
    }

    if (this.hasChanged) {
      this.loadOnChange();
      this.hasChanged = false;
    }
  }

  loadAll() {
    this.getUserList();
    this.getDeviceCount();
    this.getSessionsCount();
    this.getSharedContent();
    this.getShareCount();
    this.getScenes();
    this.getCollectionsShown();
    this.getContentOverviewForOrg();
  }

  setHasChanged() {
    this.hasChanged = true;
  }

  getScenes() {
    this.isLoadingMenu = true;
    var menuChart = document.getElementById("chartContainer");
    if (menuChart.childElementCount > 0) {
      menuChart.innerHTML = '';
    }
    this.indTtlData = [];
    if (this.selectedApp == 'AllApps') {
      this.analyticsService.getScenePercentageForOrg(this.currentOrganization.id, this.startDate, this.endDate).subscribe(list => {
        this.menuScenceAPIData = list;
        list.forEach(obj => {
          let data = {
            lebel: obj.scene + " [" + obj.application + "]",
            z: obj.application,
            y: obj.percentage,
          }
          this.indTtlData.push(data);
        });
        CanvasJS.addColorSet("colorSet", [//colorSet Array
          "#FFC0CB",
          "#e4f54e",
          "#f54242",
          "#f5bc42",
          "#c2f542",
          "#42f54e",
          "#42b6f5",
          "#6642f5",
          "#e942f5",
          "#5e5b63",
          "#f57e42"
        ]);

        let chart = new CanvasJS.Chart("chartContainer", {
          animationEnabled: true,
          colorSet: "colorSet",
          title: {
            text: this.indTtlData.length,
            verticalAlign: "center"
          },
          data: [{
            type: "doughnut",
            startAngle: 100,
            //innerRadius: 60,
            //indexLabelRadius: 5,
            indexLabelFontSize: 8,
            //indexLabel: "{lebel} - #percent%",
            toolTipContent: "<b>{lebel}:</b> {y} (#percent%)",
            dataPoints: this.indTtlData
          }]
        });
        chart.render();
        this.isLoadingMenu = false;
      },
        err => {
          this.isLoadingMenu = false;
          console.log(err);
        });
    } else {
      this.analyticsService
        .getScenePercentageForOrgAndApp(this.currentOrganization.id, this.selectedApp.id, this.startDate, this.endDate)
        .subscribe(list1 => {
          list1.forEach(obj => {
            let data = {
              lebel: obj.scene + " [" + obj.application + "]",
              y: obj.percentage,
            }
            this.indTtlData.push(data);
          });
          CanvasJS.addColorSet("colorSet", [//colorSet Array
            "#FFC0CB",
            "#e4f54e",
            "#f54242",
            "#f5bc42",
            "#c2f542",
            "#42f54e",
            "#42b6f5",
            "#6642f5",
            "#e942f5",
            "#5e5b63",
            "#f57e42"
          ]);

          let chart = new CanvasJS.Chart("chartContainer", {
            animationEnabled: true,
            colorSet: "colorSet",
            title: {
              text: this.indTtlData.length,
              verticalAlign: "center"
            },
            data: [{
              type: "doughnut",
              startAngle: 100,
              //innerRadius: 60,
              //indexLabelRadius: 5,
              indexLabelFontSize: 8,
              //indexLabel: "{lebel} - #percent%",
              toolTipContent: "<b>{lebel}:</b> {y} (#percent%)",
              dataPoints: this.indTtlData
            }]
          });
          chart.render();
          this.isLoadingMenu = false;
        },
          err => {
            this.isLoadingMenu = false;
            console.log(err);
          });
    }
  }

  getCollectionsShown() {
    this.isLoadingCollectionsShown = true;
    this.collectionsShownTotalCount = 0;
    this.collectionsShownTotalChange = 0;
    this.collectionsShownDateviseCount = [];
    this.collectionsDate = [];

    var chart = document.getElementById("collectionsChart");
    if (chart.childElementCount > 0) {
      chart.innerHTML = '';
    }

    // Create the <canvas> element
    var canvas = document.createElement("canvas") as HTMLCanvasElement;
    canvas.id = "collectionsChartCanvas";
    canvas.style.minHeight = "220px";
    canvas.style.maxHeight = "220px";
    canvas.style.width = "100%";

    // Append the <canvas> element to the chart div
    chart.appendChild(canvas);

    if (this.selectedApp == 'AllApps') {
      this.analyticsService.getCollectionsShownForOrg(this.currentOrganization.id, this.startDate, this.endDate).subscribe(list => {
        this.collectionsShownTotalCount = list.count;
        this.collectionsShownTotalChange = list.change == 0 ? 0 : (list.change * 100).toFixed(2);

        list.dateviseCount.forEach(obj => {
          this.collectionsShownDateviseCount.push(obj.count);
          this.collectionsDate.push(this.datepipe.transform(obj.onDate, "MM/dd/yyyy"));
        });

        var yValues1 = this.collectionsShownDateviseCount;
        var xValues1 = this.collectionsDate;

        new Chart(canvas, {
          type: "line",
          data: {
            labels: xValues1,
            datasets: [{
              label: 'Collections Shown',
              backgroundColor: "rgb(212, 243, 255)",
              borderColor: "rgba(0,0,0,0.1)",
              pointBackgroundColor: "rgba(44, 42, 42, 1)",
              data: yValues1
            }]
          },
          options: {
          }
        });
        this.isLoadingCollectionsShown = false;
      });
    } else {
      this.analyticsService.getCollectionsShownForOrgAndApp(this.currentOrganization.id, this.selectedApp.id, this.startDate, this.endDate).subscribe(list => {
        this.collectionsShownTotalCount = list.count;
        this.collectionsShownTotalChange = list.change == 0 ? 0 : (list.change * 100).toFixed(2);

        list.dateviseCount.forEach(obj => {
          this.collectionsShownDateviseCount.push(obj.count);
          this.collectionsDate.push(this.datepipe.transform(obj.onDate, "MM/dd/yyyy"));
        });

        var yValues1 = this.collectionsShownDateviseCount;
        var xValues1 = this.collectionsDate;

        new Chart(canvas, {
          type: "line",
          data: {
            labels: xValues1,
            datasets: [{
              label: 'Collections Shown',
              backgroundColor: "rgb(212, 243, 255)",
              borderColor: "rgba(0,0,0,0.1)",
              pointBackgroundColor: "rgba(44, 42, 42, 1)",
              data: yValues1
            }]
          },
          options: {}
        });
        this.isLoadingCollectionsShown = false;
      });
    }
  }

  getSharedContent() {
    this.isLoadingSharedContents = true;
    this.sharedContentTotalCount = 0;
    this.sharedContentTotalChange = 0;
    this.sharedContentDateviseCount = [];
    this.sharedContentDate = [];

    var chart = document.getElementById("sharedContentChart");
    if (chart.childElementCount > 0) {
      chart.innerHTML = '';
    }

    // Create the <canvas> element
    var canvas = document.createElement("canvas") as HTMLCanvasElement;
    canvas.id = "sharedContentChartCanvas";
    canvas.style.minHeight = "220px";
    canvas.style.maxHeight = "220px";
    canvas.style.width = "100%";

    // Append the <canvas> element to the chart div
    chart.appendChild(canvas);

    if (this.selectedApp == "AllApps") {
      this.analyticsService.getSharedContentForOrg(this.currentOrganization.id, this.startDate, this.endDate).subscribe(list => {
        this.sharedContentTotalCount = list.count;
        this.sharedContentTotalChange= list.change == 0 ? 0 : (list.change * 100).toFixed(2);

        list.dateviseCount.forEach(obj => {
          this.sharedContentDateviseCount.push(obj.count);
          this.sharedContentDate.push(this.datepipe.transform(obj.onDate, "MM/dd/yyyy"));
        });

        let yValues = this.sharedContentDateviseCount;
        let xValues = this.sharedContentDate;

        new Chart(canvas, {
          type: "line",
          data: {
            labels: xValues,
            datasets: [{
              label: 'Shared Content',
              backgroundColor: "rgb(212, 243, 255)",
              borderColor: "rgba(0,0,0,0.1)",
              pointBackgroundColor: "rgba(44, 42, 42, 1)",
              data: yValues
            }]
          },
          options: {
          }
        });
        this.isLoadingSharedContents = false;
      });
    } else {
      this.analyticsService.getSharedContentForOrgAndApp(this.currentOrganization.id, this.selectedApp.id, this.startDate, this.endDate).subscribe(list => {
        this.sharedContentTotalCount = list.count;
        this.sharedContentTotalChange = list.change == 0 ? 0 : (list.change * 100).toFixed(2);

        list.dateviseCount.forEach(obj => {
          this.sharedContentDateviseCount.push(obj.count);
          this.sharedContentDate.push(this.datepipe.transform(obj.onDate, "MM/dd/yyyy"));
        });

        let yValues = this.sharedContentDateviseCount;
        let xValues = this.sharedContentDate;

        new Chart(canvas, {
          type: "line",
          data: {
            labels: xValues,
            datasets: [{
              label: 'Shared Content',
              backgroundColor: "rgb(212, 243, 255)",
              borderColor: "rgba(0,0,0,0.1)",
              pointBackgroundColor: "rgba(44, 42, 42, 1)",
              data: yValues
            }]
          },
          options: {}
        });
        this.isLoadingSharedContents = false;
      });
    }
  }

  getUserList() {
    this.isLoadingUsers = true;
    this.userCount = 0;
    this.analyticsService.getUserListForOrg(this.currentOrganization.id).subscribe(list => {
      this.userData = list;
      this.userCount = list.length;
      this.isLoadingUsers = false;
    });
  }

  getSessionsCount() {
    this.isLoadingSessions = true;
    this.sessionsCount = 0;
    this.analyticsService.getSessionForOrg(this.currentOrganization.id, this.startDate, this.endDate).subscribe(list => {
      this.sessionsCount = list.count;
      this.isLoadingSessions = false;
    });
  }

  getShareCount() {
    this.isLoadingTotalShares = true;
    this.shareCount = 0;
    if (this.selectedApp == "AllApps") {
      this.analyticsService.getShareCountForOrg(this.currentOrganization.id, this.startDate, this.endDate).subscribe(list => {
        this.shareCount = list.count;
        this.isLoadingTotalShares = false;
      });
    } else {
      this.analyticsService.getShareCountForOrgAndApp(this.currentOrganization.id, this.selectedApp.id, this.startDate, this.endDate).subscribe(list => {
        this.shareCount = list.count;
        this.isLoadingTotalShares = false;
      });
    }

  }

  getDeviceCount() {
    this.isLoadingDeviceCount = true;
    this.iPhoneDeviceCount = 0;
    this.iPadDeviceCount = 0;
    this.phoneDevicesCount = 0;
    this.tabletDevicesCount = 0;
    this.windowsDevicesCount = 0;
    this.appleDevicesCount = 0;
    this.androidDevicesCount = 0;
    if (this.selectedApp == "AllApps") {
      this.analyticsService.getDeviceCountForOrg(this.currentOrganization.id, this.startDate, this.endDate).subscribe(list => {
        list.forEach(obj => {
          switch (obj.devices.toLowerCase()) {
            case "iphone":
              this.iPhoneDeviceCount = obj.count;
              break;
            case "ipad":
              this.iPadDeviceCount = obj.count;
              break;
            case "phone":
              this.phoneDevicesCount = obj.count;
              break;
            case "tablet":
              this.tabletDevicesCount = obj.count;
              break;
            case "windows":
              this.windowsDevicesCount = obj.count;
              break;

            default:
              break;
          }
        });
        this.appleDevicesCount = this.iPhoneDeviceCount + this.iPadDeviceCount;
        this.androidDevicesCount = this.phoneDevicesCount + this.tabletDevicesCount;
        this.isLoadingDeviceCount = false;
      });
    } else {
      this.analyticsService.getDeviceCountForOrgAndApp(this.currentOrganization.id, this.selectedApp.id, this.startDate, this.endDate).subscribe(list => {
        list.forEach(obj => {
          switch (obj.devices.toLowerCase()) {
            case "iphone":
              this.iPhoneDeviceCount = obj.count;
              break;
            case "ipad":
              this.iPadDeviceCount = obj.count;
              break;
            case "phone":
              this.phoneDevicesCount = obj.count;
              break;
            case "tablet":
              this.tabletDevicesCount = obj.count;
              break;
            case "windows":
              this.windowsDevicesCount = obj.count;
              break;

            default:
              break;
          }
        });
        this.appleDevicesCount = this.iPhoneDeviceCount + this.iPadDeviceCount;
        this.androidDevicesCount = this.phoneDevicesCount + this.tabletDevicesCount;
        this.isLoadingDeviceCount = false;
      });

    }
  }

  getContentOverviewForOrg() {
    this.isLoadingContentOverviews = true;
    if (this.selectedApp == "AllApps") {
      this.analyticsService.getContentOverviewForOrg(this.currentOrganization.id, this.startDate, this.endDate).subscribe(list => {
        this.contentOverview = list;
        this.isLoadingContentOverviews = false;
      },error=>{
        this.isLoadingContentOverviews = false;
        console.log("error while fetching content overviews for the org: ",error.message);
      });
    } else {
      this.analyticsService.getContentOverviewForOrgAndApp(this.currentOrganization.id, this.selectedApp.id, this.startDate, this.endDate).subscribe(list => {
        this.contentOverview = list;
        this.isLoadingContentOverviews = false;
      },error=>{
        this.isLoadingContentOverviews = false;
        console.log("error while fetching content overviews for the app: ",error.message);
      });
    }
  }


  exportExcel() {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('ProductData');
    let startDate = this.datepipe.transform(this.startDate, "MM/dd/yyyy");
    let endDate = this.datepipe.transform(this.endDate, "MM/dd/yyyy");
    let selectRange = startDate + " - " + endDate;
    worksheet.columns = [{ key: 'R1', header: 'Date Range' }, { key: 'R2', header: selectRange }, { key: 'R3', header: '' }, { key: 'R4', header: '' }];

    const rowData = [
      { R1: "", R2: '' },
      { R1: 'App Selection', R2: 'All Apps', R3: '', R4: '' },
      { R1: "Total Users", R2: this.userCount, R3: '', R4: '' },
      { R1: "Sessions", R2: this.sessionsCount, R3: '', R4: '' },
      { R1: "Total Shares", R2: this.shareCount, R3: '', R4: '' },
      { R1: "", R2: '' }, { R1: "", R2: '', R3: '', R4: '' },
      { R1: "Devices", R2: 'Apple', R3: 'Mobile', R4: this.iPhoneDeviceCount },
      { R1: "", R2: '', R3: 'Tablet', R4: this.iPadDeviceCount },
      { R1: "", R2: 'Android', R3: 'Mobile', R4: this.phoneDevicesCount },
      { R1: "", R2: '', R3: 'Tablet', R4: this.tabletDevicesCount },
      { R1: "", R2: 'Windows', R3: '', R4: this.windowsDevicesCount },
      { R1: "", R2: '', R3: '', R4: "" }
    ];

    this.rowData2 = [];
    this.menuScenceAPIData.forEach((obj: any, index: number) => {
      let percentageData = obj.percentage * 100;
      let percentage = parseFloat(percentageData.toString()).toFixed(2);
      if (index == 0) {
        this.rowData2.push({ R1: "Menus/Scenes", R2: obj.scene, R3: percentage + "%", R4: '' });
      } else {
        this.rowData2.push({ R1: "", R2: obj.scene, R3: percentage + "%", R4: '' });
      }
    });

    let rowData3 = [{ R1: '', R2: '', R3: '', R4: '' },
    { R1: '', R2: '', R3: '', R4: '' },
    { R1: 'Collections Shown', R2: this.collectionsShownTotalCount, R3: '', R4: '' },
    { R1: '', R2: '', R3: '', R4: '' },
    { R1: 'Shared Content', R2: this.sharedContentTotalCount, R3: '', R4: '' },
    { R1: '', R2: '', R3: '', R4: '' },
    { R1: '', R2: '', R3: '', R4: '' },
    { R1: 'Content Overview', R2: 'Content', R3: 'Views', R4: 'Shares' }
    ]
    this.rowData4 = [];
    this.contentOverview.forEach((obj: any, index: number) => {

      this.rowData4.push({ R1: '', R2: obj.label, R3: obj.viewCount, R4: obj.shareCount });

    });

    rowData.forEach((item1, i) => {
      worksheet.addRow(item1);
    });

    this.rowData2.forEach((item2, j) => {
      worksheet.addRow(item2);
    });

    rowData3.forEach((item3, i) => {
      worksheet.addRow(item3);
    });

    this.rowData4.forEach((item4, i) => {
      worksheet.addRow(item4);
    });

    workbook.csv.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'text/csv' });
      fs.saveAs(blob, 'Analytics.csv');
    })
  }

  exportPdf() {
   try {
    this.menuScenes = [];
    const source = document.getElementById("contentToConvert");
    let startDate = this.datepipe.transform(this.startDate, "MM/dd/yyyy");
    let endDate = this.datepipe.transform(this.endDate, "MM/dd/yyyy");
    let DateRange = "Date Range: " + startDate + " - " + endDate;

    var pdf = new jsPDF();
    var img = new Image();
    
    let logo = this.currentOrganization.logo;
    if(logo && logo.url){
      img.src = this.currentOrganization.logo.url + '?r=' + Math.floor(Math.random()*100000);
      // img.src = this.currentOrganization.logo.url;
      img.crossOrigin = 'anonymous';

      pdf.addImage(img, 'png', 10, 5, 65, 15);
    }else {
      img.src = 'assets/images/Scania-Logo-Horiz.png';
      pdf.addImage(img, 'png', 10, 6, 67, 15);
    }

    pdf.setFontSize(12).setFont(undefined, 'Medium');
    pdf.text(DateRange, 130, 15);

    pdf.setFontSize(14).setFont(undefined, 'bold');
    pdf.text("General", 15, 40);

    let generalHeader = [['App Selection', 'All App', '']]
    let generalData = [['Total User', this.userCount, ''], ['Sessions', this.sessionsCount, ''], ['Total Shares', this.shareCount, '']];

    let devicesHeader = [['Devices', 'Mobile', 'Tablet']]
    let devicesData = [['Apple', this.iPhoneDeviceCount, this.iPadDeviceCount], ['Android', this.phoneDevicesCount, this.tabletDevicesCount], ['Windows', this.windowsDevicesCount, '0']];

    let menuScenesHeader = [['Menus / Scenes', 'Percentage']]
    this.menuScenceAPIData.forEach((obj: any, index) => {
      let data = obj.percentage * 100;

      let percentage = parseFloat(data.toString()).toFixed(2);

      this.menuScenes.push([obj.scene, percentage + "%"]);
    });
    let menuScenesData = this.menuScenes;

    let visibilityHeader = [['Visibility', '']];
    let visibilityData = [['Collections shown', this.collectionsShownTotalCount], ['Shared content', this.sharedContentTotalCount]];

    // pdf.text("Content Overview", 15, 163).setFontSize(9).setFont(undefined, 'bold');
    let conetentOverviewHeader = [['Content', 'Views', 'Shares']]
    this.contentOverview.forEach(obj => {
      this.conetentOverviewData.push([obj.label, obj.viewCount, obj.shareCount]);
    });
    let conetentOverviewData = this.conetentOverviewData;

    // pdf.setFontSize(18);
    // pdf.setFontSize(11);
    pdf.setTextColor(100);

    (pdf as any).autoTable({
      headStyles: { fillColor: [211, 211, 211] },
      alternateRowStyles: { fillColor: [255, 255, 255] },
      tableLineColor: [211, 211, 211],
      margin: { top: 43 },
      head: generalHeader,
      body: generalData,
      theme: 'plain'
    });

    (pdf as any).autoTable({
      headStyles: { fillColor: [211, 211, 211] },
      alternateRowStyles: { fillColor: [255, 255, 255] },
      tableLineColor: [211, 211, 211],
      columnStyles: { "Devices": { fillColor: [23, 255, 0] } },
      margin: { top: 63 },
      head: devicesHeader,
      body: devicesData,
      theme: 'plain'
    }),

      (pdf as any).autoTable({
        headStyles: { fillColor: [211, 211, 211] },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        tableLineColor: [211, 211, 211],
        // margin: { top: 63 },
        head: menuScenesHeader,
        body: menuScenesData,
        theme: 'plain'
      }),

      (pdf as any).autoTable({
        headStyles: { fillColor: [211, 211, 211] },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        tableLineColor: [211, 211, 211],
        // margin: { top: 70 },
        head: visibilityHeader,
        body: visibilityData,
        theme: 'plain'
      }),

      (pdf as any).autoTable({
        headStyles: { fillColor: [211, 211, 211] },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        tableLineColor: [211, 211, 211],
        // margin: { top: 87 },
        head: conetentOverviewHeader,
        body: conetentOverviewData,
        theme: 'plain'
      })
    pdf.save('Analytics-PDF.pdf');
   } catch (error) {
    this.alertService.error("Unable to export pdf");
    console.log("export pdf=> "+ error);
   }
  }

  sortContent(sortProperty) {
    let direction = this.isDesc ? 1 : -1;
    this.isDesc = !this.isDesc;
    this.column = sortProperty;
    this.contentOverview.sort(function (a, b) {

      if (sortProperty == "avgViewTime") {

        let totalAvgViewTimeA, totalAvgViewTimeB;
        let avgViewTime1, hour1, min1, sec1;
        let avgViewTime2, hour2, min2, sec2;

        if (a[sortProperty].includes("hour") && a[sortProperty].includes("min") && a[sortProperty].includes("sec")) {
          let avgViewTime = a[sortProperty].split(" ");
          let hour = Number(avgViewTime[0]) * 3600;
          let min = Number(avgViewTime[2]) * 60;
          let sec = Number(avgViewTime[4]);
          totalAvgViewTimeA = hour + min + sec;
        } else if (!a[sortProperty].includes("hour") && a[sortProperty].includes("min") && a[sortProperty].includes("sec")) {
          let avgViewTime = a[sortProperty].split(" ");
          let min = Number(avgViewTime[0]) * 60;
          let sec = Number(avgViewTime[2]);
          totalAvgViewTimeA = min + sec;
        } else if (!a[sortProperty].includes("hour") && !a[sortProperty].includes("min") && a[sortProperty].includes("sec")) {
          let avgViewTime = a[sortProperty].split(" ");
          let sec = Number(avgViewTime[0]);
          totalAvgViewTimeA = sec;
        }

        if (b[sortProperty].includes("hour") && b[sortProperty].includes("min") && b[sortProperty].includes("sec")) {
          let avgViewTime = b[sortProperty].split(" ");
          let hour = Number(avgViewTime[0]) * 3600;
          let min = Number(avgViewTime[2]) * 60;
          let sec = Number(avgViewTime[4]);
          totalAvgViewTimeB = hour + min + sec;
        } else if (!b[sortProperty].includes("hour") && b[sortProperty].includes("min") && b[sortProperty].includes("sec")) {
          let avgViewTime = b[sortProperty].split(" ");
          let min = Number(avgViewTime[0]) * 60;
          let sec = Number(avgViewTime[2]);
          totalAvgViewTimeB = min + sec;
        } else if (!b[sortProperty].includes("hour") && !b[sortProperty].includes("min") && b[sortProperty].includes("sec")) {
          let avgViewTime = b[sortProperty].split(" ");
          let sec = Number(avgViewTime[0]);
          totalAvgViewTimeB = sec;
        }


        if (totalAvgViewTimeA < totalAvgViewTimeB) {
          return -1 * direction;
        } else if (totalAvgViewTimeA > totalAvgViewTimeB) {
          return 1 * direction;
        } else {
          return 0;
        }
      } else {

        if (a[sortProperty] < b[sortProperty]) {
          return -1 * direction;
        } else if (a[sortProperty] > b[sortProperty]) {
          return 1 * direction;
        } else {
          return 0;
        }
      }
    });

  }

  goToUserReport(userId: string) {
    this.router.navigate(['dashboard/user-report/' + userId]);
  }
}
