import { Directive, ElementRef, HostListener } from "@angular/core";
import { StickyPopUpService } from "../services/toast/stickyPopUp.service";

@Directive({
  selector: 'input[separator]',
})
export class SeparatorDirective {

  constructor(
    private _inputEl: ElementRef,
    private stickyPopUpService: StickyPopUpService
    ) {}

  @HostListener('input', ['$event'])
  onInput(event: any) {
    if (this._inputEl.nativeElement.value === '-') return;
    
    let commasRemoved = this._inputEl.nativeElement.value.replace(/,/g, '');
    let toInt: number;
    let toLocale: string;
    if (commasRemoved.split('.').length > 1) {
     let decimal =isNaN(parseInt(commasRemoved.split('.')[1]))? '':commasRemoved.split('.')[1];
      if(commasRemoved=="."){
        commasRemoved="0."
      }
       if(commasRemoved.includes('0.0')){
        toLocale =commasRemoved; 
       }else{
        toInt = parseInt(commasRemoved);
        toLocale = toInt.toLocaleString('en-US') + '.' + decimal;
       }
      
    } else {
      toInt = parseInt(commasRemoved);
      toLocale = toInt.toLocaleString('en-US');
    }
    if (toLocale === 'NaN') {
      this._inputEl.nativeElement.value = '';
    } else {
      this._inputEl.nativeElement.value = toLocale;
    }
  }
}