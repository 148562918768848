import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StickyPopUpService } from "../../../../services/toast/stickyPopUp.service";
import { TcoCalculatorService } from "../../../../services/tco-calculator.service";
import { HttpResponse } from "@angular/common/http";
import { CmdDevaluation } from "../../../../models/tco/CmdDevaluation";
import { CmdDevaluationProduct } from "../../../../models/tco/CmdDevaluationProduct";
import { CmdDevaluationPeriod } from "../../../../models/tco/CmdDevaluationPeriod";
import { CmdDevaluationTableData } from "../../../../models/tco/CmdDevaluationTableData";
import { CmdDevaluationAverages } from "../../../../models/tco/CmdDevaluationAverages";
import { ConfirmModalComponent } from '../../../../shared/confirm-modal/confirm-modal.component';
import { SpinService } from "../../../../services/spin.service";
import { OrganizationService } from "../../../../services/organization.service";

@Component({
  selector: "app-tco-devaluation-analysis",
  templateUrl: "./tco-devaluation-analysis.component.html",
  styleUrls: ["./tco-devaluation-analysis.component.css"],
})

export class TcoDevaluationAnalysisComponent implements OnInit {
  cofirmModalRef: BsModalRef;
  isCollapsed: number = -1;
  selectedDevaluation: CmdDevaluation;
  zero = 0.00;
  toolsetId: any = '';
  currencyDropdown: any = "$";
  constructor(
    private route: Router,
    public alertService: StickyPopUpService,
    public spinService: SpinService,
    private tcoCalculatorService: TcoCalculatorService,
    private modalService: BsModalService,
    private router: ActivatedRoute,
    private organizationService: OrganizationService,
  ) {
    this.selectedDevaluation = new CmdDevaluation();
  }

  ngOnInit() {
    
    let currencyDropdown = JSON.parse(localStorage.getItem("currencySymbol"));
    if(currencyDropdown){
      this.currencyDropdown=currencyDropdown;
    }else{
      this.currencyDropdown= "$";
    }
    this.loadDevaluation();
    
  }

  loadDevaluation() {
    var orgId = this.organizationService.getCurrentOrganizationId();
    this.tcoCalculatorService
      .getDevaluationForOrgId(orgId)
      .subscribe((res) => {
        var devaluationData = JSON.parse(res.devaluationData);
        this.selectedDevaluation.devaluationAverageData = devaluationData.averages;
        this.selectedDevaluation.devaluationTableData = devaluationData.table_data;
        this.selectedDevaluation.defaultCurrency = devaluationData.defaultCurrency;
      }, error => {
        this.alertService.error('Failed to get Devaluation: ' + error.error.message);
		  this.spinService.hide();
      });
  }


  getDepreciation(periodData, j, k, l) {
    if (l - 1 >= 0) {
      let depreciation = (((periodData[l - 1].usedPrice.replace(/,/g, '') / periodData[l].usedPrice.replace(/,/g, '')) - 1) * 100);
      this.selectedDevaluation.devaluationTableData[j].productData[k].periodData[l - 1].depreciation = depreciation.toFixed(2);
    }

    if (periodData.length > l + 1) {
      var nextUsedPrice = periodData[l + 1].usedPrice.replace(/,/g, '');
      if (!nextUsedPrice) {
        this.alertService.info("Next Year Used Price is 0.So, Depriciation will be 'INFINITY'.");
      }
      let depreciation = (((periodData[l].usedPrice.replace(/,/g, '') / periodData[l + 1].usedPrice.replace(/,/g, '')) - 1) * 100);
      this.selectedDevaluation.devaluationTableData[j].productData[k].periodData[l].depreciation = depreciation.toFixed(5);
    }

    this.getAverage(periodData, j, k);
    this.getFiveYearAverage(periodData, j, k);
  }

  getAverage(periodData, j, k) {
    let depriciationValues = [];
    let sum: number = 0;

    periodData.forEach(period => {
      if (period.depreciation != "" && period.depreciation != 0) {
        depriciationValues.push(parseFloat(period.depreciation));
      }
    });

    for (let i = 0; i < depriciationValues.length; i++) {
      sum += depriciationValues[i];
    }

    let avg = (sum / depriciationValues.length);
    if (!Number.isNaN(avg) && Number.isFinite(avg)) {
      this.selectedDevaluation.devaluationTableData[j].productData[k].average = avg.toFixed(2);
    } else {
      let avg = 0;
      this.selectedDevaluation.devaluationTableData[j].productData[k].average = avg.toFixed(2);
    }

    let allAnnualAvgs = [];
    var productData = this.selectedDevaluation.devaluationTableData[j].productData;
    productData.forEach(product => {
      var value = product.average;
      if (value != '') {
        allAnnualAvgs.push(parseFloat(value));
      }
    });

    var sumOfAnnualAvgs = 0;

    for (let i = 0; i < allAnnualAvgs.length; i++) {
      sumOfAnnualAvgs += allAnnualAvgs[i];
    }

    let avgOfAllAnnualAvg = (sumOfAnnualAvgs / allAnnualAvgs.length);
    this.selectedDevaluation.devaluationTableData[j].productAnnualAverage = avgOfAllAnnualAvg.toFixed(4);
    this.selectedDevaluation.devaluationAverageData[j].average = avgOfAllAnnualAvg.toFixed(4);
  }

  getFiveYearAverage(periodData, j, k) {
    let firstYearValue = 0;
    let lastYearValue = 0;

    let firstYearUsedPrice = this.selectedDevaluation.devaluationTableData[j].productData[k].periodData[0].usedPrice.replace(/,/g, '');
    let lastYearUsedPrice = this.selectedDevaluation.devaluationTableData[j].productData[k].periodData[periodData.length - 1].usedPrice.replace(/,/g, '');

    if (firstYearUsedPrice != '') {
      firstYearValue = parseFloat(firstYearUsedPrice);
    }
    if (lastYearUsedPrice != '') {
      lastYearValue = parseFloat(lastYearUsedPrice);
    }

    let fiveYearAverage = (((firstYearValue / lastYearValue) - 1) * 100).toFixed(2)
    
    if (!Number.isNaN(parseFloat(fiveYearAverage)) && Number.isFinite(parseFloat(fiveYearAverage))) {
      this.selectedDevaluation.devaluationTableData[j].productData[k].fiveYearAverage = fiveYearAverage;
    } else {
      let fiveYearAverage = 0;
      this.selectedDevaluation.devaluationTableData[j].productData[k].fiveYearAverage = fiveYearAverage.toFixed(2);
    }
  }


  addPeriod(j, k) {
    var periodControls = this.selectedDevaluation.devaluationTableData[j].productData[k].periodData;
    var lastIndexControl = this.selectedDevaluation.devaluationTableData[j].productData[k].periodData[periodControls.length - 1];
    var lastDepreciation = lastIndexControl.depreciation;
    this.selectedDevaluation.devaluationTableData[j].productData[k].periodData.push({
      year: (parseInt(lastIndexControl.year) + 1).toString(),
      usedPrice: this.zero.toFixed(2),
      depreciation: this.zero.toFixed(2),
    });
  }

  addProduct(j) {
    let product = new CmdDevaluationProduct();
    let period = new CmdDevaluationPeriod();
    period.year = '2000',
    period.usedPrice = this.zero.toFixed(2);
    period.depreciation = this.zero.toFixed(2);

    product.model = 'model';
    product.modelTitle = 'model title';
    product.average = this.zero.toFixed(2);
    product.fiveYearAverage = this.zero.toFixed(2);
    product.periodData = [period];
    this.selectedDevaluation.devaluationTableData[j].productData.push(product)
  }

  addTable() {
    let devaluationTableData = new CmdDevaluationTableData();

    let product = new CmdDevaluationProduct();
    let period = new CmdDevaluationPeriod();
    period.year = '2000',
    period.usedPrice = this.zero.toFixed(2);
    period.depreciation = this.zero.toFixed(2);

    product.model = 'model';
    product.modelTitle = 'model title';
    product.average = this.zero.toFixed(2);
    product.fiveYearAverage = this.zero.toFixed(2);
    product.periodData = [period];

    devaluationTableData.productTitle = 'Product Title';
    devaluationTableData.productAnnualAverage = this.zero.toFixed(2);

    devaluationTableData.productData.push(product);

    this.selectedDevaluation.devaluationTableData.push(devaluationTableData);

    let devaluationAverageData = new CmdDevaluationAverages();
    devaluationAverageData.header = 'Product Title';
    devaluationAverageData.average = this.zero.toFixed(2);

    this.selectedDevaluation.devaluationAverageData.push(devaluationAverageData);
  }

  updateTitleInAverageSection(index) {
    let changedTitle = this.selectedDevaluation.devaluationTableData[index].productTitle;
    this.selectedDevaluation.devaluationAverageData[index].header = changedTitle;
  }

  handleCollapse(divId) {
    if (this.isCollapsed == divId) {
      this.isCollapsed = -1;
    } else {
      this.isCollapsed = divId;
    }
  }

  deleteField(j, k, l) {

    if(this.selectedDevaluation.devaluationTableData[j].productData[k].periodData.length>1){
      this.selectedDevaluation.devaluationTableData[j].productData[k].periodData.splice(l, 1);
      let periodData = this.selectedDevaluation.devaluationTableData[j].productData[k].periodData;
      this.getAverage(periodData, j, k);
      this.getFiveYearAverage(periodData, j, k);
     }else{
      this.alertService.info("Must be atleast 1 period..");
     }

  }

  deleteProduct(j, k) {
    this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
    this.cofirmModalRef.content.title = "Delete a devaluation product";
    this.cofirmModalRef.content.message = 'Are you sure you want to delete ' + this.selectedDevaluation.devaluationTableData[j].productData[k].modelTitle + '? Doing so will remove the product with this devaluation.  This action cannot be undone.';
    this.cofirmModalRef.content.onClose
      .subscribe(result => {
        if (result === true) {
          this.selectedDevaluation.devaluationTableData[j].productData.splice(k, 1);
          let formLength = this.selectedDevaluation.devaluationTableData[j].productData;
          if (formLength.length) {
            this.alertService.success("Devaluation product deleted.");
            let periodData = this.selectedDevaluation.devaluationTableData[j].productData[k].periodData;
            this.getAverage(periodData, j, k);
            this.getFiveYearAverage(periodData, j, k);
          } else {
            let avgOfAllAnnualAvg = 0;
            this.alertService.success("Devaluation product deleted.");
            this.selectedDevaluation.devaluationAverageData[j].average = avgOfAllAnnualAvg.toFixed(2);
          }
          //this.onSave();
         
        }
      });
  }
  
  removeFieldValue(j, k, l, FieldName) {
    switch (FieldName) {
      case 'year':
        this.selectedDevaluation.devaluationTableData[j].productData[k].periodData[l].year = '';
        break;
      case 'usedPrice':
        this.selectedDevaluation.devaluationTableData[j].productData[k].periodData[l].usedPrice = '';
        break;
      case 'depreciation':
        this.selectedDevaluation.devaluationTableData[j].productData[k].periodData[l].depreciation = '';
        break;
      default:
        break;
    }
  }

  onCancel() {
    localStorage.removeItem('currencySymbol');
    if (this.toolsetId != null && this.toolsetId != "" && this.toolsetId != undefined) {
      this.route.navigate(["toolset/tco-calculator/" + this.toolsetId]);
    } else {
      this.route.navigate(["toolset/tco-calculator"]);
    }
  }

  onSave() {
    this.spinService.show();
    let orgId = this.organizationService.getCurrentOrganizationId();
    var data = {
      defaultCurrency: this.selectedDevaluation.defaultCurrency,
      averages: this.selectedDevaluation.devaluationAverageData,
      table_data: this.selectedDevaluation.devaluationTableData,
    };
    var obj = {
      devaluationData: JSON.stringify(data),
    };

    this.tcoCalculatorService
      .updateDevaluationAnalysis(obj, orgId)
      .subscribe((res: HttpResponse<any>) => {
        this.spinService.hide();
        if (res) {
          this.alertService.success("Devaluation has been saved successfully.");
          this.ngOnInit();
        }
      }, error => {
        this.alertService.error('Failed to create Devaluation: ' + error.error.message);
        this.spinService.hide();
      });
  }

  deleteCompetitor(index:number){
    this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
    this.cofirmModalRef.content.title = "Delete a devaluation compitetor";
    this.cofirmModalRef.content.message = 'Are you sure you want to delete ' + this.selectedDevaluation.devaluationTableData[index].productTitle+' Compitetor' + '? Doing so will remove the compitetor with this devaluation.  This action cannot be undone.';
    this.cofirmModalRef.content.onClose
      .subscribe(result => {
        if (result === true) {
          this.selectedDevaluation.devaluationTableData.splice(index,1);
          this.selectedDevaluation.devaluationAverageData.splice(index,1);
          //this.onSave();
          this.alertService.success("Devaluation compitetor deleted.");
        }
      });   
  }
 
}

