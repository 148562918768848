import { Component, OnInit } from '@angular/core';
import { AzureAuthholderService  } from '../../services/azureauthholder.service'
import { OrganizationService } from '../../services/organization.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    constructor(private azure: AzureAuthholderService,
        private router: Router,
        private orgService: OrganizationService,
        private userService: UserService) {
    }

    ngOnInit() {
        if (!this.azure.isAuthenticated()) {
            this.azure.login();
        }
        else {
            if (!this.orgService.isHasCurrentOrganization()) {
                
                this.userService.getCurrentUser().subscribe(user => {
                    this.orgService.getById(user.organizationId).subscribe(org => {
                        this.orgService.setCurrentOrganization(org);
                        if (user.role == 'SUPERADMIN' || user.role == 'ORGADMIN') {
                            this.router.navigate(['/applications']);
                        }
                        else {
                            this.router.navigate(['/salesquotes']);
                        }
                    });
                });
            }
            else {
                this.userService.getCurrentUser().subscribe(user => {
                    if (user.role == 'SUPERADMIN' || user.role == 'ORGADMIN') {
                        this.router.navigate(['/applications']);
                    }
                    else {
                        this.router.navigate(['/salesquotes']);
                    }
                });
            }
        }
    }

}
