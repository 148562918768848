import { Component, OnInit } from '@angular/core';
import { AzureAuthholderService  } from '../../services/azureauthholder.service'

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent implements OnInit {

  constructor(private azure: AzureAuthholderService ) {
    azure.silentTokenRefresh()
  }

  ngOnInit() {
  }

}
