import { CmdAsset } from './CmdAsset';
import { CmdSingleCardItem } from './cmdSingleCarditem';
import { CmdWidget } from './CmdWidget';

export class CmdSingleCard extends CmdWidget {
    image: CmdAsset;
    items: Array<CmdSingleCardItem>;
    textColor: string;
    useImage: boolean;
    useText: boolean;
    cardColor: string;
    cardBaseColor: string;
    opacityValue: number;
    constructor(id: string) {
        super(id);
        this.textColor = '#000000';
        this.type = "SingleCard"
        this.useImage = false;
        this.cardBaseColor = "#000000"
        this.cardColor = '#00000080'
        this.opacityValue = 0.5;
        this.items = new Array<CmdSingleCardItem>();
    }
}