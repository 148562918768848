import { Injectable } from '@angular/core';
import { FormUserService } from './form.user.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { BaseModelFormIO } from '../models/CmdFormModel';
import { EnvironmentSpecificService } from "./environmentspecific.service";
import { OrganizationService } from "./organization.service";
import { AzureAuthholderService } from './azureauthholder.service';

@Injectable()
export class FormService {

    private headers: HttpHeaders;

    constructor(
        private azureholder: AzureAuthholderService,
        private http: HttpClient,
        private userService: FormUserService,
        private envService: EnvironmentSpecificService,
        private organizationService: OrganizationService) {

        let httpParams = new HttpHeaders();
        httpParams = httpParams.append('Content-Type', 'application/json');
        this.headers = httpParams;
    }

    private getAllForms(): Observable<BaseModelFormIO[]> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        var params: HttpParams = new HttpParams();
        const requestOptions = {params: params, headers: headers };
        params.append("organizationId", this.organizationService.getCurrentOrganizationId());
        params.append("limit", "10000");
        return this.http
            .get<BaseModelFormIO[]>(this.envService.envSpecific.baseServerUrl + '/api/form', requestOptions);
    }

    public getFormsByUser(): Promise<BaseModelFormIO[]> {
        return new Promise((resolve, reject) => {
            this.userService.getUser()
                .then(user => {
                    this.getAllForms()
                        .pipe(take(1))
                        .subscribe(forms => {
                            resolve(forms.filter(form => {
                                return form['owner'] === user['_id'];
                            }));
                        });
                })
                .catch(error => reject(error));
        });
    }

    public getFormById(formId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        var params: HttpParams = new HttpParams();
        const requestOptions = {params: params, headers: headers };
        params.append("organizationId", this.organizationService.getCurrentOrganizationId());
        return this.http
            .get<any>(this.envService.envSpecific.baseServerUrl + '/api/form/' + formId + "?organizationId=" + this.organizationService.getCurrentOrganizationId(), requestOptions);
    }

    public saveForm(form: BaseModelFormIO): Observable<BaseModelFormIO> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        if (form._id) {
            return this.http
                .put<BaseModelFormIO>(this.envService.envSpecific.baseServerUrl + '/api/form/' + form._id + "?organizationId=" + this.organizationService.getCurrentOrganizationId(), JSON.stringify(form), requestOptions);

        } else {
            return this.http
                .post<BaseModelFormIO>(this.envService.envSpecific.baseServerUrl + '/api/form?organizationId=' + this.organizationService.getCurrentOrganizationId(), JSON.stringify(form), requestOptions);
        }
    }

    public getFormSubmission(form_path: string): Observable<BaseModelFormIO[]> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http
            .get<BaseModelFormIO[]>(this.envService.envSpecific.baseServerUrl + '/api/form/' + form_path + '/submission?limit=' + 10000 + '&organizationId=' + this.organizationService.getCurrentOrganizationId(), requestOptions);
    }

    public deleteForm(formId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http
            .delete(this.envService.envSpecific.baseServerUrl + '/api/form/' + formId + "?organizationId=" + this.organizationService.getCurrentOrganizationId(), requestOptions);
    }
}
