import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CmdOrganization } from '../../models';
import { OrganizationService } from '../../services/organization.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { SpinService } from '../../services/spin.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { OrganizationEditComponent } from './organization-edit/organization-edit.component';
import { finalize } from 'rxjs/operators';


@Component({
    selector: 'app-organizations',
    templateUrl: './organizations.component.html',
    styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {

    private modalTitle: string;
    organizations: Array<any> = [];
    modalRef: BsModalRef;
    cofirmModalRef: BsModalRef;
    constructor(private orgService: OrganizationService,
        private router: Router,
        private modalService: BsModalService,
        private alertService: StickyPopUpService,
        private spinService: SpinService) {
    }

    ngOnInit() {
        this.loadOrganizations();
        this.orgService.currentOrgModalTitle.subscribe(modalTitle => this.modalTitle = modalTitle);
    }

    loadOrganizations() {
        this.spinService.show();
        this.orgService.getAllOrganizations()
            .pipe(
                finalize(
                    () => this.spinService.hide()
                    )
                )
            .subscribe(orgs => this.organizations = orgs);
    }

    doEdit(org: CmdOrganization) {
        this.orgService.checkOrgAction(false);
        this.orgService.changeOrgModalTitle('Edit an Organisation');
        var editOrg: CmdOrganization = new CmdOrganization();
        editOrg.name = org.name;
        editOrg.address = org.address;
        editOrg.city = org.city;
        editOrg.state = org.state;
        editOrg.postalCode = org.postalCode;
        editOrg.phone = org.phone;
        editOrg.id = org.id;
        editOrg.logo = org.logo;
        editOrg.whitelabel = org.whitelabel;
        editOrg.infoAtEmail = org.infoAtEmail;
        editOrg.rentFourWeekMultiplier = org.rentFourWeekMultiplier;
        editOrg.rentWeekMultiplier = org.rentWeekMultiplier;
        editOrg.orgKey = org.orgKey;

        this.router.navigate(['/organizations', editOrg.id]);
        this.orgService.setCurrentOrganization(editOrg);
    }
    delete(org: CmdOrganization) {
        if (JSON.parse(localStorage.getItem('org')).id === org.id) {
            this.alertService.error('This market cannot be deleted as it is currently in use');
        }
        else {
            this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
            this.cofirmModalRef.content.title = "Delete an Market";
            this.cofirmModalRef.content.message = 'Are you sure you want to delete ' + org.name + '?  This will remove the market and all of its assets, products, users, applications, scenes, and toolsets from the system and cannot be undone.';
            this.cofirmModalRef.content.onClose
                .subscribe(result => {
                    
                    if (result === true) {
                        this.spinService.show();
                        this.orgService.delete(org.id).subscribe(() => {
                            this.loadOrganizations();
                            this.spinService.hide();
                            this.alertService.success('Market has been deleted');
                        }, error => {
                            this.spinService.hide();
                            var errorMessage = error.error.message;
                            this.alertService.error('Failed to delete market: ' + errorMessage);
                        });
                    }
                });
        }

    }

    addOrganization() {
        this.orgService.checkOrgAction(true);
        this.orgService.changeOrgModalTitle('Edit an Organisation');
        var newOrg: CmdOrganization = new CmdOrganization();
        this.router.navigate(['organizations', 0]);
        this.orgService.setCurrentOrganization(newOrg);
    }

    toggleWhitelabel(org: CmdOrganization, enable: boolean) {
        this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = "Change white label for an Market";
        this.cofirmModalRef.content.message = ('Are you sure you want to ' + (enable ? 'enable' : 'disable') + ' white label support for ' + org.name + '?');
        this.cofirmModalRef.content.onClose
            .subscribe(result => {
                this.spinService.hide();
                if (result === true) {
                    this.orgService.toggleWhitelabel(org.id, enable).subscribe(() => {
                        this.loadOrganizations();
                        this.orgService.refreshOrg.next();
                        this.alertService.success('Market has been updated');
                    }, error => {
                        var errorMessage = error.error.message;
                        this.alertService.error('Failed to update market: ' + errorMessage);
                    });
                }
            });
    }
}
