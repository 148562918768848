import { CmdAsset, CmdWidget, CmdSlide } from './';
export class CmdSlideDeck extends CmdWidget {
    
    canDraw:boolean = true;
    requestInformation:boolean;
    secure:boolean;
    hasAttachment:boolean;
    fileName:string;
    fileLabel:string;
    slides:Array<CmdSlide> = [];

    validate():boolean{
        if(!super.validate()){
            return false;
        }
        this.slides.forEach(slide => {
            if(!slide.validate()){
                return false;
            }
        });
        return true;
    }
    
}