import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: any[]): any {
    // return null;
    // if(!items) return [];
    // if(!searchText) return items;
    // searchText = searchText.toLowerCase();
    // return items.filter( it => {
    // return it.full_name.toLowerCase().includes(searchText) || it.address.toLowerCase().includes(searchText) || it.interests.toLowerCase().includes(searchText);
    // });
  }

}
