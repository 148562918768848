import { CmdAsset } from '.';
import { Page } from './page';


export class ProductModel {

    id: string = null;
    organizationId: string;
    sku: string;
    itemNumber: string;
    title: string;
    description: string;

    unitName: string;
    costPerUnit: number;
    consumptionPerUnitUsed: number;
    numberOfUnitsUsed: number;
    measureOfUsage: string;

    status: string;
    created: number;
    updated: number;

    properties: any;
    tags: Array<string> = [];

    thumbnail: CmdAsset;
    assets: Array<CmdAsset> = [];

    unitPrice: number = 0;
    rentPrice: number = 0; // pricePerHour
    pricePerDay:number;
    pricePerWeek:number;
    pricePerMonth:number;
    typeOfUsing: string = 'UNAVAILABLE';
    
    competitorsCount?:number;
    selected?:boolean;
}

export class ProductCompetitor {

    id: string;
    created: number;
    updated: number;
    name: string;
    description: string;
    priceUnit: number;
    unitConsumption: number;
    product: string;
}

export class SearchCompetitorModel {
    contents: Array<ProductCompetitor> = [];
    page: number;
    pageSize: number;
    sortDirection: string;
    sortProperties: string;
    totalItems: number;


    constructor() {
        this.pageSize = 10;
        this.page = 1;
    }
}
