import { CmdMenu, CmdScene, CmdMenuItem } from '../../models';
import { CmdEmailTemplate } from '../../models/CmdEmailTemplate';
import { OrganizationService } from '../../services/organization.service';
import { WidgetService } from '../../services/widget.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { WidgetEditor } from '../widgeteditor';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { concat } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent extends WidgetEditor implements OnInit {
    widgetData: CmdMenu;
    private confirmModalRef: BsModalRef;
    emailTemplates: Array<CmdEmailTemplate>;
    private widgetMenuValidation: Array<any>;
    private enableSaveButton = false;
    private widgetCount = 0;
    @ViewChild('menuWidgetForm', { static: false }) menuWidgetForm: NgForm;

    constructor(private modalService: BsModalService, private widgetService: WidgetService, private orgService: OrganizationService) {
        super();
        this.orgService.getEmailTemplates().subscribe(temps => { this.emailTemplates = temps; });
    }

    ngOnInit() {
    }
    @Input() set widget(data: CmdMenu) {
        this.widgetData = data;
        if (!this.widgetData.items || !this.widgetData.items.length) {
            this.addEmptyRow();
        }
    }
    removeItem(item: CmdMenuItem) {
        const self = this;
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this menu item?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    self.widgetData.items.splice(this.widgetData.items.indexOf(item), 1);
                    const index = -1;
                    this.notifyChange();
                }
            });
    }

    addEmptyRow() {
        this.widgetData.items.push(new CmdMenuItem(this.widgetData.id + '-' + this.widgetCount));
        this.widgetCount++;
    }
}
