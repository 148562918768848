import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { SearchModel } from "../models/SearchModel";
import { EnvironmentSpecificService } from "./environmentspecific.service";
import { AzureAuthholderService } from "../services/azureauthholder.service";
@Injectable()
export class QuoteBuilderService {
    public isProductCreated = new Subject<any>();

    constructor(
        private azureholder: AzureAuthholderService,
        private httpClient: HttpClient,
        private envService: EnvironmentSpecificService
    ) { }
    getQouteList(orgId: string, pageNo, totalSize, fillter: SearchModel = new SearchModel()): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        //const requestOptions = {headers: headers };
        let httpParams = new HttpParams().set('id', "1");
        httpParams = httpParams.append('sortBy', fillter.page.sortBy.toString());
        httpParams = httpParams.append('sortOrder', fillter.page.sortOrder.toString());
        httpParams = httpParams.append('pageSize', fillter.page.pageSize.toString());
        httpParams = httpParams.append('page', fillter.page.page.toString());
        return this.httpClient
            .get<any>(this.envService.envSpecific.baseServerUrl + '/api/quotebuilder/' + orgId + '/list?sortBy=created&sortOrder=DESC&pageSize=' + totalSize + '&page=' + pageNo,{});
    }
    postUploadCsv(orgId: string, body, fillter: SearchModel = new SearchModel()): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let httpParams = new HttpParams().set('id', "1");
        const requestOptions = {headers: headers };
        return this.httpClient
            .post<any>(this.envService.envSpecific.baseServerUrl + '/api/quotebuilder/' + orgId + '/uploadCsv', body, requestOptions);
    }
    deleteQuoteList(orgId: string, invId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .delete<any>(this.envService.envSpecific.baseServerUrl + '/api/quotebuilder/' + orgId + '/' + invId, requestOptions);
    }
    postDuplicate(orgId: string, qbId: string, title: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        const body = {
            title
        }
        return this.httpClient
            .post<any>(this.envService.envSpecific.baseServerUrl + '/api/quotebuilder/' + orgId + '/create/' + qbId, body, requestOptions);
    }
    setIsCreated(value: boolean) {
        this.isProductCreated.next(value);
    }
    getIsCreated() {
        return this.isProductCreated.asObservable();
    }


    getTemplate(): Observable<any> {
        
        return this.httpClient.get(this.envService.envSpecific.baseServerUrl + '/api/quotebuilder/csvtemplate', {
            headers: new HttpHeaders({}),
            responseType: 'text'
        });

    }
}

