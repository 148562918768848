import { Injectable } from "@angular/core";

@Injectable()
export class CacheService {

    public static SCENE: string = "SCENE";
    public static COLLECTION: string = "COLLECTION";
    public static EMAIL_TEMPLATES: string = "EMAIL_TEMPLATES";
    public static TOOLSETS: string = "TOOLSETS";

    constructor() {
        setInterval(() => {
            this.start();
        }, 60000);
    }

    private cache: any = {};

    private start(): void {
        this.cache = {
            "COLLECTION": {},
            "EMAIL_TEMPLATES": {},
        };
    }

    public isInCache(type: string, key: string): boolean {
        return this.cache[type + key] != undefined;
    }

    public get(type: string, key: string): any {
        return this.cache[type + key];
    }

    public set(type: string, key: string, data: any): void {
        this.cache[type + key] = data;
    }

    public clear(type: string): void {
        this.cache[type] = {};
    }
    public clearAll(): void {
        this.cache = {};
    }
}