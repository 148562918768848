import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EditQuoteBuilderService } from '../../../../services/editquotebuilder';
declare var $: any;
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { AddSendInfoColComponent } from '../add-send-info-col/add-send-info-col.component';
@Component({
  selector: 'app-send-quote-info-modal',
  templateUrl: './send-quote-info-modal.component.html',
  styleUrls: ['./send-quote-info-modal.component.css']
})
export class SendQuoteInfoModalComponent implements OnInit {
  public nameBool: boolean = false;
  public name: string = ""
  public values: any = [];
  public quotationDataList: any=[];
  public dataList: { code: number; name: string; realName: string; status: boolean; }[];
  public fieldData: any = [];
  public projectValues: any = [];
  public projectDataList:any=[];
  public projctFieldData: any = [];
  selectedType: string;
  fieldIndex: number;
  addcolModalRef: BsModalRef;
  public orgId:string;
  public quoteId:string;
  constructor(private apiService: EditQuoteBuilderService,private modalService: BsModalService, private bsModalRef: BsModalRef,
    private stickyPopUp: StickyPopUpService) { }

  ngOnInit() {
    this.getHeaders();
  }
  addvalue() {
    if (!this.quotationDataList.length) {
      this.stickyPopUp.error("No more columns left");
      return;
    }
    this.values.push({ name: "", realName: "", status: false, code: "" });
  }
  addProjectValue() {
    if (!this.projectDataList.length) {
      this.stickyPopUp.error("No more columns left");
      return;
    }
    this.projectValues.push({ name: "", realName: "", status: false, code: "" });
  }
  onCancel() {
    this.bsModalRef.hide();
  }
  public openAddItemMdl(type) {
    this.dataList = [];
    if (type == "project") {
      this.dataList = this.projectDataList;
      this.addcolModalRef = this.modalService.show(AddSendInfoColComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { dataList: this.dataList }, class: 'add-send-info-col-modal' });
      this.addcolModalRef.content.event.subscribe(data => {
        this.projectDataList = data.dataList;
        let index = this.projectDataList.findIndex(x => x.code == data.code);
        this.projectValues.push({ name: this.projectDataList[index].name, value: "", realName: this.projectDataList[index].name, code: this.projectDataList[index].code });
        this.projectDataList.splice(index, 1);
      });
    } else {
      this.dataList = this.quotationDataList;
      this.addcolModalRef = this.modalService.show(AddSendInfoColComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { dataList: this.dataList }, class: 'add-send-info-col-modal' });
      this.addcolModalRef.content.event.subscribe(data => {
        this.quotationDataList = data.dataList;
        let index = this.quotationDataList.findIndex(x => x.code == data.code);
        this.values.push({ name: this.quotationDataList[index].name, value: "", realName: this.quotationDataList[index].name, code: this.quotationDataList[index].code });
        this.quotationDataList.splice(index, 1);
      });
    }
  }
  public openSendInfoMdl() {
    document.getElementById("addItem").style.display = 'none';
    document.getElementById("quoteBuilder1").style.display = 'block';
  }

  public gotName() {
    this.nameBool = (this.name == "") ? true : false;
  }
  public removevalue(i) {
    // this.quotationDataList.push({ code: this.values[i].code, name: this.values[i].name, realName: this.values[i].realName, status: false })
    this.values.splice(i, 1);
  }
  public removeProjectValue(i) {
    // this.projectDataList.push({ code: this.projectValues[i].code, name: this.projectValues[i].name, realName: this.projectValues[i].realName, status: false })
    this.projectValues.splice(i, 1);
  }
  addProject(){
    this.projectValues.push({ code: this.projectValues.length, name: "", realName: "", status: true,mandate:"false" });
  }
  public saveProjectChange(index) {
    // if (this.values[index].name == "") {
    //   this.stickyPopUp.error("No more columns left");
    //   return;
    // }
    this.projectValues[index].status = false;
  }
  public editProjectColumn(index) {
    this.projectValues[index].status = true;
  }
  addQuotation(){
    this.values.push({ code: this.values.length, name: "", realName: "", status: true,mandate:"false" });
  }
  public saveQuotationChange(index) {
    // if (this.values[index].name == "") {
    //   this.stickyPopUp.error("No more columns left");
    //   return;
    // }
    this.values[index].status = false;
  }
  public editQuotationColumn(index) {
    this.values[index].status = true;
  }
  public getHeaders(){
    this.apiService.getMethod("/api/quotebuilder/"+ this.orgId +"/"+ this.quoteId +"/templateHeader").subscribe(
      (data:any)=>{
        let index=1;
        data.quoteBuilderTemplateHeader.projectInfo.forEach(element => {
          this.projectValues.push({ code: index, name: element.id, realName: element.id, status: false,mandate:element.mandatory });
          ++index;
        });
        data.quoteBuilderTemplateHeader.customerInfo.forEach(element => {
          this.values.push({ code: index, name: element.id, realName: element.id, status: false,mandate:element.mandatory });
          ++index;
        });


      },
      err=>{
        console.log(err);

      }
    )

  }
  saveData(){
    let projectArr=[]
    let customerArr=[]
    this.values.forEach(element => {
      customerArr.push({
        "id": element.name,
        "value": "",
        "mandatory": element.mandate
    },)
    });
    this.projectValues.forEach(element => {
      projectArr.push({
        "id": element.name,
        "value": "",
        "mandatory": element.mandate
    },)
    });
    let body={
      quoteBuilderTemplateHeader:{
        projectInfo:projectArr,
        customerInfo:customerArr
      }
    }
    this.apiService.putMethod("/api/quotebuilder/"+ this.orgId +"/"+ this.quoteId +"/templateHeader",body).subscribe(
      data=>{
        this.stickyPopUp.success("Updated Successfully");
        this.bsModalRef.hide();

      },
      err=>{
        this.stickyPopUp.error("Some problem occured");
      }
    )
  }
}
