import { CmdAsset } from './CmdAsset';
import { CmdWidget } from './CmdWidget';

export class CmdHorizontalImageSliderItem extends CmdWidget {
    image: CmdAsset;
    label: string;
    thumbnail: CmdAsset;
    asset: CmdAsset;
    constructor(id: string) {
        super(id);
        this.type = "HorizontalImageSliderItem";
    }

    validate(): boolean {
        if (!super.validate()) {
            return false;
        }

        return this.image != undefined;
    }
}