import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../../services/organization.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { Subject } from 'rxjs';
import { EditQuoteBuilderService } from '../../../../services/editquotebuilder';
import { ToolsetsService } from '../../../../services/toolsets.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms'
import { InputValidationService } from '../../../../services/inputvalidation.service';
import { ToastrService } from 'ngx-toastr';



export class DynamicGrid {
  ccEmails: string;
}

@Component({
  selector: 'app-add-emailccaddresses-quote-builder',
  templateUrl: './add-emailccaddresses-quote-builder.component.html',
  styleUrls: ['./add-emailccaddresses-quote-builder.component.css']
})
export class AddEmailccaddressesQuoteBuilderComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  public eventFrNew: EventEmitter<any> = new EventEmitter();

  buttonIsDisabled: boolean = false;
  model: any = {};
  public skuBool: boolean = false;
  onClose = new Subject<any>();
  public apiType: string;
  quoteArray=[];
  quoteId: string;
  public productData: any;
  dynamicArray: Array<DynamicGrid> = [];
  newDynamic: any = {};
  ccEmails: string;

  constructor(private bsModalRef: BsModalRef,
    private fb: FormBuilder,
    public organizationService: OrganizationService,
    public toolsetsService: ToolsetsService,
    private apiService: EditQuoteBuilderService,
    private stickyPopUp: StickyPopUpService,
    private validationService: InputValidationService,
    private toastr: ToastrService
  ) {
  }

  addRow(index) {
    this.newDynamic = { ccEmails: "" };
    this.dynamicArray.push(this.newDynamic);
  }

  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      this.toastr.error("Can't delete the row when there is only one row", 'Warning');
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      this.toastr.warning('Row deleted successfully', 'Delete row');
      return true;
    }
  }
  fetchQuoteData(){
    if(this.quoteArray){
      this.quoteArray.forEach(item=>{
        this.newDynamic = { ccEmails: item };
      this.dynamicArray.push(this.newDynamic);
      })
    }
  }
  ngOnInit() {
    if(this.apiType=='edit'){
      this.fetchQuoteData();
    }else{
      this.newDynamic = { ccEmails: "" };
      this.dynamicArray.push(this.newDynamic);

    }
    // this.apiType == "new" || this.apiType == "add"
    this.onClose = new Subject();
  }

  
  public gotskuval(val) {
    this.skuBool = val == "" ? true : false;
  }

  addCcEmailAddresses(type?:string,value?:any) {
    let body = { "ccEmails": [] }
    for (let i = 0; i < this.dynamicArray.length; i++) {
      body.ccEmails.push(this.dynamicArray[i].ccEmails)
    }
    const org = this.organizationService.getCurrentOrganization();
    if (this.dynamicArray[0].ccEmails == "") {
      this.stickyPopUp.error('Please enter email addresses(cc).');
      this.skuBool = true;
      return;
    }
    
    this.skuBool = false;

    if (this.apiType == "edit") {
      this.apiService.putMethod("api/quotebuilder/" + org.id + "/updateCcEmail/" + this.quoteId, body).subscribe(
        data => {
          this.stickyPopUp.success("Product updated successfully");
          this.event.emit({ message: "updated" });
          this.bsModalRef.hide();
        },
        err => {
          this.stickyPopUp.error(err.error.message);
          console.log(err);
        }
      )
    } else if (this.apiType == "add") {
      this.apiService.putMethod("api/quotebuilder/" + org.id + "/updateCcEmail/" + this.quoteId, body).subscribe(
        data => {
          // this.stickyPopUp.success("Product added successfully");
          this.toastr.success('New row added successfully', 'New Row');
          this.event.emit({ message: "updated" });
          this.bsModalRef.hide();
        },
        err => {
          this.stickyPopUp.error(err.error.message);
          console.log(err);
        }
        
      )

    }
    else {
      let body1 = {
        "quoteBuilderProductsTo": [body]
      }
      this.apiService.postMethod("/api/quotebuilder/" + org.id + "/create", body1).subscribe(
        data => {
          this.stickyPopUp.success("Product created successfully");
          this.bsModalRef.hide();
          this.eventFrNew.emit({ message: "added" });
        },
        err => {
          this.stickyPopUp.error(err.error.message);
          console.log(err);
        }
      )
    }
  }

  onCancel() {
    this.bsModalRef.hide();
  }
}
