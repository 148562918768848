import { log } from 'util';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TagModel } from '../../models/TagModel';

@Component({
    selector: 'emails-list-input',
    templateUrl: 'emails-list-input.component.html',
    styleUrls: ['emails-list-input.component.scss']
})
export class EmailsListInputComponent {

    public emails:Array<TagModel> = [];


    @Output()
    handleChange:EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    @Input() set inputEmails(data: Array<string>) {
        if(data)
            this.emails = data.map(i => new TagModel(i));
    }

    constructor() {
        
    }


    public validators = [ this.must_be_email ];
    public errorMessages = {
        'must_be_email': 'Enter valid email adress!'
    };

    private must_be_email(control: FormControl) {
        
        var EMAIL_REGEXP = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
            return { "must_be_email": true };
        }

        return null;
    }

    public onUpdate(e) {
        this.handleChange.emit(this.emails.map(i => i.value));        
    }
}