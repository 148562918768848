import { Component, OnInit } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { SearchModel } from '../../../models/SearchModel';
import { ToolsetsService } from '../../../services/toolsets.service';
import { OrganizationService } from '../../../services/organization.service';
import { BaseActionTypeSelectorComponent } from '../baseactiontypeselector';

@Component({
    selector: 'app-costcalculator',
    templateUrl: './costcalculator.component.html',
    styleUrls: ['./costcalculator.component.scss']
})
export class CostcalculatorSelectorComponent extends BaseActionTypeSelectorComponent implements OnInit {

    constructor(public widgetService: WidgetService,
        private toolsetsService: ToolsetsService,
        private orgService: OrganizationService) {
        super(widgetService);
        this.orgId = this.orgService.getCurrentOrganizationId();
    }
    ngOnInit() {
        let toolsetSearch: SearchModel = new SearchModel();
        toolsetSearch.page.sortBy = 'title';
        toolsetSearch.page.sortOrder = 'ASC';
        this.toolsetsService.getToolsetsForOrganization(this.orgId, toolsetSearch, 'COST_CALCULATOR').then(calculators => {
            this.rows = calculators.contents;
        });
    }
}
