import { CmdWebView, CmdScene } from '../../models';
import { WidgetEditor } from '../widgeteditor';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-webview',
  templateUrl: './webview.component.html',
  styleUrls: ['./webview.component.css']
})
export class WebviewComponent extends WidgetEditor implements OnInit {
    widgetData: CmdWebView;
    constructor() {
        super();
    }

    ngOnInit() {
    }
    @Input() set widget(data: CmdWebView) {
        this.widgetData = data;
    }
    
    @Input() set scenes(data: Array<CmdScene>) {
        this.sceneList = data;
    }
}