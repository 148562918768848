import { Component, OnInit } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { BaseActionTypeSelectorComponent } from '../baseactiontypeselector';
import { SceneService } from '../../../services/scene.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationSelectorComponent extends BaseActionTypeSelectorComponent implements OnInit {

    constructor(public widgetService: WidgetService,
        private sceneService: SceneService) {
        super(widgetService);

    }
    ngOnInit() {
        this.sceneService.getForApplication(this.appId).then(list => {
            this.rows = list;
        });
    }
    setSlugTitle(type, slug) {
        let theVal = this.rows.filter(s => s.slug == this.widgetData.actionData.slug)[0];
        this.widgetData.actionData.displayTitle = theVal.title;
    }

}