import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { Subject } from 'rxjs';
import { OrganizationService } from '../../../services/organization.service';
import { AppSettings } from '../../../app-settings';
import { CmdUser } from '../../../models';
import { ApplicationService } from '../../../services/application.service';
import { AssetsService } from '../../../services/assets.service';
import { InputValidationService } from '../../../services/inputvalidation.service';
import { SpinService } from '../../../services/spin.service';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit, OnDestroy {
    @ViewChild("fileInput", { static: false }) fileInput;
    /* Required to unSubscribe from userService Service */
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    currentUser: CmdUser;
    selectedUser: CmdUser;
    selectedUserRole: string = null;
    authorizedApps: Array<any>;
    allApplications: Array<any>;
    selectedApps: Array<string>;
    private userRoles: any = AppSettings.global.userRoles;
    private enableSaveButton: boolean = false;
    public organization: any = {};
    public modalRef: BsModalRef;
    public mediaModalRef: BsModalRef;
    private changePasswordMessage: string = null;
    public validationErrors: Array<any> = [];
    public hasChanges: boolean = false;
    buttonIsDisabled: boolean = true;
    invalidFirstName: boolean = true;
    invalidLastName: boolean = true;
    userOrgnizationList: Array<any> = []

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private orgService: OrganizationService,
        private modalService: BsModalService,
        private validationService: InputValidationService,
        private appService: ApplicationService,
        private alertService: StickyPopUpService,
        private spinService: SpinService,
        private assetService: AssetsService
    ) { }

    ngOnInit() {
        this.spinService.show();
        setTimeout(() => {
        this.userService.getCurrentUser().pipe(finalize(() => {
            this.spinService.hide();
        })).subscribe(usr => this.currentUser = usr);
    }, 3000)
        let userId = this.route.snapshot.params['userId'];
        if (!userId) {
            this.router.navigate(['/users']);
        }
        else if (userId == 'NEW') {
            this.setupForNewUser();
        }
        else {
            this.getUserDetails(userId);
            // disable email field once it is created successfully            
            this.buttonIsDisabled = false;
        }
      
    }

    setupForNewUser() {
        this.selectedUser = new CmdUser();
        this.selectedUser.organizationId = this.orgService.getCurrentOrganizationId();
        this.selectedUser.role = "USER";
        this.orgService.getById(this.orgService.getCurrentOrganizationId()).subscribe(org => {
            this.organization = org;
            this.appService.getOrgApplications(org.id).subscribe(apps => this.allApplications = apps);
        });
    }

    getUserDetails(userId) {
        this.userService.getById(userId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.selectedUser = data;
                this.selectedUserRole = data.role;
                this.orgService.getById(this.selectedUser.organizationId).subscribe(org => {
                    this.organization = org;
                });
                this.appService.getUserApplications(userId,this.orgService.getCurrentOrganizationId()).subscribe(apps => {
                    this.userOrgnizationList = apps;
                    this.authorizedApps = apps
                    this.appService.getOrgApplications(this.selectedUser.organizationId).subscribe(all => {
                        this.allApplications = all;
                        this.allApplications.forEach(allApp => {
                            var exists: boolean = false;
                            this.authorizedApps.forEach(authorizedApp => {
                                if (authorizedApp.id == allApp.id) {
                                    exists = true;
                                    allApp.selected = true;
                                }
                            });
                            if (!exists) {
                                allApp.selected = false;
                            }
                        });
                    });
                });

            },
                error => {
                    //Error while fetching user details, Handle error here.
                });
    }

    save() {
        if (this.selectedUser.id) {
            this.updateUserDetails();
        }
        else {
            this.createNewUser();
        }
    }

    createNewUser() {
        var selected = Array<string>();
        this.allApplications.forEach(app => {
            if (app.selected) {
                selected.push(app.id);
            }
        });
        this.selectedUser.appIds = selected;
        this.spinService.show();
        var obs = this.userService.create(this.selectedUser);
        obs.subscribe(user => {
            this.spinService.hide();
            this.selectedUser = user;
            this.hasChanges = false;
            this.router.navigate(['/users']);
            this.alertService.success("User created.");
        }, error => {
            this.spinService.hide();
            var err = JSON.parse(error.error);
            this.alertService.error("Failed to create user: " + err.message);
        }, () => {
            this.spinService.hide();
        });
    }

    updateUserDetails() {
        this.enableSaveButton = false;
        var selected: Array<string> = [];
        if (this.userOrgnizationList) {
            this.userOrgnizationList.forEach(app => {
                if (app.isAssigned) {
                    selected.push(app.id);
                }
            });
            this.selectedUser.appIds = selected;
        }
        this.spinService.show();
        this.userService.updateUser(this.selectedUser, this.orgService.getCurrentOrganizationId())
            .pipe(finalize(() => {
                this.spinService.hide();
            }))
            .subscribe(data => {
                this.alertService.success("User has been updated.");
                this.selectedUser = data;
                this.hasChanges = false;
                this.router.navigate(['/users', data.id]);
            },
                error => {
                    this.spinService.hide();
                    var err = JSON.parse(error.error);
                    this.alertService.error("Failed to update user: " + err.message);
                });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    /* Confirmation pop up before archiving asset*/
    public showPermissionModal(template: TemplateRef<any>) {
        if (this.selectedUser.role == 'USER' && this.selectedUserRole == 'ADMIN') {
            this.modalRef = this.modalService.show(template);
            this.modalRef.content.onClose
                .takeUntil(this.ngUnsubscribe)
                .subscribe(result => {
                    if (!result) {
                        this.selectedUser.role = this.selectedUserRole;
                    }
                });
        }
    }

    selectProfileImage() {
        let fileBrowser = this.fileInput.nativeElement;
        if (fileBrowser.files) {
            this.spinService.show();
            var uploadFiles = [];
            var uploadObservables = [];
            this.assetService.uploadProfilePicture(this.orgService.getCurrentOrganizationId(), fileBrowser.files[0])
                .pipe(finalize(() => {
                    this.spinService.hide()
                }))
                .subscribe(results => {
                    this.selectedUser.profilePicture = results;
                    this.notifyChanges();
                }, error => {
                    this.spinService.hide();
                    this.alertService.error("Failed to upload files: " + error.error.message);
                });
        }
    }

    notifyChanges() {
        this.hasChanges = true;
    }

    validateFirstName(firstName) {
        this.invalidFirstName = this.validationService.validateEmptyString(firstName);
        this.validateInputs();
    }

    validateLastName(lastName) {
        this.invalidLastName = this.validationService.validateEmptyString(lastName);
        this.validateInputs();
    }

    validateInputs() {
        if (this.invalidFirstName && this.invalidLastName) {
            this.buttonIsDisabled = false;
        }
        else {
            this.buttonIsDisabled = true;
        }
    }

    validatePasswords(password, passwordConfirm) {
        if (this.selectedUser.passwordConfirm != this.selectedUser.password) {
            this.buttonIsDisabled = true;
        }
        else {
            this.buttonIsDisabled = false;
        }
    }
}
