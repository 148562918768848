import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quetebuilder-report',
  templateUrl: './quetebuilder-report.component.html',
  styleUrls: ['./quetebuilder-report.component.css']
})
export class QuetebuilderReportComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
