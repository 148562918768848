import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
    constructor() {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if there is a token saved in localStorage
        const token: string = localStorage.getItem('access_token');
        if (token === null) {
            // Pass on  the original request.
            return next.handle(req);
        } else {
            const authHeader = 'Bearer ' + token;
            // Clone the request to add the new header.
            const authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
            // Pass on the cloned request instead of the original request.
            return next.handle(authReq);
        }
    }
}
