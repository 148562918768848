import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AzureAuthholderService } from '../services/azureauthholder.service'


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private azure: AzureAuthholderService) { }

    canActivate(): boolean {
        if (this.azure.isAuthenticated()) {
            return true;
        }
        else {
            this.router.navigate(['/']);
            return false;
        }
    }
}