import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { AzureAuthholderService } from "./services/azureauthholder.service";
import { SpinService } from "./services/spin.service";
import { UserService } from "./services/user.service";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { OrganizationService } from "./services/organization.service";
import { Subject } from "rxjs";
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { takeUntil } from "rxjs/operators";
import { EventMessage, EventType } from "@azure/msal-browser";

declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "Microsoft identity platform";
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  currentUser: any = {
    role: "NONE",
  };

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private azureAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public azure: AzureAuthholderService,
    private userService: UserService,
    private spinService: SpinService,
    private orgService: OrganizationService,
    public router: Router
  ) {
    let navEndEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      try {
        gtag("config", "UA-110388946-8", {
          page_path: event.urlAfterRedirects,
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  ngOnInit(){
    this.spinService.show();
    if(localStorage.getItem("loginresponse")){
      this.azure.userProfile$.subscribe((nextValue) => {
        this.userService.getCurrentUser().subscribe(user => {
          if (user) {
            this.spinService.hide();
            this.currentUser = user;
            this.orgService.setCurrentUser(this.currentUser.id);
          }
        });
       });
       this.spinService.hide();
    }
    
    if (!localStorage.getItem("loginresponse")) {
      if (this.msalGuardConfig.authRequest) {
        this.azureAuthService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
        this.spinService.hide();
      } else {
        this.spinService.hide();
        this.azureAuthService.loginRedirect();
      }
    }
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: any) => {
        this.spinService.hide();
        localStorage.setItem("loginresponse", JSON.stringify(result));
        this.azure.getUserFromAzureLoginResponse();
        const payload = result.payload as AuthenticationResult;
        this.azureAuthService.instance.setActiveAccount(payload.account);
      });
      
  }

  setLoginDisplay() {
    this.loginDisplay = this.azureAuthService.instance.getAllAccounts().length > 0;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.azureAuthService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.azureAuthService.instance.setActiveAccount(response.account);
          });
      } else {
        this.azureAuthService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.azureAuthService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.azureAuthService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.azureAuthService.loginRedirect();
      }
    }
  }

  private logout() {
    this.azure.logout();
    this.azure.login();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
