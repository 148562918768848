import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { WidgetEditor } from '../widgeteditor';
import { OrganizationService } from '../../services/organization.service';
import { WidgetService } from '../../services/widget.service';
import { CmdOrganization } from '../../models';
import { FormApiService } from '../../services/form.api.service';
import { SearchModel } from '../../models/SearchModel';
import { take } from 'rxjs/operators';
import { CmdFormIOModel } from '../../models/CmdFormIOModel';
import { CmdFormModel } from '../../models/CmdFormModel';
import { GetFormioConfig } from '../../../formio-config';


@Component({
  selector: 'app-form-widget',
  templateUrl: './formWidget.component.html',
  styleUrls: ['./formWidget.component.scss']
})
export class FormWidgetComponent extends WidgetEditor implements OnInit {

  widgetData: CmdFormIOModel;
  formsTemplates: Array<CmdFormModel>;
  currentOrganization: CmdOrganization;

  constructor(private widgetService: WidgetService,
    private formApiService: FormApiService,
    private orgService: OrganizationService) {
    super();
    this.change = new EventEmitter<string>();
  }

  ngOnInit() {
    this.currentOrganization = this.orgService.getCurrentOrganization();
    let formsSearch = new SearchModel();
    
    // bug fixes : increased list size of formIO on widget.
    formsSearch.page.pageSize = 1000;
    this.formApiService.getFormsForOrganization(this.currentOrganization.id, formsSearch)
      .pipe(take(1))
      .subscribe(data => {
        this.formsTemplates = data.contents;
      },
        error => {
          console.log(error);
        });
  }

  @Input() set widget(data: CmdFormIOModel) {    
    this.widgetData = data;
  }

  public onSelectForm() {    
    let form = this.formsTemplates.filter(f => f.id === this.widgetData.actionData.formioId)[0];    
    const url = GetFormioConfig().apiUrl + '/' + form.data['path'];
    this.widgetData.actionData.formPath = form.data['path'];
    this.widgetData.actionData.formId = form.id;
    this.widgetData.actionData.formioUrl = url;
    this.notifyChange();
  }
}
