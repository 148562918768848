import { Component, OnInit } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { SearchModel } from '../../../models/SearchModel';
import { ToolsetsService } from '../../../services/toolsets.service';
import { OrganizationService } from '../../../services/organization.service';
import { BaseActionTypeSelectorComponent } from '../baseactiontypeselector';
import { QuoteBuilderService } from '../../../services/quotebuilder.service';

@Component({
    selector: 'app-quotebuilderselector',
    templateUrl: './quotebuilder.component.html',
    styleUrls: ['./quotebuilder.component.scss']
})
export class QuotebuilderSelectorComponent extends BaseActionTypeSelectorComponent implements OnInit {
    public quotebuilderErrbool: boolean = false;
    constructor(public widgetService: WidgetService,
        public toolsetsService: ToolsetsService,
        public orgService: OrganizationService,
        private quoteBuilderService: QuoteBuilderService
    ) {
        super(widgetService);
        this.orgId = this.orgService.getCurrentOrganizationId();
    }
    ngOnInit() {
        // this.quotebuilderErrbool = true;
        // this.quoteBuilderService.getQouteList(this.orgId, 1, 100)
        //     .subscribe(
        //         data => {
        //             this.quotebuilderErrbool = false;
        //             this.rows = data.contents;
        //         },
        //         err => {
        //             this.quotebuilderErrbool = false;
        //         }
        //     )
        let tdsSearch = new SearchModel();
        tdsSearch.page.pageSize = 300;
        tdsSearch.page.sortBy = 'title';
        tdsSearch.page.sortOrder = 'ASC';
        this.toolsetsService.getToolsetsForOrganization(this.orgId, tdsSearch, 'QUOTE_BUILDER').then(resp => {
            this.rows = resp.contents//.filter(tds => tds.includeInQuotebuilder);
        });
    }
}
