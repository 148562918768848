import { Component, OnInit, OnDestroy } from '@angular/core';
import { SalesQuoteService } from '../../services/salesquote.service';
import { SearchModel } from '../../models/SearchModel';
import { OrganizationService } from '../../services/organization.service';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';
import { CmdOrganization, CmdUser } from '../../models';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';

@Component({
    selector: 'app-salesquote',
    templateUrl: './salesquotes.component.html',
    styleUrls: ['./salesquotes.component.scss']
})
export class SalesQuoteComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchTerm$ = new Subject<string>();
    public salesQuotesSearch: SearchModel = new SearchModel();
    public currentOrganization: CmdOrganization;
    public currentUser: CmdUser;

    constructor(private salesQuoteService: SalesQuoteService,
        private userService: UserService,
        private organizationService: OrganizationService,
        private alertService: StickyPopUpService) {

    }

    ngOnInit() {
        setTimeout(() => {
        this.userService.getCurrentUser().toPromise().then(user => {
            this.currentUser = user;
        });
    }, 3000)
        this.currentOrganization = this.organizationService.getCurrentOrganization();
        this.doSearch();
        this.searchTerm$
            .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchText => {
                this.salesQuotesSearch.searchText = searchText;
                this.salesQuotesSearch.page.page = 1;
                this.salesQuotesSearch.page.pageSize = 10;
                this.doSearch();
            });
    }

    doSearch() {
        this.salesQuoteService.search(this.currentOrganization.id, this.salesQuotesSearch).subscribe(res => {
            this.salesQuotesSearch.results = res;
        });
    }
    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    pageChanged($event) {
        this.salesQuotesSearch.page.page = $event.page;
        this.salesQuotesSearch.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }

    changePageSize() {
        this.doSearch();
    }

    sortResults(sortBy) {
        this.salesQuotesSearch.page.page = 1;
        if (this.salesQuotesSearch.page.sortBy == sortBy) {
            if (this.salesQuotesSearch.page.sortOrder == "ASC") {
                this.salesQuotesSearch.page.sortOrder = "DESC";
            } else {
                this.salesQuotesSearch.page.sortOrder = "ASC";
            }
        } else {
            this.salesQuotesSearch.page.sortBy = sortBy;
            this.salesQuotesSearch.page.sortOrder = "ASC"
        }
        this.doSearch();
    }
    async setStatus(quote: any) {
        try {
            await this.salesQuoteService.setStatus(quote.id, quote.status);
            this.alertService.success("Sales Quote has been updated.");
        }
        catch (e) {
            this.doSearch();
            this.alertService.error("Sales Quote update failed.");
        }
    }
}
