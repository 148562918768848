import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeparatorDirective } from './separator.directive';
import { CurrencyFormattorDirective } from './currencyFormattor.directive';



@NgModule({
 imports: [
    CommonModule
  ],
  declarations: [
    SeparatorDirective,
    CurrencyFormattorDirective
  ],
  exports: [
    SeparatorDirective,
    CurrencyFormattorDirective
  ],
})
export class Directives { }
