import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';

@Component({
  selector: 'app-add-send-info-col',
  templateUrl: './add-send-info-col.component.html',
  styleUrls: ['./add-send-info-col.component.css']
})
export class AddSendInfoColComponent implements OnInit {

  public dataList: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private bsModalRef: BsModalRef, private stickyPopUp: StickyPopUpService) { }

  ngOnInit() {
  }
  onCancel() {
    this.bsModalRef.hide();
  }
  public editColumn(index) {
    this.dataList[index].status = true;
  }
  public cancelChange(index) {
    this.dataList[index].status = false;
  }
  public saveChange(index) {
    if (this.dataList[index].name == "") {
      this.stickyPopUp.error("No more columns left");
      return;
    }
    this.dataList[index].status = false;
  }
  public deleteField(index) {
    this.dataList.splice(index, 1);
  }
  public selectRow(code, index) {
    if (this.dataList[index].status) {
      return;
    }
    this.event.emit({ dataList: this.dataList, code: code });
    this.bsModalRef.hide();
  }
  public addNew() {
    this.dataList.push({ name: "", realName: "", status: true, code: "" });
  }
}
