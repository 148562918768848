import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-select-field-name-modal',
  templateUrl: './select-field-name-modal.component.html',
  styleUrls: ['./select-field-name-modal.component.css']
})
export class SelectFieldNameModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  public fieldData: any = [];
  public colObj: any = {}
  onClose = new Subject<any>();
  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }
  public selectRow(fieldCode) {
    this.event.emit({ code: fieldCode });
    this.bsModalRef.hide();
  }
  onCancel() {
    this.bsModalRef.hide();
  }
  showChangeNameModal(data) {
    this.colObj = data;
    document.getElementById('changeName').style.display = 'block';
    document.getElementById('quoteBuilder1').style.display = 'none';
  }
  public saveName() {
    document.getElementById('changeName').style.display = 'none';
    this.selectRow(this.colObj.code);
  }
}
