import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-idinfo',
    templateUrl: './idinfo.component.html',
    styleUrls: ['./idinfo.component.scss']
})
export class IdinfoComponent implements OnInit {

    theData:any = {id:''};
    constructor(private alertService:StickyPopUpService) {}

    ngOnInit() {
    }

    @Input() set data(d:any){
        this.theData = d;
    }
    
    copyToClipboard(element){
        var copyText:any = document.getElementById("info-element-" + this.theData.id);
        copyText.select();
        var bool = document.execCommand("copy");
        if(bool){
            this.alertService.info(this.theData.id + " copied to clipboard!");
        }
        else{
            this.alertService.error("Failed to copy to clipboard!");
        }
    }
}
