import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { SearchModel } from '../models/SearchModel';
import { CmdFormModel } from '../models/CmdFormModel';
import { AzureAuthholderService } from './azureauthholder.service';

@Injectable()
export class FormApiService {

	constructor(
		private azureholder: AzureAuthholderService,
		private httpClient: HttpClient,
		private envService: EnvironmentSpecificService) {
	}


	public createForm(f: CmdFormModel): Observable<CmdFormModel> {
		const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
		return this.httpClient
			.post<CmdFormModel>(this.envService.envSpecific.baseServerUrl + '/api/formio', f, requestOptions);
	}

	public updateForm(f: CmdFormModel): Observable<CmdFormModel> {
		const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
		return this.httpClient
			.put<CmdFormModel>(this.envService.envSpecific.baseServerUrl + '/api/formio', f, requestOptions);
	}

	public getFormById(formId: string): Observable<CmdFormModel> {
		const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
		return this.httpClient
			.get<CmdFormModel>(this.envService.envSpecific.baseServerUrl + '/api/formio/' + formId, requestOptions)
	}

	public getFormsForOrganization(orgId: string, fillter: SearchModel = new SearchModel()): Observable<any> {
		const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
		let httpParams = new HttpParams();
		httpParams = httpParams.append('orgId', orgId.toString());
		httpParams = httpParams.append('sortBy', fillter.page.sortBy.toString());
		httpParams = httpParams.append('sortOrder', fillter.page.sortOrder.toString());
		httpParams = httpParams.append('pageSize', fillter.page.pageSize.toString());
		httpParams = httpParams.append('page', fillter.page.page.toString());
		httpParams = fillter.searchText.length > 0 ? httpParams.append('searchText', fillter.searchText) : httpParams;
		httpParams = fillter.searchTags.length > 0 ? httpParams.append('tags', fillter.searchTags) : httpParams;
		const requestOptions = {params: httpParams, headers: headers };
		return this.httpClient
			.get<any>(this.envService.envSpecific.baseServerUrl + '/api/formio/search', requestOptions);
	}

	public deleteForm(formId: string): Observable<CmdFormModel> {
		const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
		const requestOptions = {headers: headers };
		return this.httpClient
			.delete<CmdFormModel>(this.envService.envSpecific.baseServerUrl + '/api/formio/' + formId, requestOptions)
	}

	public generateEmailTemplate(id: string): Promise<CmdFormModel> {
		const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
		const requestOptions = {headers: headers };
		return this.httpClient.put<CmdFormModel>(this.envService.envSpecific.baseServerUrl + '/api/formio/' + id + '/generate', requestOptions, {}).toPromise();
	}

}
