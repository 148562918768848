

export class CmdFormModel {

  id: string = null;
  organizationId: string;
  formId: string;

  emailPdfTemplateId: string;
  emailWrapperTemplateId: string;

  created: number;
  updated: number;

  title: string;
  data: Object;
  emailAddresses:Array<any> = [];
}


export class BaseModelFormIO {

  _id: string;

  title: string = 'New form';
  name: string = 'New form';
  path: string = 'new-form';
  original_path: string = '';

  components: Array<any> = [];
  type: string = 'form';
  display: string = 'form';

  submissionAccess: Array<any> = [];

  access: Array<any> = [];
  created: Date;
  machineName: string;
  modified: Date;
  owner: string;
  tags: Array<any> = [];
  emailAddresses:Array<any> = [];
}
