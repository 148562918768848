import { CmdWidget, CmdButton, Cmd3DCarousel, CmdCollection, CmdList, CmdMenu, CmdTextblock, CmdWebView, CmdHorizontalImageSlider } from './';
import { Injectable } from '@angular/core';
const DEFAULT_WIDGET_ERROR = 'widget type';
const ACTION_ERROR = "Items must have 'Action'";
const ACTION_ERROR2 = 'Action is not selected';
const IMAGE_ERROR = "Items must have 'Image'";
const BACK_IMAGE_ERROR = 'Background Image is not selected';
const NOT_SEL_ERROR = 'items is not selected';
const LABEL_ERROR = 'Label is not entered';
const CAROUSEL_ERROR = "Carousel Items (must have 'label', 'thumbnail')";
const CAROUSEL_ACTION_ERROR = "Carousel Items (must have 'Action')";
const COLLECTION_ERROR = "Collection Items (must have 'thumbnail', 'slides')";
const COLLECTION_ASSET_ERROR = "Collection Items (must have 'asset')";
const ITEMS_TO_DISPLAY_ERROR = "'items to display' is must be number greater than 0";
const ITEMS_LABEL_ERROR = "Items must have 'label'";
const THUMBNAIL_ERROR = 'Thumbnail is not selected';
const TEXT_ERROR = 'text field';
const URL_ERROR = 'Url is not entered';
const URL_VALID_ERROR = 'Url not valid';
const NAME_ERROR = 'Name is not entered';

export class ValidationModel {

    public isValid: boolean;
    public errorMessage: string;


    constructor(message: string) {
        this.isValid = message.length === 0;
        this.errorMessage = message;
    }
}

@Injectable()
export class WidgetValidator {

    static expression = "https?://.+";
    validateWidget(widget: CmdWidget) {
        return (widget.id != undefined && widget.type != undefined);
    }

    validate(widget: CmdWidget): ValidationModel {
        if (!this.validDisplayName(widget)) {
            return new ValidationModel(NAME_ERROR);
        }
        switch (widget.type) {
            case 'Button':
                if (widget["buttonType"] == 'image') {
                    return this.validateImageButton(widget);
                }
                else {
                    return this.validateLabelButton(widget);
                }
            case 'Carousel3D':
                return this.validateCarousel(widget);
            case 'Collection':
                return this.validateCollection(widget);
            case 'HorizontalImageSlider':
                return this.validateHorizontalImageSlider(widget);
            case 'List':
                return this.validateList(widget);
            case 'Menu':
                return this.validateMenu(widget);
            case 'TextBlock':
                return this.validateTextBlock(widget);
            case 'WebView':
                return this.validateWebView(widget);
            case 'FORMIO':
                return this.validateFormIO(widget);
            case 'ScrollableContainer':
                return this.ScrollableContainer(widget);
            case 'HorizontalImageSliderCards':
                return this.validateHorizontalImageSliderCard(widget);
            case 'SingleCard':
                return this.validateSingleCard(widget);
            default:
                return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }
    }

    // Image Button
    validateImageButton(button: any): ValidationModel {

        if (!this.validateWidget(button)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }
        if (!button.background) {
            return new ValidationModel(BACK_IMAGE_ERROR);
        }
        if (!this.validActionList(button)) {
            return new ValidationModel(ACTION_ERROR2);
        }

        return new ValidationModel('');
    }

    // Label Button
    validateLabelButton(button: any): ValidationModel {
        if (!this.validateWidget(button)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }
        if (!this.checkTextField(button.label)) {
            return new ValidationModel(LABEL_ERROR);
        }
        if (!this.validActionList(button)) {
            return new ValidationModel(ACTION_ERROR2);
        }
        return new ValidationModel('');
    }

    // 3d Carousel
    validateCarousel(carousel: any): ValidationModel {
        if (!this.validateWidget(carousel)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }

        var itemsValid = new ValidationModel('');

        if (carousel.items.length === 0) {
            itemsValid = new ValidationModel(NOT_SEL_ERROR);
        }
        carousel.items.forEach(item => {
            if (!item.id || !this.checkTextField(item.label) || !item.asset)
                itemsValid = new ValidationModel(CAROUSEL_ERROR);

            if (!this.validActionList(item))
                itemsValid = new ValidationModel(CAROUSEL_ACTION_ERROR);
        })
        return itemsValid;
    }

    // Collection
    validateCollection(collection: any): ValidationModel {
        if (!this.validateWidget(collection)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }
        var valid = new ValidationModel('');
        if (collection.items.length === 0) {
            valid = new ValidationModel(NOT_SEL_ERROR);
        }
        collection.items.forEach(item => {
            if (!item.id ||
                !item.thumbnail ||
                !item.slideDeck ||
                !item.slideDeck.slides) {
                valid = new ValidationModel(COLLECTION_ERROR);
            }
            item.slideDeck.slides.forEach(slide => {
                if (!slide.asset) {
                    valid = new ValidationModel(COLLECTION_ASSET_ERROR);
                }
            });
        });
        return valid;
    }

    // List
    validateList(list: any): ValidationModel {
        if (!this.validateWidget(list)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }

        if (!list.itemsToDisplay || list.itemsToDisplay.length <= 0) {
            return new ValidationModel(ITEMS_TO_DISPLAY_ERROR);
        }

        var valid = new ValidationModel('');
        list.items.forEach(item => {
            if (!item.id || !item.asset)
                valid = new ValidationModel(IMAGE_ERROR);

            if (!this.validActionList(item))
                valid = new ValidationModel(ACTION_ERROR);
        });
        return valid;
    }

    // Menu
    validateMenu(menu: any): ValidationModel {
        if (!this.validateWidget(menu)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }
        var valid = new ValidationModel('');
        if (menu.items.length === 0) {
            valid = new ValidationModel(NOT_SEL_ERROR);
        }
        menu.items.forEach(item => {
            if (!item.id || !this.checkTextField(item.label))
                valid = new ValidationModel(ITEMS_LABEL_ERROR);

            if (!this.validActionList(item))
                valid = new ValidationModel(ACTION_ERROR);
        });

        return valid;
    }

    // HorizontalImageSlider
    validateHorizontalImageSlider(slider: any): ValidationModel {
        if (!this.validateWidget(slider)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }
        if (!slider.thumbnail) {
            return new ValidationModel(THUMBNAIL_ERROR);
        }

        var valid = new ValidationModel('');
        if (slider.items.length === 0) {
            valid = new ValidationModel(NOT_SEL_ERROR);
        }
        slider.items.forEach(item => {
            if (!item.id || !item.image)
                valid = new ValidationModel(IMAGE_ERROR);

            if (!this.validActionList(item))
                valid = new ValidationModel(ACTION_ERROR);
        });
        return valid;
    }

    // HorizontalImageSliderCards
    validateHorizontalImageSliderCard(slider: any): ValidationModel {
        if (!this.validateWidget(slider)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }
        var valid = new ValidationModel('');
        if (slider.items.length === 0) {
            valid = new ValidationModel(NOT_SEL_ERROR);
        }
        slider.items.forEach(item => {
            if (!item.id || !item.image)
                valid = new ValidationModel(IMAGE_ERROR);

            if (!this.validActionList(item))
                valid = new ValidationModel(ACTION_ERROR);
        });
        return valid;

    }

     // SingleCards
     validateSingleCard(slider: any) {
        if (!this.validateWidget(slider)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }
        if (slider.useImage && !slider.items[0].image) {
            return new ValidationModel(BACK_IMAGE_ERROR);
        }
        var valid = new ValidationModel('');
        return valid;
    }

    // Text Box
    validateTextBlock(textBlock: any): ValidationModel {
        if (!this.validateWidget(textBlock)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }

        if (!this.checkTextField(textBlock.content)) {
            return new ValidationModel(TEXT_ERROR);
        }
        return new ValidationModel('');
    }

    // Web View
    validateWebView(webview: any): ValidationModel {
        if (!this.validateWidget(webview)) {
            return new ValidationModel(DEFAULT_WIDGET_ERROR);
        }
        if (!webview.url) {
            return new ValidationModel(URL_ERROR);
        }

        var regex = new RegExp(WidgetValidator.expression);
        if (webview.url.match(regex)) {
            return new ValidationModel('');
        } else {
            return new ValidationModel(URL_VALID_ERROR);
        }
    }

    validateFormIO(form: any): ValidationModel {
        if (!this.validateWidget(form) || !form.displayName) {
            return new ValidationModel(NAME_ERROR);
        }
        if (form.actionData == null || form.actionData.formioId == null) {
            return new ValidationModel(ACTION_ERROR2);
        }

        return new ValidationModel('');
    }

    private validDisplayName(model: any): boolean {
        return this.checkTextField(model.displayName);
    }

    private checkTextField(model: string): boolean {
        return model &&
            model !== '' &&
            model.trim().length > 0;
    }

    private ScrollableContainer(slider: any): ValidationModel {
        let validationModel = new ValidationModel("");
        return validationModel;
    }

    private validActionList(item: any): boolean {
        if (item.actionType == "NAVIGATION" && (item.actionData == undefined || item.actionData.slug == undefined)) {
            return false;
        }
        if (item.actionType == "EMAIL" && item.actionData.templateId == undefined) {
            return false;
        }
        if (item.actionType == "COLLECTION" && (item.actionData == undefined || item.actionData.slug == undefined)) {
            return false;
        }
        if (item.actionType == "COST_CALCULATOR" && (item.actionData == undefined || item.actionData.slug == undefined)) {
            return false;
        }
        if (item.actionType == "TAB_DATA_SEARCH" && (item.actionData == undefined || item.actionData.slug == undefined)) {
            return false;
        }
        if (item.actionType == "EXTERNAL_WEBSITE" && (item.actionData == undefined || item.actionData.url == undefined)) {
            return false;
        }
        if (item.actionType == "INTERNAL_WEBSITE" && (item.actionData == undefined || item.actionData.url == undefined)) {
            return false;
        }
        return true;
    }
}
