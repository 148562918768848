import { Component, EventEmitter } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BsModalService } from "ngx-bootstrap/modal";
import { QuoteBuilderService } from "../../../../services/quotebuilder.service";
import { OrganizationService } from "../../../../services/organization.service";
import { StickyPopUpService } from "../../../../services/toast/stickyPopUp.service";


// import { Subject } from "rxjs";

@Component({
  selector: 'app-add-duplicate-modal',
  templateUrl: './add-duplicate-modal.component.html',
  styleUrls: ['./add-duplicate-modal.component.css']
})
export class AddDuplicateModalComponent {
  public invData: any;
  public event: EventEmitter<any> = new EventEmitter();

  qbId: string;
  title: string;
  priceTitle: string;
  constructor(private bsModalRef: BsModalRef,
    public organizationService: OrganizationService,
    private quoteBuilderService: QuoteBuilderService,
    private stickyPopUp: StickyPopUpService,


  ) { }

  ngOnInit() {
    this.priceTitle = (localStorage.getItem('title'))
  }
  duplicatevalidId() {
    const org = this.organizationService.getCurrentOrganization();
    this.quoteBuilderService.postDuplicate(org.id, this.invData.id, this.title)
      .subscribe(
        data => {
          if (data) {
            this.stickyPopUp.success(`${this.title} has created successfully.`);
            this.onCancel();
            this.quoteBuilderService.setIsCreated(true)
            this.event.emit({ message: "added" });
          }
        }, err => {
          this.stickyPopUp.error(err.error.message);
          console.log(err.error.message, "error");
        }
      )
  }
  onCancel() {
    this.bsModalRef.hide();
  }

}