import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { TabsModule } from "ngx-bootstrap/tabs";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { SortableModule } from "ngx-bootstrap/sortable";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { NgModule, forwardRef, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { FormioModule, FormioAppConfig } from "angular-formio";
import {
  FormsModule,
  ReactiveFormsModule,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { TagInputModule } from "ngx-chips";
import { DragulaModule } from "ng2-dragula";
import { ChartsModule } from "ng2-charts/ng2-charts";
import { ToastrModule } from "ngx-toastr";
import { AlertModule } from "ngx-bootstrap/alert";
import { AppComponent } from "./app.component";
import { appRouting } from "./app.routing";
import { ButtoneditComponent } from "./components/buttonedit/buttonedit.component";
import { ApplicationButtonComponent } from "./components/applicationbutton/applicationbutton.component";
import { AddHeaderInterceptor } from "./interceptors/addheaderinterceptor";
import { ErrorInterceptor } from "./interceptors/error-interceptor";
import { ApplicationbuilderComponent } from "./pages/applicationbuilder/applicationbuilder.component";
import { AssetsService } from "./services/assets.service";
import { ApplicationService } from "./services/application.service";
import { AzureAuthholderService } from "./services/azureauthholder.service";
import { OrganizationService } from "./services/organization.service";
import { UserService } from "./services/user.service";
import { WidgetService } from "./services/widget.service";
import { InputValidationService } from "./services/inputvalidation.service";
import { ManifestService } from "./services/manifest.service";
import { HeaderComponent } from "./shared/header/header.component";
import { LeftSidebarComponent } from "./shared/left-sidebar/left-sidebar.component";
import { HomeComponent } from "./pages/home/home.component";
import { AuthTokenComponent } from "./pages/auth-token/auth-token.component";
import { UsersComponent } from "./pages/users/users.component";
import { ThemesComponent } from "./pages/themes/themes.component";
import { UserEditComponent } from "./pages/users";
import { LightboxModalComponent } from "./pages/assets";
import { AuthGuard } from "./guards/auth.guard";
import { AppEditComponent } from "./pages/applicationbuilder/app-edit/app-edit.component";
import { AssetViewComponent } from "./pages/assets/asset-view.component";
import { ThemeEditComponent } from "./pages/themes/theme-edit/theme-edit.component";
import { SceneService } from "./services/scene.service";
import { StickyPopUpService } from "./services/toast/stickyPopUp.service";
import { MonacoEditorComponent } from "./shared/monaco-editor/monaco-editor.component";
import { ScenesComponent } from "./pages/applicationbuilder/scenes/scenes.component"; // this is needed!
import { ConfirmModalComponent } from "./shared/confirm-modal/confirm-modal.component";
import { MedialibraryComponent } from "./shared/medialibrary/medialibrary.component";
import { CarouselEditorComponent } from "./components/carouseleditor/carousel.component";
import { CollectionEditorComponent } from "./components/collectioneditor/collectioneditor.component";
import { EmailformComponent } from "./components/emailform/emailform.component";
import { ListEditorComponent } from "./components/listeditor/list.component";
import { MenuComponent } from "./components/menu/menu.component";
import { TextblockComponent } from "./components/textblock/textblock.component";
import { WebviewComponent } from "./components/webview/webview.component";
import { GuidService } from "./services/guid.service";
import { SpinService } from "./services/spin.service";
import { ActionbuttonComponent } from "./shared/actionbutton/actionbutton.component";
import { AssetUploadComponent } from "./shared/assetuploader/upload.component";
import { AssetthumbnailuploadComponent } from "./shared/assetthumbnailupload/assetthumbnailupload.component";
import { AssetpdfmodifierComponent } from "./shared/assetpdfmodifier/assetpdfmodifier.component";
import { TrackselectorComponent } from "./shared/trackselector/trackselector.component";
import { AssetdisplayComponent } from "./shared/assetdisplay/assetdisplay.component";
import { ActiontypeselectorComponent } from "./shared/actiontypeselector/actiontypeselector.component";
import { OrganizationsComponent } from "./pages/organizations/organizations.component";
import { OrganizationEditComponent } from "./pages/organizations/organization-edit/organization-edit.component";
import { IdinfoComponent } from "./shared/idinfo/idinfo.component";
import { RefreshComponent } from "./pages/refresh/refresh.component";
import { CollectionService } from "./services/collection.service";
import { MedialibraryService } from "./shared/medialibrary/medialibrary.service";
import { MedialibrarythumbnailComponent } from './shared/medialibrarythumbnail/medialibrarythumbnail.component';
import { CollectionsComponent } from "./pages/applicationbuilder/collections/collections.component";
import { HorizontalImageSliderEditorComponent } from "./components/horizontalimageslidereditor/horizontalimageslidereditor.component";
import { ChangeGuard } from "./guards/changes.guard";
import { WidgetValidator } from "./models/WidgetValidator";
import { TageditorComponent } from "./shared/tageditor/tageditor.component";
import { ReportsComponent } from "./pages/organizations/reports/reports.component";
import { AnalyticsService } from "./services/analytics.service";
import { EnvironmentSpecificService } from "./services/environmentspecific.service";
import { TagselectorComponent } from "./shared/tagselector/tagselector.component";
import { RequiredvalidatorComponent } from "./shared/requiredvalidator/requiredvalidator.component";
import { ConfigLoader } from "./utilities/ConfigLoader";
import { LoaderComponent } from "./shared/loader/loader.component";
import { ProductEditComponent } from "./pages/products/product-edit/product-edit.component";
import { ProductsComponent } from "./pages/products/products.component";
import { ProductsService } from "./services/products.service";
import { ProductSelectAssetsComponent } from "./shared/productselectassets/productselectassets.component";
import { UploadProductComponent } from "./shared/uploadproduct/uploadproduct.component";
import { CompetitorEditComponent } from "./shared/competitor-edit/competitor-edit.component";
import { ProductslibraryComponent } from "./shared/productslibrary/productslibrary.component";
import { ToolsetsService } from "./services/toolsets.service";
import { EmailsLoggingsComponent } from "./pages/emails-loggings/emails-loggings.component";
import { EmailsService } from "./services/emails.service";
import { EmailtemplatesComponent } from "./pages/emailtemplates/emailtemplates.component";
import { EmailtemplateeditComponent } from "./pages/emailtemplates/emailtemplateedit/emailtemplateedit.component";
import { FormsComponent } from "./pages/form-builder/forms.component";
import { FormEditComponent } from "./pages/form-builder/form-edit/form-edit.component";
import { GetFormioConfig } from "../formio-config";
import { FormService } from "./services/form.service";
import { FormUserService } from "./services/form.user.service";
import { FormDataComponent } from "./pages/form-builder/form-data/form-data.component";
import { FormApiService } from "./services/form.api.service";
import { FormWidgetComponent } from "./components/formWidget/formWidget.component";
import { CustomLabelComponent } from "./shared/custom-label/custom-label.component";
import { AuthLoaderComponent } from "./shared/auth-loader/auth-loader.component";
import { RightmenueditorComponent } from "./pages/applicationbuilder/app-edit/rightmenueditor/rightmenueditor.component";
import { SalesQuoteComponent } from "./pages/salesquotes/salesquotes.component";
import { SalesQuoteService } from "./services/salesquote.service";
import { WebsiteUrlSelectorComponent } from "./shared/actiontypeselector/websiteurl/websiteurl.component";
import { QuotebuilderSelectorComponent } from "./shared/actiontypeselector/quotebuilder/quotebuilder.component";
import { TabdatasearchSelectorComponent } from "./shared/actiontypeselector/tabdatasearch/tabdatasearch.component";
import { CostcalculatorSelectorComponent } from "./shared/actiontypeselector/costcalculator/costcalculator.component";
import { TCOCalComponent } from "./shared/actiontypeselector/tcocalculator/tcocalculator.component";
import { CollectionSelectorComponent } from "./shared/actiontypeselector/collection/collection.component";
import { NavigationSelectorComponent } from "./shared/actiontypeselector/navigation/navigation.component";
import { EmailTemplateSelectorComponent } from "./shared/actiontypeselector/emailtemplate/emailtemplate.component";
import { CacheService } from "./services/cache.service";
import { ToolsetModule } from "./pages/toolsets/toolset.module";
import { CustompipeModule } from "./pipes/custompipe.module";
import { SharedcomponentModule } from "./shared/sharedcomponent.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { SlidedisplayComponent } from "./shared/slide-display/slide-display.component";
import { QuoteBuilderService } from "./services/quotebuilder.service";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2OrderModule } from "ng2-order-pipe";
import { EditQuoteBuilderService } from "./services/editquotebuilder";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { QuoteBuilderReportComponent } from "./contentquotebuilder/quote-builder-report/quote-builder-report.component";
import { NotifyModalComponent } from "./pages/applicationbuilder/notify-modal/notify-modal.component";
import { SuccessModalComponent } from "./pages/applicationbuilder/success-modal/success-modal.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { ScollableContainerComponent } from "./components/scollable-container/scollable-container.component";
import { HorizontalimageslidercardsComponent } from "./components/horizontalimageslidercards/horizontalimageslidercards.component";
import { SingleCardComponent } from "./components/single-card/single-card.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgxSpinnerModule } from "ngx-spinner";
// import {DataTablesModule} from 'angular-datatables';
// import { HttpClientModule } from '@angular/common/http';

// import { DeletePopUpComponent } from './pages/toolsets/contentquotebuilder/add-quote-builder-modal/DeletePopUp.component';
// import { AddDuplicateModalComponent } from './pages/toolsets/contentquotebuilder/add-quote-builder-modal/add-duplicate-modal.component';
// import { FilterListPipe } from './pipes/pricelistsearch.pipe';
import { msalConfig } from "./auth-config";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration
} from "@azure/msal-angular";
import { TcoCalculatorService } from "./services/tco-calculator.service";
import { DecimalPipe } from "@angular/common";
import { Directives } from "./directives/directives.module";
import { IFrameComponent } from './components/i-frame/i-frame.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ButtoneditComponent,
    ApplicationButtonComponent,
    ApplicationbuilderComponent,
    HeaderComponent,
    LeftSidebarComponent,
    HomeComponent,
    AuthTokenComponent,
    UsersComponent,
    ThemesComponent,
    ThemeEditComponent,
    UserEditComponent,
    ConfirmModalComponent,
    LightboxModalComponent,
    AssetViewComponent,
    AppEditComponent,
    MonacoEditorComponent,
    ScenesComponent,
    MedialibraryComponent,
    MedialibrarythumbnailComponent,
    CarouselEditorComponent,
    ListEditorComponent,
    CollectionEditorComponent,
    EmailformComponent,
    MenuComponent,
    TextblockComponent,
    WebviewComponent,
    ActionbuttonComponent,
    CustomLabelComponent,
    AssetUploadComponent,
    AssetthumbnailuploadComponent,
    AssetpdfmodifierComponent,
    UploadProductComponent,
    ProductslibraryComponent,
    TrackselectorComponent,
    AssetdisplayComponent,
    ActiontypeselectorComponent,
    WebsiteUrlSelectorComponent,
    QuotebuilderSelectorComponent,
    TabdatasearchSelectorComponent,
    CostcalculatorSelectorComponent,
    TCOCalComponent,
    CollectionSelectorComponent,
    NavigationSelectorComponent,
    EmailTemplateSelectorComponent,
    OrganizationsComponent,
    OrganizationEditComponent,
    IdinfoComponent,
    RefreshComponent,
    ReportsComponent,
    HorizontalImageSliderEditorComponent,
    CollectionsComponent,
    TageditorComponent,
    TagselectorComponent,
    RequiredvalidatorComponent,
    LoaderComponent,
    ProductsComponent,
    ProductEditComponent,
    ProductSelectAssetsComponent,
    CompetitorEditComponent,
    EmailsLoggingsComponent,
    EmailtemplatesComponent,
    EmailtemplateeditComponent,
    FormsComponent,
    FormEditComponent,
    FormDataComponent,
    FormWidgetComponent,
    EmailtemplateeditComponent,
    AuthLoaderComponent,
    RightmenueditorComponent,
    SalesQuoteComponent,
    SlidedisplayComponent,
    QuoteBuilderReportComponent,
    NotifyModalComponent,
    SuccessModalComponent,
    ScollableContainerComponent,
    HorizontalimageslidercardsComponent,
    SingleCardComponent,
    IFrameComponent,
    // DataTablesModule,
    // HttpClientModule
    // AddQuoteBuilderModalComponent,
    // DeletePopUpComponent
    // AddDuplicateModalComponent
    // FilterListPipe
  ],
  entryComponents: [
    ConfirmModalComponent,
    LightboxModalComponent,
    MedialibraryComponent,
    MedialibrarythumbnailComponent,
    AssetpdfmodifierComponent,
    AssetUploadComponent,
    AssetthumbnailuploadComponent,
    UploadProductComponent,
    ProductslibraryComponent,
    OrganizationEditComponent,
    TageditorComponent,
    ProductSelectAssetsComponent,
    CompetitorEditComponent,
    RightmenueditorComponent,
    SalesQuoteComponent,
    NotifyModalComponent,
    SuccessModalComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormioModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    TagInputModule,
    TypeaheadModule.forRoot(),
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    SortableModule.forRoot(),
    CollapseModule.forRoot(),
    AlertModule.forRoot(),
    DragulaModule,
    ButtonsModule.forRoot(),
    ChartsModule,
    ToolsetModule,
    CustompipeModule,
    Directives,
    SharedcomponentModule,
    appRouting,
    Ng2OrderModule,
    AccordionModule.forRoot(),
    DragDropModule,
    Ng2SearchPipeModule,
    AngularEditorModule,
    NgxSpinnerModule,
    MsalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagselectorComponent),
      multi: true,
    },
    {
      provide: FormioAppConfig,
      useValue: GetFormioConfig(),
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthGuard,
    ChangeGuard,
    AssetsService,
    CacheService,
    WidgetService,
    OrganizationService,
    ProductsService,
    TcoCalculatorService,
    ToolsetsService,
    EmailsService,
    UserService,
    AzureAuthholderService,
    ApplicationService,
    ManifestService,
    SceneService,
    CollectionService,
    StickyPopUpService,
    DecimalPipe,
    GuidService,
    SpinService,
    MedialibraryService,
    WidgetValidator,
    InputValidationService,
    AnalyticsService,
    EnvironmentSpecificService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [EnvironmentSpecificService],
      multi: true,
    },
    FormService,
    FormUserService,
    FormApiService,
    SalesQuoteService,
    QuoteBuilderService,
    EditQuoteBuilderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
