import { CmdAsset } from './CmdAsset';
import { CmdWidget } from './CmdWidget';

export class CmdHorizontalImageSliderCardsItem extends CmdWidget {
    image: CmdAsset;
    label: string;
    thumbnail: CmdAsset;
    asset: CmdAsset;
    header: string;
    subHeader: string;
    description: string;
    headerFontSize: string;
    subHeaderFontSize: string;
    descriptionFontSize: string;
    headerColor: string;
    view: string;
    subHeaderColor: string;
    descriptionColor: string;
    constructor(id: string) {
        super(id);
        this.type = "HorizontalImageSliderCardsItem";
        this.headerFontSize = "14px";
        this.subHeaderFontSize = "10px";
        this.descriptionFontSize = "8px";
        this.headerColor = "#000000";
        this.subHeaderColor = "#000000";
        this.descriptionColor = "#000000";
    }

    validate(): boolean {
        if (!super.validate()) {
            return false;
        }

        return this.image != undefined;
    }
}