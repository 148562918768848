import {
    CmdScene, CmdButton, Cmd3DCarousel, CmdCollection, CmdEmailForm, CmdMenu, CmdTextblock,
    CmdWebView, CmdWidget, CmdApplication, CmdUser, CmdList, CmdAsset
} from '../../../models';
import { CmdHorizontalImageSlider } from '../../../models/CmdHorizontalImageSlider';
import { WidgetValidator, ValidationModel } from '../../../models/WidgetValidator';
import { ApplicationService } from '../../../services/application.service';
import { AssetsService } from '../../../services/assets.service';
import { GuidService } from '../../../services/guid.service';
import { OrganizationService } from '../../../services/organization.service';
import { SceneService } from '../../../services/scene.service';
import { SpinService } from '../../../services/spin.service';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { UserService } from '../../../services/user.service';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { MedialibraryComponent } from '../../../shared/medialibrary/medialibrary.component';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MedialibraryService } from '../../../shared/medialibrary/medialibrary.service';
import { TagModel } from '../../../models/TagModel';
import { finalize, map } from 'rxjs/operators';
import { CmdFormIOModel } from '../../../models/CmdFormIOModel';
import { CollectionService } from '../../../services/collection.service';
import { CmdScrollableContainer } from '../../../models/CmdScrollableConatiner';
import { CmdHorizontalImageSliderCards } from '../../../models/CmdHorizontalImageSliderCards';
import { CmdSingleCard } from '../../../models/cmdSingleCard';

@Component({
    selector: 'app-scenes',
    templateUrl: './scenes.component.html',
    styleUrls: ['./scenes.component.css'],
    providers: [DragulaService]
})
export class ScenesComponent implements OnInit {
    public validationErrors: Array<any> = [];
    private confirmModalRef: BsModalRef;
    private mediaModalRef: BsModalRef;
    private sceneId: string;
    public tags: Array<TagModel>;
    appId: string;
    public application: CmdApplication;
    public scene: CmdScene = new CmdScene('');
    originalSceneCss: string;
    originalThemeCss: string;
    public currentWidget: string = '';
    public currentUser: CmdUser;
    public tracks: Array<string> = [];
    theme: CmdAsset;
    allScenes: Array<any>;
    public editorOptions: any;
    public sceneHasChanges: boolean;
    timer: any;
    _hasChanges: boolean = false;
    collapseData: boolean = false;
    selectedValue: any;
    public widgetId: {};


    constructor(private route: ActivatedRoute,
        private sceneService: SceneService,
        private alertService: StickyPopUpService,
        private modalService: BsModalService,
        private orgService: OrganizationService,
        private collectionService: CollectionService,
        private router: Router,
        private guidService: GuidService,
        private dragulaService: DragulaService,
        private appService: ApplicationService,
        private spinService: SpinService,
        private userService: UserService,
        private mediaLibraryService: MedialibraryService,
        private assetService: AssetsService,
        private widgetValidator: WidgetValidator) {
        this.dragulaService.setOptions('widgets', {
            revertOnSpill: true,
            moves: function (el: any, container: any, handle: any): any {
                if (handle.classList.contains('panel-heading')) {
                    return true;
                }
                return false;
            }
        });
    }


    ngOnInit() {
        this.loadData();
        this.editorOptions = {
            automaticLayout: true,
            folding: true,
            noEmptyRulesets: false
        }

        this.dragulaService.dropModel.subscribe(val => {
            this.recordChange();
        });
    }

    /**
     * Getting the selected value form the dropwdown
     */


    addSubWidget(subWidget: CmdWidget[], widgetId) {
        this.scene.widgets.forEach((item: any) => {
            if (item.type == "ScrollableContainer" && item.id == widgetId.id) {
                item.items = [...subWidget]
            }
        })
    }

    public collapseContainer(event: any): void {
        this.collapseData = event
    }


    loadData() {
        this.userService.getCurrentUser().subscribe(user => this.currentUser = user);
        this.route.params.subscribe(params => {
            this.sceneId = params['sceneId'];
            this.appId = params['appId'];
            this.collectionService.getForApplication(this.appId);
            this.appService.getById(this.appId).subscribe(app => {
                this.application = app;
                this.assetService.getByIdWithContents(app.themeId).subscribe(theme => {
                    this.theme = theme;
                    this.originalThemeCss = this.theme.content;
                });
            });
            if (this.sceneId === 'NEW') {
                this.scene = new CmdScene('');
                this.originalSceneCss = ''
            }
            else {
                this.sceneService.getById(this.sceneId).subscribe(scene => {
                    this.scene = scene;
                    if (this.scene.widgets === null) this.scene.widgets = [];
                    this.tags = scene.tags.map(t => new TagModel(t));
                    this.originalSceneCss = scene.customCss;
                }, error => {
                    this.alertService.error("Failed to load scene: " + error.error.message);
                });
            }
        });
        this.sceneService.getForApplication(this.appId).then(all => this.allScenes = all);
        this.orgService.getTags().subscribe(theTags => {
            this.tracks = theTags;
        });
    }

    get hasChanges() {
        return this._hasChanges;
    }

    getHasChanges() {
        this.sceneHasChanges = this._hasChanges ||
            (this.scene && (this.scene.customCss !== this.originalSceneCss)) ||
            (this.theme && (this.theme.content !== this.originalThemeCss));
        return this._hasChanges ||
            (this.scene && (this.scene.customCss !== this.originalSceneCss)) ||
            (this.theme && (this.theme.content !== this.originalThemeCss));
    }

    setHasChanges(changes: boolean) {
        this._hasChanges = changes;
    }


    onTagsChanged(tags) {
        this.scene.tags = tags;
        this.setHasChanges(true);
    }


    publish() {
        if (this.getHasChanges()) {
            this.alertService.error("There are unsaved changes on this scene. You must save your changes before publishing.");
        }
        else if (this.validate().length == 0) {
            this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
            this.confirmModalRef.content.message = 'Are you sure you want to publish this application?';
            this.confirmModalRef.content.onClose
                .subscribe(result => {
                    if (result) {
                        this.spinService.show();
                        this.appService.publish(this.appId, this.currentUser.id)
                            .pipe(finalize(() => { this.spinService.hide() }))
                            .subscribe(app => {
                                this.alertService.success("A new version (" + app.version + ") of your application has been published.");
                            }, error => {
                                this.alertService.error("Failed to publish: " + error.error.message);
                            });
                    }
                });
        }
    }

    public recordChange() {
        this.setHasChanges(true);
    }


    save() {
        if (this.validate().length == 0) {
            this.spinService.show();
            // update scene
            if (this.scene.id != undefined && this.scene.id.length > 0) {
                this.sceneService.save(this.scene).pipe(map(scene => scene))
                    .subscribe(resp => {
                        this.assetService.updateByString(this.theme).subscribe(resp => {
                            this.spinService.hide();
                            this.alertService.success("Scene updated successfully.");
                            this.setHasChanges(false);
                            this.originalSceneCss = this.scene.customCss;
                            this.originalThemeCss = this.theme.content;
                        }, error => {
                            this.spinService.hide();
                            this.alertService.error("Failed to update scene: " + error.error.message);
                        });
                    }, error => {
                        this.spinService.hide();
                        this.alertService.error("Failed to update scene: " + error.error.message);
                    });
            }

            // new scene
            else {
                this.scene.applicationId = this.appId;
                this.sceneService.create(this.scene)
                    .pipe(finalize(() => { this.spinService.hide() }))
                    .subscribe(scene => {
                        this.scene = scene;
                        //this.router.navigate(['/applications/' + this.appId + '/scenes/' + scene.id]);
                        this.alertService.success("Scene saved successfully.");
                        this.originalSceneCss = this.scene.customCss;
                        this.setHasChanges(false);
                    }, error => {
                        this.alertService.error("Failed to update scene: " + error.error.message);
                    });
            }
        }
    }

    selectBackground(size) {
        this.mediaLibraryService.assetType = 'image';
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.multiselect = false;
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result.length == 1) {
                    if (size == 'small') {
                        this.scene.backgroundSmall = result[0];
                    } else if (size == 'medium') {
                        this.scene.backgroundMedium = result[0];
                    } else if (size == 'large') {
                        this.scene.backgroundLarge = result[0];
                    } else if (size == 'small-landscape') {
                        this.scene.backgroundSmallLandscape = result[0];
                    } else if (size == 'medium-landscape') {
                        this.scene.backgroundMediumLandscape = result[0];
                    } else if (size == 'large-landscape') {
                        this.scene.backgroundLargeLandscape = result[0];
                    }
                    this.recordChange();
                }
            });
    }

    clearBackground(size) {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this background asset?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    if (size == 'small') {
                        this.scene.backgroundSmall = undefined;
                    } else if (size == 'medium') {
                        this.scene.backgroundMedium = undefined;
                    } else if (size == 'large') {
                        this.scene.backgroundLarge = undefined;
                    } else if (size == 'small-landscape') {
                        this.scene.backgroundSmallLandscape = undefined;
                    } else if (size == 'medium-landscape') {
                        this.scene.backgroundMediumLandscape = undefined;
                    } else if (size == 'large-landscape') {
                        this.scene.backgroundLargeLandscape = undefined;
                    }
                    this.recordChange();
                }
            });
    }

    addWidget() {
        var guid = this.guidService.guid();
        var widget: CmdWidget;
        switch (this.currentWidget) {
            case "ImageButton":
                widget = new CmdButton("cmd-button-" + guid);
                widget["buttonType"] = "image";
                widget["displayName"] = "Button" + this.getCount('Button');
                this.scene.widgets.push(widget);
                break;
            case "LabelButton":
                widget = new CmdButton("cmd-button-" + guid);
                widget["buttonType"] = "label";
                widget["displayName"] = "Button" + this.getCount('Button');
                this.scene.widgets.push(widget);
                break;
            case "Carousel3D":
                widget = new Cmd3DCarousel("cmd-carousel-" + guid);
                widget["displayName"] = "Carousel" + this.getCount('Carousel3D');
                this.scene.widgets.push(widget);
                break;
            case "Menu":
                widget = new CmdMenu("cmd-menu-" + guid);
                widget["displayName"] = "Menu" + this.getCount('Menu');
                this.scene.widgets.push(widget);
                break;
            case "TextBlock":
                widget = new CmdTextblock("cmd-textblock-" + guid);
                widget["displayName"] = "TextBlock" + this.getCount('TextBlock');
                this.scene.widgets.push(widget);
                break;
            case "WebView":
                widget = new CmdWebView("cmd-webview-" + guid);
                widget["displayName"] = "WebView" + this.getCount('WebView');
                this.scene.widgets.push(widget);
                break;
            case "List":
                widget = new CmdList("cmd-list-" + guid);
                widget["displayName"] = "List" + this.getCount('List');
                this.scene.widgets.push(widget);
                break;
            case "HorizontalImageSlider":
                widget = new CmdHorizontalImageSlider("cmd-horizontal-image-slider-" + guid);
                widget["displayName"] = "HorizontalImageSlider" + this.getCount('HorizontalImageSlider');
                this.scene.widgets.push(widget);
                break;
            case "FORMIO":
                widget = new CmdFormIOModel("cmd-form-" + guid);
                widget.actionType = "FORMIO";
                widget.displayName = "Form - " + this.getCount('FORMIO');
                this.scene.widgets.push(widget);
                break;
            case "ScrollableContainer":
                widget = new CmdScrollableContainer("cmd-scrollable-container" + guid);
                this.widgetId = widget.id;
                // widget.actionType = "ScrollableContainer";
                widget["displayName"] = "ScrollableContainer" + this.getCount('ScrollableContainer');
                this.scene.widgets.push(widget);
                break;
            case "HorizontalImageSliderCards":
                widget = new CmdHorizontalImageSliderCards("cmd-horizontal-image-slider-card-" + guid);
                widget["displayName"] = "HorizontalImageSliderCards" + this.getCount('HorizontalImageSliderCards');
                this.scene.widgets.push(widget);
                break;
            case "SingleCard":
                widget = new CmdSingleCard("cmd-single-card-" + guid);
                widget["displayName"] = "SingleCard" + this.getCount('SingleCard');
                this.scene.widgets.push(widget);
                break;
        }
        var newStyles = "\r";
        newStyles += "." + widget.id + " {\r";
        newStyles += "    /* enter styles here */\r";
        newStyles += "}\r\r";
        this.scene.customCss += newStyles;
        this.recordChange();
    }

    getCount(type: string): number {
        var num: number = 0;
        this.scene.widgets.forEach(widget => {
            if (widget.type == type) {
                num++;
            }
        });
        return num + 1;
    }

    handleRemoveWidget(widget) {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this widget?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.scene.widgets.splice(this.scene.widgets.indexOf(widget), 1);
                    this.recordChange();
                }
            });
    }

    handleDuplicateWidget(widget, widgetIndex) {
        const index = this.scene.widgets.indexOf(widget)+1;
        var guid = this.guidService.guid();
        var cloned: CmdWidget = JSON.parse(JSON.stringify(widget));

        switch (widget.type) {
            case "Button":
                if(widget.buttonType == 'image'){
                    cloned.id = "cmd-button-" + guid;
                    cloned["displayName"] = "Button" + this.getCount('Button');
                }
                if(widget.buttonType == 'label'){
                    cloned.id = "cmd-button-" + guid;
                    cloned["displayName"] = "Button" + this.getCount('Button');
                }
                break;
            case "Carousel3D":
                cloned.id = "cmd-carousel-" + guid;
                cloned["displayName"] = "Carousel" + this.getCount('Carousel3D');
                break;
            case "Menu":
                cloned.id = "cmd-menu-" + guid;
                cloned["displayName"] = "Menu" + this.getCount('Menu');
                break;
            case "TextBlock":
                cloned.id = "cmd-textblock-" + guid;
                cloned["displayName"] = "TextBlock" + this.getCount('TextBlock');
                break;
            case "WebView":
                cloned.id = "cmd-webview-" + guid;
                cloned["displayName"] = "WebView" + this.getCount('WebView');
                break;
            case "List":
                cloned.id = "cmd-list-" + guid;
                cloned["displayName"] = "List" + this.getCount('List');
                break;
            case "HorizontalImageSlider":
                cloned.id = "cmd-horizontal-image-slider-" + guid;
                cloned["displayName"] = "HorizontalImageSlider" + this.getCount('HorizontalImageSlider');
                break;
            case "FORMIO":
                cloned.id = "cmd-form-" + guid;
                cloned.actionType = "FORMIO";
                cloned.displayName = "Form - " + this.getCount('FORMIO');
                break;
            case "ScrollableContainer":
                cloned['cloning'] = true;
                cloned.id = "cmd-scrollable-container" + guid;
                this.widgetId = widget.id;
                cloned["displayName"] = "ScrollableContainer" + this.getCount('ScrollableContainer');
                cloned['items'] = [];
                widget.items.forEach(element => {
                    cloned['items'].push(this.duplicateWidgetItems(element));
                });
                break;
            case "HorizontalImageSliderCards":
                cloned.id = "cmd-horizontal-image-slider-card-" + guid;
                cloned["displayName"] = "HorizontalImageSliderCards" + this.getCount('HorizontalImageSliderCards');
                break;
            case "SingleCard":
                cloned.id = "cmd-single-card-" + guid;
                cloned["displayName"] = "SingleCard" + this.getCount('SingleCard');
                break;
        }
        
        this.scene.widgets.splice(index,0,cloned);
        var newStyles = "\r";
        newStyles += "." + cloned.id + " {\r";
        newStyles += "    /* enter styles here */\r";
        newStyles += "}\r\r";
        this.scene.customCss += newStyles;
        this.recordChange();
    }

    duplicateWidgetItems(subWidget) {
        var guid = this.guidService.guid();
        // hard cloning of object
        var clonedItem: CmdWidget = JSON.parse(JSON.stringify(subWidget));

        switch (subWidget.type) {
            case "Button":
                if(subWidget.buttonType == 'image'){
                    clonedItem.id = "cmd-button-" + guid;
                    clonedItem["displayName"] = "Button" + this.getCount('Button');
                }
                if(subWidget.buttonType == 'label'){
                    clonedItem.id = "cmd-button-" + guid;
                    clonedItem["displayName"] = "Button" + this.getCount('Button');
                }
                break;
            case "Carousel3D":
                clonedItem.id = "cmd-carousel-" + guid;
                clonedItem["displayName"] = "Carousel" + this.getCount('Carousel3D');
                break;
            case "Menu":
                clonedItem.id = "cmd-menu-" + guid;
                clonedItem["displayName"] = "Menu" + this.getCount('Menu');
                break;
            case "TextBlock":
                clonedItem.id = "cmd-textblock-" + guid;
                clonedItem["displayName"] = "TextBlock" + this.getCount('TextBlock');
                break;
            case "WebView":
                clonedItem.id = "cmd-webview-" + guid;
                clonedItem["displayName"] = "WebView" + this.getCount('WebView');
                break;
            case "List":
                clonedItem.id = "cmd-list-" + guid;
                clonedItem["displayName"] = "List" + this.getCount('List');
                break;
            case "HorizontalImageSlider":
                clonedItem.id = "cmd-horizontal-image-slider-" + guid;
                clonedItem["displayName"] = "HorizontalImageSlider" + this.getCount('HorizontalImageSlider');
                break;
            case "FORMIO":
                clonedItem.id = "cmd-form-" + guid;
                clonedItem.actionType = "FORMIO";
                clonedItem.displayName = "Form - " + this.getCount('FORMIO');
                break;
            case "ScrollableContainer":
                clonedItem.id = "cmd-scrollable-container" + guid;
                clonedItem["displayName"] = "ScrollableContainer" + this.getCount('ScrollableContainer');
                break;
            case "HorizontalImageSliderCards":
                clonedItem.id = "cmd-horizontal-image-slider-card-" + guid;
                clonedItem["displayName"] = "HorizontalImageSliderCards" + this.getCount('HorizontalImageSliderCards');
                break;
            case "SingleCard":
                clonedItem.id = "cmd-single-card-" + guid;
                clonedItem["displayName"] = "SingleCard" + this.getCount('SingleCard');
                break;
        }
        var newStyles = "\r";
        newStyles += "." + clonedItem.id + " {\r";
        newStyles += "    /* enter styles here */\r";
        newStyles += "}\r\r";
        this.scene.customCss += newStyles;
        return clonedItem;
    }

    validate() {
        this.validationErrors = [];
        if (this.scene && !this.scene.title || this.scene.title.trim().length === 0) {
            this.validationErrors.push("Scenes must have a title.");
        }
        if (this.application && this.scene) {
            if ((this.application.desktopLayoutMode == "portrait" || this.application.desktopLayoutMode == "both") && this.scene.backgroundLarge == undefined) {
                this.validationErrors.push("This application requires a Tablet Pro Portait background image.");
            }
            else if ((this.application.desktopLayoutMode == "landscape" || this.application.desktopLayoutMode == "both") && this.scene.backgroundLargeLandscape == undefined) {
                this.validationErrors.push("This application requires a Tablet Pro Landscape background image.");
            }
            else if ((this.application.tabletLayoutMode == "portrait" || this.application.tabletLayoutMode == "both") && this.scene.backgroundMedium == undefined) {
                this.validationErrors.push("This application requires a Tablet Portait background image.");
            }
            else if ((this.application.tabletLayoutMode == "landscape" || this.application.tabletLayoutMode == "both") && this.scene.backgroundMediumLandscape == undefined) {
                this.validationErrors.push("This application requires a Tablet Landscape background image.");
            }
            else if ((this.application.phoneLayoutMode == "portrait" || this.application.phoneLayoutMode == "both") && this.scene.backgroundSmall == undefined) {
                this.validationErrors.push("This application requires a Phone Portait background image.");
            }
            else if ((this.application.phoneLayoutMode == "landscape" || this.application.phoneLayoutMode == "both") && this.scene.backgroundSmallLandscape == undefined) {
                this.validationErrors.push("This application requires a Phone Landscape background image.");
            }
        }
         if (this.scene && this.scene.widgets) {
            this.scene.widgets.forEach(widget => {
                let result: ValidationModel;
                if(widget.type != 'ScrollableContainer'){
                    result = this.widgetValidator.validate(widget);
                }else {
                    const scrollableContainer:any = widget;
                    scrollableContainer.items.forEach(item => {
                        result = this.widgetValidator.validate(item);
                    });
                }
                if (!result.isValid) {
                    this.validationErrors.push("There is an invalid " + (widget.type === 'FORMIO' ? 'FORM' : widget.type) +
                        (widget.displayName ? (" named " + widget.displayName) : "") +
                        " in your widgets. You cannot save until - " + result.errorMessage);
                }
            });

        }
        return this.validationErrors;
    }
}
