import { CmdApplication } from '../../models';
import { Page } from '../../models/page';
import { NgForm, FormsModule } from '@angular/forms';
import { ApplicationService } from '../../services/application.service';
import { AssetsService } from '../../services/assets.service';
import { GuidService } from '../../services/guid.service';
import { OrganizationService } from '../../services/organization.service';
import { InputValidationService } from '../../services/inputvalidation.service';
import { SpinService } from '../../services/spin.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { UserService } from '../../services/user.service';
import { WidgetService } from '../../services/widget.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NotifyModalComponent } from './notify-modal/notify-modal.component';



@Component({
    selector: 'app-applicationbuilder',
    templateUrl: './applicationbuilder.component.html',
    styleUrls: ['./applicationbuilder.component.css']
})
export class ApplicationbuilderComponent implements OnInit, OnDestroy {
    cofirmModalRef: BsModalRef;
    widgets: Array<any>;
    currentUser: any;
    currentOrganization: any = {};
    applications: Array<CmdApplication>;
    applicationUser: number
    currentApplication: CmdApplication;
    themes: Array<any>;
    orgSubscription: Subscription;
    isDesc = false;
    column = 'title';
    direction: number;
    buttonIsDisabled = false;
    widgetData = 0;
    isPublished = false;
    page = new Page();
    search: any = {
        searchType: 'theme',
        searchText: ''
    };

    @ViewChild('createApplicationForm', { static: false }) createApplicationForm: NgForm;
    constructor(private assetService: AssetsService,
        private widgetService: WidgetService,
        private userService: UserService,
        private appService: ApplicationService,
        private orgService: OrganizationService,
        private validationService: InputValidationService,
        private alertService: StickyPopUpService,
        private modalService: BsModalService,
        private router: Router,
        private guidService: GuidService,
        private spinService: SpinService) {
        this.search.page = new Page();
        this.search.page.page = 1;
        this.search.page.pageSize = 50;
        localStorage.removeItem("TcoCalculator");
        localStorage.removeItem("Amoritization");
    }

    ngOnInit() {
        this.loadData();
        this.orgSubscription = this.orgService.getCurrentOrganizationObservable().subscribe(() => {
            this.loadData();
        });
    }

    ngOnDestroy() {
        this.orgSubscription.unsubscribe();
    }

    title = 'app';

    // loadData() {
    //     this.spinService.show();
    //     forkJoin(
    //         this.widgetService.getWidgets(),
    //         this.userService.getCurrentUser(),
    //         this.appService.getOrgApplications(this.orgService.getCurrentOrganizationId()),
    //         // this.assetService.getThemes(this.orgService.getCurrentOrganizationId())
    //         // get all themes belonging to an organisation
    //         // this.assetService.getAssets(this.orgService.getCurrentOrganizationId(),
    //         //     [this.search.searchType], this.search.searchText, this.search.tags, this.search.page)
    //     )
    //         .pipe(finalize(() => {

    //         }))
    //         .subscribe(datas => {
    //             this.spinService.hide();
    //             this.widgets = datas[0];
    //             this.currentUser = datas[1];
    //             this.applications = datas[2];
    //             if (this.isPublished) {
    //                 this.themes = datas[3].contents;
    //                 this.application.themeId = '';
    //             } else {
    //                 this.themes = datas[3].contents;
    //             }
    //         }, errors => {
    //             this.spinService.hide();
    //         });
    //     this.currentOrganization = this.orgService.getCurrentOrganization();
    // }

    loadData() {
        this.spinService.show();
        forkJoin(
            this.widgetService.getWidgets(),
            this.userService.getCurrentUser(),
            this.appService.getOrgApplications(this.orgService.getCurrentOrganizationId()),
            // this.assetService.getThemes(this.orgService.getCurrentOrganizationId())
            // get all themes belonging to an organisation
            this.assetService.getAssets(this.orgService.getCurrentOrganizationId(),
                [this.search.searchType], this.search.searchText, this.search.tags, this.search.page)
        )
            .pipe(finalize(() => {
                this.spinService.hide();
            }))
            .subscribe(datas => {
                this.widgets = datas[0];
                this.currentUser = datas[1];
                this.applications = datas[2];
                if (this.isPublished) {
                    this.themes = datas[3].contents;
                    this.application.themeId = '';
                } else {
                    this.themes = datas[3].contents;
                }
            }, errors => {
                this.spinService.hide();
            });
        this.currentOrganization = this.orgService.getCurrentOrganization();
    }

    application: CmdApplication = {
        id: this.guidService.guid(),
        title: undefined,
        themeId: undefined,
        description: undefined,
        defaultSceneSlug: undefined,
        defaultScene: undefined,
        customCss: undefined,
        scenes: [],
        created: undefined,
        version: '1',
        hasUnpublishedChanges: false,
        hasBeenPublished: false,
        desktopLayoutMode: 'none',
        tabletLayoutMode: 'none',
        phoneLayoutMode: 'none',
        kioskVideoTimeoutSec: 300,
        isOld: undefined,
        targetLanguage: undefined
    };

    selectedComponent = undefined;

    selectComponent(comp) {
        this.selectedComponent = comp;
    }

    scene = {
        widgets: []
    };

    //Sort applications when user clicks on table header.
    sortApplications(sortProperty) {
        this.direction = this.isDesc ? 1 : -1;
        this.isDesc = !this.isDesc;
        this.column = sortProperty;
    }

    onItemDrop(e: any) {
        let theItem;
        if (e.dragData.existing === undefined) {
            theItem = Object.assign({}, e.dragData);
            theItem.existing = true;
            theItem.action = { type: 'NONE', target: '' };
            this.scene.widgets.push(theItem);
        }
        else {

        }
        this.selectComponent(theItem);
    }

    toggleSideBar() {
        document.getElementById("main-sidebar").classList.toggle("toggled");
    }

    create() {
        this.spinService.show();
        this.appService.create(this.orgService.getCurrentOrganizationId(),
            this.application.themeId, this.application.title, '')
            .pipe(finalize(() => this.spinService.hide()))
            .subscribe(app => {
                this.alertService.success("Application created.");
                this.router.navigate(['/applications/' + app.id]);
            }, error => {
                this.spinService.hide();
                this.alertService.error(error.error.message);
            });
    }
    delete(app: CmdApplication) {
        this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = "Delete an Application";
        this.cofirmModalRef.content.message = 'Are you sure you want to delete ' + app.title + '? Doing so will remove all collections, scenes, and user authorizations associated with this application.  This action cannot be undone.';
        this.cofirmModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.appService.deleteById(app.id).subscribe(() => {
                        this.alertService.success("Application deleted.");
                        this.loadData();
                    }, error => {
                        var err = error.error;
                        this.alertService.error("Failed to delete application: " + err.message);
                    });

                }
            });
    }

    setWhitelabelConfirmation(currentApplication: CmdApplication, toggle: boolean) {
        this.currentApplication = currentApplication;
        this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = this.application.title;
        this.cofirmModalRef.content.message = toggle ? 'Are you sure you want to make this the default whitelabel application?' : 'Are you sure you want to remove the default whitelabel application?';
        this.cofirmModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.setWhitelabel(this.currentOrganization.id, currentApplication.id, toggle);
                }
            });
    }

    setWhitelabel(orgId: string, appId: string, toggle: boolean) {
        this.orgService.setWhitelabelApplication(orgId, toggle ? appId : undefined)
            .subscribe(() => {
                this.loadData();
                this.alertService.success("The application was successfully set as the whitelabel default for this organization.");
            }, error => {
                this.alertService.error("Failed to change whitelabel default. " + error.error.message);
            });
    }


    publishConfirmation(currentApplication: CmdApplication) {
        this.currentApplication = currentApplication;
        this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = this.application.title;
        this.cofirmModalRef.content.message = 'Are you sure you want to publish this application?';
        this.cofirmModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.publish();
                }
            });
    }

    publish() {
        this.spinService.show();
        this.appService.publish(this.currentApplication.id, this.currentUser.id)
            .pipe(finalize(() => this.spinService.hide()))
            .subscribe(app => {
                this.alertService.success("A new version (" + app.version + ") of your application has been published.");
                this.isPublished = true;
                this.loadData();
            }, error => {
                this.spinService.hide();
                this.alertService.error("Failed to publish application: " + error.error.message);
            });
    }

    rolloutConfirmation(appId, assignedUsers: any) {
        // currentApplication: CmdApplication
        // this.currentApplication = currentApplication;
        // if (!this.currentApplication.hasBeenPublished) {
        //     this.alertService.error("This application has not yet been published.");
        //     return;
        // }

        // var message = "";
        // if (currentApplication.hasUnpublishedChanges) {
        //     message += "This application has unpublished changes.";
        // }
        // message += '  Are you sure you want to roll out this application?'
        // this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        // this.cofirmModalRef.content.title = this.application.title;
        // this.cofirmModalRef.content.message = message;
        // this.cofirmModalRef.content.onClose
        //     .subscribe(result => {
        //         if (result === true) {
        //             this.rollout();
        //         }
        //     });
        // ===========================
        let head = `You are preparing to rollout an update to ${assignedUsers} users. Would you like to add a description to the notification they will receive about the update?`
        this.modalService.show(NotifyModalComponent, { initialState: { appId: appId, heading: head, modalTitle: "Rollout" }, class: 'modal-rollout' },);
    }

    rollout() {
        this.spinService.show();
        this.appService.rollout(this.currentApplication.id)
            .pipe(finalize(() => this.spinService.hide()))
            .subscribe(list => {
                var title;
                var version;
                var users: Array<string> = new Array<string>();
                var message = "";
                list.forEach(auth => {
                    version = auth.appVersion;
                    title = auth.appTitle;
                    users.push(auth.user);
                });
                message += "Version " + version + " of " + title + " has been rolled out to " + users.length + " users. (" + users.join(", ") + ")";
                this.alertService.success(message);
                this.loadData();
            }, error => {
                this.spinService.hide();
                this.alertService.error("Failed to roll out application: " + error.error.message);
            });
    }

    validateInput() {
        this.buttonIsDisabled = this.validationService.validateEmptyString(this.application.title);
    }
    public openNotifyModal(id) {
        this.modalService.show(NotifyModalComponent, { initialState: { appId: id, heading: "What do you want your notification to say?", modalTitle: "Notify" }, class: 'modal-rounded' },);

    }

    cloneAppConfirmation(currentApplication:CmdApplication){
        this.currentApplication = currentApplication;
        this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = this.application.title;
        this.cofirmModalRef.content.message = 'Are you sure! you want to clone this application?';
        this.cofirmModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.clone();
                }
            });
    }

    clone(){
        this.spinService.show();
        this.appService.clone(this.currentApplication.id)
            .pipe(finalize(() => this.spinService.hide()))
            .subscribe(clonedApp => {
                var message = 'Application successfully cloned';
                this.alertService.success(message);
                this.router.navigate(['/applications/' + clonedApp.id]);
            }, error => {
                this.spinService.hide();
                this.alertService.error("Failed to clone the application: " + error.error.message);
            });
    }
}
