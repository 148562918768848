import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class StickyPopUpService {

    constructor(private toastrService: ToastrService) {
    }


    public success(message){
      this.toastrService.success(message, 'Success');
    }

    public error(message){
      this.toastrService.error(message, 'Error', { timeOut: 10000 });
    }

    public info(message){
      this.toastrService.info(message, 'Info');
    }
}
