import { CmdAsset } from '../../models';
import { OrganizationService } from '../../services/organization.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-tageditor',
    templateUrl: './tageditor.component.html',
    styleUrls: ['./tageditor.component.scss']
})
export class TageditorComponent implements OnInit {

    actionType: string = "add";
    actionTypeLabel: string = "Add";
    theAssets: Array<CmdAsset> = [];
    tags: Array<string>;
    public onClose: Subject<Array<any>>;
    hasChanges: boolean = false;
    constructor(public bsModalRef: BsModalRef, private orgService: OrganizationService) { }

    public ngOnInit(): void {
        this.onClose = new Subject();
        this.orgService.getTags().subscribe(tags => {
            this.tags = tags;
        });
    }

    setActionType(type, assets: Array<CmdAsset>) {
        this.actionType = type;
        this.hasChanges = false;
        assets.forEach(ast => {
            this.theAssets.push({
                id: ast.id,
                tags: Object.assign([], ast.tags)
            });
        });
        if (this.actionType == 'add') {
            this.actionTypeLabel = "Add";
        }
        else {
            this.actionTypeLabel = "Remove";
        }
    }
    onSelectAdds(event: Array<any>) {
        this.hasChanges = true;
        this.theAssets.forEach(asset => {
            event.forEach(tag => {
                if (asset.tags.indexOf(tag) == -1) {
                    asset.tags.push(tag);
                }
            });
        });
    }

    onSelectRemoves(event: Array<any>) {
        this.hasChanges = true;
        this.theAssets.forEach(asset => {
            event.forEach(tag => {
                if (asset.tags.indexOf(tag) != -1) {
                    asset.tags.splice(asset.tags.indexOf(tag), 1);
                }
            });
        });
    }
    public onConfirm(): void {
        this.onClose.next(this.theAssets);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(undefined);
        this.bsModalRef.hide();
    }


}
