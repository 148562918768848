import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../../services/organization.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { QuoteBuilderService } from '../../../../services/quotebuilder.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import * as $ from "jquery";
import { SelectFieldNameModalComponent } from '../select-field-name-modal/select-field-name-modal.component';
import { EditQuoteBuilderService } from '../../../../services/editquotebuilder';

@Component({
  selector: 'app-add-product-quote-mdl',
  templateUrl: './add-product-quote-mdl.component.html',
  styleUrls: ['./add-product-quote-mdl.component.css']
})
export class AddProductQuoteMdlComponent implements OnInit {
  dataList: Array<any> = [];
  public event: EventEmitter<any> = new EventEmitter();
  public eventFrNew: EventEmitter<any> = new EventEmitter();
  schedule: { branch: '' };
  values = [];
  name = 'Angular';
  myForm: FormGroup;
  arr: FormArray;
  addCreateProductBuildeModalRef: BsModalRef;
  fieldNameModalRef: BsModalRef;
  title: string = '';
  uploadData: any;
  fileData: any;
  onClose = new Subject<any>();
  public skuVal: string = '';
  public skuBool: boolean = false;
  public invData: any;
  public quoteId: any;
  public apiType: string;
  public btnName: string;
  public productData: any;
  public table: any;
  public headerData: any = [];
  constructor(private bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private modalService: BsModalService,
    public organizationService: OrganizationService,
    private quoteBuilderService: QuoteBuilderService,
    private apiService: EditQuoteBuilderService,
    private stickyPopUp: StickyPopUpService
  ) {

  }

  ngOnInit() {
    if (this.apiType == "new" || this.apiType == "add") {
      this.initFrNew();
    } else {
      this.initFrUpdate();
    }
    this.onClose = new Subject();
  }
  initFrUpdate() {
    this.btnName = "Update";
    let code = 1;
    this.skuVal = this.productData.sku;
   // this.table.map(e => {
      //   var element = e.columnName;
      //   this.productData = element;
      // });
    
    this.headerData.forEach(element => {
      if (element.columnName != "sku") 
        if (this.productData[element.columnName] || (this.productData[element.columnName]==0 && element.columnName == "sellPrice")) {
          this.values.push({ name: element.headerName, realName: element.columnName, value: this.productData[element.columnName], type: "exist", code: code });
        } else {
          this.dataList.push({ code: code, name: element.headerName, realName: element.columnName });
        }
      ++code;
    });
  }
  initFrNew() {
    this.values.push({ name: "Name", realName: "name", value: "", type: "exist", code: 12 });
    this.values.push({ name: "Description", realName: "description", value: "", type: "exist", code: 13 });
    this.values.push({ name: "Category", realName: "category", value: "", type: "exist", code: 14 });
    this.dataList = [
      { code: 3, name: "Item Number", realName: "itemNumber" },
      { code: 10, name: "Unit", realName: "unit" },
      { code: 1, name: "Quantity", realName: "quantity" },
      { code: 11, name: "Type", realName: "productType" },
      { code: 2, name: "Sell Price", realName: "sellPrice" },
      { code: 9, name: "Discount Available", realName: "discountAvl" },
      { code: 4, name: "Variable 1", realName: "variable1" },
      { code: 5, name: "Variable 2", realName: "variable2" },
      { code: 6, name: "Variable 3", realName: "variable3" },
      { code: 7, name: "Variable 4", realName: "variable4" },
      { code: 8, name: "Variable 5", realName: "variable5" }
    ]
    this.btnName = "Save";
  }
  addvalue() {
    if (!this.dataList.length) {
      this.stickyPopUp.error("No more columns left");
      return;
    }
    this.values.push({ name: "", value: "", type: "new", realName: "", code: "" });
    $("#myList").animate({ scrollTop: $('#myList').prop("scrollHeight") }, 500);
  }

  removevalue(i) {
    if (this.values[i].name != "")
      this.dataList.push({ code: this.values[i].code, name: this.values[i].name, realName: this.values[i].realName })
    this.values.splice(i, 1);
  }
  public openFieldMdl(fieldIndex) {
    this.fieldNameModalRef = this.modalService.show(SelectFieldNameModalComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { fieldData: this.dataList }, class: 'add-Column-Quote-Modal' });
    this.fieldNameModalRef.content.event.subscribe(data => {
      let index = this.dataList.findIndex(x => x.code == data.code);
      this.values[fieldIndex].type = "exist";
      this.values[fieldIndex].name = this.dataList[index].name;
      this.values[fieldIndex].realName = this.dataList[index].realName;
      this.values[fieldIndex].code = this.dataList[index].code;
      this.dataList.splice(index, 1);
    });
  }
  onCancel() {
    this.bsModalRef.hide();
  }

  saveTitle = () => {
    document.getElementById('createproduct').style.display = 'block';
    document.getElementById('quoteBuilder').style.display = 'none';
  }
  uploadCSVFile(event) {
    this.fileData = event.target.files[0];
  }
  public gotskuval(val) {
    this.skuBool = val == "" ? true : false;
  }
  addProduct() {
    const org = this.organizationService.getCurrentOrganization();
    if (this.skuVal == "") {
      this.stickyPopUp.error('Please enter sku value.');
      this.skuBool = true;
      return;
    }
    this.skuBool = false;
    var sell = this.values.find(o => o.realName === 'sellPrice') ? (this.values.find(o => o.realName === 'sellPrice').value) : "";
    
    var sellPrice = sell.toString().replaceAll(',','');
    let body = {
      "sku": this.skuVal,
      "itemNumber": this.values.find(o => o.realName === 'itemNumber') ? this.values.find(o => o.realName === 'itemNumber').value : "",
      "name": this.values.find(o => o.realName === 'name') ? this.values.find(o => o.realName === 'name').value : "",
      "description": this.values.find(o => o.realName === 'description') ? this.values.find(o => o.realName === 'description').value : "",
      "category": this.values.find(o => o.realName === 'category') ? this.values.find(o => o.realName === 'category').value : "",
      "unit": this.values.find(o => o.realName === 'unit') ? this.values.find(o => o.realName === 'unit').value : "",
      "quantity": this.values.find(o => o.realName === 'quantity') ? this.values.find(o => o.realName === 'quantity').value : "",
      "sellPrice": sellPrice,
      "discountAvl": this.values.find(o => o.realName === 'discountAvl') ? this.values.find(o => o.realName === 'discountAvl').value : "",
      "variable1": this.values.find(o => o.realName === 'variable1') ? this.values.find(o => o.realName === 'variable1').value : "",
      "variable2": this.values.find(o => o.realName === 'variable2') ? this.values.find(o => o.realName === 'variable2').value : "",
      "variable3": this.values.find(o => o.realName === 'variable3') ? this.values.find(o => o.realName === 'variable3').value : "",
      "variable4": this.values.find(o => o.realName === 'variable4') ? this.values.find(o => o.realName === 'variable4').value : "",
      "variable5": this.values.find(o => o.realName === 'variable5') ? this.values.find(o => o.realName === 'variable5').value : "",
      "productType": this.values.find(o => o.realName === 'productType') ? this.values.find(o => o.realName === 'productType').value : "UNAVAILABLE"
    }

    if (this.apiType == "edit") {
      this.apiService.postMethod("/api/quotebuilder/" + org.id + "/update/" + this.quoteId + "/product/" + this.productData.id, body).subscribe(
        data => {
          this.stickyPopUp.success("Product updated successfully");
          this.event.emit({ message: "updated" });
          this.bsModalRef.hide();
        },
        err => {
          this.stickyPopUp.error(err.error.message);
          console.log(err);
        }
      )
    } else if (this.apiType == "add") {
      this.apiService.postMethod("/api/quotebuilder/" + org.id + "/update/" + this.quoteId, body).subscribe(
        data => {
          this.stickyPopUp.success("Product added successfully");
          this.event.emit({ message: "updated" });
          this.bsModalRef.hide();
        },
        err => {
          this.stickyPopUp.error(err.error.message);
          console.log(err);
        }
      )
    }
    else {
      let body1 = {
        "title": this.title,
        "quoteBuilderProductsTo": [body]
      }
      this.apiService.postMethod("/api/quotebuilder/" + org.id + "/create", body1).subscribe(
        data => {
          this.stickyPopUp.success("Product created successfully");
          this.bsModalRef.hide();
          this.eventFrNew.emit({ message: "added" });
        },
        err => {
          this.stickyPopUp.error(err.error.message);
          console.log(err);
        }
      )
    }

  }

}
