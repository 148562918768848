import { ScenesComponent } from '../pages/applicationbuilder/scenes/scenes.component';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfirmModalComponent } from '../shared/confirm-modal/confirm-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Injectable()
export class ChangeGuard implements CanDeactivate<any> {

    bsModalRef: BsModalRef;
    constructor(private router: Router, private modalService: BsModalService) {
    }

    canDeactivate(component: any): Promise<boolean> {
        if (component.hasChanges) {
            return new Promise((resolve, reject) => {
                this.bsModalRef = this.modalService.show(ConfirmModalComponent);
                this.bsModalRef.content.title = "You have unsaved changes";
                this.bsModalRef.content.message = 'If you continue you will lose your changes.  Continue?';
                this.bsModalRef.content.onClose
                    .subscribe(result => {
                        resolve(result);
                    });
            });
        }
        else {
            return Promise.resolve(true);
        }
    }
}
