import { CmdCollection, CmdScene, CmdCollectionItem, CmdSlideDeck, CmdAsset } from '../../models';
import { CmdEmailTemplate } from '../../models/CmdEmailTemplate';
import { CmdSlide } from '../../models/CmdSlide';
import { WidgetService } from '../../services/widget.service';
import { OrganizationService } from '../../services/organization.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { MedialibraryComponent } from '../../shared/medialibrary/medialibrary.component';
import { WidgetEditor } from '../widgeteditor';
import { Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { MedialibraryService } from '../../shared/medialibrary/medialibrary.service';
import { ProductslibraryComponent } from '../../shared/productslibrary/productslibrary.component';
import { DragulaService } from 'ng2-dragula';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'app-collectioneditor',
    templateUrl: './collectioneditor.component.html',
    styleUrls: ['./collectioneditor.component.scss']
})
export class CollectionEditorComponent extends WidgetEditor implements OnInit, OnDestroy {
    private confirmModalRef: BsModalRef;
    private mediaModalRef: BsModalRef;
    private selectedItem: CmdCollectionItem;
    private selectedSlideDeck: CmdSlideDeck;
    private selectedAsset: CmdAsset;
    private widgetCollectionValidation: Array<any>;
    private enableSaveButton: boolean = false;
    private widgetCount: number = 0;
    private uploadModalRef: BsModalRef;
    //public pdfSelected: boolean = true;
    widgetData: CmdCollection;
    emailTemplates: Array<CmdEmailTemplate> = [];
    @ViewChild('collectionWidgetForm', { static: false }) collectionWidgetForm: NgForm;

    constructor(private changeDetector: ChangeDetectorRef,
        private modalService: BsModalService,
        private orgService: OrganizationService,
        private widgetService: WidgetService,
        private mediaLibraryService: MedialibraryService,
        private dragulaService: DragulaService) {
        super();
    }

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '7rem',
        maxHeight: '10rem',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '4',
        fonts: [
            { class: 'Scania', name: 'Scania' },
            { class: 'ScaniaItalic', name: 'ScaniaItalic' },
            { class: 'ScaniaRegular', name: 'ScaniaRegular' },
            { class: 'ScaniaHeadlineBold', name: 'ScaniaHeadlineBold' },
            { class: 'ScaniaHeadlineRegular', name: 'ScaniaHeadlineRegular' },
            { class: 'arial', name: 'Arial' },
            { class: 'times-new-roman', name: 'Times New Roman' },
            { class: 'calibri', name: 'Calibri' },
            { class: 'comic-sans-ms', name: 'Comic Sans MS' },
            { class: 'GT-Eesti-Text-Regular', name: 'GT Eesti Text Regular' },
            { class: 'GT-Eesti-Text-Light', name: 'GT Eesti Text Light' },
            { class: 'EBGaramond-Regular', name: 'Garamond Regular' },
            { class: 'FranklinGothic', name: 'Franklin Gothic' },
            { class: 'Helvetica', name: 'Helvetica' },
            { class: 'OpenSans-Regular', name: 'Open Sans Regular' },
            //{ class: 'Rockwell', name: 'Rockwell' },
            { class: 'Verdana', name: 'Verdana' }
        ],
        customClasses: [
          {
            name: 'h1-text-left',
            class: 'text-left',
            tag: 'h1',
          },
          {
            name: 'h1-text-center',
            class: 'text-center',
            tag: 'h1',
          },
          {
            name: 'h1-text-right',
            class: 'text-right',
            tag: 'h1',
          },
          {
            name: 'h2-text-left',
            class: 'text-left',
            tag: 'h2',
          },
          {
            name: 'h2-text-center',
            class: 'text-center',
            tag: 'h2',
          },
          {
            name: 'h2-text-right',
            class: 'text-right',
            tag: 'h2',
          },
          {
            name: 'h3-text-left',
            class: 'text-left',
            tag: 'h3',
          },
          {
            name: 'h3-text-center',
            class: 'text-center',
            tag: 'h3',
          },
          {
            name: 'h3-text-right',
            class: 'text-right',
            tag: 'h3',
          },
          {
            name: 'h4-text-left',
            class: 'text-left',
            tag: 'h4',
          },
          {
            name: 'h4-text-center',
            class: 'text-center',
            tag: 'h4',
          },
          {
            name: 'h4-text-right',
            class: 'text-right',
            tag: 'h4',
          },
          {
            name: 'h5-text-left',
            class: 'text-left',
            tag: 'h5',
          },
          {
            name: 'h5-text-center',
            class: 'text-center',
            tag: 'h5',
          },
          {
            name: 'h5-text-right',
            class: 'text-right',
            tag: 'h5',
          },
          {
            name: 'p-text-left',
            class: 'text-left',
            tag: 'p',
          },
          {
            name: 'p-text-center',
            class: 'text-center',
            tag: 'p',
          },
          {
            name: 'p-text-right',
            class: 'text-right',
            tag: 'p',
          }
        ],
        sanitize:false
    };


    ngOnInit() {
        this.orgService.getEmailTemplates().subscribe(templates => this.emailTemplates = templates);
    }
    ngOnDestroy() {
        this.emailTemplates = [];
        // const bag: any = this.dragulaService.find('assets-bag-one');
        // if (bag !== undefined ) this.dragulaService.destroy('assets-bag-one');
    }
    @Input() set widget(data: CmdCollection) {
        this.widgetData = data;
        if (!this.widgetData.items || !this.widgetData.items.length) {
            // this.addEmptyRow();
        }
    }

    cloneItem(item: CmdCollectionItem) {
        let index = this.widgetData.items.indexOf(item);
        let cloned:CmdCollectionItem = JSON.parse(JSON.stringify(item)); // deep cloning of collection item
            cloned.id = item.id + '-' + this.widgetCount; // update id of cloned item
        this.widgetData.items.splice(index+1,0,cloned);
        this.widgetCount++;
    }
    
    removeItem(item: CmdCollectionItem) {
        let self = this;
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this collection item?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.widgetData.items.splice(this.widgetData.items.indexOf(item), 1);
                    this.notifyChange();
                }
            });
    }

    addEmptyRow() {
        this.widgetData.items.push(new CmdCollectionItem(this.widgetData.id + '-' + this.widgetCount));
        this.widgetCount++;
    }

    handleSecureContentClick(event, slideDeck: CmdSlideDeck) {
        if (!slideDeck.secure) {
            slideDeck.actionData = {};
            slideDeck.requestInformation = false;
            slideDeck.hasAttachment = false;
        }
    }

    removeThumbnailImage(item: CmdCollectionItem) {
        item.thumbnail = undefined;
        this.notifyChange();
    }

    selectThumbnailImage(item: CmdCollectionItem) {
        this.selectedItem = item;
        this.mediaLibraryService.assetType = 'image';
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.multiselect = false;
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result && result.length == 1) {
                    this.selectedItem.thumbnail = result[0];
                    this.notifyChange();
                }
            });
    }
    clearImage(item: CmdCollectionItem) {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this image?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    item.thumbnail = undefined;
                    this.notifyChange();
                }
            });
    }

    selectSlide(deck: CmdSlideDeck) {
        this.selectedSlideDeck = deck;
        this.mediaLibraryService.assetTypes = ['image', 'video', 'pdf'];
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.multiselect = true;
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result && result instanceof Array) {
      //              if (result[0].mimeType == "applications/pdf") {
        //                this.pdfSelected = false;
          //          }
                    if (!this.selectedSlideDeck.slides) {
                        this.selectedSlideDeck.slides = [];
                    }
                    result.forEach(asset => {
                        var newSlide = new CmdSlide();
                        newSlide.asset = asset;
                        newSlide.assetBehavior = asset.assetBehavior ? asset.assetBehavior : 'END';
                        this.selectedSlideDeck.slides.push(newSlide);
                    });
                    this.notifyChange();
                }
            });
    }
    clearSlide(deck: CmdSlideDeck, slide: CmdSlide) {
        //this.pdfSelected = true
        if (deck.slides.indexOf(slide) > -1) {
            deck.slides.splice(deck.slides.indexOf(slide), 1);
        }
        this.notifyChange();
    }

    selectAttachment(slideDeck: CmdSlideDeck) {
        this.selectedSlideDeck = slideDeck;
        this.mediaLibraryService.assetTypes = ['image', 'document', 'video', 'pdf'];
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result && result.length == 1) {
                    slideDeck.actionData.attachment = result[0];
                    this.notifyChange();
                }
            });
    }
    clearAttachment(slideDeck: CmdSlideDeck) {
        slideDeck.actionData.attachment = undefined;
    }

    /*
     * Resets the slide deck's request info.
     */
    toggleRequestInformation(slideDeck: CmdSlideDeck) {
        if (!slideDeck.requestInformation) {
            slideDeck.actionData = {};
            slideDeck.actionType = "EMAIL";
            slideDeck.hasAttachment = undefined;
        }
    }


    public addProduct() {
        this.uploadModalRef = this.modalService.show(ProductslibraryComponent, { class: 'modal-lg asset-modal' });
        this.uploadModalRef.content.isMustBeCompetitors = false;
        this.uploadModalRef.content.allowProductsWithoutCompetitors = true;
        this.uploadModalRef.content.onClose
            .subscribe(result => {
                if (result)
                    result.forEach(prod => {
                        let new_item = new CmdCollectionItem(this.widgetData.id + '-' + this.widgetCount);
                        new_item.thumbnail = prod.thumbnail;
                        new_item.productId = prod.id;
                        new_item.productSku = prod.sku;
                        new_item.productTitle = prod.title;

                        if (prod.assets) {
                            prod.assets.forEach(asset => {
                                var newSlide = new CmdSlide();
                                newSlide.asset = asset;
                                new_item.slideDeck.slides.push(newSlide);
                            });
                        }

                        this.widgetData.items.push(new_item);
                        this.widgetCount++;

                        this.notifyChange();

                    });

            });

    }
}
