import { Component, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({    
    selector: 'app-custom-label',
    templateUrl: 'custom-label.component.html',
    styleUrls: ['custom-label.component.scss']
})
export class CustomLabelComponent {

    @Input() theLabel:string;
    @Input() theModel:any;
}
