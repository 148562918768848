import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CmdScene } from '../models';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { CacheService } from './cache.service';
import { AzureAuthholderService } from "../services/azureauthholder.service";

@Injectable()
export class SceneService {

    constructor(private http: HttpClient, 
        private azureholder: AzureAuthholderService,
        private envService:EnvironmentSpecificService,
        private cacheService: CacheService) {}
    
    getById(id:string):Observable<CmdScene>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/scenes/' + id, requestOptions);
    }
    
    /**
     * Retrieves the scenes for an application and supports paging.
     */
    getForApplication(appId:string):Promise<Array<CmdScene>>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        return new Promise((resolve, reject) => {
            if(this.cacheService.isInCache(CacheService.SCENE, appId)){
                resolve(this.cacheService.get(CacheService.SCENE, appId));        
            }
            else{
                let parms = new HttpParams();
                parms = parms.set('applicationId', appId);
                const requestOptions = {params:parms, headers: headers };
                this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/scenes/application', requestOptions).toPromise().then(rows => {
                    this.cacheService.set(CacheService.SCENE, appId, rows);
                    resolve(rows);
                });
            }    
        });
        
    }
    
    search(orgId:string, applicationId, searchText, tags, page):Observable<Array<CmdScene>>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let params = page.toParams()
            .set('appId', applicationId)
            .set('orgId', orgId)
            .set('searchText', searchText);
            const requestOptions = { params: params, headers: headers };
        if(tags != undefined && tags.length > 0){
            params = params.append('tags', tags);
        }
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/scenes/search', requestOptions);
    }
    
    delete(scene):Observable<any>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        this.cacheService.clear(CacheService.SCENE);
        return this.http.delete(this.envService.envSpecific.baseServerUrl + '/api/scenes/' + scene.id, requestOptions);
    }
    create(scene:CmdScene):Observable<CmdScene>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        this.cacheService.clear(CacheService.SCENE);
        //scene.slug = scene.title.replace(/[^A-Za-z]/gi, '-').toLowerCase();
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/scenes', scene, requestOptions);        
    }
    save(scene:CmdScene):Observable<CmdScene>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        this.cacheService.clear(CacheService.SCENE);
        const requestOptions = {headers: headers };
        return this.http.put<any>(this.envService.envSpecific.baseServerUrl + '/api/scenes', scene, requestOptions);
    }
    saveAndPublish(scene:CmdScene):Observable<CmdScene>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        this.cacheService.clear(CacheService.SCENE);
        return this.http.put<any>(this.envService.envSpecific.baseServerUrl + '/api/scenes', scene, requestOptions);
    }
	clone(id:string):Observable<CmdScene>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
		this.cacheService.clear(CacheService.SCENE);
        return this.http.put<any>(this.envService.envSpecific.baseServerUrl + '/api/scenes/' + id + '/clone', requestOptions, {});
	}
} 