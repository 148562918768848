import { CmdOrganization, Toolsets } from '../models';
import { CmdEmailTemplate } from '../models/CmdEmailTemplate';
import { CmdToolsetAuthorization } from "../models/CmdToolsetAuthorization";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { AzureAuthholderService } from "../services/azureauthholder.service";

@Injectable()
export class OrganizationService {

    // change organisation add/edit modal title
    orgModalTitle = new BehaviorSubject<string>("Add an Organisation");
    currentOrgModalTitle = this.orgModalTitle.asObservable();

    // check if organisation is being added/edited
    orgState = new BehaviorSubject<boolean>(null);
    currentOrgAction = this.orgState.asObservable();

    currentOrganization = new Subject<any>();
    currentUser = new Subject<any>();
    organizationUpdate = new Subject<any>();
    updateOrganizationList = new Subject<any>();
    refreshOrg = new Subject<any>();
    emailTemplates: Array<any>;
    accessToken: String;
    constructor(private azureholder: AzureAuthholderService, private httpClient: HttpClient, private envService: EnvironmentSpecificService) { }

    checkOrgAction(orgAction) {
        this.orgState.next(orgAction);
    }

    changeOrgModalTitle(title) {
        this.orgModalTitle.next(title);
    }

    getCurrentOrganizationObservable() {
        return this.currentOrganization.asObservable();
    }

    isHasCurrentOrganization() {
        return this.getCurrentOrganization() != undefined;
    }
    setCurrentOrganization(org) {
        localStorage.setItem('org', JSON.stringify(org));
        this.currentOrganization.next(org);
    }
    getCurrentOrganization() {
        if (localStorage.getItem('org') == undefined) {
            return undefined;
        }
        return JSON.parse(localStorage.getItem('org'));
    }
    setCurrentUser(userId) {
        localStorage.setItem('userId', JSON.stringify(userId));
        this.currentUser.next(userId);
    }
    getCurrentUser() {
        if (localStorage.getItem('userId') == undefined) {
            return undefined;
        }
        return JSON.parse(localStorage.getItem('userId'));
    }
    getCurrentOrganizationId() {
        var org = this.getCurrentOrganization();
        return org == undefined ? undefined : org.id;
    }

    create(org: any): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.post<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations', org, requestOptions)
            .pipe(map(org => {
                this.updateOrganizationList.next();
                return org;
            }));
    }
    update(org: CmdOrganization): Observable<CmdOrganization> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.put<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + org.id, org, requestOptions)
            .pipe(map(org => {
                this.organizationUpdate.next(org);
                this.updateOrganizationList.next();
                return org;
            }));
    }

    getLimitOfQuoteBuilder(orgId: string): Observable<any> {
        // TODO: need api method for geting Limit of QueteBuilders
        // for now it's only workaround
        return this.getAuthorizedToolsets(orgId)
            .pipe(
                map(resp => {
                    var result = false;
                    resp.forEach(t => {
                        if (t.type === 'QUOTE_BUILDER' && t.authorized)
                            result = true;
                    });
                    return result;
                }));
    }

    getAuthorizedToolsets(orgId: string): Observable<Array<CmdToolsetAuthorization>> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.get<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + orgId + '/toolsets', requestOptions)
            .pipe(
                map(tools => {
                    return tools;
                }));
    }

    setToolsetAuthorizations(orgId: string, tools: Array<CmdToolsetAuthorization>): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.put<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + orgId + '/toolsets', tools, requestOptions);
    }

    getAllOrganizations(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        return this.httpClient.get<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations',requestOptions);
    }

    getOrganizationsForOrgAdmin(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        return this.httpClient.get<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/getOrgs',requestOptions);
    }

    getById(id: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        return this.httpClient.get<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + id, requestOptions);
    }

    getTags(): Observable<Array<string>> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.get<any>(this.envService.envSpecific.baseServerUrl + '/api/tags/organization/' + this.getCurrentOrganizationId(), requestOptions);
    }


    getStandardNotes(id: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        return this.httpClient.get<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + id + '/standard_notes', requestOptions);
    }
    updateStandardNotes(id: string, notes: Array<string>) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        return this.httpClient.put<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + id + '/standard_notes', notes, requestOptions);
    }


    getEmailTemplates(): Observable<Array<CmdEmailTemplate>> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        // if (this.emailTemplates == undefined) {
            return this.httpClient.get<any>(this.envService.envSpecific.baseServerUrl + '/api/emails/templates?orgId=' + this.getCurrentOrganizationId() + '&type=EMAIL&page=1&pageSize=100', requestOptions)
                .pipe(
                    map(data => {
                        this.emailTemplates = data.contents;
                        return this.emailTemplates;
                    }));
        // }
        // else {
        //     return of(this.emailTemplates);
        // }
    }

    delete(id: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        return this.httpClient.delete(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + id, requestOptions)
            .pipe(
                map(org => {
                    this.updateOrganizationList.next();
                }));
    }
    setLogo(id: string, file: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        const formData = new FormData();
        formData.append("file", file);
        return this.httpClient.post<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + id + '/logo', formData, requestOptions)
            .pipe(map(org => {
                this.organizationUpdate.next(org);
                return org;
            }));
    }

    setWhitelabelApplication(orgId: string, applicationId: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        const formData = new FormData();
        if (applicationId) {
            formData.append("appId", applicationId);
        }
        return this.httpClient.post<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + orgId + '/default_app', formData, requestOptions);
    }

    toggleWhitelabel(orgId: string, enabled: boolean) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        return this.httpClient.post<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/' + orgId + '/whitelabel/' + (enabled ? 'true' : 'false'), new FormData(), requestOptions);
    }

    getReportingAccessToken(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
        const requestOptions = { headers: headers };
        if (this.accessToken) {
            return of(this.accessToken);
        }
        else {
            return this.httpClient.get<any>(this.envService.envSpecific.baseServerUrl + '/api/organizations/reporting/accesstoken', requestOptions)
                .pipe(map(data => this.accessToken = data));
        }
    }

    private mapJsonTools(tools: Toolsets[]) {
        let i = 0;
        let result = '{';
        tools.forEach(t => {
            i++;
            const number = t.authorized ? t.numberAllowed : 0;
            result += ('"' + t.type_name + '":' + number);
            if (tools.length > i) {
                result += ',';
            }
        });
        result += '}';
        return JSON.parse(result);
    }
    public getDate(date) {
        if (!date) {
            return "NA";
        }
        let d = new Date(date);
        return (('0' + (d.getMonth() + 1)).slice(-2) + "/" + ('0' + d.getDate()).slice(-2) + "/" + d.getFullYear());
    }
    public getCurrPageTotlItem(page, itemPerPage, totalItem) {
        let lastPage = Math.ceil(totalItem / itemPerPage);
        if (page < lastPage) {
            return (page * itemPerPage);
        } else {
            return totalItem;
        }
    }
}
