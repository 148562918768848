import { CmdOrganization } from "../../models";
import { OrganizationEditComponent } from '../../pages/organizations/organization-edit/organization-edit.component';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { AzureAuthholderService } from '../../services/azureauthholder.service';
import { OrganizationService } from '../../services/organization.service';
import { ApplicationService } from '../../services/application.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take, timeout } from "../../../../node_modules/rxjs/operators";
@Component({
    selector: 'app-left-sidebar',
    templateUrl: './left-sidebar.component.html',
    styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {

    isShown: boolean = false;
    currentOrganization: CmdOrganization;
    currentUser: any = {
        role: "USER"
    };
    model: any = {};
    private userProfile: any;
    public modalRef: BsModalRef;
    activeModule = "applications";
    organizations: Array<any> = [];
    states: Array<any> = ["AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"];

    adminShown: boolean = true;
    appShown: boolean = false;
    reportsShown: boolean = false;
    toolShown: boolean = false;

    isLoadingOrgs: boolean = true;


    constructor(public azure: AzureAuthholderService,
        private modalService: BsModalService,
        private userService: UserService,
        private organizationService: OrganizationService,
        private applicationService: ApplicationService,

        private router: Router) {
        azure.userProfile$.subscribe((nextValue) => {
            this.userProfile = nextValue;
        });
        this.organizationService.organizationUpdate.subscribe(() => {
            this.getOrganizations();

        });

        this.organizationService.updateOrganizationList.subscribe(() => {
            this.getOrganizations();
        });

        this.organizationService.refreshOrg.subscribe(() => {
            this.refresh();
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.userService.getCurrentUser().subscribe(user => {
                this.currentUser = user;
                localStorage.setItem('userId', JSON.stringify(this.currentUser.id));
                if (this.currentUser.role == 'SUPERADMIN') {
                    this.getOrganizations();
                    if (this.currentOrganization == undefined) {
                        this.setCurrentOrg(user.organizationId);
                        this.router.navigate(['/applications/']);
                    }
                }
                if (this.currentUser.role == 'ORGADMIN') {
                    this.getOrganizationsForOrgAdmin();
                    if (this.currentOrganization == undefined) {
                        this.setCurrentOrg(user.organizationId);
                        this.router.navigate(['/applications/']);
                    }

                }
            });
            if (localStorage.getItem('org')) {
                this.isShown = true;
            }
            this.currentOrganization = this.organizationService.getCurrentOrganization();
            this.organizationService.currentOrganization.subscribe(selectedOrganization => {
                if (selectedOrganization) {
                    this.currentOrganization = selectedOrganization;
                }
            });
            this.model.state = null;
        }, 3000);
    }


    public setCurrentOrg(orgId) {
        this.organizationService.getById(orgId).subscribe(currentOrganization => {
            this.organizationService.setCurrentOrganization(currentOrganization);
            this.currentOrganization = currentOrganization;
            this.isShown = true;
        });
    }
    public toggleNav(type) {
        switch (type) {
            case 'admin':
                this.adminShown = !this.adminShown;
                this.appShown = false;
                this.toolShown = false; //----
                this.reportsShown = false;
                break;
            case 'app':
                // if(!this.appShown){
                //     this.adminShown = false;
                //     this.appShown = !this.appShown;
                //     this.reportsShown = false;
                //     this.toolShown = false;//----
                // }
                // else{
                //    this.appShown = !this.appShown; //chetu dev
                //     this.toolShown = !this.toolShown;
                // }
                this.adminShown = false;
                this.appShown = !this.appShown;
                this.reportsShown = false;
                this.toolShown = false;
                break;
            case 'tool':
                this.adminShown = false;
                this.appShown = this.appShown;
                this.reportsShown = false;
                this.toolShown = !this.toolShown;
                break;
            case 'report':
                this.adminShown = false;
                this.appShown = false;
                this.toolShown = false;
                this.reportsShown = !this.reportsShown;
                break;
            // case 'tool':

            // this.toolShown = !this.toolShown; // chetu dev
            // break;
        }
    }

    private login() {
        this.azure.login();
    }

    private logout() {
        this.azure.logout();
        this.login();
    }


    toggleSideBar(toggled) {
        if (toggled) {
            document.getElementById("main-sidebar").classList.toggle("toggled");
        }
        else {
            document.getElementById("main-sidebar").classList.remove("toggled");
        }
    }

    getOrganizations() {
        this.isLoadingOrgs = true;
        this.organizationService.getAllOrganizations()
            .subscribe(data => {
                this.processData(data);
                this.isLoadingOrgs = false;
            }, error => {
                console.error(error);
                this.isLoadingOrgs = false;
            });
    }

    getOrganizationsForOrgAdmin() {
        this.isLoadingOrgs = true;
        this.organizationService.getOrganizationsForOrgAdmin()
            .subscribe(data => {
                this.processData(data);
                this.isLoadingOrgs = false;
            }, error => {
                console.error(error);
                this.isLoadingOrgs = false;
            });
    }

    private processData(data) {
        this.organizations = data;
    }

    private refresh() {
        this.organizationService.getAllOrganizations()
            .pipe(take(1))
            .subscribe(data => {
                this.organizations = data;
                if (this.currentOrganization.id && this.organizations.length > 0) {
                    const org = this.organizations.filter(o => o.id === this.currentOrganization.id)[0];
                    this.currentOrganization = org;
                    this.organizationService.setCurrentOrganization(org);
                }
            });
    }

    public openModal() {
        this.organizationService.checkOrgAction(true);
        this.organizationService.changeOrgModalTitle('Add an Organisation');
        var newOrg: CmdOrganization = new CmdOrganization();
        this.router.navigate(['organizations', 0]);
        this.organizationService.setCurrentOrganization(newOrg);
    }
    selectOrganization(org) {
        this.isShown = true;
        this.currentOrganization = org;
        this.organizationService.setCurrentOrganization(org);
        this.router.navigate(['/applications/']);
    }

}
