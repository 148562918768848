import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CmdOrganization, Toolsets } from '../../../models';
import { CmdToolsetAuthorization } from "../../../models/CmdToolsetAuthorization";
import { OrganizationService } from '../../../services/organization.service';
import { InputValidationService } from '../../../services/inputvalidation.service';
import { SpinService } from '../../../services/spin.service';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { Subject, zip, of } from 'rxjs';


@Component({
    selector: 'app-organization-edit',
    templateUrl: './organization-edit.component.html',
    styleUrls: ['./organization-edit.component.scss']
})
export class OrganizationEditComponent implements OnInit {

    public orgAction: boolean;
    public modalTitle: string;
    public curentToolsets: Array<CmdToolsetAuthorization> = [];
    public standardNotes: Array<string> = [];
    public currentText: string = "";
    @ViewChild('fileInput', { static: false }) fileInput;
    @ViewChild('logoImg', { static: false }) logoImg;
    buttonIsDisabled: boolean = false;
    isOrgBeingEdited: boolean = false;
    hasChanges: boolean = false;
    orgId: string;
    organization: CmdOrganization;
    states: Array<any> = ["AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"];
    onClose: Subject<boolean>;
    logoFile: any;
    constructor(
        private router: Router,
        private organizationService: OrganizationService,
        private validationService: InputValidationService,
        private alertService: StickyPopUpService,
        private renderer: Renderer2,
        private spinService: SpinService) {
        this.onClose = new Subject();
        this.organization = {
            id: undefined,
            name: '',
            address: '',
            city: '',
            state: '',
            postalCode: '',
            phone: '',
            analyticsId: '',
            infoAtEmail: '',
            rentWeekMultiplier: 2.33,
            rentFourWeekMultiplier: 6,
            orgKey: '',
        };
    }

    ngOnInit() {
        // get organisation add/edit modal title
        this.organizationService.currentOrgAction.subscribe(orgAction => this.orgAction = orgAction);
        // check if org is being created/edited
        this.organizationService.currentOrgModalTitle.subscribe(modalTitle => this.modalTitle = modalTitle);

        this.organization = this.organizationService.getCurrentOrganization();
        if (this.organization && this.organization.id) {
            this.organizationService.getStandardNotes(this.organization.id).subscribe(notes => {
                this.standardNotes = notes;
            });
        }
        this.getExistToolsets();

        if (!this.organization.id) {
            this.hasChanges = true;
        }
    }

    upload() {
        var self = this;
        let fileBrowser = this.fileInput.nativeElement;
        if (fileBrowser.files.length == 1) {
            this.logoFile = fileBrowser.files[0];
            var reader = new FileReader();
            reader.onload = function (event: any) {
                self.renderer.setAttribute(self.logoImg.nativeElement, 'src', event.target.result);
            }
            reader.readAsDataURL(this.logoFile);
        }
    }

    save() {
        this.spinService.show();
        this.checkToolsets();
        if (this.organization.id) {
            const tasks = zip(
                this.organizationService.update(this.organization),
                this.organizationService.setToolsetAuthorizations(this.organization.id, this.curentToolsets),
                (this.logoFile ? this.organizationService.setLogo(this.organization.id, this.logoFile) : of('fake'))
            );
            tasks.subscribe(data => {
                this.hasChanges = false;
                this.alertService.success('Market has been updated');
                this.router.navigate(['/organizations']);
                this.spinService.hide();
                this.onClose.next(true);
            },
                error => {
                    this.spinService.hide();
                    this.alertService.error('Failed to update market: ' + error.error.message);
                });
        }
        else {
            this.organizationService.create(this.organization)
                .subscribe(data => {
                    const orgId = data['id'];
                    this.organizationService.setCurrentOrganization(data);
                    const tasks = zip(
                        (this.checkIsToolsExist() ? this.organizationService.setToolsetAuthorizations(orgId, this.curentToolsets) : of('fake')),
                        (this.logoFile ? this.organizationService.setLogo(orgId, this.logoFile) : of('fake'))
                    );
                    tasks.subscribe(data => {
                        this.hasChanges = false;
                        this.alertService.success('Market has been created');
                        this.router.navigate(['/organizations']);
                        this.spinService.hide();
                        this.onClose.next(true);
                    });
                },
                    error => {
                        this.spinService.hide();
                        this.alertService.error('Failed to create market: ' + error.error.message);
                    });
        }
    }
    close() {
        this.router.navigate(['/organizations']);
        this.onClose.next(false);
    }

    validateInput() {
        this.buttonIsDisabled = this.validationService.validateEmptyString(this.organization.name);
        this.buttonIsDisabled = this.validationService.validateEmptyString(this.organization.orgKey);
    }

    getExistToolsets() {
        if (this.organization.id) {
            this.organizationService.getAuthorizedToolsets(this.organization.id).subscribe(response => {
                this.curentToolsets = response;
            });
        } else {
            this.curentToolsets = [];
            let cost = new CmdToolsetAuthorization();
            cost.toolsetName = "Cost Calculator";
            cost.type = "COST_CALCULATOR";
            this.curentToolsets.push(cost);

            let search = new CmdToolsetAuthorization();
            search.toolsetName = "Tabbed Data Search";
            search.type = "TAB_DATA_SEARCH";
            this.curentToolsets.push(search);

            let quote = new CmdToolsetAuthorization();
            quote.toolsetName = "Quote Builder";
            quote.type = "QUOTE_BUILDER";
            this.curentToolsets.push(quote);

            let tco = new CmdToolsetAuthorization();
            tco.toolsetName = "Total Cost of Ownership Calculator";
            tco.type = "TCO_CALCULATOR";
            this.curentToolsets.push(tco);
        }
    }

    checkIsToolsExist(): boolean {
        let result = false;
        this.curentToolsets.forEach(t => {
            if (t.authorized)
                result = true;
        });
        return result;
    }
    checkToolsets() {
        this.curentToolsets.forEach(t => {
            if (!t.numberAllowed)
                t.numberAllowed = 0;
        });
    }

    setToolNumber(tool: Toolsets) {
        tool.numberAllowed = +tool.authorized;
    }

    saveSalesFields() {
        this.organizationService.updateStandardNotes(this.organization.id, this.standardNotes).subscribe(result => {
            this.alertService.success('Standard notes have been updated');
        }, error => {
            this.alertService.error('Failed to update standard notes');
        });
    }
    addNote(currentText) {
        if (this.currentText.trim().length > 0) {
            this.standardNotes.push(currentText);
        }
        this.currentText = '';
    }
    removeNote(note) {
        this.standardNotes.splice(this.standardNotes.indexOf(note), 1);
    }
    public notifyChange() {
        this.hasChanges = true;
    }
}
