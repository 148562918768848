import { Component, OnInit } from '@angular/core';
import { CmdAsset } from '../../models';
import { OrganizationService } from '../../services/organization.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { BindProdAssetsModel } from '../../models/BindProdAssetsModel';

@Component({
    selector: 'app-productselectassets',
    templateUrl: 'productselectassets.component.html',
    styleUrls: ['productselectassets.component.scss']
})
export class ProductSelectAssetsComponent implements OnInit {

    actionType: string = "add";
    theAssets: Array<CmdAsset> = [];
    all_products = [];
    selProducts = [];
    public onClose: Subject<BindProdAssetsModel>;
    hasChanges: boolean = false;

    constructor(public bsModalRef: BsModalRef) { }

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    setActionType(type, assets: Array<CmdAsset>) {
        this.actionType = type;
        this.hasChanges = false;
        this.theAssets = assets;
    }


    public onConfirm(): void {
        let result = new BindProdAssetsModel();
        result.assets.push(...this.theAssets.map(function (a) { return a.id }));
        result.products.push(...this.selProducts.map(function (a) { return a.value }));

        this.onClose.next(result);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(undefined);
        this.bsModalRef.hide();
    }


}
