import { Component, OnInit } from '@angular/core';
import { CmdOrganization } from '../../../../models';
import { OrganizationService } from '../../../../services/organization.service';
import { ToolsetsService } from '../../../../services/toolsets.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SpinService } from '../../../../services/spin.service';
import { ActivatedRoute } from '@angular/router';
import { SearchModel } from '../../../../models/SearchModel';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Page } from '../../../../models/page';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { JsPdfFontService } from '../../../../services/jsPdfFont.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from "../../../../shared/confirm-modal/confirm-modal.component";
import { CmdToolsetAuthorization } from '../../../../models/CmdToolsetAuthorization';
import { TcoCalculatorService } from '../../../../services/tco-calculator.service';

@Component({
  selector: 'app-tco-history',
  templateUrl: './tco-history.component.html',
  styleUrls: ['./tco-history.component.css']
})
export class TcoHistoryComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  searchTerm$ = new Subject<string>();
  searchTermTags$ = new Subject<string>();
  currentOrganization: CmdOrganization;
  toolsetsSearch: SearchModel;
  cofirmModalRef: BsModalRef;
  authorizedToolsets: Array<CmdToolsetAuthorization> = [];

  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private toolsetsService: ToolsetsService,
    private tcoService: TcoCalculatorService,
    private alertService: StickyPopUpService,
    private spinService: SpinService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private jsPdfFontService: JsPdfFontService) {
    this.resetPagination();

    route.params
      .pipe(take(1))
      .subscribe(params => {
        if (params['page'] && params['pageSize']) {
          this.toolsetsSearch.page.page = +params['page'];
          this.toolsetsSearch.page.pageSize = +params['pageSize'];
        }
      });
  }


  ngOnInit() {
    this.currentOrganization = this.organizationService.getCurrentOrganization();
    this.searchTerm$
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe(searchText => {
        this.toolsetsSearch.searchText = searchText;
        this.doSearch();
      });

    this.doSearch();
  }

  clearSearch() {
    if (this.toolsetsSearch && this.toolsetsSearch.searchText) {
      this.resetPagination();
      this.doSearch()
    }
  }

  doSearch() {
    this.spinService.show();
    this.toolsetsService.getToolsetsForOrganization(this.organizationService.getCurrentOrganizationId(), this.toolsetsSearch, 'TCO_CALCULATOR')
      .then(data => {
        this.spinService.hide();
        this.toolsetsSearch.results = data;
      }).catch(error => {
        this.spinService.hide();
      });
  }

  sortToolsets(sortBy) {
    this.toolsetsSearch.page.page = 1;
    if (this.toolsetsSearch.page.sortBy == sortBy) {
      if (this.toolsetsSearch.page.sortOrder == "ASC") {
        this.toolsetsSearch.page.sortOrder = "DESC";
      } else {
        this.toolsetsSearch.page.sortOrder = "ASC";
      }
    } else {
      this.toolsetsSearch.page.sortBy = sortBy;
      this.toolsetsSearch.page.sortOrder = "ASC"
    }
    this.doSearch();
  }

  resetPagination() {
    this.toolsetsSearch = new SearchModel();
    this.toolsetsSearch.page = new Page();
  }

  pageChanged($event) {
    this.toolsetsSearch.page.page = $event.page;
    this.toolsetsSearch.page.pageSize = $event.itemsPerPage;
    this.doSearch();
  }

  changePageSize() {
    this.doSearch();
  }

  public deleteToolset(tool: any) {
    this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
    this.cofirmModalRef.content.title = "Delete a Toolset";
    this.cofirmModalRef.content.message = 'Are you sure you want to delete ' + tool.slug + '?';
    this.cofirmModalRef.content.onClose.subscribe(result => {
        if (result === true) {
            this.spinService.show();
            this.toolsetsService.deleteToolset(tool.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.alertService.success('The toolset has been deleted.');
                    this.spinService.hide();
                    this.doSearch();
                    this.checkListToolsets();
                }, error => {
                    this.alertService.error(error.error.message);
                    this.spinService.hide();
                    this.doSearch();
                });
        }
    });
}



  generateReportAsPdf(tco) {
    const tcoData = tco.properties.tcoData;
    const operationalInfo = tcoData.OperationInformation;
    const vehiclePurchaseDetails = tcoData.vehiclePurchaseDetails;
    const financialCost = tcoData.financialCost;
    const fuelConsumption = tcoData.fuelConsumption.averageConsumption;
    const adBlueConsumption = tcoData.adBlueConsumption.averageConsumption;
    const contractedServices = tcoData.contractedServices;
    const usedVehicleLiquidity = tcoData.usedVehicleLiquidity;
    const usedVehicleResale = tcoData.usedVehicleResale;
    const finalResult = tcoData.finalResult;

    var pdf = new jsPDF({
      format: [470, 210]
    });
    var img = new Image();

    this.jsPdfFontService.addFonts(pdf);
    pdf.setFont("ScaniaSansCYRegular", "Normal");
      
    //scania logo on top-left
    img.src = 'assets/images/Scania-Logo-Horiz.png';
    pdf.addImage(img, 'png', 10, 5, 54, 10);

    //completed date on top-right corner
    pdf.setFontSize(10);
    pdf.setTextColor('#414141');
   
    
    pdf.text(this.datePipe.transform(tco.created, "hh:mma MM/dd/yyyy"), 160, 12);

    //Customer name
    pdf.setFontSize(8);
    pdf.setTextColor('#2B70D3');
    pdf.text("Name", 10, 25);

    pdf.setFontSize(12);
    pdf.setTextColor('#414141');
    pdf.text(operationalInfo.customerName, 10, 30);

    //Negotiation volume
    pdf.setFontSize(8);
    pdf.setTextColor('#2B70D3');
    pdf.text("Negotiation Volume (Quantity)", 60, 25);

    pdf.setFontSize(12);
    pdf.setTextColor('#414141');
    pdf.text(operationalInfo.negotiationVolume.toLocaleString('en-US')+'', 60, 30);

    //vehicle lifecycle
    pdf.setFontSize(8);
    pdf.setTextColor('#2B70D3');
    pdf.text("Vehicle Lifecycle", 110, 25);

    pdf.setFontSize(12);
    pdf.setTextColor('#414141');
    pdf.text(operationalInfo.vehicleLifeCycle.toLocaleString('en-US')+'', 110, 30);

    //km monthly
    pdf.setFontSize(8);
    pdf.setTextColor('#2B70D3');
    pdf.text("Km Monthly", 140, 25);

    pdf.setFontSize(12);
    pdf.setTextColor('#414141');
    pdf.text(operationalInfo.vehicleLifeCycle.toLocaleString('en-US')+'', 140, 30);

    //km totle lifetime
    pdf.setFontSize(8);
    pdf.setTextColor('#2B70D3');
    pdf.text("Km Total Lifetime", 170, 25);

    pdf.setFontSize(12);
    pdf.setTextColor('#414141');
    pdf.text(operationalInfo.kmTotalLifetime.toLocaleString('en-US')+'', 170, 30);

    //TCO heading
    pdf.setFontSize(14);
    pdf.setTextColor('#041E42');
    pdf.setFont("ScaniaSansCYHeadline-Regular", "Normal");
    pdf.text("TOTAL COST OF OWNERSHIP", 10, 45);

    pdf.setFontSize(10);
    pdf.setTextColor('#000000');
    pdf.setFont("ScaniaSansCYSemiCondensed-Regular", "Normal");
    pdf.text("Total economy for the operation (Life Cycle)", 10, 55);

    pdf.setFontSize(10);
    pdf.setTextColor('#000000');
    pdf.text("Performance of the financial application of the difference of the final price of the acquisition (Annual Tax Performance)", 10, 75);

    pdf.setFontSize(10);
    pdf.setTextColor('#000000');
    pdf.text("Total economy for operation minus return on financial application", 10, 95);

    pdf.setFontSize(10);
    pdf.setTextColor('#000000');
    pdf.text("Total operational economy based on volume under negotiation", 10, 115);

    pdf.setFontSize(10);
    pdf.setTextColor('#000000');
    pdf.text("Return on investment period (months)", 10, 135);

    //draw line
    pdf.setDrawColor('#E4E4E4');
    pdf.line(50, 155, 160, 155);

    pdf.setFontSize(14);
    pdf.setTextColor('#041E42');
    pdf.setFont("ScaniaSansCYRegular", "Normal")
    pdf.text("Vehicle Purchase Price Difference", 10, 168);

    pdf.setFontSize(14);
    pdf.setTextColor('#041E42');
    pdf.text("Financial Cost Details", 10, 203);

    pdf.setFontSize(14);
    pdf.setTextColor('#041E42');
    pdf.text("Fuel Consumption Details", 10, 298);

    pdf.setFontSize(14);
    pdf.setTextColor('#041E42');
    pdf.text("AdBlue Consumption Details", 10, 333);

    pdf.setFontSize(14);
    pdf.setTextColor('#041E42');
    pdf.text("Contracted Services", 10, 368);

    pdf.setFontSize(14);
    pdf.setTextColor('#041E42');
    pdf.text("Used Vehicle Resale", 10, 403);

    pdf.setFontSize(14);
    pdf.setTextColor('#041E42');
    pdf.text("Used Vehicle Liquidity", 10, 438);

    let xAxis = 10;
    vehiclePurchaseDetails.forEach((element, index: number) => {
      pdf.setFontSize(9);
      pdf.setTextColor('#000000');
      pdf.text(element.productName, xAxis, 175);

      if (index == 0) {
        //final result
        pdf.setFont("ScaniaSansCYSemiCondensed-Regular", "Normal");
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 57, 36, 10, 1, 1, 'F');

        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 77, 36, 10, 1, 1, 'F');

        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 97, 36, 10, 1, 1, 'F');

        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 117, 36, 10, 1, 1, 'F');

        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 137, 36, 10, 1, 1, 'F');

        //vehicle price difference
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 177, 36, 10, 1, 1, 'F');

        //fincancial cost
        pdf.text("Down Payment Amount", 10, 210);
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 212, 37, 10, 1, 1, 'F');

        pdf.text("Monthly Installment Value", 10, 230);
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 232, 37, 10, 1, 1, 'F');

        pdf.text("Down Payment Difference", 10, 250);
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 252, 37, 10, 1, 1, 'F');

        pdf.text("Interest Rate Difference for the Curresponding Financing Contract", 10, 270);
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 272, 37, 10, 1, 1, 'F');

        //fuel cunsumption difference during life cycle
        pdf.text("Fuel Consumption Difference During Lifecycle", 10, 305);
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 307, 37, 10, 1, 1, 'F');

        //adBlue cunsumption difference during life cycle
        pdf.text("AdBlue Consumption Difference During Lifecycle", 10, 340);
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 342, 37, 10, 1, 1, 'F');

        //contracted services
        pdf.text("Maintenance Cost Difference During Vehicle Lifecycle", 10, 375);
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 377, 37, 10, 1, 1, 'F');

        //used vehicle resale
        pdf.text("5-Year Future Used Vehicle Price Difference", 10, 410);
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 412, 37, 10, 1, 1, 'F');

        //used vehicle liquidity
        pdf.text("Capital Cost Difference (stock/days in yard)", 10, 445);
        pdf.setFillColor(43, 112, 211);
        pdf.roundedRect(xAxis, 447, 37, 10, 1, 1, 'F');

      } else {
        //final result
        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 57, 36, 10, 1, 1, 'F');
        pdf.setFont("ScaniaSansCYRegular", "Normal")
        pdf.text(finalResult[index].lifeCycle.toLocaleString('en-US')+'', xAxis + 1, 63);

        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 77, 36, 10, 1, 1, 'F');
        pdf.text(finalResult[index].annualTaxPerformance.toLocaleString('en-US')+'', xAxis + 1, 83);

        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 97, 36, 10, 1, 1, 'F');
        pdf.text(finalResult[index].lifeCycleMinusAnnualTaxPerformance.toLocaleString('en-US')+'', xAxis + 1, 103);

        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 117, 36, 10, 1, 1, 'F');
        pdf.text(finalResult[index].volumeUnderNegotiation.toLocaleString('en-US')+'', xAxis + 1, 123);

        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 137, 36, 10, 1, 1, 'F');
        pdf.text(finalResult[index].returnOnInvestmentPeriod.toLocaleString('en-US')+'', xAxis + 1, 143);

        //vehicle price difference
        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 177, 36, 10, 1, 1, 'F');

        pdf.text(element.vehiclePurchasePriceDifference.toLocaleString('en-US')+'', xAxis + 1, 183);

        //financial cost
        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 212, 37, 10, 1, 1, 'F');
        pdf.text(financialCost[index].financialCostDetails.downPaymentAmount.toLocaleString('en-US')+'', xAxis + 1, 218);

        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 232, 37, 10, 1, 1, 'F');
        pdf.text(financialCost[index].financialCostDetails.monthlyInstallmentValue.toLocaleString('en-US')+'', xAxis + 1, 238);

        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 252, 37, 10, 1, 1, 'F');
        pdf.text(financialCost[index].financialCostDetails.downPaymentDifference.toLocaleString('en-US')+'', xAxis + 1, 258);

        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 272, 37, 10, 1, 1, 'F');
        pdf.text(financialCost[index].financialCostDetails.interestRateDifference.toLocaleString('en-US')+'', xAxis + 1, 278);

        //fuel cunsumption difference during life cycle
        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 307, 37, 10, 1, 1, 'F');
        pdf.text(fuelConsumption[index].consumptionDifference.toLocaleString('en-US')+'', xAxis + 1, 313);

        //adBlue cunsumption difference during life cycle
        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 342, 37, 10, 1, 1, 'F');
        pdf.text(adBlueConsumption[index].consumptionDifference.toLocaleString('en-US')+'', xAxis + 1, 348);

        //contracted services
        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 377, 37, 10, 1, 1, 'F');
        pdf.text(contractedServices[index].maintenanceCostDifference.toLocaleString('en-US')+'', xAxis + 1, 383);

        //used vehicle resale
        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 412, 37, 10, 1, 1, 'F');
        pdf.text(usedVehicleResale.vehicleResalePrice[index].resalePriceDifferenceFromScania.toLocaleString('en-US')+'', xAxis + 1, 418);

        //used vehicle liquidity
        pdf.setFillColor(239, 239, 239);
        pdf.roundedRect(xAxis, 447, 37, 10, 1, 1, 'F');
        pdf.text(usedVehicleLiquidity.stockPeriod[index].capitalCostDifference.toLocaleString('en-US')+'', xAxis + 1, 453);
      }
      xAxis = xAxis + 39;
    });

    pdf.save(operationalInfo.customerName+'-TCO-report.pdf');
  }

  
  private checkListToolsets() {
    this.organizationService.getAuthorizedToolsets(this.currentOrganization.id).subscribe(authedToolsets => {
        this.authorizedToolsets = authedToolsets;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  showLog(toolId:string){
    // document.getElementById("iconhover").style.display = "block";
    document.getElementById(toolId).style.display = "block";
  }

  hideLog(toolId:string){
    // document.getElementById("iconhover").style.display = "none";
    document.getElementById(toolId).style.display = "none";
  }

  getTCOSharings(tcoId:string){
    this.tcoService.getTCOSharingsById(tcoId).subscribe((resp)=>{
            
    },
    (error)=>{
      console.log("Error while fetching sharing data for TCO", error);
    });
  }
}
