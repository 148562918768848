import { Pipe, PipeTransform } from '@angular/core';
import { AssetsService } from '../services/assets.service';

@Pipe({
    name: 'bytes',
    pure: true
})
export class BytesPipe implements PipeTransform {

    constructor(private assetsService: AssetsService) {

    }

    transform(bytesNum: number, args?: any): any {
        if (!bytesNum) {
            return '';
        }
        return this.assetsService.formatBytes(bytesNum);
    }

}
