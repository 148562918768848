import { Injectable } from '@angular/core';

@Injectable()
export class InputValidationService {

    constructor() { }

    validateEmptyString(input) {
        if (/\S/.test(input)) {
            return true;
        }
        else {
            return false;            
        }
    }

    // allow only alphanumeric input for user firstname & lastname
    validateAlphanumeric(input) {
        if((/^[0-9]*$/i.test(input)) || (!/^[a-z0-9]*$/i.test(input))) {
            return false;
        }
        else {
            return true;            
        }
    }

    // set kisok timeout max limit to 1000 seconds
    validatekitoskVideoTimeou(input) {
        if (/^([1-9][0-9]{0,2}|1000)$/.test(input)) {
            return false;
        }
        else {
            return true;            
        }
    }
    
}
