export class CmdAmoritizationForms{
    id: string;
    created: string;
    updated: string;
    title: string;
    vehiclePriceDifference: string;
    fuel: string;
    adBlue: string;
    contractedServices:string;
    usedVehiclePrice: string;
    usedVehicleLiquidity: string;
    financialCost:string;
    monthlyDifference: string;
    initialBalance: string;
    endBalance: string;
    toolset: {
      
    } 
}