import { Component } from '@angular/core';

@Component({    
    selector: 'app-auth-loader',
    templateUrl: 'auth-loader.component.html',
    styleUrls: ['auth-loader.component.scss']
})
export class AuthLoaderComponent {

}
