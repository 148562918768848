import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-column-modal',
  templateUrl: './add-column-modal.component.html',
  styleUrls: ['./add-column-modal.component.css']
})
export class AddColumnModalComponent implements OnInit {
  public columnData: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  onCancel() {
    this.bsModalRef.hide();
  }
  public selectRow(obj) {
    this.event.emit({ columnObj: obj });
    this.bsModalRef.hide();
  }
}
