import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '../../../../services/organization.service';
import { ToolsetsService } from '../../../../services/toolsets.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SpinService } from '../../../../services/spin.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { CacheService } from '../../../../services/cache.service';
import { ToolsetBaseComponent } from '../toolset-base.component';
import { MeasureList } from '../../../../models/CmdToolsets';
import { CmdToolsetType } from '../../../../models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tabdata',
  templateUrl: './tabdata.component.html',
  styleUrls: ['../toolset-edit.component.scss']
})
export class TabdataComponent extends ToolsetBaseComponent {

  sourceArray = [];
  array = [];
  batchSize = 50;
  constructor(public route: ActivatedRoute,
    public router: Router,
    public organizationService: OrganizationService,
    public toolsetsService: ToolsetsService,
    public modalService: BsModalService,
    public spinService: SpinService,
    public alertService: StickyPopUpService,
    public cacheService: CacheService) {
    super(route,
      router,
      organizationService,
      toolsetsService,
      modalService,
      spinService,
      alertService,
      cacheService);
    route.params.subscribe(params => {
      if (params['toolId'] === 'NEW') {
        this.hasChanges = true;
        this.editToolset.id = '';
        this.editToolset.type = 'TAB_DATA_SEARCH';
        this.editToolset.usageType = CmdToolsetType.RENT;
        this.editToolset.includeInQuotebuilder = false;
        this.editToolset.properties = new MeasureList();
      } else
        this.toolsetsService.getToolsetById(params['toolId'])
          .pipe(takeUntil(this.destroy$))
          .subscribe(resp => {
            this.editToolset = resp;
            this.sourceArray = this.editToolset.products;
            this.array = this.editToolset.products.length >= this.batchSize ? this.editToolset.products.slice(0, this.batchSize) : this.editToolset.products;
            if (!this.editToolset.properties || !this.editToolset.properties.measures_list) {
              this.editToolset.properties = new MeasureList();
            }
          }, error => {
            console.error(error);
            this.alertService.error('Failed to load toolset: ' + error.error.message);
            this.isError = true;
          });

    });
  }

  onScrollDown(e) {
    if ((e.srcElement.scrollTop / e.srcElement.scrollHeight) > .75 && (this.array.length < this.sourceArray.length)) {
      if ((this.sourceArray.length - this.array.length) > this.batchSize) {
        this.array.push(...this.sourceArray.slice(this.array.length, this.array.length + this.batchSize));
      }
      else {
        this.array.push(...this.sourceArray.slice(this.array.length, this.sourceArray.length));
      }
    }
  }

  delProd(prod: any) {
    super.delProd(prod);
    this.sourceArray = this.editToolset.products;
    this.array = this.editToolset.products.length >= this.batchSize ? this.editToolset.products.slice(0, this.batchSize) : this.editToolset.products;
  }
  
  addProd() {
    super.addProd((newData) => {
      this.sourceArray = this.editToolset.products;
      this.array = this.editToolset.products.length >= this.batchSize ? this.editToolset.products.slice(0, this.batchSize) : this.editToolset.products;
    });
  }

  validData() { }
}
