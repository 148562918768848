import {AssetsService} from '../../../services/assets.service';
import {StickyPopUpService} from '../../../services/toast/stickyPopUp.service';
import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';


@Component({
    selector: 'app-theme-edit',
    templateUrl: './theme-edit.component.html',
    styleUrls: ['./theme-edit.component.css']
})
export class ThemeEditComponent implements OnInit {

    theme: any;
    cssContents: any;
    editorOptions: any;
    hasChanges: boolean = false;
    forIgnoreFirstChange = 0;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private assetService: AssetsService,
        private domSanitizer: DomSanitizer, private toast: StickyPopUpService) {

        this.editorOptions = {
            automaticLayout: true,
//            lineNumbers: false,
//            roundedSelection: false,
//            scrollBeyondLastLine: false,
//            wrappingColumn: -1,
            folding: true,
//            renderLineHighlight: false,
//            overviewRulerLanes: 0,
//            // theme: "vs-dark",
//            customPreventCarriageReturn: true,
//            scrollbar: {
//                vertical: 'hidden',
//                horizontal: 'auto',
//                useShadows: false
//            }
        }
    }

        ngOnInit() {
            this.theme = this.route.params.subscribe(params => {
                const id = params['themeId'];
                this.assetService.getByIdWithContents(id)
                    .pipe(map(theme => {this.theme = theme}))
                    .subscribe(theme => {

                });
            });
        }

        save(){
            this.assetService.updateByString(this.theme).subscribe(data => {
                this.hasChanges = false;
                this.toast.success("Theme has been updated.");
            }, error => {
                console.log(error);
                this.toast.error("Theme could not be saved.");
            });
        }

        close() {
            this.router.navigate(['/themes']);
        }

        public notifyChange(){            
            if(this.forIgnoreFirstChange > 1){
                this.hasChanges = true;
            } else {
                this.forIgnoreFirstChange++;
            }
        }
    }
