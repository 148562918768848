import { AssetsService } from '../../services/assets.service';
import { OrganizationService } from '../../services/organization.service';
import { SpinService } from '../../services/spin.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AssetpdfmodifierComponent } from '../assetpdfmodifier/assetpdfmodifier.component';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Component({
    selector: 'app-asset-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css']
})
export class AssetUploadComponent implements OnInit {
    @ViewChild('fileInput', { static: false }) fileInput;
    uploadModalRef: BsModalRef;
    public tags: Array<string> = [];
    public selectedTags: Array<any> = [];
    public onClose: Subject<boolean>;
    public completedFiles: Array<any> = [];

    public  : Boolean = false;
    remaining: number = 0;
    totalFiles: number = 0;
    totalSize: number = 0;
    loadedSize: number = 0;
    loadedPercent: number = 0;
    checkImageExist :boolean ;
    uploadType: string = "image";
    buttonIsDisabled: boolean = true;
    checked: boolean = false;
    disableAttachementCheckbox:boolean=true;
    allFileTypes: Array<string> = [".png, .jpg, .jpeg, video/mp4, .pdf, .doc, .odt, .docx, .xls, .xlsx, .css"];
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private apiData = new BehaviorSubject<any>(null);
    public apiData$ = this.apiData.asObservable();
    uploadInProgress: boolean;

    constructor(public bsModalRef: BsModalRef,
        private assetService: AssetsService,
        private modalService: BsModalService,
        private orgService: OrganizationService,
        private alertService: StickyPopUpService,
        private spinService: SpinService) { }

    fileTypes(): Array<string> {
        return this.allFileTypes;
    }


    ngOnInit() {
        this.orgService.getTags().subscribe(tags => {
            this.tags = tags;
        });
        this.onClose = new Subject();
    }
    onSelected($event) {
        this.selectedTags = $event;
    }
    onConfirm() {
        let fileBrowser = this.fileInput.nativeElement;
        if (fileBrowser.files) {
            this.totalFiles = fileBrowser.files.length;
            this.remaining = fileBrowser.files.length;
            this.spinService.show();
            this.uploadInProgress = true;
            var uploadFiles = [];
            var uploadObservables = [];
            this.completedFiles = [];
            this.totalSize = 0;
            for (var x = 0; x < fileBrowser.files.length; x++) {
                var aFile = fileBrowser.files[x];
                this.totalSize += aFile.size;
                this.completedFiles.push({
                    file: aFile,
                    status: 'loading'
                });
                this.assetService.uploadAsset(this.orgService.getCurrentOrganizationId(), this.selectedTags, aFile,this.checked)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(file => {
                        if (file.type == "pdf" && this.checked == false) {
                            this.spinService.hide();
                            this.setStatus(this.completedFiles, file, 'success');
                            this.assetService.response.next(file.files);
                            
                                this.uploadModalRef = this.modalService.show(AssetpdfmodifierComponent, { ignoreBackdropClick: true, keyboard: false });
                                this.uploadModalRef.content.title = "Upload Files";
                                this.uploadModalRef.content.onClose.subscribe(result => {
                                    if (result === true) {
                                        this.bsModalRef.hide();
                                        this.onClose.next(true);
                                    }
                                })
                            

                        } else {
                            this.remaining--;
                            this.loadedSize += file.size;
                            this.loadedPercent = (this.loadedSize / this.totalSize) * 100;
                            this.setStatus(this.completedFiles, file, 'success');
                            if (this.remaining == 0) {
                                this.spinService.hide();
                                this.alertService.success("Uploaded " + fileBrowser.files.length + " files");
                                this.onClose.next(true);
                                this.bsModalRef.hide();
                            }
                        }

                    }, error => {
                        this.remaining--;
                        this.completedFiles.unshift({ status: 'error', error: error.message });
                        if (this.remaining == 0) {
                            this.spinService.hide();
                            this.uploadInProgress = false;
                        }
                    });
            }
        }
    }

    setData(data) {
        this.apiData.next(data)
    }

    private setStatus(list: Array<any>, file, status) {
        list.forEach(item => {
            if (item.file.name == file.originalFileName) {
                item.status = status;
            }
        });
    }

    public onCancel(): void {
        if (this.spinService.shown) {
            this.spinService.hide();
        }
        this.ngUnsubscribe.next(undefined);
        this.onClose.next(undefined);
        this.bsModalRef.hide();
    }
    formatBytes(bytes): string {
        return this.assetService.formatBytes(bytes);
    };

    validateFileInput() {
        let fileBrowser = this.fileInput.nativeElement;
        this.checkImageExist = false;
        this.buttonIsDisabled = true;
        this.disableAttachementCheckbox=true;
        fileBrowser.files.forEach(element => {
            if ((element.type != 'application/pdf') && (element.type != 'application/msword') && (element.type != 'image/jpeg') && (element.type != 'image/jpg') && (element.type != 'image/png') && (element.type != 'text/css') && (element.type != 'video/mp4') && (element.type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && (element.type != 'application/vnd.ms-excel') && (element.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && (element.type != 'application/vnd.oasis.opendocument.text')) {
                this.checkImageExist = true;
            }
            if(element.type == 'application/pdf'){
                this.disableAttachementCheckbox=false;
            }
        });
        if(this.checkImageExist == false){
            this.buttonIsDisabled = false;
        }
        // if (fileBrowser.files.length) {
        //     this.buttonIsDisabled = false;
        // }
        // else {
        //     this.buttonIsDisabled = true;
        // }
    }
    dialogShowHide(status:boolean){
        this.checked=status;
    }
}
