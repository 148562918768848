import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CmdApplication } from '../../models/CmdApplication';

@Component({
    selector: 'applicationbutton',
    templateUrl: './applicationbutton.component.html',
    styleUrls: ['./applicationbutton.component.css']
})
export class ApplicationButtonComponent{

    
    componentId:string;
    @Input("app") app: CmdApplication;
    @Output("onpublish") publish: EventEmitter<CmdApplication> = new EventEmitter();
    @Output("ondelete") delete: EventEmitter<CmdApplication> = new EventEmitter();
    @Output("onrollout") rollout: EventEmitter<CmdApplication> = new EventEmitter();
    @Output("onclone") clone: EventEmitter<CmdApplication> = new EventEmitter();
    constructor() {
    	this.componentId = new Date().getTime() + '';
    }

    ngOnInit() {
    }


  	startToPublish(app:CmdApplication){
	    this.publish.emit(this.app);
	    return false;
  	}
  	startToDelete(app:CmdApplication){
	    this.delete.emit(this.app);
	    return false;
  	}
  	startToRollout(app:CmdApplication){
	    this.rollout.emit(this.app);
	    return false;
  	}
  	startToClone(app:CmdApplication){
	    this.clone.emit(this.app);
	    return false;
  	}  	  	  	
}
