import { CmdAsset } from './';
import { CmdWidget } from './CmdWidget';

export class CmdButton extends CmdWidget{
    label: string;
    background: CmdAsset;
    buttonType?:string = "image";
    content?:string;
    
    constructor(id:string){
        super(id);
        this.type = "Button";
    }
}