import { CmdApplication, CmdCollection, CmdUser, CmdCollectionItem, CmdSlide } from '../../../models';
import { WidgetValidator } from '../../../models/WidgetValidator';
import { ApplicationService } from '../../../services/application.service';
import { CollectionService } from '../../../services/collection.service';
import { OrganizationService } from '../../../services/organization.service';
import { SpinService } from '../../../services/spin.service';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { UserService } from '../../../services/user.service';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DragulaService } from "ng2-dragula";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-collections',
    templateUrl: './collections.component.html',
    styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, OnDestroy {
    private confirmModalRef: BsModalRef;
    application: CmdApplication;
    tracks: Array<string> = [];
    currentCollection: CmdCollection;
    currentUser: CmdUser;
    collectionId: string = 'NEW';
    applicationId: string;
    hasChanges: boolean = false;
    public validationErrors: Array<any> = [];
    constructor(private route: ActivatedRoute,
        private appService: ApplicationService,
        private colService: CollectionService,
        private userService: UserService,
        private router: Router,
        private orgService: OrganizationService,
        private alertService: StickyPopUpService,
        private spinService: SpinService,
        private modalService: BsModalService,
        private widgetValidator: WidgetValidator,
        private dragulaService: DragulaService) {
        dragulaService.setOptions('assets-bag-one', {
            invalid: function (el, handle) {
                return el.classList.contains('widget-container');
            }
        });
    }
    ngOnDestroy() {
        this.dragulaService.destroy('assets-bag-one');
    }

    ngOnInit() {
        this.userService.getCurrentUser().subscribe(user => this.currentUser = user);
        this.orgService.getTags().subscribe(theTags => { this.tracks = theTags });
        this.route.params.subscribe(params => {
            this.collectionId = params['collectionId'];
            this.applicationId = params['appId'];
            this.appService.getById(this.applicationId).subscribe(app => {
                this.application = app;
            });
            if (this.collectionId === 'NEW') {
                this.currentCollection = new CmdCollection('');
                this.currentCollection.title = 'NEW';
                this.currentCollection.tags = [];
                this.currentCollection.applicationId = this.applicationId;
            }
            else {
                this.colService.getById(this.collectionId).subscribe(col => {
                    this.currentCollection = col;
                }, error => {
                    this.alertService.error("Failed to load collection: " + error.error.message);
                });
            }
        });
        this.orgService.getTags().subscribe(theTags => { this.tracks = theTags });
    }
    onTagsChanged(tags) {
        this.currentCollection.tags = tags;
    }
    save() {
        if (this.validate().length == 0) {
            this.spinService.show();
            if (this.currentCollection.id != undefined && this.currentCollection.id.length > 0) {
                this.colService.save(this.currentCollection)
                    .pipe(finalize(() => this.spinService.hide()))
                    .subscribe(scene => {
                        this.alertService.success("Collection updated successfully.");
                    }, error => {
                        this.spinService.hide();
                        this.alertService.error("Failed to update collection: " + error.error.message);
                    });
            }
            else {
                this.currentCollection.applicationId = this.applicationId;
                this.colService.create(this.currentCollection)
                    .pipe(finalize(() => this.spinService.hide()))
                    .subscribe(col => {
                        this.router.navigate(['/applications/' + this.applicationId + '/collections/' + col.id]);
                        this.alertService.success("Collection saved successfully.");
                    }, error => {
                        this.alertService.error("Failed to update collection: " + error.error.message);
                    });
            }
        }
    }

    publish() {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want to publish this application?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.spinService.show();
                    this.appService.publish(this.applicationId, this.currentUser.id)
                        .pipe(finalize(() => this.spinService.hide()))
                        .subscribe(app => {
                            this.alertService.success("A new version (" + app.version + ") of your application has been published.");
                        }, error => {
                            this.spinService.hide();
                            this.alertService.error("Failed to publish: " + error.error.message);
                        });
                }
            });
    }

    validate() {
        this.validationErrors = [];
        if (this.currentCollection) {
            if (!this.currentCollection.title) {
                this.validationErrors.push("Collections must have a title.");
            }
            if (!this.currentCollection.items || this.currentCollection.items.length == 0) {
                this.validationErrors.push("Collections must have at least one Collection Item.");
            }
            if (this.currentCollection.items && this.currentCollection.items.length > 0) {

                //if there is more than one collection item, then they all have thumbnails
                var hasSlideOnly;
                if (this.currentCollection.items.length > 1) {
                    for (var i = 0; i < this.currentCollection.items.length; i++) {
                        var anItem: CmdCollectionItem = this.currentCollection.items[i];
                        if (!anItem.thumbnail) {
                            this.validationErrors.push("Only single item collections can be built without a thumbnail.  Collections that have multiple collection items must have a thumbnail for each collection item.");
                            break;
                        }
                    }
                }

                this.currentCollection.items.forEach(item => {
                    if (item.slideDeck && item.slideDeck.slides && item.slideDeck.slides.length == 0) {
                        this.validationErrors.push("Collection Items must have at least one Slide");
                    }
                    else if (item.slideDeck.requestInformation && (!item.slideDeck.actionData.emailFormTitle)) {
                        this.validationErrors.push("Collection Items that support emails must have an Email Form Title.");
                    }
                    else if (item.slideDeck.requestInformation && (!item.slideDeck.actionData.templateId)) {
                        this.validationErrors.push("Collection Items that support emails must have an Email Template selected.");
                    }
                });
            }
        }
        return this.validationErrors;
    }

}
