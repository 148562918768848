import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SearchModel } from '../../models/SearchModel';
import { CmdOrganization } from '../../models';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../../services/organization.service';
import { SpinService } from '../../services/spin.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { Page } from '../../models/page';
import { FormService } from '../../services/form.service';
import { FormApiService } from '../../services/form.api.service';
import { CmdFormModel } from '../../models/CmdFormModel';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-forms',
    templateUrl: 'forms.component.html',
    styleUrls: ['forms.component.scss']
})
export class FormsComponent implements OnInit {

    destroy$: Subject<boolean> = new Subject<boolean>();
    uploadModalRef: BsModalRef;
    cofirmModalRef: BsModalRef;
    data: SearchModel;
    formsSearch: SearchModel;
    searchTerm$ = new Subject<string>();
    searchTermTags$ = new Subject<string>();
    selProduct: string = '1';
    currentOrganization: CmdOrganization;

    //forms: FormListModel[];

    constructor(private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private organizationService: OrganizationService,
        private spinService: SpinService,
        private formService: FormService,
        private formApiService: FormApiService,
        private alertService: StickyPopUpService) {

        this.resetPagination();
        route.params
            .pipe(take(1))
            .subscribe(params => {
                if (params['page'] && params['pageSize']) {
                    this.formsSearch.page.page = +params['page'];
                    this.formsSearch.page.pageSize = +params['pageSize'];
                }
            });
    }

    ngOnInit() {
        //this.getForms();
        this.getFormsDb();
    }

    getFormsDb() {
        this.searchTerm$
            .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchText => {
                this.formsSearch.searchText = searchText;
                this.formsSearch.page.page = 1;
                this.doSearch();
            });
        this.searchTermTags$
            .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchTag => {
                this.formsSearch.searchTags = searchTag;
                this.formsSearch.page.page = 1;
                this.doSearch();
            });

        this.currentOrganization = this.organizationService.getCurrentOrganization();
        this.doSearch();
        this.organizationService.getCurrentOrganizationObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.doSearch();
                this.currentOrganization = this.organizationService.getCurrentOrganization();
            });
    }


    doSearch(): any {
        this.spinService.show();
        this.formApiService.getFormsForOrganization(this.organizationService.getCurrentOrganizationId(), this.formsSearch)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.spinService.hide();
                this.formsSearch.results = data;
            },
                error => {
                    this.spinService.hide();
                });
    }

    public addForm() {
        this.router.navigate(['/form-builder', 0]);
    }

    public edititem(formId: string) {
        this.router.navigate(['/form-builder', formId]);
    }

    public showData(formId: string) {
        this.router.navigate(['/form-data', formId]);
    }

    public deleteItem(form: CmdFormModel) {
        this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = "Delete a Form";
        this.cofirmModalRef.content.message = 'Are you sure you want to delete ' + form.title + '?';
        this.cofirmModalRef.content.onClose.subscribe(result => {
            if (result === true) {
                this.spinService.show();

                // Remove from Db
                this.formApiService.deleteForm(form.id).subscribe(resp => {

                    // if success, remove from FormIO server
                    this.formService.deleteForm(form.formId).subscribe(resp => {
                        this.doSearch();
                        this.spinService.hide();
                    }, error => {
                        console.log(error);
                        this.spinService.hide();
                        this.doSearch();
                    });

                }, error => {
                    this.spinService.hide();
                    console.log(error);
                    this.alertService.error('Failed to Remove Form(API): ' + error.error.message);
                });
            }
        });
    }

    resetPagination(): any {
        this.formsSearch = new SearchModel();
        this.formsSearch.page = new Page();
    }

    public pageChanged($event) {
        this.formsSearch.page.page = $event.page;
        this.formsSearch.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }

    public changePageSize() {
        this.doSearch();
    }
	async generateTemplate(form: CmdFormModel){
		
		this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = "Generate Email Template?";
        this.cofirmModalRef.content.message = 'Are you sure you want to generate an email template for this form?';
        this.cofirmModalRef.content.onClose.subscribe(async result => {
            if (result === true) {
                this.spinService.show();
				try{
					await this.formApiService.generateEmailTemplate(form.id);
					this.alertService.success("Email template has been generated.");
					this.getFormsDb();
				}
				catch(e){
					this.alertService.error("Failed to generate email template.");
				}
				finally{
					this.spinService.hide();
				}
			}
		});
	}
}
