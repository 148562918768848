import { CmdWidget } from './CmdWidget';

export class CmdTextblock extends CmdWidget{
    content: string;
    constructor(id:string){
        super(id);
        this.type = "TextBlock";
    }
    
    validate():boolean{
        if(!super.validate()){
            return false;
        }
        return this.content != undefined;
    }
}