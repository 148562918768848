import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CmdCollection } from '../models';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { CacheService } from './cache.service';
import { AzureAuthholderService } from './azureauthholder.service';


@Injectable()
export class CollectionService {

    constructor(private azureholder: AzureAuthholderService, private http: HttpClient, 
        private envService: EnvironmentSpecificService,
        private cacheService: CacheService) {
    }

    getById(id: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.get(this.envService.envSpecific.baseServerUrl + '/api/collections/' + id, requestOptions);
    }

    /**
     * Retrieves the scenes for an application and supports paging.
     */
    getForApplication(appId: string): Promise<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
       
        return new Promise((resolve,reject) => {
            if(this.cacheService.isInCache(CacheService.COLLECTION,appId)){
                resolve(this.cacheService.get(CacheService.COLLECTION,appId));
            }
            else{
                let parms = new HttpParams();
                parms = parms.set('applicationId', appId);
                const requestOptions = { params: parms, headers: headers };
                let res = this.http.get(this.envService.envSpecific.baseServerUrl + '/api/collections/application', requestOptions).toPromise();
                res.then(cols => {
                    this.cacheService.set(CacheService.COLLECTION, appId, cols);
                    resolve(cols);
                });
            }
        });
    }

    search(orgId: string, applicationId, searchText, tags, page): Observable<Array<CmdCollection>> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let params = page.toParams()
            .set('appId', applicationId)
            .set('orgId', orgId)
            .set('searchText', searchText);
        if (tags != undefined && tags.length > 0) {
            params = params.append('tags', tags);
        }
        const requestOptions = {params: params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/collections/search', requestOptions);
    }

    delete(collection: CmdCollection): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        this.cacheService.clear(CacheService.COLLECTION);
        return this.http.delete(this.envService.envSpecific.baseServerUrl + '/api/collections/' + collection.id, requestOptions);
    }

    create(collection: CmdCollection): Observable<CmdCollection> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        this.cacheService.clear(CacheService.COLLECTION);
        collection.slug = collection.title.replace(/[^0-9A-Za-z]/gi, '-').toLowerCase();
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/collections', collection, requestOptions);
    }

    save(collection: CmdCollection): Observable<CmdCollection> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        this.cacheService.clear(CacheService.COLLECTION);
        return this.http.put<any>(this.envService.envSpecific.baseServerUrl + '/api/collections', collection, requestOptions);
    }
    
	clone(id:string):Observable<CmdCollection>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
		this.cacheService.clear(CacheService.COLLECTION);
        return this.http.put<any>(this.envService.envSpecific.baseServerUrl + '/api/collections/' + id + '/clone', requestOptions, {});
	}
}
