import { CmdWidget } from './CmdWidget';
export class CmdMenuItem extends CmdWidget{
    label: string;
    constructor(id:string){
        super(id);
        this.type = "MenuItem"
    }
    
    
    validate():boolean{
        if(!super.validate()){
            return false;
        }
        
        return this.label != undefined;
    }
}