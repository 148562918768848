import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-requiredvalidator',
    templateUrl: './requiredvalidator.component.html',
    styleUrls: ['./requiredvalidator.component.scss']
})
export class RequiredvalidatorComponent implements OnInit {

    constructor() {}

    ngOnInit() {
    }

    @Input() field:any;
    
}
