import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrganizationService } from '../../services/organization.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { SpinService } from '../../services/spin.service';
import { ProductsService } from '../../services/products.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-upload-product',
    templateUrl: 'uploadproduct.component.html',
    styleUrls: ['uploadproduct.component.scss']
})
export class UploadProductComponent implements OnInit {
    @ViewChild('fileInput', { static: false }) fileInput;
    public onClose: Subject<boolean>;
    public completedFiles: Array<any> = [];
    public uploadInProgress: Boolean = false;
    public title: string;
    remaining: number = 0;
    totalFiles: number = 0;
    totalSize: number = 0;
    loadedSize: number = 0;
    loadedPercent: number = 0;
    buttonIsDisabled: boolean = true;
    public allFileTypes: Array<string> = [".zip"];
    public uploadType: string;
    public uploadTo: string = 'product';
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(public bsModalRef: BsModalRef,
        private productService: ProductsService,
        private orgService: OrganizationService,
        private alertService: StickyPopUpService,
        private spinService: SpinService) { }

    fileTypes(): Array<string> {
        return this.allFileTypes;
    }


    ngOnInit() {
        this.onClose = new Subject();
    }

    onConfirm() {
        let fileBrowser = this.fileInput.nativeElement;
        if (fileBrowser.files) {
            this.prepareUpload(fileBrowser);
            for (var x = 0; x < fileBrowser.files.length; x++) {
                var aFile = fileBrowser.files[x];
                this.totalSize += aFile.size;
                this.completedFiles.push({
                    file: aFile,
                    status: 'loading'
                });
                // Upload Product
                if (this.uploadTo === 'product') {
                    if (this.uploadType === 'zip') {
                        this.uploadProductZIP(aFile, fileBrowser);
                    } else
                        if (this.uploadType === 'csv') {
                            this.uploadProductCSV(aFile, fileBrowser);
                        } else {
                            this.uploadError('unrecognized type');
                        }
                } else
                    // Upload Competitors
                    if (this.uploadTo === 'competitors') {
                        if (this.uploadType === 'zip') {
                            this.uploadCompetitorsZIP(aFile, fileBrowser);
                        } else
                            if (this.uploadType === 'csv') {
                                this.uploadCompetitorCSV(aFile, fileBrowser);
                            } else {
                                this.uploadError('unrecognized type');
                            }
                    } else {
                        this.uploadError('unrecognized type to');
                    }
            }
        }
    }





    private setStatus(list: Array<any>, file, status) {
        list.forEach(item => {
            if (item.file.name == file.originalFileName) {
                item.status = status;
            }
        });
    }

    public onCancel(): void {
        if (this.spinService.shown) {
            this.spinService.hide();
        }
        this.ngUnsubscribe.next(undefined);
        this.onClose.next(undefined);
        this.bsModalRef.hide();
    }
    formatBytes(bytes): string {
        return this.productService.formatBytes(bytes);
    };

    validateFileInput() {


        let fileBrowser = this.fileInput.nativeElement;
        if (fileBrowser.files.length) {
            this.buttonIsDisabled = false;
        }
        else {
            this.buttonIsDisabled = true;
        }
    }

    private prepareUpload(fileBrowser: any): any {
        this.totalFiles = fileBrowser.files.length;
        this.remaining = fileBrowser.files.length;
        this.spinService.show();
        this.uploadInProgress = true;
        var uploadFiles = [];
        var uploadObservables = [];
        this.spinService.hide();
    }

    private uploadProductZIP(aFile: any, fileBrowser: any): any {
        this.productService.uploadProductZIP(this.orgService.getCurrentOrganizationId(), aFile)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(file => {
                this.uploadSuccess(file, fileBrowser);
            }, error => {
                this.uploadError(error);
            });
    }

    private uploadProductCSV(aFile: any, fileBrowser: any) {
        this.productService.uploadProductCSV(this.orgService.getCurrentOrganizationId(), aFile)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(file => {
                this.uploadSuccess(file, fileBrowser);
            }, error => {
                this.uploadError(error);
            });
    }

    private uploadCompetitorCSV(aFile: any, fileBrowser: any) {
        this.productService.uploadCompetitorsCSV(this.orgService.getCurrentOrganizationId(), aFile)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(file => {
                this.uploadSuccess(file, fileBrowser);
            }, error => {
                this.uploadError(error);
            });
    }

    private uploadCompetitorsZIP(aFile: any, fileBrowser: any) {
        this.productService.uploadCompetitorsCSV(this.orgService.getCurrentOrganizationId(), aFile)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(file => {
                this.uploadSuccess(file, fileBrowser);
            }, error => {
                console.log(error);

                this.uploadError(error);
            });
    }

    private uploadSuccess(file: any, fileBrowser: any): any {
        this.remaining--;
        this.loadedSize += file.size;
        this.loadedPercent = (this.loadedSize / this.totalSize) * 100;
        this.setStatus(this.completedFiles, file, 'success');
        if (this.remaining == 0) {
            this.spinService.hide();
            this.alertService.success("Uploaded " + fileBrowser.files.length + " files");
            this.onClose.next(true);
            this.bsModalRef.hide();
        }
    }

    private uploadError(error: any): any {
        this.remaining--;
        this.completedFiles.unshift({ status: 'error', error: error.error });
        if (this.remaining == 0) {
            this.spinService.hide();
        }
    }

    public clear() {

        this.completedFiles = [];
        this.totalSize = 0;
        this.uploadInProgress = false;

        if (this.fileInput.nativeElement.files.length) {
            this.validateFileInput();
        }
    }
}

