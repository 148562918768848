import { CmdBaseModel } from './';
export class CmdAsset extends CmdBaseModel{
    type?: string;
    name?: string;
    title?: string;
    originalFileName?: string;
    folder?: string;
    size?: number;
    url?: string;
    thumbnailUrl?: string;
    mimeType?: string;
    version?: number;
    state?:string;
    tags?:Array<string>;
    content?:string;
    selected?:boolean = false;
    applications?:Array<string>;
}