import { CmdRightMenuItem } from "../../../../models/CmdRightMenuItem";
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from "rxjs";

@Component({
  selector: 'app-rightmenueditor',
  templateUrl: './rightmenueditor.component.html',
  styleUrls: ['./rightmenueditor.component.css']
})
export class RightmenueditorComponent implements OnInit {

  public applicationId: string;
  public widgetData: CmdRightMenuItem = new CmdRightMenuItem();
  public onClose: Subject<CmdRightMenuItem>;
  public rightMenuForm: FormGroup;
  constructor(public bsModalRef: BsModalRef, public options: ModalOptions) {
    this.applicationId = options.initialState["applicationId"];
    this.widgetData = options.initialState["widgetData"];
  }

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {

    this.onClose.next(this.widgetData);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(undefined);
    this.bsModalRef.hide();
  }

  public isValid(): boolean {
    if (this.widgetData.displayName.trim().length > 0 &&
      ((this.widgetData.actionType == 'BACK' ||
        this.widgetData.actionType == 'HOME' ||
        this.widgetData.actionType == 'EXIT' ||
        this.widgetData.actionType == 'NONE' ||
        this.widgetData.actionType == 'FORM_HISTORY') ||
        this.widgetData.actionType == 'SALES_QUOTE_HISTORY') ||
      (this.widgetData.actionType == 'NAVIGATION' && this.widgetData.actionData && this.widgetData.actionData.slug) ||
      (this.widgetData.actionType == 'EMAIL' && this.widgetData.actionData && this.widgetData.actionData.templateId) ||
      (this.widgetData.actionType == 'COLLECTION' && this.widgetData.actionData && this.widgetData.actionData.slug) ||
      (this.widgetData.actionType == 'COST_CALCULATOR' && this.widgetData.actionData && this.widgetData.actionData.slug) ||
      (this.widgetData.actionType == 'TAB_DATA_SEARCH' && this.widgetData.actionData && this.widgetData.actionData.slug) ||
      (this.widgetData.actionType == 'QUOTE_BUILDER' && this.widgetData.actionData && this.widgetData.actionData.slug) ||
      (this.widgetData.actionType == 'EXTERNAL_WEBSITE' && this.widgetData.actionData && this.widgetData.actionData.url) ||
      (this.widgetData.actionType == 'INTERNAL_WEBSITE' && this.widgetData.actionData && this.widgetData.actionData.url)) {
      return true;
    }
    else {
      return false;
    }
  }

}
