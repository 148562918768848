import { CmdEmailForm, CmdScene } from '../../models';
import { WidgetEditor } from '../widgeteditor';
import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-emailform',
  templateUrl: './emailform.component.html',
  styleUrls: ['./emailform.component.css']
})
export class EmailformComponent extends WidgetEditor implements OnInit {
    widgetData: CmdEmailForm;
    constructor() {
        super();
    }

    ngOnInit() {
    }
    @Input() set widget(data: CmdEmailForm) {
        this.widgetData = data;
    }

}
