import { Component, OnInit, OnDestroy } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { BaseActionTypeSelectorComponent } from '../baseactiontypeselector';
import { CollectionService } from '../../../services/collection.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionSelectorComponent extends BaseActionTypeSelectorComponent implements OnInit, OnDestroy {

    constructor(public widgetService: WidgetService,
        private collectionService: CollectionService) {
        super(widgetService);
    }
    
    _sub:Subscription;
    ngOnInit() {
        this.collectionService.getForApplication(this.appId).then(cols => {
            this.rows = cols;
        });
    }
    ngOnDestroy(){
    }
}