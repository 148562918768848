import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProductModel, ProductCompetitor, SearchCompetitorModel } from '../../../models/CmdProduct';
import { Router, ActivatedRoute } from '@angular/router';
import { CmdOrganization, CmdAsset } from '../../../models';
import { OrganizationService } from '../../../services/organization.service';
import { ProductsService } from '../../../services/products.service';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MedialibraryComponent } from '../../../shared/medialibrary/medialibrary.component';
import { MedialibraryService } from '../../../shared/medialibrary/medialibrary.service';
import { CompetitorEditComponent } from '../../../shared/competitor-edit/competitor-edit.component';
import { TagModel } from '../../../models/TagModel';


@Component({
    selector: 'app-product-edit',
    templateUrl: 'product-edit.component.html',
    styleUrls: ['product-edit.component.scss']
})
export class ProductEditComponent implements OnInit, OnDestroy {

    destroy$: Subject<boolean> = new Subject<boolean>();
    uploadModalRef: BsModalRef;
    organization: CmdOrganization;
    editProduct: ProductModel;
    hasChanges: boolean = false;
    tags_list: Array<TagModel> = [];
    properties_list: Array<TagModel> = [];
    competingProds: SearchCompetitorModel;
    public _tracks: Array<string> = [];
    private returnUrl: string;
    public types_of_using = [
        { 'label': 'Unavailable', 'value': 'UNAVAILABLE' },
        { 'label': 'Both', 'value': 'BOTH' },
        { 'label': 'Sale', 'value': 'SELL' },
        { 'label': 'Rent', 'value': 'RENT' }
    ];

    isDisabledUnitPrice() {
        return (this.editProduct.typeOfUsing === 'UNAVAILABLE' ||
            this.editProduct.typeOfUsing === 'RENT')
    }
    isDisabledRentPrice() {
        return (this.editProduct.typeOfUsing === 'UNAVAILABLE' ||
            this.editProduct.typeOfUsing === 'SELL')
    }


    constructor(private router: Router,
        private route: ActivatedRoute,
        private orgService: OrganizationService,
        private modalService: BsModalService,
        private alertService: StickyPopUpService,
        private organizationService: OrganizationService,
        private mediaLibraryService: MedialibraryService,
        private productsService: ProductsService) {
        this.editProduct = new ProductModel();
        this.resetPagination();
        route.params.subscribe(params => {

            if (params['returnUrl']) {
                this.returnUrl = params['returnUrl'];
            } else {
                this.returnUrl = '/products'
            }

            if (params['prodId'] !== '0') {
                this.productsService.getProductById(params['prodId'])
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(resp => {

                        this.editProduct = resp;
                        // get tags
                        this.tags_list = this.getTagsList(resp);
                        // get properties
                        this.properties_list = this.getPropList(resp);
                        this.searchCompetitors();
                    }, error => {
                        console.log(error);
                        this.alertService.error('Failed to load product: ' + error.error.message);
                    });
            } else {
                this.hasChanges = true;
            }
        });
    }

    ngOnInit() {
        this.organization = this.organizationService.getCurrentOrganization();
        this.editProduct.organizationId = this.organization.id;
        this.orgService.getTags().subscribe(theTags => { this._tracks = theTags });
    }

    saveProduct() {
        // prepare tags
        this.editProduct.tags = [];
        this.editProduct.tags = this.tags_list.map(t => t.value);

        // prepare properties
        const jsonProps = this.mapJsonProperties(this.properties_list);
        this.editProduct.properties = jsonProps;

        this.productsService.createProduct(this.editProduct)
            .subscribe(resp => {
                this.hasChanges = false;
                this.alertService.success('Product has been created');
                this.router.navigate(['/product', resp.id]);
            }, error => {
                this.alertService.error('Failed to create product: ' + error.error.message);
            });
    }

    save() {
        // prepare tags
        this.editProduct.tags = [];
        this.editProduct.tags = this.tags_list.map(t => t.value);

        // prepare properties
        const jsonProps = this.mapJsonProperties(this.properties_list);
        this.editProduct.properties = jsonProps;
        if (this.editProduct.id) {
            this.productsService.updateProduct(this.editProduct)
                .subscribe(resp => {
                    this.hasChanges = false;
                    this.alertService.success('Product has been updated');
                    this.router.navigate([this.returnUrl]);

                }, error => {
                    console.log(error);
                    this.alertService.error('Failed to update product: ' + error.error.message);
                    this.router.navigate([this.returnUrl]);
                });
        } else {
            this.productsService.createProduct(this.editProduct)
                .subscribe(resp => {
                    this.hasChanges = false;
                    this.alertService.success('Product has been created');
                    this.router.navigate([this.returnUrl]);

                }, error => {
                    console.log(error);
                    this.alertService.error('Failed to create product: ' + error.error.message);
                    this.router.navigate([this.returnUrl]);
                });
        }
    }

    close() {
        this.router.navigate([this.returnUrl]);
    }


    //#region Assets
    addAssets() {
        this.mediaLibraryService.assetTypes = ['all'];
        this.uploadModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.uploadModalRef.content.multiselect = true;
        this.uploadModalRef.content.onClose
            .subscribe(result => {

                for (let i = 0; i < result.length; i++) {
                    if (result[i]) {
                        let newAsset = result[i];
                        if (this.editProduct.assets.filter(asset => asset.id === newAsset.id).length) {
                            this.alertService.error('Current asset already exist');
                            return;
                        } else {
                            this.hasChanges = true;
                            this.editProduct.assets.push(result[i]);
                        }
                    }
                }
            });
    }

    removeAsset(asset: CmdAsset) {
        this.hasChanges = true;
        this.editProduct.assets = this.editProduct.assets.filter(file => file.id !== asset.id);
    }
    //#endregion


    //#region Thumbnail
    selMainImg() {
        this.mediaLibraryService.assetType = 'image';
        this.uploadModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.uploadModalRef.content.onClose
            .subscribe(result => {
                if (result.length == 1) {
                    this.editProduct.thumbnail = result[0];
                    this.hasChanges = true;
                }
            });
    }
    removeThumbnail() {
        this.hasChanges = true;
        this.editProduct.thumbnail = null;
    }
    //#endregion


    //#region Properties
    addProp() {
        const t = new TagModel('');
        this.properties_list.push(t);
    }
    removeProp(prop: TagModel) {
        this.properties_list = this.properties_list.filter(p => p !== prop);
    }
    //#endregion


    //#region Competitors

    public addCompetitor() {

        this.uploadModalRef = this.modalService.show(CompetitorEditComponent, { class: 'modal-md asset-modal' });
        this.uploadModalRef.content.prodId = this.editProduct.id;
        this.uploadModalRef.content.onClose
            .subscribe(result => {
                this.searchCompetitors();
            });
    }

    public editCompetitor(comp: ProductCompetitor) {

        this.uploadModalRef = this.modalService.show(CompetitorEditComponent, { class: 'modal-md asset-modal' });
        this.uploadModalRef.content.prodId = this.editProduct.id;
        this.uploadModalRef.content.editCompetitor = Object.assign({}, comp);
        this.uploadModalRef.content.onClose
            .subscribe(result => {
                this.searchCompetitors();
            });
    }

    public deleteCompetitor(comp: ProductCompetitor) {
        this.productsService.deleteCompetitors(comp.id)
            .pipe(take(1))
            .subscribe(result => {
                this.searchCompetitors();
                this.alertService.success('Competitor: ' + comp.name + ' - was removed.');
            }, error => {
                this.alertService.error("Can't remove competitor: " + comp.name + ' - ' + error);
            });
    }

    public searchCompetitors() {
        this.productsService.searchCompetitors(this.editProduct.id, this.competingProds)
            .pipe(take(1))
            .subscribe(resp => {
                this.competingProds.contents = resp.contents;
                this.competingProds.page = resp.page;
                this.competingProds.totalItems = resp.totalItems;
                this.competingProds.pageSize = resp.pageSize;
            });
    }

    resetPagination() {
        this.competingProds = new SearchCompetitorModel();
        this.competingProds.page = 1;
    }

    pageChanged($event) {
        this.competingProds.page = $event.page;
        this.competingProds.pageSize = $event.itemsPerPage;
        this.searchCompetitors();
    }

    changePageSize() {
        this.searchCompetitors();
    }



    //#endregion


    //#region Helpers

    isValid(): boolean {
        let sellValid: boolean = true;
        if ((this.editProduct.typeOfUsing == 'BOTH' || this.editProduct.typeOfUsing == 'SELL')) {
            sellValid = this.editProduct.unitPrice != undefined;
        }
        let rentValid: boolean = true;
        if ((this.editProduct.typeOfUsing == 'BOTH' || this.editProduct.typeOfUsing == 'RENT')) {
            rentValid = this.editProduct.pricePerDay != undefined;
            rentValid = this.editProduct.pricePerWeek != undefined;
            rentValid = this.editProduct.pricePerMonth != undefined;
        }
        return this.editProduct.title &&
            this.editProduct.sku &&
            sellValid && rentValid &&
            /\S/.test(this.editProduct.title) &&
            /\S/.test(this.editProduct.sku);
    }

    private mapJsonProperties(props: TagModel[]) {
        try {
            props = props.filter(p => p.display.length > 0);
            let i = 0;
            let result = '{';
            props.forEach(p => {
                i++;
                if (p.display.length > 0) {
                    result += ('"' + p.display + '":"' + p.value + '"');
                }
                if (props.length > i) {
                    result += ',';
                }
            });
            result += '}';
            return JSON.parse(result);
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    private getPropList(resp: any) {
        if (resp.properties) {
            const propObject = resp.properties;
            let result = [];
            Object.getOwnPropertyNames(propObject).forEach(function (val, idx, array) {
                const model = new TagModel(val);
                model.display = val;
                model.value = propObject[val];
                result.push(model);
            });
            return result;
        } else {
            return [];
        }
    }

    private getTagsList(resp: any) {
        if (resp.tags) {
            return resp.tags.map(t => {
                return new TagModel(t);
            });
        } else {
            return [];
        }
    }

    public notifyChange() {
        this.hasChanges = true;
    }


    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
    //#endregion
}
