import { Component, OnInit } from '@angular/core';
import { ToolsetBaseComponent } from '../toolset-base.component';
import { MeasureList, Measure } from '../../../../models/CmdToolsets';
import { UUID } from 'angular2-uuid';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../../../../services/organization.service';
import { ToolsetsService } from '../../../../services/toolsets.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SpinService } from '../../../../services/spin.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { CacheService } from '../../../../services/cache.service';

@Component({
  selector: 'app-quotebuilder',
  templateUrl: './quotebuilder.component.html',
  styleUrls: ['../toolset-edit.component.scss']
})
export class QuotebuilderComponent extends ToolsetBaseComponent {

  measure_count_limit = 4;
  constructor(public route: ActivatedRoute,
    public router: Router,
    public organizationService: OrganizationService,
    public toolsetsService: ToolsetsService,
    public modalService: BsModalService,
    public spinService: SpinService,
    public alertService: StickyPopUpService,
    public cacheService: CacheService) {
    super(route,
      router,
      organizationService,
      toolsetsService,
      modalService,
      spinService,
      alertService,
      cacheService);
    route.params.subscribe(params => {
      if (params['toolId'] === 'NEW') {
        this.hasChanges = true;
        this.editToolset.id = '';
        this.editToolset.type = 'QUOTE_BUILDER';
        this.editToolset.properties = new MeasureList();
        this.editToolset.slug = UUID.UUID();
      } else
        this.toolsetsService.getToolsetById(params['toolId'])
          .pipe(takeUntil(this.destroy$))
          .subscribe(resp => {
            this.editToolset = resp;
            this.getCustopProperties()
            this.editToolset.slug = UUID.UUID();
          }, error => {
            console.error(error);
            this.alertService.error('Failed to load toolset: ' + error.error.message);
            this.isError = true;
          });

    });
  }

  validData() { }

  addProp() {
    if (this.editToolset.properties.measures_list.length < this.measure_count_limit)
      this.editToolset.properties.measures_list.push({ 'result': '' } as Measure);
  }
  removeProp(prop: Measure) {
    this.notifyChange();
    this.editToolset.properties.measures_list = this.editToolset.properties.measures_list.filter(m => m !== prop);
  }
  //#region Emails
  public setEmails(emails: Array<string>) {
    this.notifyChange();
    this.editToolset.emails = emails;
  }
}
