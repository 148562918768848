import { CmdAsset } from './';
export class CmdOrganization {
    id: string;
    name: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    phone: string;
    analyticsId: string;
    analyticsViewId?: string;
    logo?: CmdAsset;
    whitelabel?: boolean;
    infoAtEmail?: string;
    rentWeekMultiplier?: number = 2.33;
    rentFourWeekMultiplier?: number = 6;
    orgKey: string;
}