import { Component, OnInit } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { SearchModel } from '../../../models/SearchModel';
import { ToolsetsService } from '../../../services/toolsets.service';
import { OrganizationService } from '../../../services/organization.service';
import { BaseActionTypeSelectorComponent } from '../baseactiontypeselector';
import { TcoCalculatorService } from '../../../services/tco-calculator.service';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';

@Component({
    selector: 'app-tcocalculator',
    templateUrl: './tcocalculator.component.html',
    styleUrls: ['./tcocalculator.component.scss']
})
export class TCOCalComponent extends BaseActionTypeSelectorComponent implements OnInit {
    constructor(public widgetService: WidgetService,
        public toolsetsService: ToolsetsService,
        public orgService: OrganizationService, 
        public alertService: StickyPopUpService,
        private tcoCalculatorService: TcoCalculatorService) {
        super(widgetService);
        this.orgId = this.orgService.getCurrentOrganizationId();
    }
    ngOnInit() {
        this.tcoCalculatorService
      .getDevaluationForOrgId(this.orgId)
      .subscribe((res) => {
        this.widgetData.actionData.slug = res.id;
        this.rows.push(res);
      }, error => {
        this.alertService.error('Failed to get Devaluation: ' + error.error.message);
      });
        
    }
}
