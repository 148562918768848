import { OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { CMDToolsetsModel } from "../../../models/CmdToolsets";
import { ActivatedRoute, Router } from "@angular/router";
import { OrganizationService } from "../../../services/organization.service";
import { ToolsetsService } from "../../../services/toolsets.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SpinService } from "../../../services/spin.service";
import { StickyPopUpService } from "../../../services/toast/stickyPopUp.service";
import { CacheService } from "../../../services/cache.service";
import { ProductslibraryComponent } from "../../../shared/productslibrary/productslibrary.component";
import { TagModel } from "../../../models/TagModel";
import { CmdOrganization } from "../../../models";
import { take } from "rxjs/operators";

export abstract class ToolsetBaseComponent implements OnInit, OnDestroy {

    public returnUrl: string;
    public hasChanges: boolean;
    public isError: boolean;
    public destroy$: Subject<boolean> = new Subject<boolean>();
    public editToolset: CMDToolsetsModel = new CMDToolsetsModel();
    public email_templates: Array<any> = [];
    public organization: CmdOrganization;
    uploadModalRef: BsModalRef;
    custom_properties: Array<TagModel> = [];

    constructor(public route: ActivatedRoute, public router: Router,
        public organizationService: OrganizationService,
        public toolsetsService: ToolsetsService,
        public modalService: BsModalService,
        public spinService: SpinService,
        public alertService: StickyPopUpService,
        public cacheService: CacheService) {
        route.params.subscribe(params => {
            if (params['returnUrl']) {
                this.returnUrl = params['returnUrl'];
            } else {
                this.returnUrl = '/toolsets'
            }
        });
    }
    abstract validData(): any;
    ngOnDestroy() {

    }
    ngOnInit() {
        this.organization = this.organizationService.getCurrentOrganization();
        this.editToolset.organizationId = this.organization.id;
        this.toolsetsService.getEmailsTemplates(this.organization.id)
            .pipe(take(1))
            .subscribe(resp => {
                this.email_templates = resp.contents;
            });
    }
    public notifyChange() {
        this.hasChanges = true;
    }
    public isValid(): boolean {
        return (this.editToolset !== null
            && this.editToolset.title
            && this.editToolset.slug
            && /\S/.test(this.editToolset.title)
            && /\S/.test(this.editToolset.slug));
    }

    onCancel() {
        this.router.navigate([this.returnUrl]);
    }

    onSave() {
        this.spinService.show();
        this.validData();

        this.editToolset.productIds = this.editToolset.products.map(p => p.id);
        //clear out the products so we don't have to upload them since only the ids are needed
        this.editToolset.products = [];
        if (this.editToolset.id) {
            this.toolsetsService.updateToolset(this.editToolset)
                .subscribe(resp => {
                    this.cacheService.clearAll();
                    this.spinService.hide();
                    this.hasChanges = false;
                    this.alertService.success('Toolset has been updated');
                    this.router.navigate([this.returnUrl]);
                }, error => {
                    this.spinService.hide();
                    this.alertService.error('Failed to update Toolset: ' + error.error.message);
                });
        } else {
            this.toolsetsService.createToolset(this.editToolset)
                .subscribe(resp => {
                    this.cacheService.clearAll();
                    this.spinService.hide();
                    this.hasChanges = false;
                    this.alertService.success('Toolset has been created');
                    this.router.navigate([this.returnUrl]);
                }, error => {
                    this.spinService.hide();
                    this.alertService.error('Failed to create Toolset: ' + error.error.message);
                });
        }
    }

    addProd(callback?: any) {

        this.uploadModalRef = this.modalService.show(ProductslibraryComponent, { class: 'modal-lg asset-modal' });
        this.uploadModalRef.content.allowProductsWithoutCompetitors = this.editToolset.type !== 'COST_CALCULATOR'
        this.uploadModalRef.content.allowProductsWithoutPrice = this.editToolset.type !== 'COST_CALCULATOR'
        this.uploadModalRef.content.sel_products.push(...this.editToolset.products);
        this.uploadModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.notifyChange();
                    result.forEach(p => {
                        if (p.thumbnail)
                            p.thumbnailUrl = p.thumbnail.thumbnailUrl;
                    });
                    this.editToolset.products = result;
                    callback(this.editToolset.products);
                }
            });
    }
    delProd(prod: any) {
        this.notifyChange();
        this.editToolset.products = this.editToolset.products.filter(p => p.id !== prod.id);
    }

    public getCustopProperties() {
        this.custom_properties = this.editToolset.properties.measures_list.map(p => new TagModel(p.result))
    }
}