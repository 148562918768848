import { CmdTextblock, CmdScene } from '../../models';
import { WidgetEditor } from '../widgeteditor';
import { Component, Input, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-textblock',
  templateUrl: './textblock.component.html',
  styleUrls: ['./textblock.component.css']
})
export class TextblockComponent extends WidgetEditor implements OnInit {
    widgetData: CmdTextblock;
    constructor() {
        super();
    }
    editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '7rem',
      maxHeight: '10rem',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '4',
      fonts: [
          { class: 'Scania', name: 'Scania' },
          { class: 'ScaniaItalic', name: 'ScaniaItalic' },
          { class: 'ScaniaRegular', name: 'ScaniaRegular' },
          { class: 'ScaniaHeadlineBold', name: 'ScaniaHeadlineBold' },
          { class: 'ScaniaHeadlineRegular', name: 'ScaniaHeadlineRegular' },
          { class: 'arial', name: 'Arial' },
          { class: 'times-new-roman', name: 'Times New Roman' },
          { class: 'calibri', name: 'Calibri' },
          { class: 'comic-sans-ms', name: 'Comic Sans MS' },
          { class: 'GT-Eesti-Text-Regular', name: 'GT Eesti Text Regular' },
          { class: 'GT-Eesti-Text-Light', name: 'GT Eesti Text Light' },
          { class: 'EBGaramond-Regular', name: 'Garamond Regular' },
          { class: 'FranklinGothic', name: 'Franklin Gothic' },
          { class: 'Helvetica', name: 'Helvetica' },
          { class: 'OpenSans-Regular', name: 'Open Sans Regular' },
          //{ class: 'Rockwell', name: 'Rockwell' },
          { class: 'Verdana', name: 'Verdana' }
      ],
      customClasses: [
        {
          name: 'h1-text-left',
          class: 'text-left',
          tag: 'h1',
        },
        {
          name: 'h1-text-center',
          class: 'text-center',
          tag: 'h1',
        },
        {
          name: 'h1-text-right',
          class: 'text-right',
          tag: 'h1',
        },
        {
          name: 'h2-text-left',
          class: 'text-left',
          tag: 'h2',
        },
        {
          name: 'h2-text-center',
          class: 'text-center',
          tag: 'h2',
        },
        {
          name: 'h2-text-right',
          class: 'text-right',
          tag: 'h2',
        },
        {
          name: 'h3-text-left',
          class: 'text-left',
          tag: 'h3',
        },
        {
          name: 'h3-text-center',
          class: 'text-center',
          tag: 'h3',
        },
        {
          name: 'h3-text-right',
          class: 'text-right',
          tag: 'h3',
        },
        {
          name: 'h4-text-left',
          class: 'text-left',
          tag: 'h4',
        },
        {
          name: 'h4-text-center',
          class: 'text-center',
          tag: 'h4',
        },
        {
          name: 'h4-text-right',
          class: 'text-right',
          tag: 'h4',
        },
        {
          name: 'h5-text-left',
          class: 'text-left',
          tag: 'h5',
        },
        {
          name: 'h5-text-center',
          class: 'text-center',
          tag: 'h5',
        },
        {
          name: 'h5-text-right',
          class: 'text-right',
          tag: 'h5',
        },
        {
          name: 'p-text-left',
          class: 'text-left',
          tag: 'p',
        },
        {
          name: 'p-text-center',
          class: 'text-center',
          tag: 'p',
        },
        {
          name: 'p-text-right',
          class: 'text-right',
          tag: 'p',
        }
      ],
      sanitize:false
    };

    ngOnInit() {
    }
    @Input() set widget(data: CmdTextblock) {
        this.widgetData = data;
    }
}