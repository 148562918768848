import { CmdBaseModel } from "../CmdBaseModel";
import { CmdOrganization } from "../CmdOrganization";
import { CmdDevaluationAverages } from "./CmdDevaluationAverages";
import { CmdDevaluationTableData } from "./CmdDevaluationTableData";

export class CmdDevaluation extends CmdBaseModel {
    created?:Date;
    updated?:Date;
    organization?:CmdOrganization;
    devaluationAverageData?: CmdDevaluationAverages[];
    devaluationTableData?: CmdDevaluationTableData[];
    table_data?:CmdDevaluationTableData[];
    averages?:CmdDevaluationAverages[];
    defaultCurrency?:string;
}