import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-product-builder-model',
  templateUrl: './create-product-builder-model.component.html',
  styleUrls: ['./create-product-builder-model.component.css']
})
export class CreateProductBuilderModelComponent implements OnInit {
  onClose = new Subject<any>();

  constructor() { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  onCancel() {
    
  }

}
