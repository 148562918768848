import { Component, OnInit } from "@angular/core";CmdAmoritizationForms
import { ActivatedRoute, Router } from "@angular/router";
import { TcoCalculatorService } from "../../../services/tco-calculator.service";
import { SpinService } from "../../../services/spin.service";
import { StickyPopUpService } from "../../../services/toast/stickyPopUp.service";
import { OrganizationService } from "../../../services/organization.service";
import { CmdTCO } from "../../../models";
import { CmdFormComparison } from "../../../models/tco/CmdFormComparison";
import { CmdAmoritizationInvestment } from "../../../models/tco/CmdAmoritizationInvestment";
import { CmdAmoritizationForms } from "../../../models/tco/CmdAmoritizationForms";
import { CmdDevaluation } from "../../../models/tco/CmdDevaluation";

@Component({
  selector: "app-tco-calculator",
  templateUrl: "./tco-calculator.component.html",
  styleUrls: ["./tco-calculator.component.css"],
})
export class TcoCalculatorComponent implements OnInit {

  currencies: { currencyName: string, symbol: string, keyword:string }[] = [
    { currencyName: 'Albania Lek', symbol: 'Lek', keyword: 'ALBANIA_LEK'},
    { currencyName: 'Algerian Dinar', symbol: 'DZD', keyword: 'ALGERIAN_DINAR'},
    { currencyName: 'Angolan Kwanza', symbol: 'Kz', keyword: 'ANGOLAN_KWANZA' },
    { currencyName: 'Argentina Peso', symbol: '$', keyword: 'ARGENTINA_PESO' },
    { currencyName: 'Armenian Dram', symbol: '֏', keyword: 'ARMENIAN_DRAM' },
    { currencyName: 'Aruban Florin', symbol: 'ƒ', keyword: 'ARUBAN_FLORIN' },
    { currencyName: 'Australian Dollar', symbol: '$', keyword: 'AUSTRALIAN_DOLLAR' },
    { currencyName: 'Azerbaijani Manat', symbol: '₼', keyword: 'AZERBAIJANI_MANAT' },
    { currencyName: 'Bahraini Dinar', symbol: '.د.ب', keyword: 'BAHRAINI_DINAR' },
    { currencyName: 'Bangladeshi Taka', symbol: '৳', keyword: 'BANGLADESHI_TAKA' },
    { currencyName: 'Barbados Dollar', symbol: 'Bds$' , keyword: 'BARBADOS_DOLLAR'},
    { currencyName: 'Belize Dollar', symbol: '$' , keyword: 'BELIZE_DOLLAR'},
    { currencyName: 'Bermudian Dollar', symbol: '$' , keyword: 'BERMUDIAN_DOLLAR'},
    { currencyName: 'Bhutanese Ngultrum', symbol: 'Nu' , keyword: 'BHUTANESE_NGULTRUM'},
    { currencyName: 'Bolivian Boliviano', symbol: 'Bs' , keyword: 'BOLIVIAN_BOLIVIANO'},
    { currencyName: 'Bosnia-Herzegovina Convertible Mark', symbol: 'KM' , keyword: 'BOSNIA_HERZEGOVINA_CONVERTIBLE_MARK'},
    { currencyName: 'Botswanan Pula', symbol: 'P' , keyword: 'BOTSWANAN_PULA'},
    { currencyName: 'Brazilian Real', symbol: 'R$' , keyword: 'BRAZILIAN_REAL'},
    { currencyName: 'Brunei Dollar', symbol: 'B$' , keyword: 'BRUNEI_DOLLAR'},
    { currencyName: 'Bulgarian Lev', symbol: 'Лв' , keyword: 'BULGARIAN_LEV'},
    { currencyName: 'Burundian Franc', symbol: 'FBu' , keyword: 'BURUNDIAN_FRANC'},
    { currencyName: 'Cambodian Riel', symbol: '៛' , keyword: 'CAMBODIAN_RIEL'},
    { currencyName: 'Canadian Dollar', symbol: 'Can$' , keyword: 'CANADIAN_DOLLAR'},
    { currencyName: 'Cayman Islands Dollar', symbol: '$' , keyword: 'CAYMAN_ISLANDS_DOLLAR'},
    { currencyName: 'Central African CFA franc', symbol: 'FCFA' , keyword: 'CENTRAL_AFRICAN_CFA_FRANC'},
    { currencyName: 'CFP Franc', symbol: '₣' , keyword: 'CFP_FRANC'},
    { currencyName: 'Chilean Peso', symbol: '$' , keyword: 'CHILEAN_PESO'},
    { currencyName: 'Chinese Yuan', symbol: '¥' , keyword: 'CHINESE_YUAN'},
    { currencyName: 'Colombian peso', symbol: '$' , keyword: 'COLOMBIAN_PESO'},
    { currencyName: 'Comorian Franc', symbol: 'CF' , keyword: 'COMORIAN_FRANC'},
    { currencyName: 'Costa Rican Colón', symbol: '¢' , keyword: 'COSTA_RICAN_COLON'},
    { currencyName: 'Croatian Kuna', symbol: 'kn' , keyword: 'CROATIAN_KUNA'},
    { currencyName: 'Czech Koruna', symbol: 'Kc' , keyword: 'CZECH_KORUNA'},
    { currencyName: 'Danish Krone', symbol: 'Øre' , keyword: 'DANISH_KRONE'},
    { currencyName: 'Djiboutian Franc', symbol: 'Fdj' , keyword: 'DJIBOUTIAN_FRANC'},
    { currencyName: 'Dominican Peso', symbol: '$' , keyword: 'DOMINICAN_PESO'},
    { currencyName: 'East Caribbean Dollar', symbol: '$' , keyword: 'EAST_CARIBBEAN_DOLLAR'},
    { currencyName: 'Egyptian pound', symbol: 'E£' , keyword: 'EGYPTIAN_POUND'},
    { currencyName: 'Ethiopian Birr', symbol: 'Br' , keyword: 'ETHIOPIAN_BIRR'},
    { currencyName: 'Euro', symbol: '€' , keyword: 'EURO'},
    { currencyName: 'Fijian Dollar', symbol: 'FJ$' , keyword: 'FIJIAN_DOLLAR'},
    { currencyName: 'Gambian Dalasi', symbol: 'D' , keyword: 'GAMBIAN_DALASI'},
    { currencyName: 'Georgian Lari', symbol: 'GEL' , keyword: 'GEORGIAN_LARI'},
    { currencyName: 'Ghanaian Cedi', symbol: 'GH¢' , keyword: 'GHANAIAN_CEDI'},
    { currencyName: 'Gibraltar Pound', symbol: '£' , keyword: 'GIBRALTAR_POUND'},
    { currencyName: 'Guatemalan Quetzal', symbol: 'Q' , keyword: 'GUATEMALAN_QUETZAL'},
    { currencyName: 'Guinean Franc', symbol: 'FG' , keyword: 'GUINEAN_FRANC'},
    { currencyName: 'Haitian Gourde', symbol: 'G' , keyword: 'HAITIAN_GOURDE'},
    { currencyName: 'Honduran Lempira', symbol: 'L' , keyword: 'HONDURAN_LEMPIRA'},
    { currencyName: 'Hong Kong Dollar', symbol: '$' , keyword: 'HONG_KONG_DOLLAR'},
    { currencyName: 'Hungarian Forint', symbol: 'Ft' , keyword: 'HUNGARIAN_FORINT'},
    { currencyName: 'Icelandic Króna', symbol: 'kr' , keyword: 'ICELANDIC_KRONA'},
    { currencyName: 'Indian Rupee', symbol: '₹' , keyword: 'INDIAN_RUPEE'},
    { currencyName: 'Indonesian Rupiah', symbol: 'Rp' , keyword: 'INDONESIAN_RUPIAH'},
    { currencyName: 'Israeli new shekel', symbol: '₪' , keyword: 'ISRAELI_NEW_SHEKEL'},
    { currencyName: 'Jamaican Dollar', symbol: '$' , keyword: 'JAMAICAN_DOLLAR'},
    { currencyName: 'Japanese Yen', symbol: '¥' , keyword: 'JAPANESE_YEN'},
    { currencyName: 'Jordanian Dinar', symbol: 'د.ا' , keyword: 'JORDANIAN_DINAR'},
    { currencyName: 'Kazakhstani Tenge', symbol: '₸' , keyword: 'KAZAKHSTANI_TENGE'},
    { currencyName: 'Kenyan Shilling', symbol: '/-' , keyword: 'KENYAN_SHILLING'},
    { currencyName: 'Kuwaiti Dinar', symbol: 'KD' , keyword: 'KUWAITI_DINAR'},
    { currencyName: 'Laotian Kip', symbol: '₭' , keyword: 'LAOTIAN_KIP'},
    { currencyName: 'Lebanese pound', symbol: 'ل.ل.' , keyword: 'LEBANESE_POUND'},
    { currencyName: 'Libyan Dinar', symbol: 'LD' , keyword: 'LIBYAN_DINAR'},
    { currencyName: 'Macanese Pataca', symbol: 'MOP$' , keyword: 'MACANESE_PATACA'},
    { currencyName: 'Macedonian Denar', symbol: 'Ден' , keyword: 'MACEDONIAN_DENAR'},
    { currencyName: 'Malagasy Ariary', symbol: 'Ar' , keyword: 'MALAGASY_ARIARY'},
    { currencyName: 'Malawian Kwacha', symbol: 'MK' , keyword: 'MALAWIAN_KWACHA'},
    { currencyName: 'Malaysian Ringgit', symbol: 'RM' , keyword: 'MALAYSIAN_RINGGIT'},
    { currencyName: 'Maldivian Rufiyaa', symbol: 'MRf' , keyword: 'MALDIVIAN_RUFIYAA'},
    { currencyName: 'Mauritian Rupee', symbol: 'Rs' , keyword: 'MAURITIAN_RUPEE'},
    { currencyName: 'Mexican Peso', symbol: 'Mex$' , keyword: 'MEXICAN_PESO'},
    { currencyName: 'Moldovan Leu', symbol: 'L' , keyword: 'MOLDOVAN_LEU'},
    { currencyName: 'Mongolian Tugrik', symbol: '₮' , keyword: 'MONGOLIAN_TUGRIK'},
    { currencyName: 'Moroccan Dirham', symbol: 'MAD' , keyword: 'MOROCCAN_DIRHAM'},
    { currencyName: 'Mozambican Metical', symbol: 'MT' , keyword: 'MOZAMBICAN_METICAL'},
    { currencyName: 'Namibian Dollar', symbol: 'N$' , keyword: 'NAMIBIAN_DOLLAR'},
    { currencyName: 'Nepalese Rupee', symbol: 'Re' , keyword: 'NEPALESE_RUPEE'},
    { currencyName: 'Netherlands Antillean Guilder', symbol: 'NAƒ' , keyword: 'NETHERLANDS_ANTILLEAN_GUILDER'},
    { currencyName: 'New Taiwan dollar', symbol: '$' , keyword: 'NEW_TAIWAN_DOLLAR'},
    { currencyName: 'New Zealand Dollar', symbol: '$' , keyword: 'NEW_ZEALAND_DOLLAR'},
    { currencyName: 'Nicaraguan Córdoba', symbol: 'C$' , keyword: 'NICARAGUAN_CORDOBA'},
    { currencyName: 'Nigerian Naira', symbol: '₦' , keyword: 'NIGERIAN_NAIRA'},
    { currencyName: 'Norwegian Krone', symbol: 'kr' , keyword: 'NORWEGIAN_KRONE'},
    { currencyName: 'Omani Rial', symbol: 'ر.ع.' , keyword: 'OMANI_RIAL'},
    { currencyName: 'Pakistani Rupee', symbol: 'Rs' , keyword: 'PAKISTANI_RUPEE'},
    { currencyName: 'Papua New Guinean Kina', symbol: 'K' , keyword: 'PAPUA_NEW_GUINEAN_KINA'},
    { currencyName: 'Paraguayan Guarani', symbol: '₲' , keyword: 'PARAGUAYAN_GUARANI'},
    { currencyName: 'Philippine peso', symbol: '₱' , keyword: 'PHILIPPINE_PESO'},
    { currencyName: 'Polish Zloty', symbol: 'zl' , keyword: 'POLISH_ZLOTY'},
    { currencyName: 'Pound Sterling', symbol: '£' , keyword: 'POUND_STERLING'},
    { currencyName: 'Qatari Riyal', symbol: 'QR' , keyword: 'QATARI_RIYAL'},
    { currencyName: 'Romanian Leu', symbol: 'lei' , keyword: 'ROMANIAN_LEU'},
    { currencyName: 'Russian Ruble', symbol: 'RUB' , keyword: 'RUSSIAN_RUBLE'},
    { currencyName: 'Rwandan Franc', symbol: 'FRw' , keyword: 'RWANDAN_FRANC'},
    { currencyName: 'Salvadoran Colón', symbol: 'SVC' , keyword: 'SALVADORAN_COLON'},
    { currencyName: 'Saudi Riyal', symbol: 'SR' , keyword: 'SAUDI_RIYAL'},
    { currencyName: 'Serbian Dinar', symbol: 'din' , keyword: 'SERBIAN_DINAR'},
    { currencyName: 'Seychellois Rupee', symbol: 'SR' , keyword: 'SEYCHELLOIS_RUPEE'},
    { currencyName: 'Singapore Dollar', symbol: '$' , keyword: 'SINGAPORE_DOLLAR'},
    { currencyName: 'Sol', symbol: 'S/' , keyword: 'SOL'},
    { currencyName: 'Solomon Islands Dollar', symbol: 'Si$' , keyword: 'SOLOMON_ISLANDS_DOLLAR'},
    { currencyName: 'South African Rand', symbol: 'R' , keyword: 'SOUTH_AFRICAN_RAND'},
    { currencyName: 'South Korean won', symbol: '₩' , keyword: 'SOUTH_KOREAN_WON'},
    { currencyName: 'Sri Lankan Rupee', symbol: 'Rs' , keyword: 'SRI_LANKAN_RUPEE'},
    { currencyName: 'Swedish Krona', symbol: 'kr' , keyword: 'SWEDISH_KRONA'},
    { currencyName: 'Swiss Franc', symbol: 'CHf' , keyword: 'SWISS_FRANC'},
    { currencyName: 'Tanzanian Shilling', symbol: 'TSh' , keyword: 'TANZANIAN_SHILLING'},
    { currencyName: 'Thai Baht', symbol: '฿' , keyword: 'THAI_BAHT'},
    { currencyName: 'Tonga Pa anga', symbol: 'PT' , keyword: 'TONGA_PA_ANGA'},
    { currencyName: 'Trinidad & Tobago Dollar', symbol: '$' , keyword: 'TRINIDAD_AND_TOBAGO_DOLLAR'},
    { currencyName: 'Tunisian Dinar', symbol: 'DT' , keyword: 'TUNISIAN_DINAR'},
    { currencyName: 'Turkish lira', symbol: '₺' , keyword: 'TURKISH_LIRA'},
    { currencyName: 'Ugandan Shilling', symbol: 'Ush' , keyword: 'UGANDAN_SHILLING'},
    { currencyName: 'Ukrainian Hryvnia', symbol: '₴' , keyword: 'UKRAINIAN_HRYVNIA'},
    { currencyName: 'United Arab Emirates Dirham', symbol: 'د.إ' , keyword: 'UNITED_ARAB_EMIRATES_DIRHAM'},
    { currencyName: 'United States Dollar', symbol: '$' , keyword: 'UNITED_STATES_DOLLAR'},
    { currencyName: 'Uruguayan Peso', symbol: '$' , keyword: 'URUGUAYAN_PESO'},
    { currencyName: 'Uzbekistani Som', symbol: 'so m' , keyword: 'UZBEKISTANI_SOM'},
    { currencyName: 'Vanuatu Vatu', symbol: 'VT' , keyword: 'VANUATU_VATU'},
    { currencyName: 'Vietnamese Dong', symbol: '₫' , keyword: 'VIETNAMESE_DONG'},
    { currencyName: 'West African CFA franc', symbol: 'CFA' , keyword: 'WEST_AFRICAN_CFA_FRANC'},
    { currencyName: 'Yemeni Rial', symbol: '﷼' , keyword: 'YEMENI_RIAL'},
    { currencyName: 'Zambian Kwacha', symbol: 'ZK' , keyword: 'ZAMBIAN_KWACHA'},
    { currencyName: 'Zimbabwean dollar', symbol: 'Z$' , keyword: 'ZIMBABWEAN_DOLLAR'},
  ];

  selectedCurrency: any =  this.currencies[3];

  isHasOperationInformation: boolean;
  opacityValueYear: string = "5";
  placeholder: string = "";
  maxProgressBarResaleValue: number = 5;
  maxValue: any = 60;
  customerName: string = '';
  vehicleLifecycle: number;
  scaniaResalePrice: any = [];
  competitorResalePrice: any = [];
  selectedProductName: string = "";
  downPaymentPercentage: number;
  currentPricePerLiter: number;
  averageConsumption: number;
  downPaymentAmount: number;
  financingPeriodMonths: number;
  monthlyInstallmentValue: number;
  InterestRateMonthly: number;
  toolsetId: any = "";
  devaluationDataResponse: any;
  vehicleCompetitorProductName: any = [];
  usedVehicleLiquidityDifference: any = [];
  periodMonth: any = [];
  calculateCapitalCosts: any;
  /////////////////////////////////////////////
  
  tco: CmdTCO = new CmdTCO();
  vehiclePurchasePriceDifference: any = [];
  downPaymentAmounts: any = [];
  monthlyInstallmentAmounts: any = [];
  downPaymentPriceDifferences: any = [];
  interestRateDifferences: any = [];
  fuelConsumptionDifference: any = [];
  adBlueConsumptionDifferences: any = [];
  maintenanceCostDifference: any = [];
  sumTotalEconomy: any = []
  annualYieldTax: any = [];
  financialApplicationYield: any = [];
  totalOperationalEconomy: any = [];

  tcoDataRes: any;
  amoritization: CmdAmoritizationInvestment = new CmdAmoritizationInvestment();
  comparision: CmdFormComparison = new CmdFormComparison();
  amoritizationForm: CmdAmoritizationForms;


  devaluationForCurrentOrg:CmdDevaluation;
  currentOrgId:string;
  loadingCurrency = false;

  formattedDefaultCurrency = "\u202A"+this.selectedCurrency.symbol+"\u202C ";

  constructor(
    private route: Router,
    public spinService: SpinService,
    private tcoCalculatorService: TcoCalculatorService,
    private organizationService: OrganizationService,
    private stickyPopUpService: StickyPopUpService
  ) { }

  ngOnInit() {
    this.currentOrgId = this.organizationService.getCurrentOrganizationId();
    this.loadTCO();
  }

  selectOption(selectedProductName, index) {
    this.selectedProductName = selectedProductName.target.value;
    if (this.selectedProductName != "") {
      var form = this.tco.steps[1].forms[index];
      form.header = this.selectedProductName + " - Vehicle Purchase Details";
      var productName =
        (
          document.getElementById(
            "selectedProductNames-" + index
          ) as HTMLInputElement
        ).value + " - Vehicle Purchase Details";

      var value = ((
        document.getElementById("purchaseDetails-" + index) as HTMLInputElement
      ).innerHTML = productName);
      this.comparisonProduct(this.selectedProductName);
      this.addFinancialCost(this.selectedProductName, index);
      this.addFuelConsumption(this.selectedProductName, index);
      this.addAdBlue(this.selectedProductName, index);
      this.addContractedServices(this.selectedProductName, index);
      this.addUsedVehicleResale(this.selectedProductName, index);
      this.addUsedVehicleLiquidity(this.selectedProductName, index);
      return form.header;
    }
  }

  loadTCO() {
    this.vehicleCompetitorProductName = [];
    this.loadingCurrency = true;
    this.tcoCalculatorService
      .getDevaluationForOrgId(this.currentOrgId)
      .subscribe((res) => {
        this.devaluationForCurrentOrg = res;
        var response = JSON.parse(res.devaluationData);
        this.devaluationDataResponse = response.averages;

         this.currencies.forEach(currency => {
          if(currency.keyword == response.defaultCurrency.keyword){
            this.selectedCurrency = currency;
            this.formattedDefaultCurrency = "\u202A"+this.selectedCurrency.symbol+"\u202C ";
          }
        });
        this.loadingCurrency = false;
        this.devaluationDataResponse.forEach((obj) => {
          this.vehicleCompetitorProductName.push(obj.header);
        });

        var tcoDataFromLocalStorage = JSON.parse(localStorage.getItem("TcoCalculator"));
        if (tcoDataFromLocalStorage) {
          if (tcoDataFromLocalStorage.organizationId == this.currentOrgId) {
            this.parseTcoData(tcoDataFromLocalStorage);
          } else {
            this.getTCOTemplate();
          }
        } else {
          this.getTCOTemplate();
        }
      }, error => {
        this.loadingCurrency = false;
        this.stickyPopUpService.error('Failed to get Devaluation: ' + error.error.message);
      });
  }

  getTCOTemplate() {
    this.tcoCalculatorService.getTCOTemplate().subscribe((res) => {
      this.tco.steps = JSON.parse(res.tco_template).steps; 
      this.comparisonProduct(this.tco.steps);
    }, error => {
      this.stickyPopUpService.error('Failed to get TCO Template: ' + error.error.message);
    });
  }

  parseTcoData(tcoDataFromLocalStorage) {
      this.tco = tcoDataFromLocalStorage.properties.tcoData.steps;
      this.comparisonProduct(this.tco.steps);
      this.comparisionCalculation();
  }

  comparisionCalculation() {
    this.calculateProjectInformationFormula();
    this.calculateFinancingComparison();
    this.calculateFuelConsumption();
    this.getAdBlueAverageConsumption();
    this.calculateContractedServicesComparison();
    this.calculateUsedVehicleResale();
    this.calculateUsedVehicleLiquidityComparison();
    this.calculateFinalResultsTCO();
  }

  //not in use
  createToolset(tcoDataObj) {
    this.tcoCalculatorService.createToolset(tcoDataObj).subscribe((res) => {
      this.spinService.hide();
      this.stickyPopUpService.success("TCO has been saved successfully.");
    }, error => {
      this.spinService.hide();
      this.stickyPopUpService.error('Failed to create Toolset: ' + error.error.message);
    });
  }

  //not in use
  updateToolset(tcoDataObj) {
    this.tcoCalculatorService.updateToolset(tcoDataObj).subscribe((res) => {
      this.spinService.hide();
      this.stickyPopUpService.success("TCO has been updated successfully.");
    }, error => {
      this.spinService.hide();
      this.stickyPopUpService.error('Failed to update TCO: ' + error.error.message);
    });
  }

  onChangeProjectInformation(event, placeholder) {
    var step2 = this.tco.steps[1];
    let customerName = this.tco.steps[0].forms[0].fields[0].value;
    var isHasOMEName = true;
    var isHasVehiclePrice = true;
    if (placeholder == "Vehicle Lifecycle") {
      this.maxProgressBarResaleValue = event.target.value;
    }

      var step1 = this.tco.steps[0];
      var totalMilage=step1.forms[0].fields[4].value.replace(/,/g, '');
      if (totalMilage == "" || totalMilage == null) {
        isHasOMEName = false;
      }

    step2.forms.forEach((element) => {
      var omeName = element.fields[0].value;
      var vehiclePrice = element.fields[3].value;
      if (omeName == "" || omeName == null) {
        isHasOMEName = false;
      }
      if (vehiclePrice == "" || vehiclePrice == null) {
        isHasVehiclePrice = false;
      }
    });

    
      this.calculateProjectInformationFormula();
      if (isHasOMEName && isHasVehiclePrice) {
        this.calculateFuelConsumption();
        this.calculateContractedServicesComparison();
        this.calculateUsedVehicleResale();
        this.opacityValues();
        this.calculateFinalResultsTCO();
      }
  }

  onChangeVehiclePurchase(event, index) {
    let isVechilePrice = false;
    var step1 = this.tco.steps[0];
    var totalMilage=step1.forms[0].fields[4].value.replace(/,/g, '');
    if (totalMilage == "" || totalMilage == null) {
      isVechilePrice = false;  
    }
    this.comparisonProduct(event.target.value);
    if(isVechilePrice){
      this.calculateUsedVehicleResale();
      this.calculateFinancingComparison();
      this.calculateUsedVehicleLiquidityComparison();
      this.calculateFinalResultsTCO();
    }
  }

  onChangeFinancialCost(event, placeholder) {
    
    if (placeholder == "Down Payment %") {
      this.downPaymentPercentage = event.target.value;
    } else if (placeholder == "Interest Rate(Monthly)") {
      this.InterestRateMonthly = event.target.value;
    } else if (placeholder == "Financing Period(Months)") {
      this.financingPeriodMonths = event.target.value;
    }
    this.calculateFinancingComparison();
    this.calculateFinalResultsTCO();
  }

  onChangeFuelConsumption(event, placeholder) {
    if (placeholder == "Current Price Per Liter") {
      this.currentPricePerLiter = event.target.value;
    } else if (placeholder == "Average Consumption(Km Per Liter)") {
      this.averageConsumption = event.target.value;
    }

    var isHasOMEName = true;
    var step1 = this.tco.steps[0];
   
      var totalMilage=step1.forms[0].fields[4].value.replace(/,/g, '');
      if (totalMilage == "" || totalMilage == null) {
        isHasOMEName = false;
        this.stickyPopUpService.info('Please fill the total milage (Km Total).');
      }

    if(isHasOMEName){
      this.calculateFuelConsumption();
      this.calculateFinalResultsTCO();
    }
   
  }

  onChangeContractedServices(event, placeholder) {
    this.calculateContractedServicesComparison();
    this.calculateUsedVehicleResale();
    this.calculateFinalResultsTCO();
  }

  onChangeMaxResaleValue(event) {
    this.maxProgressBarResaleValue = event;
    var step1 = this.tco.steps[0];
    var isHasTotalMilage = true;
    step1.forms.forEach(element => {
      var totalMilage = element.fields[4].value;
      if (totalMilage == null || totalMilage == "" || totalMilage == undefined) {
        isHasTotalMilage = false;
        this.stickyPopUpService.info("Please fill the total Milage (Km Total).");
      }
    });

    if (isHasTotalMilage) {
      this.calculateUsedVehicleResale();
      this.calculateFinalResultsTCO();
    }
  }

  onChangeUsedVehicleLiquidity(event, placeholder) {
    this.calculateUsedVehicleLiquidityComparison();
    this.calculateFinalResultsTCO();
  }

  calculateProjectInformationFormula() {
    this.maxValue = 0;
    let step1 = this.tco.steps[0];
    step1.forms[0].fields[4].value = "";

    let vehicleLifecycle = parseFloat(step1.forms[0].fields[2].value.replace(/,/g, ''));
    let kmMonthly = parseFloat(step1.forms[0].fields[3].value.replace(/,/g, ''));

    if (vehicleLifecycle != null && !Number.isNaN(vehicleLifecycle)) {
      this.maxValue = vehicleLifecycle;
      if (kmMonthly != null && !Number.isNaN(kmMonthly)) {
        let kmTotalLifetime = ((kmMonthly * 12 * vehicleLifecycle).toLocaleString('en-US')).toString();
        step1.forms[0].fields[4].value = kmTotalLifetime;
      }     
    }
  }

  comparisonProduct(responseTCO) {
    if (responseTCO != undefined) {
      this.vehiclePurchasePriceDifference = [];
      var step2 = this.tco.steps[1];
      var formLength = step2.forms.length;
      if (formLength >= 2) {
        var formZero = step2.forms[0];
        var purchaseDetailsDiff = {
          header: "",
          amount: "",
        };

        for (let i = 0; i < formLength; i++) {
          if (i == 0) {
            purchaseDetailsDiff.header=formZero.fields[0].value;
            purchaseDetailsDiff.amount=step2.forms[i].fields[3].value.trim().replace(this.formattedDefaultCurrency,'');
          } else {
            if (step2.forms[i].fields[0].value != "" && step2.forms[i].fields[0].value != null) {
              purchaseDetailsDiff.header = step2.forms[i].fields[0].value;
            }

            if (step2.forms[i].fields[3].value != "" && step2.forms[i].fields[3].value != null && 
             parseInt(step2.forms[i].fields[3].value.trim().replace(this.formattedDefaultCurrency,'')) != 0
              && !Number.isNaN(parseInt(step2.forms[i].fields[3].value.trim().replace(this.formattedDefaultCurrency,'')))) {
              purchaseDetailsDiff.amount = (Math.round(
                parseFloat(step2.forms[i].fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,'')) -
                parseFloat(formZero.fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''))).toLocaleString('en-US')
              ).toString();
            }
          }
          this.vehiclePurchasePriceDifference.push(purchaseDetailsDiff);
          purchaseDetailsDiff = {
            header: "",
            amount: "",
          };
        }
      }
    }
  }

  calculateFinancingComparison() {
    var step2 = this.tco.steps[1];
    var step3 = this.tco.steps[2];
    this.downPaymentPriceDifferences = [];
    this.downPaymentAmounts = [];
    this.monthlyInstallmentAmounts = [];
    this.interestRateDifferences = [];
    var isHasScaniaVehiclePrice = true;
    step2.forms.forEach((element) => {
      var vehiclePrice = element.fields[3].value;
      if (vehiclePrice == "" || vehiclePrice == null) {
        isHasScaniaVehiclePrice = false;
        this.stickyPopUpService.info("Please fill the Vehicle Price.");
      }
    });


    if (isHasScaniaVehiclePrice) {
      let scaniaProductName = step2.forms[0].fields[0].value;
      let scaniaVehiclePrice = parseFloat(step2.forms[0].fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));
      let scaniaDownPaymentPercentage = parseFloat(step3.forms[0].fields[0].value.replace(/,/g, ''));
      let scaniaDownpaymentAmount = scaniaVehiclePrice * (scaniaDownPaymentPercentage / 100);
      let scaniaMonthlyInrestRate = parseFloat(step3.forms[0].fields[1].value.replace(/,/g, ''));
      let scaniaFinancingPeriodInMonths = parseFloat(step3.forms[0].fields[2].value.replace(/,/g, ''));

      if (scaniaDownPaymentPercentage && scaniaMonthlyInrestRate && scaniaFinancingPeriodInMonths) {
        // var percentageToBeFinanced = 100 - this.downPaymentPercentage;
        let principalAmount = scaniaVehiclePrice - scaniaDownpaymentAmount;
        let rateOfIntrest = scaniaMonthlyInrestRate / 100;
        let numberOfPeriod = scaniaFinancingPeriodInMonths;

        /* Calculate Monthly Installment Value formula */
        let scaniaMonthlyInstallmentValue = parseFloat(((principalAmount *
          rateOfIntrest *
          Math.pow(1 + rateOfIntrest, numberOfPeriod)) /
          (Math.pow(1 + rateOfIntrest, numberOfPeriod) - 1)
        ).toFixed(2)
        );

        if(!Number.isNaN(scaniaMonthlyInstallmentValue)){
            const monthlyInstallmentAmount: any = {
                   productName: scaniaProductName,
                   amount: Math.round(scaniaMonthlyInstallmentValue).toLocaleString('en-US'),
              };
           this.monthlyInstallmentAmounts.push(monthlyInstallmentAmount);
          }

        if (scaniaMonthlyInstallmentValue) {
          let vehicleprice = parseFloat(step2.forms[0].fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));
          let rateOfIntrest=100-parseFloat(step3.forms[0].fields[0].value.replace(/,/g, ''));
          let paymentOfIntrest = (vehicleprice *rateOfIntrest / 100);
          var principalRateOfIntrest = (scaniaMonthlyInstallmentValue * numberOfPeriod) - paymentOfIntrest;
        }
      }

      if (scaniaDownpaymentAmount && !Number.isNaN(scaniaDownpaymentAmount)) {
        const downPaymentAmount: any = {
          productName: scaniaProductName,
          amount: Math.round(scaniaDownpaymentAmount).toLocaleString('en-US'),
        };
        this.downPaymentAmounts.push(downPaymentAmount);
      }

      if (step2.forms.length > 1) {
        for (let i = 0; i < step2.forms.length; i++) {
          if (i == 0) {
            const downPaymentPriceDifference: any = {
              productName: scaniaProductName,
              amount: '',
            };
            this.downPaymentPriceDifferences.push(downPaymentPriceDifference);
            const interestRateDifference: any = {
              productName: scaniaProductName,
              amount: '',
            };

            this.interestRateDifferences.push(interestRateDifference);
          } else {

            let vehiclePrice: number = 0;
            let downPayment: number = 0;

            if (step2.forms[i].fields[3].value) {

              vehiclePrice = parseFloat(step2.forms[i].fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));
              downPayment = vehiclePrice * (parseFloat(step3.forms[i].fields[0].value.replace(/,/g, '')) / 100);
              if (downPayment != null && !Number.isNaN(downPayment)) {
                /* Calculate Down Payment Amount=>
                Down Payment Amount = Down Payment Percentage x Vehicle Price */
                const downPaymentAmount: any = {
                  productName: step2.forms[i].fields[0].value,
                  amount: Math.round(downPayment).toLocaleString('en-US'),
                };
                this.downPaymentAmounts.push(downPaymentAmount);
              }

              if (downPayment != null && scaniaDownpaymentAmount != null && !Number.isNaN(downPayment) && !Number.isNaN(scaniaDownpaymentAmount)) {
                const downPaymentPriceDifference: any = {
                  productName: step2.forms[i].fields[0].value,
                  amount: (downPayment - scaniaDownpaymentAmount).toLocaleString('en-US'),
                };
                this.downPaymentPriceDifferences.push(downPaymentPriceDifference);
              }
            }

            let downpaymentPercentage: number = parseFloat(
              step3.forms[i].fields[0].value.replace(/,/g, '')
            );
            let monthlyInrestRate = parseFloat(step3.forms[i].fields[1].value.replace(/,/g, ''));
            let financingPeriodInMonths = parseFloat(step3.forms[i].fields[2].value.replace(/,/g, ''));

            if (downpaymentPercentage && monthlyInrestRate && financingPeriodInMonths) {
              // var percentageToBeFinanced = 100 - this.downPaymentPercentage;
              let principalAmount = vehiclePrice - downPayment;
              let rateOfIntrest = monthlyInrestRate / 100;
              let numberOfPeriod = financingPeriodInMonths;

              /* Calculate Monthly Installment Value formula */
              let monthlyInstallmentValue = parseFloat(
                (
                  (principalAmount *
                    rateOfIntrest *
                    Math.pow(1 + rateOfIntrest, numberOfPeriod)) /
                  (Math.pow(1 + rateOfIntrest, numberOfPeriod) - 1)
                ).toFixed(2)
              );

              if(!Number.isNaN(monthlyInstallmentValue)){
                const monthlyInstallmentAmount: any = {
                  productName: step2.forms[i].fields[0].value,
                  amount: Math.round(monthlyInstallmentValue).toLocaleString('en-US'),
                };
                this.monthlyInstallmentAmounts.push(monthlyInstallmentAmount);
              }
              
              /* Calculate Interest Rate Difference formula */
              if (step2.forms[i].fields[3].value.replace(/,/g, '')) {
                let vehicleprices = parseFloat(step2.forms[i].fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));
                let competitorRateOfIntrest=100-parseFloat(step3.forms[i].fields[0].value.replace(/,/g, ''));
                let paymentOfIntrest = (vehicleprices * competitorRateOfIntrest / 100);
                let productRateOfIntrest = (monthlyInstallmentValue * numberOfPeriod) - paymentOfIntrest;
                let interestRateDiff = Math.round(productRateOfIntrest - principalRateOfIntrest).toLocaleString('en-US');
                let interestRateDiffs=parseFloat(interestRateDiff);
                if(!Number.isNaN(interestRateDiffs)){
                  const interestRateDifference: any = {
                    productName: step2.forms[i].fields[0].value,
                    amount: (interestRateDiff),
                  };
                  this.interestRateDifferences.push(interestRateDifference);
                }
              }
            }
          }
        }
      }
    }
  }

  calculateFuelConsumption() {
    var step1 = this.tco.steps[0];
    var step2 = this.tco.steps[1];
    var step4 = this.tco.steps[3];
    var isHasOMEName = true;

    step2.forms.forEach((element) => {
      var omeName = element.fields[0].value;
      var vehiclePrice = element.fields[3].value;
      if (omeName == "" || omeName == null) {
        isHasOMEName = false;
        this.stickyPopUpService.info("'Select OME - Product Name' first!");
      }
    });

    let scaniaProductName = step2.forms[0].fields[0].value;
    if (isHasOMEName) {
      this.fuelConsumptionDifference = [];
      let scaniatotalMilage: any = 0;
      let scaniaAvgConsumption: any = 0;
      let currentPricePerLiter: any = 0;
      var scaniaTotalAverageConsumption: any = 0;
      scaniatotalMilage = step1.forms[0].fields[4].value.replace(/,/g, '');
      currentPricePerLiter = step4.forms[0].fields[0].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,'');
      scaniaAvgConsumption = step4.forms[1].fields[0].value.replace(/,/g, '');
      if (scaniatotalMilage) {
        scaniaTotalAverageConsumption = ((scaniatotalMilage / scaniaAvgConsumption) * currentPricePerLiter);

        var competitorTotalAverageConsumption: any = 0;
        for (let i = 1; i <= step2.forms.length; i++) {
          if (i == 1) {
            const fuelConsumptionDiff = {
              productName: scaniaProductName,
              amount: '',
            }
            this.fuelConsumptionDifference.push(fuelConsumptionDiff);
          } else {
            let averageConsumption: any = 0;
            averageConsumption = step4.forms[i].fields[0].value.replace(/,/g, '');
            competitorTotalAverageConsumption = ((scaniatotalMilage / averageConsumption) * currentPricePerLiter);

            /*   Scania Average Consumption (Kilometers per liter)*/
            /*  Fuel consumption difference during lifecycle */
            let totalAverageConsumptionDiff = parseFloat((competitorTotalAverageConsumption - scaniaTotalAverageConsumption).toFixed(2));
            if(!Number.isNaN(totalAverageConsumptionDiff)){
              const fuelConsumptionDiff = {
                productName: step2.forms[i - 1].fields[0].value,
                amount: Math.round(totalAverageConsumptionDiff).toLocaleString('en-US'),
              }
              this.fuelConsumptionDifference.push(fuelConsumptionDiff);
            }
          }
        }
      } else {
        this.stickyPopUpService.info('Please fill the total milage (Km Total).');
      }
    }
  }

  getAdBlueAverageConsumption() {
    this.adBlueConsumptionDifferences = [];
    var adBlueForms = this.tco.steps[4].forms;
    var fuelForms = this.tco.steps[3].forms;
    let scaniaAdBlueConsumption: any;
    let zeroIdxValue: any;
    if (adBlueForms[0].fields[0].value && adBlueForms[0].fields[0].value != '') {
      let totalMilage = this.tco.steps[0].forms[0].fields[4].value.replace(/,/g, '');
      if (totalMilage && totalMilage != '') {
        for (let i = 1; i < adBlueForms.length; i++) {
          let fuelConsumption = parseFloat(fuelForms[i].fields[0].value.replace(/,/g, ''));
          if (fuelConsumption && fuelConsumption != null) {
            var a = parseFloat(totalMilage) / fuelConsumption;
            // for (let i = 1; i < adBlueForms.length; i++) {
            let adBlueAvgConsumptionPercentage = adBlueForms[i].fields[0].value.replace(/,/g, '');
            if (adBlueAvgConsumptionPercentage && adBlueAvgConsumptionPercentage != null) {
              var b = a * (parseFloat(adBlueAvgConsumptionPercentage) / 100);
              let adBlueCurrentPrice = adBlueForms[0].fields[0].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,'');
              if (adBlueCurrentPrice && adBlueCurrentPrice != null) {
                let adBlueConsumption;
                adBlueConsumption = b * parseFloat(adBlueCurrentPrice);
                if (i == 1) {
                  scaniaAdBlueConsumption = b * parseFloat(adBlueCurrentPrice);
                  zeroIdxValue = {
                    productName: adBlueForms[i].header,
                    amount: "",
                  }
                  this.adBlueConsumptionDifferences.push(zeroIdxValue);
                } else {
                  let difference;
                  difference = (adBlueConsumption - parseFloat(scaniaAdBlueConsumption)).toFixed(2);
                  if(!Number.isNaN(difference)){
                    this.adBlueConsumptionDifferences.push({
                      productName: adBlueForms[i].header,
                      amount: Math.round(difference).toLocaleString('en-US'),
                    });
                  }
                }
              }
            }
          }
        }
      } else {
        this.stickyPopUpService.info('Total milage during vehicle life cycle.');
      }
    }
  }

  calculateContractedServicesComparison() {
    var step1 = this.tco.steps[0];
    var step2 = this.tco.steps[1];
    var step6 = this.tco.steps[5];

    this.maintenanceCostDifference = [];

    let scaniaProductName = step2.forms[0].fields[0].value;
    let scaniatotalMilage: any = 0;
    let scaniaMaintenanceCostPerKm: any = 0;
    var scaniaMaintenanceCost: any = 0;

    var isHasOMEName = true;
    step2.forms.forEach((element) => {
      var omeName = element.fields[0].value;
      if (omeName == "" || omeName == null) {
        isHasOMEName = false;
        this.stickyPopUpService.info("'Select OME - Product Name' first!");
      }
    });

    if (isHasOMEName) {
      scaniatotalMilage = step1.forms[0].fields[4].value.replace(/,/g, '');
      if (scaniatotalMilage != undefined && scaniatotalMilage != null && scaniatotalMilage != "") {

        scaniaMaintenanceCostPerKm = step6.forms[0].fields[1].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,'');
        scaniaMaintenanceCost = scaniaMaintenanceCostPerKm * scaniatotalMilage;

        let competitorMaintenanceCostPerKm: any = 0;
        let competitorMaintenanceCost: any = 0;

        if (scaniaMaintenanceCost != null && scaniaMaintenanceCost != 0) {
          for (var i = 0; i < step2.forms.length; i++) {
            if (i == 0) {
              const maintenanceCostDiff = {
                productName: scaniaProductName,
                amount: '',
              };
              this.maintenanceCostDifference.push(maintenanceCostDiff);
            } else {
              competitorMaintenanceCostPerKm = step6.forms[i].fields[1].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,'');
              competitorMaintenanceCost = competitorMaintenanceCostPerKm * scaniatotalMilage;
              let costDiff = parseFloat((competitorMaintenanceCost - scaniaMaintenanceCost).toFixed(2));
              if(!Number.isNaN(costDiff)){
                const maintenanceCostDiff = {
                  productName: step2.forms[i].fields[0].value,
                  amount: Math.round(costDiff).toLocaleString('en-US'),
                };
                this.maintenanceCostDifference.push(maintenanceCostDiff);
              }
              
            }
          }
        }
      } else {
        this.stickyPopUpService.info('Total milage during vehicle life cycle.');
      }
    }
  }

  calculateUsedVehicleResale() {
    if (this.devaluationDataResponse.length != null && this.maintenanceCostDifference.length>1) {
      var average = this.devaluationDataResponse;
      var step1 = this.tco.steps[0];
      var step2 = this.tco.steps[1];
      var step7 = this.tco.steps[6];
      var scaniaResalePriceDiff;

      let scaniaProductName = "";
      let scaniaVehiclePrice = parseFloat(step2.forms[0].fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));
      this.scaniaResalePrice = [];

      let scaniaHeader = "";
      var scaniaAverage;
      var competitorProductName;
      var competitorVehiclePrice;
      var competitorHeader;
      var competitorAverage;
      for (let i = 0; i <= step2.forms.length; i++) {
        let maximumResaleYears = parseFloat(step1.forms[0].fields[2].value.replace(/,/g, ''));
        let scaniaFirstYearResalePrice;
        var competitorFirstYearResalePrice;
        scaniaProductName = step2.forms[0].fields[0].value;

        average.forEach((obj: any, index: number) => {
          if (scaniaProductName == obj.header.toUpperCase()) {
            scaniaHeader = obj.header.toUpperCase();
            scaniaAverage = Math.abs(obj.average);
            // var scaniaAverage=6.92;
          }
          // if (competitorProductName == obj.header.toUpperCase()) {
          //   competitorHeader = obj.header.toUpperCase();
          //   competitorAverage = Math.abs(obj.average);
          //   // var competitorAverage=7.53;
          // }

        });

        if (i == 1) {
          if (maximumResaleYears != null) {
            for (let i = 0; i < maximumResaleYears; i++) {
              scaniaFirstYearResalePrice = scaniaVehiclePrice - (scaniaVehiclePrice * (scaniaAverage / 100));
              scaniaVehiclePrice = scaniaFirstYearResalePrice;
              scaniaFirstYearResalePrice.toFixed();
              if(!Number.isNaN(scaniaFirstYearResalePrice)){
                this.scaniaResalePrice.push((Math.round(scaniaFirstYearResalePrice).toLocaleString('en-US')));
              }
            }
          }
          if (this.maxProgressBarResaleValue != null) {
            this.scaniaResalePrice.forEach((value: any, index1: number) => {
              if (index1 == this.maxProgressBarResaleValue - 1) {
                scaniaResalePriceDiff = value;
                step7.forms[1].fields[0].value = this.formattedDefaultCurrency+value;
              }
            })
          }
        }

        if (i > 1) {
          competitorProductName = step2.forms[i - 1].fields[0].value;
          competitorVehiclePrice = parseFloat(step2.forms[i - 1].fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));
          average.forEach((obj: any, index: number) => {
            if (competitorProductName == obj.header.toUpperCase()) {
              competitorHeader = obj.header.toUpperCase();
              competitorAverage = Math.abs(obj.average);
              // var competitorAverage=7.53;
            }

          });

          this.competitorResalePrice = [];
          for (let j = 0; j < maximumResaleYears; j++) {
            competitorFirstYearResalePrice = competitorVehiclePrice - (competitorVehiclePrice * (competitorAverage / 100));
            competitorVehiclePrice = competitorFirstYearResalePrice;
            this.competitorResalePrice.push((Math.round(competitorFirstYearResalePrice).toLocaleString('en-US')));
          }
          if (this.maxProgressBarResaleValue != null) {
            this.competitorResalePrice.forEach((value: any, index2: number) => {
              if (index2 == this.maxProgressBarResaleValue - 1) {
                if (competitorHeader == competitorProductName) {
                  let diffResaleValue = ((scaniaResalePriceDiff.replace(/,/g, '')) - value.replace(/,/g, ''));
                  step7.forms[i].fields[0].value = this.formattedDefaultCurrency+value;
                  step7.forms[i].fields[1].value = this.formattedDefaultCurrency+(diffResaleValue.toLocaleString('en-US'));
                }
              }
            })
          }
        }
      }
    }
  }

  calculateUsedVehicleLiquidityComparison() {
    var step1 = this.tco.steps[0];
    var step2 = this.tco.steps[1];
    var step7 = this.tco.steps[6];
    var step8 = this.tco.steps[7];
    let scaniaProductName = step2.forms[0].fields[0].value;
    var isHasOMEName = true;
    var isHasinvestmentInterestRate = true;

    step2.forms.forEach(element => {
      var OMEName = element.fields[0].value;
      if (OMEName == null || OMEName == "" || OMEName == undefined) {
        isHasOMEName = false;
        this.stickyPopUpService.info("'Select OME - Product Name' first!");
      }
    });



    step8.forms.forEach((element) => {
      var investmentInterestRate = element.fields[0].value;

      if (investmentInterestRate == "" || investmentInterestRate == null) {
        isHasinvestmentInterestRate = false;
      }
    });

    if (isHasOMEName) {

      var lastDepreciationAverage;
      var average = this.devaluationDataResponse;
      for (let i = 0; i < step8.forms.length; i++) {
        var lastDepreciationHeader = step8.forms[i].header;
      }

      average.forEach((obj: any, index: number) => {
        if (lastDepreciationHeader == obj.header.toUpperCase()) {
          lastDepreciationAverage = Math.abs(obj.average);
        }
      });
      var investmentInterestRate: any;
      let usedVehicleResalePrice: any;
      let calculateCapitalCost: any;
      let competitorAverageStockPeriod: any;
      let scaniaAverageStockPeriod: any;
      let daysInStock: any;

      if (isHasinvestmentInterestRate) {
        this.usedVehicleLiquidityDifference = [];
        for (let j = 0; j < step2.forms.length; j++) {
          if (j == 0) {
            const capitalCostDifferenceDiff = {
              productName: scaniaProductName,
              amount: '',
            }
            this.usedVehicleLiquidityDifference.push(capitalCostDifferenceDiff);
          } else {
            investmentInterestRate = parseFloat(step8.forms[0].fields[0].value.replace(/,/g, '')) / 100;
            scaniaAverageStockPeriod = step8.forms[1].fields[0].value.replace(/,/g, '');
            competitorAverageStockPeriod = step8.forms[j + 1].fields[0].value.replace(/,/g, '');
            if (scaniaAverageStockPeriod && competitorAverageStockPeriod) {
              daysInStock = scaniaAverageStockPeriod - competitorAverageStockPeriod;
              usedVehicleResalePrice = step7.forms[j + 1].fields[0].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,'');
              calculateCapitalCost = ((((lastDepreciationAverage / 100) / 365) + (investmentInterestRate / 365) * usedVehicleResalePrice) * (-daysInStock));
              calculateCapitalCost.toFixed(2);
              this.calculateCapitalCosts = calculateCapitalCost;
              if( !Number.isNaN(calculateCapitalCost)){
                const capitalCostDifferenceDiff = {
                  productName: step2.forms[j].fields[0].value,
                  amount: Math.round(calculateCapitalCost).toLocaleString('en-US'),
                }
                this.usedVehicleLiquidityDifference.push(capitalCostDifferenceDiff);
              }
              
            }
          }
        }

      }
      this.calculateFinalResultsTCO();
    }


  }

  calculateFinalResultsTCO() {
    
    var step1 = this.tco.steps[0];
    var step7 = this.tco.steps[6];
    var step8 = this.tco.steps[7];
    var step2 = this.tco.steps[1];
    var step3 = this.tco.steps[2];
    var vehiclePurchasePriceDiff;
    var interestRateDiff;
    var fuelConsumptionDiff;
    var adBlueConsumptionDiff;
    var maintenanceCostDiff;
    var usedVehiclePriceDiff;
    var usedVehicleLiquidityDiff;
    var scaniaProductName;
    var competitorProductName;
    var downPaymentPriceDiff;
    var financialApplication;


    this.sumTotalEconomy = [];
    this.annualYieldTax = [];
    this.financialApplicationYield = [];
    this.totalOperationalEconomy = [];
    scaniaProductName = step2.forms[0].fields[0].value;

    const scaniaObj = {
      productName: scaniaProductName,
      amount: "",
    }
    this.sumTotalEconomy.push(scaniaObj);
    this.annualYieldTax.push(scaniaObj);
    this.financialApplicationYield.push(scaniaObj);
    this.totalOperationalEconomy.push(scaniaObj);

    let length = step2.forms.length;
    let negotiationVolume = step1.forms[0].fields[1].value;
    var investmentInterestRate = parseFloat(step8.forms[0].fields[0].value) / 100;


    if (investmentInterestRate != null && investmentInterestRate != 0 && !Number.isNaN(investmentInterestRate)) {
      if (this.calculateCapitalCosts) {
        for (let i = 1; i <= length; i++) {
          if (step2.forms.length > i && this.usedVehicleLiquidityDifference[i]) {
            vehiclePurchasePriceDiff = parseFloat(this.vehiclePurchasePriceDifference[i].amount.replace(/,/g, ''));
            interestRateDiff = parseFloat(this.interestRateDifferences[i].amount.replace(/,/g, ''));
            fuelConsumptionDiff = parseFloat(this.fuelConsumptionDifference[i].amount.replace(/,/g, ''));
            adBlueConsumptionDiff = parseFloat(this.adBlueConsumptionDifferences[i].amount.replace(/,/g, ''));
            maintenanceCostDiff = parseFloat(this.maintenanceCostDifference[i].amount.replace(/,/g, ''));
            usedVehicleLiquidityDiff = parseFloat(this.usedVehicleLiquidityDifference[i].amount.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));

            step7.forms.forEach((value1, index) => {
              if (index == 1) {
                scaniaProductName = value1.header;
              }
              if (index > 1) {
                usedVehiclePriceDiff = parseFloat(value1.fields[1].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));
                competitorProductName = value1.header;
                if (step2.forms.length - 1 >= i) {

                  if (competitorProductName == step2.forms[i].fields[0].value) {
                    var sum = vehiclePurchasePriceDiff + interestRateDiff + fuelConsumptionDiff
                      + adBlueConsumptionDiff + maintenanceCostDiff + usedVehicleLiquidityDiff
                      + usedVehiclePriceDiff;

                    sum = Math.round(sum);
                    if(!Number.isNaN(sum)){
                      const competetiorObj = {
                        productName: competitorProductName,
                        amount: sum.toLocaleString('en-US'),
                      }
                      this.sumTotalEconomy.push(competetiorObj);
                    }

                    var futureValue;
                    var futureValueObj;
                    step3.forms.forEach((value2, index2: number) => {
                      if (index2 != 0) {
                        if(value1.header == value2.header){
                        var financingPeriodMonths = parseFloat(value2.fields[2].value) / 12;
                        downPaymentPriceDiff = parseFloat(this.downPaymentPriceDifferences[index2].amount.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));
                        futureValue = -this.FV(investmentInterestRate, financingPeriodMonths, 0, downPaymentPriceDiff, 1);
                        futureValue = (futureValue - downPaymentPriceDiff).toFixed(2);
                        futureValue = Math.round(futureValue);
                        if(!Number.isNaN(parseFloat(futureValue))){
                          futureValueObj = {
                            productName: competitorProductName,
                            amount: futureValue.toLocaleString('en-US'),
                          }
                        }
                      }
                      }

                    })
                    this.annualYieldTax.push(futureValueObj);
                    if (futureValue != "" && futureValue != null && sum != "" && sum != null) {
                      futureValue = parseFloat(futureValue);
                      financialApplication = Math.round(sum + futureValue);
                      if(!Number.isNaN(parseFloat(financialApplication))){
                        const obj = {
                          productName: competitorProductName,
                          amount: financialApplication.toLocaleString('en-US'),
                        }
                        this.financialApplicationYield.push(obj);
                      }
                     
                    }

                    if (financialApplication != "" && financialApplication != null) {
                      let amountApplication = Math.round(financialApplication * parseFloat(negotiationVolume));
                      if(!Number.isNaN(amountApplication)){
                        const obj = {
                          productName: competitorProductName,
                          amount: amountApplication.toLocaleString('en-US'),
                        }
                        this.totalOperationalEconomy.push(obj);
                      }
                    }
                  }
                }
              }
            })

          }

        }
        this.onSave();
      }

    }
  }

  FV(rate, nper, pmt, pv, type) {
    var pow = Math.pow(1 + rate, nper),
      fv;
    if (rate) {
      fv = (pmt * (1 + rate * type) * (1 - pow) / rate) - pv * pow;
    } else {
      fv = -1 * (pv + pmt * nper);
    }
    return fv.toFixed(2);
  }

  addVehiclePurchaseForm(form, index) {

    // retriving step 2 forms
    var forms = this.tco.steps[1].forms;
    var isHasOMEName = true;
    forms.forEach((element) => {
      var omeName = element.fields[0].value;
      var vehiclePrice = element.fields[3].value;
      if (omeName == "" || omeName == null) {
        isHasOMEName = false;
        this.stickyPopUpService.info("'Select OME - Product Name' first!");
      }
      if(isHasOMEName){
        if (vehiclePrice == "" || vehiclePrice == null) {
          isHasOMEName = false;
          this.stickyPopUpService.info("Please fill the Vehicle Price.");
        }
      }
      
    });

    if (isHasOMEName) {
      var arrayfield = [];
      var placeholder = [
        "OEM - Product Name",
        "Horsepower",
        "Optionals",
        "Vehicle Price",
      ];
      for (let index = 0; index <= 3; index++) {
        arrayfield.push({
          position: index,
          placeholder: placeholder[index],
          formula: "",
          value: "",
        });
      }

      forms.push({
        position: 0,
        header: this.selectedProductName + " Vehicle Purchase Details",
        fields: arrayfield,
      });
      this.comparisonProduct(this.selectedProductName);
      this.addFinancialCost(this.selectedProductName, index);
      this.addAdBlue(this.selectedProductName, forms.length - 1);
    }
  }

  public addFinancialCost(productName, index) {
    var placeholder = [
      "Down Payment %",
      "Interest Rate(Monthly)",
      "Financing Period(Months)",
    ];
    var financialFormFields = [];
    for (let i = 1; i <= 3; i++) {
      financialFormFields.push({
        position: i,
        placeholder: placeholder[i - 1],
        formula: "",
        value: "",
      });
    }
    var form = this.tco.steps[2].forms[index];
    if (form) {
      form.position = parseInt(index) + 1;
      form.header = productName;
      form.fields = financialFormFields;
    } else {
      var forms = this.tco.steps[2].forms;
      forms.push({
        position: index,
        header: productName,
        fields: financialFormFields,
      });
    }
  }

  public addFuelConsumption(productName, index) {
    var placeholder = [
      "Average consumption (kilometers per liter)",
    ];
    var fuelFormFields = [];
    for (let i = 1; i <= 1; i++) {
      fuelFormFields.push({
        position: i,
        placeholder: placeholder[i - 1],
        formula: "",
        value: "",
      });
    }
    let indexPosition = index + 1;
    var form = this.tco.steps[3].forms[indexPosition];
    if (form) {
      form.position = parseInt(index) + 1;
      form.header = productName;
      form.fields = fuelFormFields;
    } else {
      var forms = this.tco.steps[3].forms;
      forms.push({
        position: index,
        header: productName,
        fields: fuelFormFields,
      });
    }
  }

  ////////////////////////////////////////////////////OKKKK
  public addAdBlue(productName, index) {
    var adBlueFormFields = [];
    adBlueFormFields.push({
      position: 1,
      placeholder: "Average consumption AdBlue / Diesel",
      formula: "",
      value: "",
    });

    var form = this.tco.steps[4].forms[index + 1];

    if (form) {
      form.position = parseInt(index) + 1;
      form.header = productName;
      form.fields = adBlueFormFields;
    } else {
      var forms = this.tco.steps[4].forms;
      forms.push({
        position: index,
        header: productName,
        fields: adBlueFormFields,
      });
    }
  }


  public addContractedServices(productName, index) {

    var placeholder = [
      "Contracted Services (Maintenance + Repair)",
      "Maintenance Cost Per Run Kilometer"
    ];
    var contractedFormFields = [];
    for (let i = 1; i <= 2; i++) {
      contractedFormFields.push({
        position: i,
        placeholder: placeholder[i - 1],
        formula: "",
        value: "",
      });
    }

    var form = this.tco.steps[5].forms[index];
    if (form) {
      form.position = parseInt(index) + 1;
      form.header = productName;
      form.fields = contractedFormFields;
    } else {
      var forms = this.tco.steps[5].forms;
      forms.push({
        position: index,
        header: productName,
        fields: contractedFormFields,
      });
    }
  }

  public addUsedVehicleLiquidity(productName, index) {

    var placeholder = [
      "Average Stock Period / Days(yard)",
    ];
    var usedVehicleLiquidityFormFields = [];
    for (let i = 1; i <= 1; i++) {
      usedVehicleLiquidityFormFields.push({
        position: index,
        placeholder: placeholder[i - 1],
        formula: "",
        value: "",
      });
    }

    var form = this.tco.steps[7].forms[index + 1];
    if (form) {
      form.position = parseInt(index) + 1;
      form.header = productName;
      form.fields = usedVehicleLiquidityFormFields;
    } else {
      var forms = this.tco.steps[7].forms;
      forms.push({
        position: index,
        header: productName,
        fields: usedVehicleLiquidityFormFields,
      });
    }
  }

  public addUsedVehicleResale(productName, index) {
    if (index == 0) {
      var placeholder = [
        "Vehicle resale price based on amount of years",
      ];
      var usedVehicleResaleFormFields = [];
      for (let i = 1; i <= 1; i++) {
        usedVehicleResaleFormFields.push({
          position: i,
          placeholder: placeholder[i - 1],
          formula: "",
          value: "",
        });
      }

    } else {
      var placeholder = [
        "Vehicle resale price based on amount of years",
        "Competitor used vehicle price difference from Scania"
      ];
      var usedVehicleResaleFormFields = [];
      for (let i = 1; i <= 2; i++) {
        usedVehicleResaleFormFields.push({
          position: i,
          placeholder: placeholder[i - 1],
          formula: "",
          value: "",
        });
      }
    }

    var form = this.tco.steps[6].forms[index + 1];
    if (form) {
      form.position = parseInt(index) + 1;
      form.header = productName;
      form.fields = usedVehicleResaleFormFields;
    } else {
      var forms = this.tco.steps[6].forms;
      forms.push({
        position: index,
        header: productName,
        fields: usedVehicleResaleFormFields,
      });
    }
  }


  devaluationAnalysis() {
    localStorage.setItem("currencySymbol", JSON.stringify(this.formattedDefaultCurrency));
    if (this.toolsetId != "" && this.toolsetId != undefined) {
      this.route.navigate(["toolset/tco-calculator/" + this.toolsetId + "/devaluationAnalysis"]);
    } else {
      this.route.navigate(["toolset/tco-calculator/New/devaluationAnalysis"]);
    }
  }

  amoritizationInvestment() {
    if (this.periodMonth.length > 1) {
      this.route.navigate(["toolset/tco-calculator/New/amoritizationinvestment"]);
    } else {
      this.stickyPopUpService.info("Please complete all TCO Calculator steps.");
    }
  }

  removePlaceholder(i, j, k) {
    this.tco.steps[i].forms[j].fields[k].value = "";
  }

  opacityValues() {
    this.opacityValueYear = this.tco.steps[0].forms[0].fields[2].value;
  }


  calculateFormulaAmoritization(tcoDataRes) {
    var step1 = tcoDataRes.steps[0];
    var step2 = tcoDataRes.steps[1];
    var step3 = tcoDataRes.steps[2];
    var step7 = tcoDataRes.steps[6].forms;
    var comparison = tcoDataRes.comparison;
    var vehicleLifecycleYear = parseFloat(step1.forms[0].fields[2].value);
    this.periodMonth = [];
    var usedVehiclePrice = [];
    var amoritizationData: any = [];
    var vehiclePriceDiff: any;
    var title: any;
    var fuel: any;
    var adBlue: any;
    var contractedServices: any;
    var usedVehiclePr: any;
    var usedVehicleLiquidity: any;
    var monthlyDifference: any;

    step7.forEach((element: any, index: number) => {
      if (index != 0) {
        usedVehiclePrice.push(element);
      }

    });

    if (comparison) {
      var scaniaProductName = step2.forms[0].fields[0].value;
      var obj = {
        productName: scaniaProductName,
        amount: 0,
      }
      this.periodMonth.push(obj);

      for (let i = 0; i < step2.forms.length; i++) {
        var financialCost: any;
        var scaniaVehiclePrice = parseFloat(step2.forms[0].fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));


        if (i != 0) {
          var competetiorVehiclePrice = parseFloat(step2.forms[i].fields[3].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,''));

          title = comparison.vehiclePurchaseComparison[i].header;
          vehiclePriceDiff = scaniaVehiclePrice - competetiorVehiclePrice;
          fuel = parseFloat(comparison.fuelConsumptionComparison[i].amount.replace(/,/g, '')) / vehicleLifecycleYear / 12;
          adBlue = parseFloat(comparison.adBlueDieselComparison[i].amount.replace(/,/g, '')) / vehicleLifecycleYear / 12;
          contractedServices = parseFloat(comparison.contractedServicesComparison[i].amount.replace(/,/g, '')) / vehicleLifecycleYear / 12;
          usedVehiclePr = parseFloat(usedVehiclePrice[i].fields[1].value.replace(/,/g, '').trim().replace(this.formattedDefaultCurrency,'')) / vehicleLifecycleYear / 12;
          usedVehicleLiquidity = parseFloat(comparison.usedVehicleLiquidityComparison[i].amount.replace(/,/g, '')) / vehicleLifecycleYear / 12;
          financialCost = parseFloat(comparison.financingComparison[0].InterestRateDifference[i].amount.replace(/,/g, '')) / vehicleLifecycleYear / 12;
          //financialCost=parseFloat(financialCost.InterestRateDifference[i].amount.replace(/,/g, ''))/vehicleLifecycleYear/12;
         // var localdiffrence=0.0147;
          monthlyDifference = (fuel + adBlue + contractedServices + usedVehiclePr + usedVehicleLiquidity + financialCost);
          vehiclePriceDiff = Math.round(vehiclePriceDiff);
          fuel = Math.round(fuel);
          adBlue = Math.round(adBlue);
          contractedServices = Math.round(contractedServices);
          usedVehiclePr = Math.round(usedVehiclePr);
          usedVehicleLiquidity = Math.round(usedVehicleLiquidity);
          financialCost = Math.round(financialCost);
          
          var formObj;
          var endInitialBalance: any;
          var value: any;
          var endBalance: any;
          var initialBalance: any;

         var monthlyDiff = parseFloat(monthlyDifference);
           
          initialBalance = -vehiclePriceDiff;
          endBalance = initialBalance + monthlyDiff;
          var length = parseFloat(step1.forms[0].fields[2].value.replace(/,/g, '')) * 12;
          var interestRateMonthly = parseFloat(step3.forms[i].fields[1].value.replace(/,/g, '')) / 100;

          for (let i = 1; i <= length; i++) {

            if (endBalance >= 0) {
              value = 0;
              var objPeriodMonth = {
                productName: title,
                amount: i,
              }

              this.periodMonth.push(objPeriodMonth);
              break;

            } else {
              endInitialBalance = endBalance * (1 + interestRateMonthly);
              endBalance = endBalance * (1 + interestRateMonthly) + monthlyDiff;
               if(i==length){
                var objPeriodMonth = {
                  productName: title,
                  amount: i,
                }
                this.periodMonth.push(objPeriodMonth);
               }
            }

          }
          endInitialBalance = Math.round(endInitialBalance);
          initialBalance = Math.round(initialBalance);
          monthlyDifference = Math.round(monthlyDifference);
          formObj = {
            // id: "",
            // created: "",
            // updated: "",
            title: title,
            vehiclePriceDifference: this.formattedDefaultCurrency + vehiclePriceDiff.toLocaleString('en-US'),
            fuel: this.formattedDefaultCurrency + fuel.toLocaleString('en-US'),
            adBlue: this.formattedDefaultCurrency + adBlue.toLocaleString('en-US'),
            contractedServices: this.formattedDefaultCurrency + contractedServices.toLocaleString('en-US'),
            usedVehiclePrice: this.formattedDefaultCurrency + usedVehiclePr.toLocaleString('en-US'),
            usedVehicleLiquidity: this.formattedDefaultCurrency + usedVehicleLiquidity.toLocaleString('en-US'),
            financialCost: this.formattedDefaultCurrency + financialCost.toLocaleString('en-US'),
            monthlyDifference: this.formattedDefaultCurrency + monthlyDifference.toLocaleString('en-US'),
            initialBalance: this.formattedDefaultCurrency + initialBalance.toLocaleString('en-US'),
            endBalance: this.formattedDefaultCurrency + endInitialBalance.toLocaleString('en-US')
          }
          this.amoritizationForm = formObj;
          amoritizationData.push(this.amoritizationForm);
        }
      }
      localStorage.setItem("Amoritization", JSON.stringify(amoritizationData)); 
    }
  }

  onSave() {
    var forms = this.tco;
    var comparisionForms: any = new CmdFormComparison();
    comparisionForms.vehiclePurchaseComparison = this.vehiclePurchasePriceDifference;
    comparisionForms.financingComparison = [{
      downPaymentAmount: this.downPaymentAmounts,
      monthlyInstallment: this.monthlyInstallmentAmounts,
      downPaymentDifference: this.downPaymentPriceDifferences,
      InterestRateDifference: this.interestRateDifferences
    }];

    comparisionForms.fuelConsumptionComparison = this.fuelConsumptionDifference;
    comparisionForms.adBlueDieselComparison = this.adBlueConsumptionDifferences;
    comparisionForms.contractedServicesComparison = this.maintenanceCostDifference;
    comparisionForms.usedVehicleLiquidityComparison = this.usedVehicleLiquidityDifference;

    comparisionForms.finalResult = [{
      lifeCycle: this.sumTotalEconomy,
      financialApplication: this.annualYieldTax,
      negotiationVolume: this.financialApplicationYield,
      investmentPeriod: this.totalOperationalEconomy,
      returnOnInvestmentPeriod: this.totalOperationalEconomy
    }]

    forms.comparison = comparisionForms;

    var org = this.organizationService.getCurrentOrganizationId();
    var tcoObject = {
      type: "TCO_CALCULATOR",
      properties: {
        tcoData: {
          tcoTitle: "TCO",
          tcoSubTitle: "Total cost of ownership",
          tcoImage: {},
          steps: forms,
        },
      },
      organizationId: org,
    };
    localStorage.setItem("TcoCalculator", JSON.stringify(tcoObject));

    this.tcoDataRes = tcoObject.properties.tcoData.steps;
    this.calculateFormulaAmoritization(this.tcoDataRes);
  }

  onCancel() {
    localStorage.removeItem('TcoCalculator');
    this.route.navigate(["/toolsets"]);
  }
  
  changeCurrency(){
    if( this.devaluationForCurrentOrg ) this.updateDefaultCurrency(this.devaluationForCurrentOrg.id, this.selectedCurrency.keyword);
  }

  updateDefaultCurrency(devaluationId:string, currencyKeyword:string){
    this.loadingCurrency = true;
    this.tcoCalculatorService.updateTcoDefaultCurrencyInDevaluation(devaluationId,currencyKeyword).subscribe(res => {
      this.stickyPopUpService.success("Currency Updated");
      this.tcoCalculatorService
      .getDevaluationForOrgId(this.currentOrgId)
      .subscribe((devaluation) => {
        localStorage.removeItem("TcoCalculator");
        localStorage.removeItem("Amoritization");
        this.getTCOTemplate();
        this.clearAllCalculation();
        var response = JSON.parse(devaluation.devaluationData);
        this.loadingCurrency = false;
         this.currencies.forEach(currency => {
          if(currency.keyword == response.defaultCurrency.keyword){
            this.selectedCurrency = currency;
            this.formattedDefaultCurrency = "\u202A"+ this.selectedCurrency.symbol +"\u202C ";
            localStorage.setItem("currencySymbol", JSON.stringify(this.formattedDefaultCurrency));
          }
        });
      }, error => {
        this.loadingCurrency = false;
        this.stickyPopUpService.error('Failed to get Devaluation with updated currency: ' + error.error.message);
        console.log('Failed to get Devaluation with updated currency: ',error.error.message);
      });
    });
  }

  clearAllCalculation(){
    this.vehiclePurchasePriceDifference = [];
    this.downPaymentAmounts = [];
    this.monthlyInstallmentAmounts = [];
    this.downPaymentPriceDifferences = [];
    this.interestRateDifferences = [];
    this.fuelConsumptionDifference = [];
    this.adBlueConsumptionDifferences = [];
    this.maintenanceCostDifference = [];
    this.usedVehicleLiquidityDifference = [];
    this.sumTotalEconomy = []
    this.annualYieldTax = [];
    this.financialApplicationYield = [];
    this.totalOperationalEconomy = [];
    this.periodMonth = [];
  }

  openHistory() {
    this.route.navigate(['/toolset/tco-calculator/tco-history']);
  }
}
