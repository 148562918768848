import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EnvironmentSpecificService } from "./environmentspecific.service";
import { Subject, Observable } from "rxjs";
import { SearchModel } from "../models/SearchModel";
import { AzureAuthholderService } from "../services/azureauthholder.service";

@Injectable()
export class SalesQuoteService {

    constructor(
        private azureholder: AzureAuthholderService,
        private httpClient: HttpClient,
        private envService: EnvironmentSpecificService) {
    }


    search(orgId: string, fillter: SearchModel = new SearchModel()): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let httpParams = new HttpParams();
        httpParams = httpParams.append('orgId', orgId.toString());
        httpParams = httpParams.append('sortBy', fillter.page.sortBy.toString());
        httpParams = httpParams.append('sortOrder', fillter.page.sortOrder.toString());
        httpParams = httpParams.append('pageSize', fillter.page.pageSize.toString());
        httpParams = httpParams.append('page', fillter.page.page.toString());
        httpParams = fillter.searchText.length > 0 ? httpParams.append('searchText', fillter.searchText) : httpParams;
        const requestOptions = {params: httpParams, headers: headers };
        return this.httpClient
            .get<any>(this.envService.envSpecific.baseServerUrl + '/api/salesquotes/search',requestOptions );
    }

    setStatus(id: string, status: string): Promise<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let httpParams = new HttpParams();
        const requestOptions = {params: httpParams, headers: headers };
        httpParams = httpParams.append('status', status);
        return this.httpClient.put<any>(this.envService.envSpecific.baseServerUrl + '/api/salesquotes/' + id, requestOptions).toPromise();
    }
}