import { CmdMenuItem, CmdWidget } from '../models';
export class CmdMenu extends CmdWidget{
    
    items: Array<CmdMenuItem>;
    constructor(id:string){
        super(id);
        this.type = "Menu";
        this.items = new Array<CmdMenuItem>();
    }
    
    
    validate():boolean{
        if(!super.validate()){
            return false;
        }
        this.items.forEach(item => {
            if(!item.validate()){
                return false;
            }
        });
        return true;
    }    
}