import { CmdEmailFormItem, CmdWidget } from '../models';
export class CmdEmailForm extends CmdWidget {
    
    formHeader:string;
    formFooter:string;
    subject:string;
    
    constructor(id:string){
        super(id);
        this.type = "EmailForm";
    }
}