import { CmdAsset } from '../../models';
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-assetdisplay',
    templateUrl: './assetdisplay.component.html',
    styleUrls: ['./assetdisplay.component.scss']
})
export class AssetdisplayComponent implements OnInit {

    theAsset:CmdAsset;
    theLabel:string = "Remove";
    theIcon:string = "trash";
    @Output() onRemove:EventEmitter<CmdAsset> = new EventEmitter<CmdAsset>();
    @Output() onClick:EventEmitter<CmdAsset> = new EventEmitter<CmdAsset>();
    constructor() {}

    ngOnInit() {
    }
    
    @Input() set asset(ast:CmdAsset){
        this.theAsset = ast;
    }
    @Input() set label(l:string){
        this.theLabel = l;
    }
    @Input() set icon(l:string){
        this.theIcon = l;
    }
    
    handleClick(){
        this.onClick.emit(this.theAsset);
    }
    
    handleRemove(){
        this.onRemove.emit(this.theAsset);
    }
}
