import { CmdButton } from '../../models';
import { CmdEmailTemplate } from '../../models/CmdEmailTemplate';
import { OrganizationService } from '../../services/organization.service';
import { WidgetService, ItemModel } from '../../services/widget.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { MedialibraryComponent } from '../../shared/medialibrary/medialibrary.component';
import { WidgetEditor } from '../widgeteditor';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { MedialibraryService } from '../../shared/medialibrary/medialibrary.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'app-buttonedit',
    templateUrl: './buttonedit.component.html',
    styleUrls: ['./buttonedit.component.css']
})
export class ButtoneditComponent extends WidgetEditor implements OnInit {

    showImage: string = "Image";
    widgetData: CmdButton;
    emailTemplates: Array<CmdEmailTemplate>;
    mediaModalRef: BsModalRef;
    confirmModalRef: BsModalRef;
    @ViewChild('buttonWidgetForm', { static: false }) buttonWidgetForm: NgForm;

    constructor(private widgetService: WidgetService,
        private orgService: OrganizationService,
        private modalService: BsModalService,
        private mediaLibraryService: MedialibraryService) {
        super();
        this.change = new EventEmitter<string>();
        this.orgService.getEmailTemplates().subscribe(temps => { this.emailTemplates = temps });
    }

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '7rem',
        maxHeight: '10rem',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '4',
        fonts: [
            { class: 'Scania', name: 'Scania' },
            { class: 'ScaniaItalic', name: 'ScaniaItalic' },
            { class: 'ScaniaRegular', name: 'ScaniaRegular' },
            { class: 'ScaniaHeadlineBold', name: 'ScaniaHeadlineBold' },
            { class: 'ScaniaHeadlineRegular', name: 'ScaniaHeadlineRegular' },
            { class: 'arial', name: 'Arial' },
            { class: 'times-new-roman', name: 'Times New Roman' },
            { class: 'calibri', name: 'Calibri' },
            { class: 'comic-sans-ms', name: 'Comic Sans MS' },
            { class: 'GT-Eesti-Text-Regular', name: 'GT Eesti Text Regular' },
            { class: 'GT-Eesti-Text-Light', name: 'GT Eesti Text Light' },
            { class: 'EBGaramond-Regular', name: 'Garamond Regular' },
            { class: 'FranklinGothic', name: 'Franklin Gothic' },
            { class: 'Helvetica', name: 'Helvetica' },
            { class: 'OpenSans-Regular', name: 'Open Sans Regular' },
            //{ class: 'Rockwell', name: 'Rockwell' },
            { class: 'Verdana', name: 'Verdana' }
        ],
        customClasses: [
          {
            name: 'h1-text-left',
            class: 'text-left',
            tag: 'h1',
          },
          {
            name: 'h1-text-center',
            class: 'text-center',
            tag: 'h1',
          },
          {
            name: 'h1-text-right',
            class: 'text-right',
            tag: 'h1',
          },
          {
            name: 'h2-text-left',
            class: 'text-left',
            tag: 'h2',
          },
          {
            name: 'h2-text-center',
            class: 'text-center',
            tag: 'h2',
          },
          {
            name: 'h2-text-right',
            class: 'text-right',
            tag: 'h2',
          },
          {
            name: 'h3-text-left',
            class: 'text-left',
            tag: 'h3',
          },
          {
            name: 'h3-text-center',
            class: 'text-center',
            tag: 'h3',
          },
          {
            name: 'h3-text-right',
            class: 'text-right',
            tag: 'h3',
          },
          {
            name: 'h4-text-left',
            class: 'text-left',
            tag: 'h4',
          },
          {
            name: 'h4-text-center',
            class: 'text-center',
            tag: 'h4',
          },
          {
            name: 'h4-text-right',
            class: 'text-right',
            tag: 'h4',
          },
          {
            name: 'h5-text-left',
            class: 'text-left',
            tag: 'h5',
          },
          {
            name: 'h5-text-center',
            class: 'text-center',
            tag: 'h5',
          },
          {
            name: 'h5-text-right',
            class: 'text-right',
            tag: 'h5',
          },
          {
            name: 'p-text-left',
            class: 'text-left',
            tag: 'p',
          },
          {
            name: 'p-text-center',
            class: 'text-center',
            tag: 'p',
          },
          {
            name: 'p-text-right',
            class: 'text-right',
            tag: 'p',
          }
        ],
        sanitize:false
      };

    ngOnInit() {
    }

    @Input() set widget(data: CmdButton) {
        this.widgetData = data;
    }
    selectImage() {
        let self = this;
        this.mediaLibraryService.assetType = 'image';
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result && result.length == 1) {
                    this.widgetData.background = result[0];
                    this.notifyChange();
                }
            });
    }

    clearImage() {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this image?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.widgetData.background = undefined;
                    this.notifyChange();
                }
            });
    }
}
