import { CmdAsset } from './CmdAsset';
import { CmdWidget } from './CmdWidget';

export class CmdSingleCardItem extends CmdWidget {
    image: CmdAsset;
    label: string;
    thumbnail: CmdAsset;
    asset: CmdAsset;
    headerFontSize: any;
    subHeaderFontSize: string;
    descriptionFontSize: string;
    headerColor: string;
    subHeaderColor: string;
    descriptionColor: string;
    constructor(id: string) {
        super(id);
        this.type = "SingleCardItem";
    }

    validate(): boolean {
        if (!super.validate()) {
            return false;
        }

        return this.image != undefined;
    }
}
