import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../../services/organization.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import * as $ from "jquery";
import { EditQuoteBuilderService } from '../../../../services/editquotebuilder';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';

@Component({
  selector: 'app-add-ons-modal',
  templateUrl: './add-ons-modal.component.html',
  styleUrls: ['./add-ons-modal.component.css']
})
export class AddOnsModalComponent implements OnInit {
  schedule: { branch: '' };
  values = [];
  name = 'Angular';
  myForm: FormGroup;
  arr: FormArray;
  addCreateProductBuildeModalRef: BsModalRef;
  fieldNameModalRef: BsModalRef;
  title: string = '';
  uploadData: any;
  fileData: any;
  onClose = new Subject<any>();
  public skuVal: string = '';
  public skuBool: boolean = false;
  public invData: any;
  public quoteId: any;
  public orgId: any;
  public apiType: string;
  public btnName: string="Save";
  public productData: any;
  public headerData: any = [];
  public addOnData: any = [];

  constructor(private bsModalRef: BsModalRef,
    public organizationService: OrganizationService,private apiService:EditQuoteBuilderService,
    private stickyPopUp: StickyPopUpService,
  ) {

  }

  ngOnInit() {
    this.onClose = new Subject();
  }
  addvalue() {
    this.addOnData.push({
      "name": "",
      "isNameEditable": false,
      "amount": "",
      "isAmountEditable": false,
      "isPercentage": false,
      "type": "discount"
  });
    $("#myList").animate({ scrollTop: $('#myList').prop("scrollHeight") }, 500);
  }

  removevalue(i) {
    this.addOnData.splice(i, 1);
    this.updateAddOns('delete')
  }
  onCancel() {
    this.bsModalRef.hide();
  }
  updateAddOns(type){
    let flag=false;
    if(type=='update'){
      
      this.addOnData.forEach(element => {
        if(element.name=='' || element.amount=='' || element.type==''){
          flag=true;
          return;
        }
      });

    }
    if(flag){
      this.stickyPopUp.error("Please enter all fields to continue");
      return;
    }
    let body={quoteBuilderAddOns:this.addOnData};
    this.apiService.putMethod("/api/quotebuilder/"+ this.orgId.id+"/"+this.quoteId+"/"+"addons",body).subscribe(
      data=>{
        this.stickyPopUp.success('Add Ons Updated');
        if(type=="update")
        this.bsModalRef.hide();

      },
      err=>{
        this.stickyPopUp.error("Some problem occured");

      }
    )
  }
  amountEditableStatus(status,index){
    console.log(status);
    this.addOnData[index].isAmountEditable=status;
  }
  percentEditableStatus(status,index){
    console.log(status);
    this.addOnData[index].isPercentage=status;
  }
  nameEditableStatus(status,index){
    console.log(status);
    this.addOnData[index].isNameEditable=status;
  }
}
