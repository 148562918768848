import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '../../../services/form.service';
import { FormUserService } from '../../../services/form.user.service';
import { take } from 'rxjs/operators';
import { CmdOrganization } from '../../../models';
import { OrganizationService } from '../../../services/organization.service';
import { BaseModelFormIO } from '../../../models/CmdFormModel';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';

@Component({
    selector: 'app-form-data',
    templateUrl: 'form-data.component.html',
    styleUrls: ['form-data.component.scss']
})
export class FormDataComponent implements OnInit {

    form: BaseModelFormIO;
    submissions: Array<any>;
    organization: CmdOrganization;
    isHide = true;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private alertService: StickyPopUpService,
        private organizationService: OrganizationService,
        private userService: FormUserService,
        private formService: FormService
    ) { }

    ngOnInit() {
        this.organization = this.organizationService.getCurrentOrganization();
        this.getForm();
    }

    getForm(): void {
        const formId = this.route.snapshot.paramMap.get('formId');
        if (!formId) {
            this.form = new BaseModelFormIO();
        } else {
            this.formService.getFormById(formId).pipe(take(1))
              .subscribe(form => {
                    
                    this.form = form;
                    this.formService.getFormSubmission(this.form.path)
                    .pipe(take(1))
                    .subscribe(submissions => {                            
                            this.submissions = submissions;
                            this.isHide = false;
                    }, error => {
                        this.isHide = false;
                        this.alertService.error('Failed to get Form Data(Form): ' + error.error.message);
                    });
                }, error => {
                    console.log(error);
                    
                    this.form = new BaseModelFormIO();
                    this.isHide = false;
                    this.alertService.error('Failed to get Form Data(API) for '+formId+': ' + error.error);
                });
        }
    }

    public close(){
        this.router.navigate(['/forms']);
    }

    getKeys(): string[] {
        return Object.keys(this.submissions[0]['data']);
    }

    getKeyLabel(key: string): string {
        for (const component of this.form['components']) {
            if (component.key === key) {
                return component.label;
            }
        }
        return key;
    }
}
