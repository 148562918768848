import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LightboxopenerDirective } from './lightboxopener/lightboxopener.directive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EmailsListInputComponent } from './emails-list-input/emails-list-input.component';
import { TagInputModule } from 'ngx-chips';
import { FormsModule } from '@angular/forms';





@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    TagInputModule,
    FormsModule
  ],
  declarations: [
    LightboxopenerDirective,
    EmailsListInputComponent
  ],
  exports: [
    LightboxopenerDirective,
    EmailsListInputComponent
  ]
})
export class SharedcomponentModule { }
