import {ApplicationService} from './application.service';
import { EnvironmentSpecificService } from './environmentspecific.service';
import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AzureAuthholderService } from "../services/azureauthholder.service";

declare var createjs: any;
declare var HTMLImageElement:any;
declare var Image:any;
@Injectable()
export class ManifestService {

    constructor(private azureholder: AzureAuthholderService,private http: HttpClient, private envService:EnvironmentSpecificService) {}

    getByApplicationId(applicationId) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+this.azureholder.getAccessToken()
          });
          
        const requestOptions = { headers: headers };

        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/applications/' + applicationId + '/manifest', requestOptions);
    }

    loadManifest(applicationId) {
        const that = this;
        this.getByApplicationId(applicationId).subscribe(function(data) {
            if (data.assets !== undefined) {
//                for (let i = 0; i < data.assets.length; i++) {
//                    const asset: any = data.assets[i];
//                    const queue = new createjs.LoadQueue(true);
//                    queue.on("fileload", function(event){
//                        const data = event.target.getResult(asset.url, true);
//                    }),
//                    queue.on("complete", function(event){
//                        const data = event.target.getResult(asset.url, true);
//                        localStorage.setItem(asset.id, data);
//                    }, that);
//                    queue.on("progress", function(event){
//                    })
//                    queue.loadFile(asset.url);
//                }
                for (let i = 0; i < data.assets.length; i++) {
                    const asset: any = data.assets[i];
                    const img = new Image();
                    img.src = asset.url;
                    img.load = function() {
                     const canvas = document.createElement('canvas');
                     document.body.appendChild(canvas);
                     const context = canvas.getContext('2d');
                     context.drawImage(img, 0, 0);
                     const adata:any = context.getImageData(0, 0, img.width, img.height).data;
                     localStorage.setItem('image', adata); // save image data
                    };
                }
            }
        });
    }
    
    getBase64Image(img) {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
    
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
    
        const dataURL = canvas.toDataURL("image/png");
    
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }
}
