import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: 'input[currencyFormatter]',
})
export class CurrencyFormattorDirective {

  currencies: { currencyName: string, symbol: string, keyword:string }[] = [
    { currencyName: 'Albania Lek', symbol: 'Lek', keyword: 'ALBANIA_LEK'},
    { currencyName: 'Algerian Dinar', symbol: 'DZD', keyword: 'ALGERIAN_DINAR'},
    { currencyName: 'Angolan Kwanza', symbol: 'Kz', keyword: 'ANGOLAN_KWANZA' },
    { currencyName: 'Argentina Peso', symbol: '$', keyword: 'ARGENTINA_PESO' },
    { currencyName: 'Armenian Dram', symbol: '֏', keyword: 'ARMENIAN_DRAM' },
    { currencyName: 'Aruban Florin', symbol: 'ƒ', keyword: 'ARUBAN_FLORIN' },
    { currencyName: 'Australian Dollar', symbol: '$', keyword: 'AUSTRALIAN_DOLLAR' },
    { currencyName: 'Azerbaijani Manat', symbol: '₼', keyword: 'AZERBAIJANI_MANAT' },
    { currencyName: 'Bahraini Dinar', symbol: '.د.ب', keyword: 'BAHRAINI_DINAR' },
    { currencyName: 'Bangladeshi Taka', symbol: '৳', keyword: 'BANGLADESHI_TAKA' },
    { currencyName: 'Barbados Dollar', symbol: 'Bds$' , keyword: 'BARBADOS_DOLLAR'},
    { currencyName: 'Belize Dollar', symbol: '$' , keyword: 'BELIZE_DOLLAR'},
    { currencyName: 'Bermudian Dollar', symbol: '$' , keyword: 'BERMUDIAN_DOLLAR'},
    { currencyName: 'Bhutanese Ngultrum', symbol: 'Nu' , keyword: 'BHUTANESE_NGULTRUM'},
    { currencyName: 'Bolivian Boliviano', symbol: 'Bs' , keyword: 'BOLIVIAN_BOLIVIANO'},
    { currencyName: 'Bosnia-Herzegovina Convertible Mark', symbol: 'KM' , keyword: 'BOSNIA_HERZEGOVINA_CONVERTIBLE_MARK'},
    { currencyName: 'Botswanan Pula', symbol: 'P' , keyword: 'BOTSWANAN_PULA'},
    { currencyName: 'Brazilian Real', symbol: 'R$' , keyword: 'BRAZILIAN_REAL'},
    { currencyName: 'Brunei Dollar', symbol: 'B$' , keyword: 'BRUNEI_DOLLAR'},
    { currencyName: 'Bulgarian Lev', symbol: 'Лв' , keyword: 'BULGARIAN_LEV'},
    { currencyName: 'Burundian Franc', symbol: 'FBu' , keyword: 'BURUNDIAN_FRANC'},
    { currencyName: 'Cambodian Riel', symbol: '៛' , keyword: 'CAMBODIAN_RIEL'},
    { currencyName: 'Canadian Dollar', symbol: 'Can$' , keyword: 'CANADIAN_DOLLAR'},
    { currencyName: 'Cayman Islands Dollar', symbol: '$' , keyword: 'CAYMAN_ISLANDS_DOLLAR'},
    { currencyName: 'Central African CFA franc', symbol: 'FCFA' , keyword: 'CENTRAL_AFRICAN_CFA_FRANC'},
    { currencyName: 'CFP Franc', symbol: '₣' , keyword: 'CFP_FRANC'},
    { currencyName: 'Chilean Peso', symbol: '$' , keyword: 'CHILEAN_PESO'},
    { currencyName: 'Chinese Yuan', symbol: '¥' , keyword: 'CHINESE_YUAN'},
    { currencyName: 'Colombian peso', symbol: '$' , keyword: 'COLOMBIAN_PESO'},
    { currencyName: 'Comorian Franc', symbol: 'CF' , keyword: 'COMORIAN_FRANC'},
    { currencyName: 'Costa Rican Colón', symbol: '¢' , keyword: 'COSTA_RICAN_COLON'},
    { currencyName: 'Croatian Kuna', symbol: 'kn' , keyword: 'CROATIAN_KUNA'},
    { currencyName: 'Czech Koruna', symbol: 'Kc' , keyword: 'CZECH_KORUNA'},
    { currencyName: 'Danish Krone', symbol: 'Øre' , keyword: 'DANISH_KRONE'},
    { currencyName: 'Djiboutian Franc', symbol: 'Fdj' , keyword: 'DJIBOUTIAN_FRANC'},
    { currencyName: 'Dominican Peso', symbol: '$' , keyword: 'DOMINICAN_PESO'},
    { currencyName: 'East Caribbean Dollar', symbol: '$' , keyword: 'EAST_CARIBBEAN_DOLLAR'},
    { currencyName: 'Egyptian pound', symbol: 'E£' , keyword: 'EGYPTIAN_POUND'},
    { currencyName: 'Ethiopian Birr', symbol: 'Br' , keyword: 'ETHIOPIAN_BIRR'},
    { currencyName: 'Euro', symbol: '€' , keyword: 'EURO'},
    { currencyName: 'Fijian Dollar', symbol: 'FJ$' , keyword: 'FIJIAN_DOLLAR'},
    { currencyName: 'Gambian Dalasi', symbol: 'D' , keyword: 'GAMBIAN_DALASI'},
    { currencyName: 'Georgian Lari', symbol: 'GEL' , keyword: 'GEORGIAN_LARI'},
    { currencyName: 'Ghanaian Cedi', symbol: 'GH¢' , keyword: 'GHANAIAN_CEDI'},
    { currencyName: 'Gibraltar Pound', symbol: '£' , keyword: 'GIBRALTAR_POUND'},
    { currencyName: 'Guatemalan Quetzal', symbol: 'Q' , keyword: 'GUATEMALAN_QUETZAL'},
    { currencyName: 'Guinean Franc', symbol: 'FG' , keyword: 'GUINEAN_FRANC'},
    { currencyName: 'Haitian Gourde', symbol: 'G' , keyword: 'HAITIAN_GOURDE'},
    { currencyName: 'Honduran Lempira', symbol: 'L' , keyword: 'HONDURAN_LEMPIRA'},
    { currencyName: 'Hong Kong Dollar', symbol: '$' , keyword: 'HONG_KONG_DOLLAR'},
    { currencyName: 'Hungarian Forint', symbol: 'Ft' , keyword: 'HUNGARIAN_FORINT'},
    { currencyName: 'Icelandic Króna', symbol: 'kr' , keyword: 'ICELANDIC_KRONA'},
    { currencyName: 'Indian Rupee', symbol: '₹' , keyword: 'INDIAN_RUPEE'},
    { currencyName: 'Indonesian Rupiah', symbol: 'Rp' , keyword: 'INDONESIAN_RUPIAH'},
    { currencyName: 'Israeli new shekel', symbol: '₪' , keyword: 'ISRAELI_NEW_SHEKEL'},
    { currencyName: 'Jamaican Dollar', symbol: '$' , keyword: 'JAMAICAN_DOLLAR'},
    { currencyName: 'Japanese Yen', symbol: '¥' , keyword: 'JAPANESE_YEN'},
    { currencyName: 'Jordanian Dinar', symbol: 'د.ا' , keyword: 'JORDANIAN_DINAR'},
    { currencyName: 'Kazakhstani Tenge', symbol: '₸' , keyword: 'KAZAKHSTANI_TENGE'},
    { currencyName: 'Kenyan Shilling', symbol: '/-' , keyword: 'KENYAN_SHILLING'},
    { currencyName: 'Kuwaiti Dinar', symbol: 'KD' , keyword: 'KUWAITI_DINAR'},
    { currencyName: 'Laotian Kip', symbol: '₭' , keyword: 'LAOTIAN_KIP'},
    { currencyName: 'Lebanese pound', symbol: 'ل.ل.' , keyword: 'LEBANESE_POUND'},
    { currencyName: 'Libyan Dinar', symbol: 'LD' , keyword: 'LIBYAN_DINAR'},
    { currencyName: 'Macanese Pataca', symbol: 'MOP$' , keyword: 'MACANESE_PATACA'},
    { currencyName: 'Macedonian Denar', symbol: 'Ден' , keyword: 'MACEDONIAN_DENAR'},
    { currencyName: 'Malagasy Ariary', symbol: 'Ar' , keyword: 'MALAGASY_ARIARY'},
    { currencyName: 'Malawian Kwacha', symbol: 'MK' , keyword: 'MALAWIAN_KWACHA'},
    { currencyName: 'Malaysian Ringgit', symbol: 'RM' , keyword: 'MALAYSIAN_RINGGIT'},
    { currencyName: 'Maldivian Rufiyaa', symbol: 'MRf' , keyword: 'MALDIVIAN_RUFIYAA'},
    { currencyName: 'Mauritian Rupee', symbol: 'Rs' , keyword: 'MAURITIAN_RUPEE'},
    { currencyName: 'Mexican Peso', symbol: 'Mex$' , keyword: 'MEXICAN_PESO'},
    { currencyName: 'Moldovan Leu', symbol: 'L' , keyword: 'MOLDOVAN_LEU'},
    { currencyName: 'Mongolian Tugrik', symbol: '₮' , keyword: 'MONGOLIAN_TUGRIK'},
    { currencyName: 'Moroccan Dirham', symbol: 'MAD' , keyword: 'MOROCCAN_DIRHAM'},
    { currencyName: 'Mozambican Metical', symbol: 'MT' , keyword: 'MOZAMBICAN_METICAL'},
    { currencyName: 'Namibian Dollar', symbol: 'N$' , keyword: 'NAMIBIAN_DOLLAR'},
    { currencyName: 'Nepalese Rupee', symbol: 'Re' , keyword: 'NEPALESE_RUPEE'},
    { currencyName: 'Netherlands Antillean Guilder', symbol: 'NAƒ' , keyword: 'NETHERLANDS_ANTILLEAN_GUILDER'},
    { currencyName: 'New Taiwan dollar', symbol: '$' , keyword: 'NEW_TAIWAN_DOLLAR'},
    { currencyName: 'New Zealand Dollar', symbol: '$' , keyword: 'NEW_ZEALAND_DOLLAR'},
    { currencyName: 'Nicaraguan Córdoba', symbol: 'C$' , keyword: 'NICARAGUAN_CORDOBA'},
    { currencyName: 'Nigerian Naira', symbol: '₦' , keyword: 'NIGERIAN_NAIRA'},
    { currencyName: 'Norwegian Krone', symbol: 'kr' , keyword: 'NORWEGIAN_KRONE'},
    { currencyName: 'Omani Rial', symbol: 'ر.ع.' , keyword: 'OMANI_RIAL'},
    { currencyName: 'Pakistani Rupee', symbol: 'Rs' , keyword: 'PAKISTANI_RUPEE'},
    { currencyName: 'Papua New Guinean Kina', symbol: 'K' , keyword: 'PAPUA_NEW_GUINEAN_KINA'},
    { currencyName: 'Paraguayan Guarani', symbol: '₲' , keyword: 'PARAGUAYAN_GUARANI'},
    { currencyName: 'Philippine peso', symbol: '₱' , keyword: 'PHILIPPINE_PESO'},
    { currencyName: 'Polish Zloty', symbol: 'zl' , keyword: 'POLISH_ZLOTY'},
    { currencyName: 'Pound Sterling', symbol: '£' , keyword: 'POUND_STERLING'},
    { currencyName: 'Qatari Riyal', symbol: 'QR' , keyword: 'QATARI_RIYAL'},
    { currencyName: 'Romanian Leu', symbol: 'lei' , keyword: 'ROMANIAN_LEU'},
    { currencyName: 'Russian Ruble', symbol: 'RUB' , keyword: 'RUSSIAN_RUBLE'},
    { currencyName: 'Rwandan Franc', symbol: 'FRw' , keyword: 'RWANDAN_FRANC'},
    { currencyName: 'Salvadoran Colón', symbol: 'SVC' , keyword: 'SALVADORAN_COLON'},
    { currencyName: 'Saudi Riyal', symbol: 'SR' , keyword: 'SAUDI_RIYAL'},
    { currencyName: 'Serbian Dinar', symbol: 'din' , keyword: 'SERBIAN_DINAR'},
    { currencyName: 'Seychellois Rupee', symbol: 'SR' , keyword: 'SEYCHELLOIS_RUPEE'},
    { currencyName: 'Singapore Dollar', symbol: '$' , keyword: 'SINGAPORE_DOLLAR'},
    { currencyName: 'Sol', symbol: 'S/' , keyword: 'SOL'},
    { currencyName: 'Solomon Islands Dollar', symbol: 'Si$' , keyword: 'SOLOMON_ISLANDS_DOLLAR'},
    { currencyName: 'South African Rand', symbol: 'R' , keyword: 'SOUTH_AFRICAN_RAND'},
    { currencyName: 'South Korean won', symbol: '₩' , keyword: 'SOUTH_KOREAN_WON'},
    { currencyName: 'Sri Lankan Rupee', symbol: 'Rs' , keyword: 'SRI_LANKAN_RUPEE'},
    { currencyName: 'Swedish Krona', symbol: 'kr' , keyword: 'SWEDISH_KRONA'},
    { currencyName: 'Swiss Franc', symbol: 'CHf' , keyword: 'SWISS_FRANC'},
    { currencyName: 'Tanzanian Shilling', symbol: 'TSh' , keyword: 'TANZANIAN_SHILLING'},
    { currencyName: 'Thai Baht', symbol: '฿' , keyword: 'THAI_BAHT'},
    { currencyName: 'Tonga Pa anga', symbol: 'PT' , keyword: 'TONGA_PA_ANGA'},
    { currencyName: 'Trinidad & Tobago Dollar', symbol: '$' , keyword: 'TRINIDAD_AND_TOBAGO_DOLLAR'},
    { currencyName: 'Tunisian Dinar', symbol: 'DT' , keyword: 'TUNISIAN_DINAR'},
    { currencyName: 'Turkish lira', symbol: '₺' , keyword: 'TURKISH_LIRA'},
    { currencyName: 'Ugandan Shilling', symbol: 'Ush' , keyword: 'UGANDAN_SHILLING'},
    { currencyName: 'Ukrainian Hryvnia', symbol: '₴' , keyword: 'UKRAINIAN_HRYVNIA'},
    { currencyName: 'United Arab Emirates Dirham', symbol: 'د.إ' , keyword: 'UNITED_ARAB_EMIRATES_DIRHAM'},
    { currencyName: 'United States Dollar', symbol: '$' , keyword: 'UNITED_STATES_DOLLAR'},
    { currencyName: 'Uruguayan Peso', symbol: '$' , keyword: 'URUGUAYAN_PESO'},
    { currencyName: 'Uzbekistani Som', symbol: 'so m' , keyword: 'UZBEKISTANI_SOM'},
    { currencyName: 'Vanuatu Vatu', symbol: 'VT' , keyword: 'VANUATU_VATU'},
    { currencyName: 'Vietnamese Dong', symbol: '₫' , keyword: 'VIETNAMESE_DONG'},
    { currencyName: 'West African CFA franc', symbol: 'CFA' , keyword: 'WEST_AFRICAN_CFA_FRANC'},
    { currencyName: 'Yemeni Rial', symbol: '﷼' , keyword: 'YEMENI_RIAL'},
    { currencyName: 'Zambian Kwacha', symbol: 'ZK' , keyword: 'ZAMBIAN_KWACHA'},
    { currencyName: 'Zimbabwean dollar', symbol: 'Z$' , keyword: 'ZIMBABWEAN_DOLLAR'},
  ];

  constructor(private _inputEl: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    const defaultCurrency = document.getElementById('defaultCurrency') as HTMLSelectElement;
    const currency = this.currencies[defaultCurrency.selectedIndex];

    if (this._inputEl.nativeElement.value === "\u202A" + currency.symbol + "\u202C ") {
      this._inputEl.nativeElement.value = '';
      return;
    }
    if (this._inputEl.nativeElement.value === '-') return;
    
    let commasRemoved = this._inputEl.nativeElement.value.replace(/,/g, '');

    if(currency){
      commasRemoved = commasRemoved.trim().replace("\u202A" + currency.symbol + "\u202C ",'');
    }

    let toInt: number;
    let toLocale: string;
    if (commasRemoved.split('.').length > 1) {
      let decimal =isNaN(parseInt(commasRemoved.split('.')[1]))? '':parseInt(commasRemoved.split('.')[1]);

      let wholeNumber =commasRemoved.split('.')[0];
      if(wholeNumber == ''){
        commasRemoved = 0+''+commasRemoved;
      }

      if(commasRemoved=="."){
        commasRemoved="0."
      }
       if(commasRemoved.includes('0.0')){
        toLocale =commasRemoved; 
       }else{
        toInt = parseInt(commasRemoved);
        toLocale = toInt.toLocaleString('en-US') + '.' + decimal;
       }
    } else {
      toInt = parseInt(commasRemoved);
      toLocale = toInt.toLocaleString('en-US');
    }

    if (toLocale === 'NaN') {
      this._inputEl.nativeElement.value = '';
    } else {
      if(currency){
        this._inputEl.nativeElement.value = "\u202A" + currency.symbol + "\u202C "+toLocale;
      }else this._inputEl.nativeElement.value = toLocale;
    }
    event.preventDefault();
  }
}
