import { CmdEmailFormItem } from "../../models";
import { CmdEmailTemplate } from "../../models/CmdEmailTemplate";
import { EmailsService } from "../../services/emails.service";
import { OrganizationService } from "../../services/organization.service";
import { StickyPopUpService } from "../../services/toast/stickyPopUp.service";
import { ConfirmModalComponent } from "../../shared/confirm-modal/confirm-modal.component";
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from "rxjs/operators";
import { SpinService } from "../../services/spin.service";

@Component({
    selector: 'app-emailtemplates',
    templateUrl: './emailtemplates.component.html',
    styleUrls: ['./emailtemplates.component.scss']
})
export class EmailtemplatesComponent implements OnInit {
    emailTemplates: Array<CmdEmailTemplate> = [];
    private cofirmModalRef: BsModalRef;

    constructor(private organizationService: OrganizationService,
        private emailService: EmailsService,
        private spinService: SpinService,
        private modalService: BsModalService,
        private alertService: StickyPopUpService) {
    }

    ngOnInit() {
        this.loadData();
    }
    loadData() {
        this.spinService.show();
        this.emailService.getEmailTemplates(this.organizationService.getCurrentOrganizationId()).pipe(finalize(() => { this.spinService.hide() })).subscribe(templates => {
            this.emailTemplates = templates;
        });
    }
    delete(template: CmdEmailFormItem) {
        this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = "Delete an Email Template";
        this.cofirmModalRef.content.message = 'Are you sure you want to delete ' + template.label + '?';
        this.cofirmModalRef.content.onClose.subscribe(result => {
            if (result === true) {
                this.spinService.show();
                this.emailService.delete(template.id).pipe(finalize(() => this.spinService.hide())).subscribe(result => {
                    this.alertService.success('Email template deleted!');
                    this.loadData();
                }, error => {
                    this.spinService.hide();
                    this.alertService.error('Failed to delete email: ' + error.error.message);
                });
            }
        });
    }

}
