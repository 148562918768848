import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, delay, retryWhen, map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { AzureAuthholderService } from '../services/azureauthholder.service';
import { HttpResponse } from 'aws-sdk';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private azureAuthholderService: AzureAuthholderService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isTokenExpired()) {
            this.azureAuthholderService.logout();
        }
        else {
            return next.handle(request)
                .pipe(
                    map(res => {
                        return res
                    }),
                    catchError((error: HttpErrorResponse) => {
                        let errorMsg = '';
                        if (error.error instanceof ErrorEvent) {
                            console.log('This is client side error');
                            errorMsg = `Error: ${error.error.message}`;
                        } else {
                            if (error.status === 404) {
                                errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                            }
                        }
                        return throwError(error);
                    })
                )
        }
    }

    private isTokenExpired() {
        const token: string = localStorage.getItem('access_token');
        if (token) {
            const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
            return (Math.floor((new Date).getTime() / 1000)) >= (expiry + 86400);
        }
    }
}
