import { Page } from "./page";
import { ProductModel } from './CmdProduct';

export class SearchModel {
    searchText = '';
    searchTags = '';
    searchType = 'all';
    tags = [];
    results: ResponseModel = new ResponseModel();
    page: Page;

    constructor() {
        let p = new Page();
        p.pageSize = 10;
        this.page = p;
    }
}

export class ResponseModel {
    contents = []
    page = 1;
    pageSize = 10;
    sortDirection = "ASC";
    sortProperties = "created";
    totalItems = 0;
}