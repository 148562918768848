import { CmdUser } from "../models";
import { Page } from "../models/page";
import { AzureAuthholderService } from './azureauthholder.service';
import { EnvironmentSpecificService } from "./environmentspecific.service";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { StickyPopUpService } from "./toast/stickyPopUp.service";
import { SpinService } from "./spin.service";

@Injectable()
export class UserService {
    public accessToken: any;
    public authenticate$: EventEmitter<any>;
    private currentUser: any;

    constructor(private httpClient: HttpClient,
        private azureholder: AzureAuthholderService,
        private envService: EnvironmentSpecificService,
        private stickyPopUpService: StickyPopUpService,
        private spinnerService: SpinService,
        ) {
        this.authenticate$ = new EventEmitter();
    }

    authenticate(username: string, password: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        const obs = this.httpClient.post<any>(
            this.envService.envSpecific.baseServerUrl + "/api/users/auth",
            {
                username: username,
                password: password,
            }, requestOptions
        );
        obs.subscribe(
            (accessToken) => {
                this.azureholder.accessToken = accessToken;
                this.authenticate$.emit(accessToken);
            },
            (err) => {
                console.log(err);
            }
        );
        return obs;
    }

    getCurrentUser(): Observable<any> {
        if (localStorage && localStorage.getItem("loginresponse") && localStorage.getItem("azureMeUser")) {
            const headers = new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.azureholder.getAccessToken(),
            });
            const requestOptions = { headers: headers };
            const azureMeUser = JSON.parse(localStorage.getItem("azureMeUser"));
            var current = this.httpClient.post<any>(
                this.envService.envSpecific.baseServerUrl + "/api/users/current",
                azureMeUser,
                requestOptions).pipe(catchError((res: HttpErrorResponse) => {
                if (res.status === 200) {
                    return current;
                }
                else if(res.status === 401){
                    this.stickyPopUpService.error(res.error.message + "! Please Logout and Login again.");
                }
                else {
                    this.stickyPopUpService.error(res.error.message);
                }
            }));
            return current;
        }
    }

    
    // This function is for updating user details.
    updateUser(user: CmdUser, orgId: String): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        var newUser: any = {
            id: user.id,
            organizationId: user.organizationId,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            password: user.password,
            role: user.role,
            businessRole: user.businessRole,
            appIds: user.appIds,
            profilePicture: user.profilePicture ? user.profilePicture : undefined,
        };
        if (user.profilePicture) {
            user["profilePicture"] = user.profilePicture;
        }

        return this.httpClient.put(
            this.envService.envSpecific.baseServerUrl + "/api/users/" + orgId,
            newUser,
            requestOptions
        );
    }
    /**
     * Searches a user by first, last, and email.  Supports paging.
     */
     search(
        orgId: string,
        searchText: string,
        searchType: string,
        page: Page
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let params = new HttpParams()
            .set("orgId", orgId)
            // .set('appId',app_id)
            .set("page", page.page + "")
            .set("pageSize", page.pageSize + "")
            .set("sortBy", page.sortBy)
            .set("sortOrder", page.sortOrder);
        searchType === "All fields"
            ? (params = params.append("search", searchText))
            : (params = params.append(searchType, searchText));
            const requestOptions = { params: params, headers: headers };
        return this.httpClient.get(
            this.envService.envSpecific.baseServerUrl + "/api/users",requestOptions
            
        );
    }

 
    searchNotification(
        app_id: string,
        searchText: string,
        searchType: string,
        page: Page
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let params = new HttpParams()
            // .set('orgId', orgId)
            .set("appId", app_id)
            .set("page", page.page + "")
            .set("pageSize", page.pageSize + "")
            .set("sortBy", page.sortBy)
            .set("sortOrder", page.sortOrder);
        searchType === "All fields"
            ? (params = params.append("search", searchText))
            : (params = params.append(searchType, searchText));
            const requestOptions = { params: params, headers: headers };
        return this.httpClient.get(
            this.envService.envSpecific.baseServerUrl + "/api/users/appUsers/page",requestOptions
            
        );
    }

    // This function will display user detials of specified Id.
    getById(userId): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.get(
            this.envService.envSpecific.baseServerUrl + "/api/users/" + userId, requestOptions
        );
    }

    create(user: CmdUser): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        var newUser: any = {
            organizationId: user.organizationId,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            password: user.password,
            role: user.role,
            businessRole: user.businessRole,
            appIds: user.appIds,
            profilePicture: user.profilePicture ? user.profilePicture : undefined,
        };
        if (user.profilePicture) {
            newUser["profilePicture"] = user.profilePicture;
        }
        return this.httpClient.post(
            this.envService.envSpecific.baseServerUrl + "/api/users/",
            newUser,
            requestOptions
        );
    }

    setApplicationAuthorizations(userId: string, appIds: Array<string>) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        var params = new HttpParams();
        const requestOptions = { params: params, headers: headers };
        appIds.forEach((id) => {
            params = params.append("appIds", id);
        });
        return this.httpClient.put(
            this.envService.envSpecific.baseServerUrl + "/api/users/" + userId,
            {},
            requestOptions
        );
    }

    delete(user: CmdUser): Observable<any> {
        const headers = new HttpHeaders({
            "responseType": "text",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.delete(
            this.envService.envSpecific.baseServerUrl + "/api/users/" + user.id,requestOptions
            // { responseType: "text" }
        );
    }
}
