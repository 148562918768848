import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../../services/organization.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { QuoteBuilderService } from '../../../../services/quotebuilder.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { Subject } from 'rxjs';
import { FormGroup, FormArray } from '@angular/forms';
import { AddProductQuoteMdlComponent } from '../add-product-quote-mdl/add-product-quote-mdl.component';


@Component({
  selector: 'app-add-quote-builder-modal',
  templateUrl: './add-quote-builder-modal.component.html',
  styleUrls: ['./add-quote-builder-modal.component.css']
})
export class AddQuoteBuilderModalComponent implements OnInit {
  dataList: Array<any> = [];
  public event: EventEmitter<any> = new EventEmitter();
  public eventFrNew: EventEmitter<any> = new EventEmitter();
  schedule: { branch: '' };
  values = [];
  name = 'Angular';
  addCreateProductBuildeModalRef: BsModalRef;
  addProductModalRef: BsModalRef;
  title: string = "";
  uploadData: any;
  fileData: any;
  onClose = new Subject<any>();
  public invData: any;
  constructor(private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public organizationService: OrganizationService,
    private quoteBuilderService: QuoteBuilderService,
    private stickyPopUp: StickyPopUpService
  ) {
    this.dataList = [
      { code: 1, name: "Select" },
      { code: 1, name: "Quantity" },
      { code: 2, name: "Price" },
      { code: 3, name: "Item Number" },
      { code: 4, name: "Variable #1" },
      { code: 5, name: "Variable #2" },
      { code: 6, name: "Variable #3" },
      { code: 7, name: "Variable #4" },
      { code: 8, name: "Variable #5" },
      { code: 9, name: "Discount Available Y/N" },
      { code: 10, name: "UNIT - Individual / Case / Pack" },
      { code: 11, name: "or Sale / For Rent / Unavailable" }
    ]
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  addvalue() {
    this.values.push({ value: "" });
  }

  removevalue(i) {
    this.values.splice(i, 1);
  }

  onCancel() {
    this.bsModalRef.hide();
  }


  uploadCSVData = () => {
    if (this.title == "") {
      this.stickyPopUp.error("Please enter title of quote builder");
      return;
    }
    if (this.fileData == undefined) {
      this.stickyPopUp.error("Please select file to upload");
      return;
    }
    let formData = new FormData();
    formData.append('file', this.fileData);
    formData.append('title', this.title);
    const org = this.organizationService.getCurrentOrganization();
    this.quoteBuilderService.postUploadCsv(org.id, formData)
      .subscribe(
        data => {
          this.stickyPopUp.success('Price list created successully.');
          this.event.emit({ message: "added" });
          this.bsModalRef.hide();
          this.quoteBuilderService.setIsCreated(true);
        }, err => {
          // this.stickyPopUp.error('Failed to create price list.');
          this.stickyPopUp.error(err.error.message);
          console.log(err, 'file upload error')
        }
      )
  }
  uploadCSVFile(event) {
    this.fileData = event.target.files[0];
  }

  showCreateProductModal() {
    this.addProductModalRef = this.modalService.show(AddProductQuoteMdlComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { title: this.title, apiType: "new" } });
    this.addProductModalRef.content.eventFrNew.subscribe(data => {
      this.event.emit({ message: "added123" });
      this.bsModalRef.hide();
    });
  }
}


