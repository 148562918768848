import { CmdToolsetType } from "./CmdToolsetType";

export class Toolsets {
    'title': string;
    'type_name': string;
    'numberAllowed': number;
    'authorized': boolean;
}

export class CMDToolsetsModel {
    id: string;
    organizationId: string;
    slug: string;
    title: string;
    description: string = '';
    type: string;
    properties?: MeasureList;
    tags?: Array<string>;
    unitName?: string;  // TODO: obsolete
    priceUnit?: number; // TODO: obsolete
    consumptionUnit?: number;
    unitCostLabel?: string;
    products: Array<ProductLite> = [];
    productIds: Array<string> = [];
    emailTemplateId?: string;
    emails?: Array<string>;
    includeInQuotebuilder?: boolean = false;
    usageType: CmdToolsetType;
}

export interface ProductLite {
    'id': string;
    'sku': string;
    'title': string;
    'thumbnailUrl': string;
    'consumptionPerUnitUsed': number;
    'costPerUnit': number;
    'unitName': string;
}

export class MeasureList {
    measures_list: Array<Measure> = [];
}

export class Measure {
    value?: number;
    type?: string;
    result?: string;
}
