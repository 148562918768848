import { Cmd3DCarouselItem } from './Cmd3DCarouselItem';
import { CmdWidget } from './CmdWidget';
export class Cmd3DCarousel extends CmdWidget{
    items:Array<Cmd3DCarouselItem>;
    width:number = 1080;
    height:number = 625;
    scaleX:number = .77;
    scaleY:number = .45;
    scaleZ:number = .38;
    shiftX:number = 0;
    shiftY:number = 549;
    shiftZ:number = -1050;    
    constructor(id:string){
        super(id);
        this.type = "Carousel3D";
        this.items = new Array<Cmd3DCarouselItem>();
    }
}