import { log } from 'util';
import { CmdAsset } from '../../models';
import { Page } from '../../models/page';
import { AssetsService } from '../../services/assets.service';
import { OrganizationService } from '../../services/organization.service';
import { AssetUploadComponent } from '../assetuploader/upload.component';
import { MedialibraryService } from './medialibrary.service';
import { Component, OnInit, Output, EventEmitter, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { AssetthumbnailuploadComponent } from '../../shared/assetthumbnailupload/assetthumbnailupload.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-medialibrary',
    templateUrl: './medialibrary.component.html',
    styleUrls: ['./medialibrary.component.scss']
})
export class MedialibraryComponent implements OnInit, AfterContentInit {
    @ViewChild("searchField", { static: false }) searchField: ElementRef;
    private uploadModalRef: BsModalRef;
    private appId: String;
    public onClose: Subject<Array<CmdAsset>>;
    public search: any;
    types: Array<string> = [];
    tags: Array<string> = [];
    selectedAssets: Array<CmdAsset> = [];
    multiselect: boolean = false;
    allTypes: Array<string> = ['all'];
    assetTypes: Array<string> = ['image', '.pdf'];
    documentTypes: Array<string> = [".pdf"];
    imageTypes: Array<string> = [".png, .jpg, .jpeg"];
    videoTypes: Array<string> = ["video/mp4"];
    currentOrganization: any;
  
    // private orgSubscription: Subscription;


    constructor(public bsModalRef: BsModalRef,
        private modalService: BsModalService,
        private assetService: AssetsService,
        private orgService: OrganizationService,
        private mediaLibraryService: MedialibraryService) {
        this.resetPagination();
        this.search.searchTypes = mediaLibraryService.allTypes;
    }

    public ngAfterContentInit(): void {
        this.doSearch();
    }

    public ngOnInit(): void {
        this.onClose = new Subject();
        this.orgService.getTags().subscribe(tags => this.tags = tags);
        this.currentOrganization = this.orgService.getCurrentOrganization();
        setTimeout(() => {
            this.searchField.nativeElement.focus();
        }, 500);

    }

    public onConfirm(): void {
        this.onClose.next(undefined);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.bsModalRef.hide();
        this.onClose.next(undefined);
    }
    onChanged(event) {
        this.search.tags = event;
        this.doSearch();
    }

    doSearch() {
        this.assetService.getAssets(this.orgService.getCurrentOrganizationId(),
            this.search.searchTypes, this.search.searchText, this.search.tags, this.search.page)
            .subscribe(data => {
                if (data && data.contents) {

                    data.contents.forEach(row => row.assetBehavior = 'END');
                }
                this.search.results = data;
            },
                error => {
                    console.log(error);
                    //Error while fetching assets, Handle error here.
                });
    }

    resetPagination() {
        this.search = {
            searchText: "",
            searchTypes: this.assetTypes,
            tags: [],
            results: []
        }
        this.search.page = new Page();
        this.search.page.pageSize = 6;
    }

    //This function will format bytes to KB/MB/GB depending upon file size.
    formatBytes(bytes): string {
        if(bytes !== null && bytes !== undefined){
            return this.assetService.formatBytes(bytes);
        }
        else{
            return '';
        }
    };
    pageChanged($event) {
        this.search.page.page = $event.page;
        this.search.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }
    isSupported(type) {
        return this.types.indexOf(type) != -1;
    }
    selectAsset() {
        this.onClose.next(this.selectedAssets);
        this.bsModalRef.hide();
    }
    isSelected(asset) {
        return this.selectedAssets.indexOf(asset) != -1;
    }

    doSelectAsset(asset: CmdAsset) {
        if (this.multiselect) {
            if (this.isSelected(asset)) {
                this.selectedAssets.splice(this.selectedAssets.indexOf(asset), 1);
            }
            else {
                this.selectedAssets.push(asset);
            }
        }
        else {
            this.selectedAssets = [];
            this.selectedAssets.push(asset);
        }
    }

    doSelectAndGo(asset: CmdAsset) {
        this.doSelectAsset(asset);
        this.selectAsset();
    }

    startUpload() {
        this.uploadModalRef = this.modalService.show(AssetUploadComponent);
        this.uploadModalRef.content.title = "Upload Files";
        this.uploadModalRef.content.onClose.subscribe(result => {
            if (result === true) {
                this.doSearch();
                this.orgService.getTags().subscribe(tags => this.tags = tags);
            }
        });
    }

    chagnePageSize() {
        this.doSearch();
    }

    openPdf(assets: any) {
        window.open(assets.url)
    }
}
