import { CmdAsset, CmdScene, CmdApplication } from '../../../models';
import { CmdCollection } from '../../../models/CmdCollection';
import { CmdRightMenuItem } from "../../../models/CmdRightMenuItem";
import { ApplicationService } from '../../../services/application.service';
import { SceneService } from '../../../services/scene.service';
import { ViewChild, Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Page } from '../../../models/page';
import { AssetsService } from '../../../services/assets.service';
import { CollectionService } from '../../../services/collection.service';
import { OrganizationService } from '../../../services/organization.service';
import { SpinService } from '../../../services/spin.service';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { UserService } from '../../../services/user.service';
import { InputValidationService } from '../../../services/inputvalidation.service';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { MedialibraryComponent } from '../../../shared/medialibrary/medialibrary.component';
import { MedialibraryService } from '../../../shared/medialibrary/medialibrary.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ToolsetsService } from '../../../services/toolsets.service';
import { SearchModel } from '../../../models/SearchModel';
import { RightmenueditorComponent } from "./rightmenueditor/rightmenueditor.component";
import { finalize } from 'rxjs/operators';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { AssetthumbnailuploadComponent } from '../../../shared/assetthumbnailupload/assetthumbnailupload.component';
import { DragulaService } from 'ng2-dragula';
import { MedialibrarythumbnailComponent } from '../../../shared/medialibrarythumbnail/medialibrarythumbnail.component';

@Component({
    selector: 'app-user-edit',
    templateUrl: './app-edit.component.html',
    styleUrls: ['./app-edit.component.css']
})
export class AppEditComponent implements OnInit, AfterViewInit {
    uploadModalRef: BsModalRef;
    @ViewChild('videoPlayer', { static: false }) videoplayer: any;
    @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;
    bsModalRef: BsModalRef;
    _hasChanges: boolean = false;
    private confirmModalRef: BsModalRef;
    mediaModalRef: BsModalRef;
    cofirmModalRef: BsModalRef;
    rightMenuModalRef: BsModalRef;
    applicationId: string;
    application: CmdApplication = new CmdApplication('');
    scenes: Array<CmdScene>;
    page: Page = new Page();
    public search: any;
    public searchCollection: any;
    tags: Array<string>;
    themes: Array<CmdAsset>;
    allScenes: Array<CmdScene>;
    allCollections: Array<CmdCollection>;
    currentUser: any;
    hasChanges: boolean = false;
    originalDesktopLayoutMode: string;
    originalTabletLayoutMode: string;
    originalPhoneLayoutMode: string;
    isCollapsed: boolean = false;
    buttonIsDisabled: boolean = false;
    quote_builder_is_authorize = false;
    quote_builder: Array<any> = [];
    isOld: boolean = true;
    targetLanguage: string;




    constructor(private route: ActivatedRoute,
        private appService: ApplicationService,
        private sceneService: SceneService,
        private collectionService: CollectionService,
        private alertService: StickyPopUpService,
        private dragulaService: DragulaService,
        private assetService: AssetsService,
        private orgService: OrganizationService,
        private modalService: BsModalService,
        private userService: UserService,
        private spinService: SpinService,
        private toolsetsService: ToolsetsService,
        private mediaLibraryService: MedialibraryService,
        private validationService: InputValidationService,) {
        this.resetCollectionPagination();
        this.resetPagination();

        this.search = {
            searchType: 'theme',
            searchText: '',
            tags: [],
            results: []
        };

        this.search.page = new Page();
        this.search.page.page = 1;
        this.search.page.pageSize = 50;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const activeTab = localStorage.getItem('activeTab');
            if (activeTab && activeTab !== "undefined") {
              this.staticTabs.tabs[parseInt(activeTab)].active = true;
            }
        }, 0);
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.applicationId = params['appId'];
            if (this.applicationId === 'NEW') {
                this.application = new CmdApplication('');
                this.application.desktopLayoutMode = 'none';
                this.application.tabletLayoutMode = 'none';
                this.application.phoneLayoutMode = 'none';
                this.scenes = [];
            }
            else {
                this.loadApplication();
            }
        });
        this.dragulaService.dropModel.subscribe(val => {
            this.recordChange();
        });
        this.loadData();
    }

    onTabSelect(tabId: string) {
        localStorage.setItem('activeTab', tabId);
      }

    loadApplication() {
        this.appService.getById(this.applicationId).subscribe(app => {
            this.application = app;
            this.originalDesktopLayoutMode = app.desktopLayoutMode;
            this.originalTabletLayoutMode = app.tabletLayoutMode;
            this.originalPhoneLayoutMode = app.phoneLayoutMode;
        });
    }

    playKioskVideo() {
        this.videoplayer.nativeElement.play();
    }
    selectKioskVideo() {
        this.mediaLibraryService.assetType = 'video';
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.multiselect = false;
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result && result.length == 1) {
                    var theVideo: CmdAsset = result[0];
                    if (theVideo.type != 'video' || theVideo.originalFileName.indexOf('mp4') == -1) {
                        this.alertService.error("MP4 is the only supported format for Kiosk-mode videos.");
                    }
                    else {
                        this.application.kioskVideo = theVideo;
                    }
                }
            });

    }

    // startUploadThumbnail() {
    //     this.uploadModalRef = this.modalService.show(AssetthumbnailuploadComponent, { ignoreBackdropClick: true, keyboard: false });
    //     this.uploadModalRef.content.title = "Upload Files";
    //     this.uploadModalRef.content.onClose.subscribe(result => {
    //         if (result === true) {
    //             this.doSearch();
    //         }
    //     });
    // }

    selectAppThumbnail() {
        this.mediaLibraryService.assetType = 'app_thumbnail';
        this.mediaModalRef = this.modalService.show(MedialibrarythumbnailComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.multiselect = false;
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result.length == 1) {
                    this.application.appThumbnail = result[0];
                }
            });
    }

    clearBackground() {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this background asset?';
        this.confirmModalRef.content.onClose
        .subscribe(result => {
            if (result) {
                this.application.appThumbnail = undefined;
            }
        });
    }

    public recordChange() {
        this.setHasChanges(true);
    }

    setHasChanges(changes: boolean) {
        this._hasChanges = changes;
    }


    loadData() {
        var orgId = this.orgService.getCurrentOrganizationId();
        this.orgService.getTags().subscribe(tags => this.tags = tags);
        // this.assetService.getThemes(orgId).subscribe(themes => this.themes = themes.contents);
        // get all themes belonging to an organisation        
        this.assetService.getAssets(orgId, [this.search.searchType], this.search.searchText, this.search.tags, this.search.page).subscribe(themes => this.themes = themes.contents);
        this.userService.getCurrentUser().subscribe(user => this.currentUser = user);
        this.sceneService.getForApplication(this.applicationId).then(all => this.allScenes = all);
        this.doSearch();
        this.doSearchCollection();
        this.orgService.getLimitOfQuoteBuilder(orgId).subscribe(resp => {
            if (resp) {
                this.toolsetsService.getToolsetsForOrganization(orgId, new SearchModel(), 'QUOTE_BUILDER').then(resp => {
                    this.quote_builder = resp.contents;
                    this.quote_builder_is_authorize = true;
                });
            }
        });

    }

    onChanged(event) {
        this.search.tags = event;
        this.doSearch();
    }

    onPageChanged($event) {
        this.search.page.page = $event.page;
        this.search.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }

    onCollectionTracksChanged(event) {
        this.searchCollection.tags = event;
        this.doSearch();
    }
    onCollectionPageChanged($event) {
        this.searchCollection.page.page = $event.page;
        this.searchCollection.page.pageSize = $event.itemsPerPage;
        this.doSearchCollection();
    }
    getScenes() {
        this.spinService.show();
        this.sceneService.search(this.orgService.getCurrentOrganizationId(),
            this.applicationId, this.search.searchText, this.search.tags, this.search.page)
            .pipe(finalize(() => this.spinService.hide()))
            .subscribe(list => {
                this.search.results = list
            },
                error => {
                    this.spinService.hide();
                    this.alertService.error("Failed to load scenes: " + error.message);
                });
    }
    getCollections() {
        this.spinService.show();
        this.collectionService.search(this.orgService.getCurrentOrganizationId(),
            this.applicationId, this.searchCollection.searchText, this.searchCollection.tags, this.searchCollection.page)
            .pipe(finalize(() => this.spinService.hide()))
            .subscribe(list => {
                this.searchCollection.results = list
            },
                error => {
                    this.spinService.hide();
                    this.alertService.error("Failed to load collections: " + error.message);
                });
    }
    resetPagination() {
        this.search = {
            searchText: "",
            searchType: "all",
            tags: [],
            results: {
                contents: new Array<CmdScene>()
            }
        }
        this.search.page = new Page();
    }
    resetCollectionPagination() {
        this.searchCollection = {
            searchText: "",
            searchType: "all",
            tags: [],
            results: {
                contents: new Array<CmdCollection>()
            }
        }
        this.searchCollection.page = new Page();
    }
    doSearch() {
        this.getScenes();
    }

    doSearchCollection() {
        if (this.searchCollection.page.pageNumber != 1) {
            this.searchCollection.page.pageNumber = 1;
        }
        this.getCollections();
    }
    startSave() {
        if (this.application.desktopLayoutMode != this.originalDesktopLayoutMode ||
            this.application.tabletLayoutMode != this.originalTabletLayoutMode ||
            this.application.phoneLayoutMode != this.originalPhoneLayoutMode) {
            this.bsModalRef = this.modalService.show(ConfirmModalComponent);
            this.bsModalRef.content.title = "Layout Modes Have Changed";
            this.bsModalRef.content.message = 'You are changing one or more of the layout modes for this application.  Doing so will modify any currently selected backgrounds for your existing scenes.  Do you wish to continue?';
            this.bsModalRef.content.onClose
                .subscribe(result => {
                    if (result === true) {
                        this.save();
                    }
                });
        }
        else {
            this.save();
        }
    }
    save() {
        this.spinService.show();
        // this.appService.updateMetadata(this.currentUser.id)

        this.appService.updateMetadata(this.application).pipe(finalize(() => this.spinService.hide())).subscribe(() => {
            
            this.alertService.success("Application updated successfully.");
            this.hasChanges = false;
            this.loadApplication();
        }, error => {
            this.spinService.hide();
            this.alertService.error("Failed to update application: " + error.error.message);
        });
    }
    /* Confirmation pop up before archiving asset*/
    showSceneDeleteConfirmationModal(scene) {
        this.bsModalRef = this.modalService.show(ConfirmModalComponent);
        this.bsModalRef.content.title = scene.title;
        this.bsModalRef.content.message = 'Are you sure you want to delete this scene?';
        this.bsModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.deleteScene(scene);
                }
            });
    }
    /* Confirmation pop up before archiving asset*/
    showCollectionDeleteConfirmationModal(collection) {
        this.bsModalRef = this.modalService.show(ConfirmModalComponent);
        this.bsModalRef.content.title = collection.title;
        this.bsModalRef.content.message = 'Are you sure you want to delete this collection?';
        this.bsModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.deleteCollection(collection);
                }
            });
    }
    publishConfirmation() {
        this.bsModalRef = this.modalService.show(ConfirmModalComponent);
        this.bsModalRef.content.title = this.application.title;
        this.bsModalRef.content.message = 'Are you sure you want to publish this application?';
        this.bsModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.publish();
                }
            });
    }

    deleteScene(scene) {
        this.sceneService.delete(scene).subscribe(() => {
            this.alertService.success("Scene deleted successfully.");
            this.loadData();
        }, error => {
            this.alertService.error("Failed to delete scene: " + error.error.message);
        });
    }
    deleteCollection(collection) {
        this.collectionService.delete(collection).subscribe(() => {
            this.alertService.success("Collection deleted successfully.");
            this.loadData();
        }, error => {
            this.alertService.error("Failed to delete collection: " + error.error.message);
        });
    }
    publish() {
        this.spinService.show();
        this.appService.publish(this.applicationId, this.currentUser.id)
            .pipe(finalize(() => this.spinService.hide()))
            .subscribe(app => {
                this.alertService.success("A new version (" + app.version + ") of your application has been published.");
            }, error => {
                this.spinService.hide();
                this.alertService.error("Failed to publish application: " + error.error.message);
            });
    }

    getCollectionThumb(col: CmdCollection): CmdAsset {
        if (col && col.items && col.items.length > 0) {
            if (col.items[0] && col.items[0].thumbnail) {
                return col.items[0].thumbnail;
            }
            else if (col.items[0] &&
                col.items[0].slideDeck &&
                col.items[0].slideDeck.slides &&
                col.items[0].slideDeck.slides.length > 0 &&
                col.items[0].slideDeck.slides[0].asset) {
                return col.items[0].slideDeck.slides[0].asset;
            }
        }
        return undefined;
    }

    sortScenes(sortBy) {
        if (this.search.page.sortBy == sortBy) {
            if (this.search.page.sortOrder == "ASC") {
                this.search.page.sortOrder = "DESC";
            } else {
                this.search.page.sortOrder = "ASC";
            }
        } else {
            this.search.page.sortBy = sortBy;
            this.search.page.sortOrder = "ASC"
        }
        this.doSearch();
    }

    sortCollectionAssets(sortBy) {
        if (this.searchCollection.page.sortBy == sortBy) {
            if (this.searchCollection.page.sortOrder == "ASC") {
                this.searchCollection.page.sortOrder = "DESC";
            } else {
                this.searchCollection.page.sortOrder = "ASC";
            }
        } else {
            this.searchCollection.page.sortBy = sortBy;
            this.searchCollection.page.sortOrder = "ASC"
        }
        this.doSearchCollection();
    }
    getThumbnailImage(scene: CmdScene) {
        if (scene.backgroundLarge) {
            return scene.backgroundLarge;
        }
        else if (scene.backgroundLargeLandscape) {
            return scene.backgroundLargeLandscape;
        }
        else if (scene.backgroundMedium) {
            return scene.backgroundMedium;
        }
        else if (scene.backgroundMediumLandscape) {
            return scene.backgroundMediumLandscape;
        }
        else if (scene.backgroundSmall) {
            return scene.backgroundSmall;
        }
        else if (scene.backgroundSmallLandscape) {
            return scene.backgroundSmallLandscape;
        }
        else {
            return undefined;
        }
    }

    notifyChange() {
        this.hasChanges = true;
        // call kioskVideoTimeoutSec only if kioskVideoTimeoutSec input is changed
        if (this.application.kioskVideoTimeoutSec != null) {
            this.validatekioskVideoTimeoutSec();
        }
    }

    onTranslateChange(targetLanguage) {
        this.application.targetLanguage = targetLanguage;
    }


    changeCollectionPageSize() {
        this.doSearchCollection();
    }
    
    changeScenePageSize() {
        this.doSearch();
    }


    validatekioskVideoTimeoutSec() {
        this.buttonIsDisabled = this.validationService.validatekitoskVideoTimeou(this.application.kioskVideoTimeoutSec);
    }

    addRightMenuItem() {
        var menuItem = new CmdRightMenuItem();
        menuItem.id = new Date().getTime() + "";
        this.rightMenuModalRef = this.modalService.show(RightmenueditorComponent, {
            initialState: {
                widgetData: menuItem,
                applicationId: this.applicationId
            }
        });
        this.rightMenuModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.hasChanges = true;
                    this.application.rightMenuItems.push(result);
                }
            });
    }
    showRightMenuItemDeleteConfirmationModal(item: CmdRightMenuItem) {
        this.bsModalRef = this.modalService.show(ConfirmModalComponent);
        this.bsModalRef.content.title = "Remove " + item.displayName + "?";
        this.bsModalRef.content.message = 'Are you sure you want to remove this menu item?';
        this.bsModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.hasChanges = true;
                    this.application.rightMenuItems = this.application.rightMenuItems.filter(it => it.id != item.id);
                }
            });
    }


    async cloneScene(id: string) {
        await this.sceneService.clone(id).toPromise();
        this.alertService.success("Scene cloned successfully!");
        this.getScenes();
    }
    cloneSceneConfirmation(id: string) {
        this.bsModalRef = this.modalService.show(ConfirmModalComponent);
        this.bsModalRef.content.title = this.application.title;
        this.bsModalRef.content.message = 'Are you sure you want to duplicate this scene?';
        this.bsModalRef.content.onClose
            .subscribe(async result => {
                if (result === true) {
                    await this.cloneScene(id);
                }
            });
    }

    async cloneCollection(id: string) {
        await this.collectionService.clone(id).toPromise();
        this.alertService.success("Collection cloned successfully!");
        this.getCollections();
    }
    cloneCollectionConfirmation(id: string) {
        this.bsModalRef = this.modalService.show(ConfirmModalComponent);
        this.bsModalRef.content.title = this.application.title;
        this.bsModalRef.content.message = 'Are you sure you want to duplicate this collection?';
        this.bsModalRef.content.onClose
            .subscribe(async result => {
                if (result === true) {
                    await this.cloneCollection(id);
                }
            });
    }

}
