import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'dollar' })

export class FormatAmountPipe implements PipeTransform {

    transform(amount: number, args?: any): any {
        if (!amount) {
            return '';
        }else {
            if(amount.toString().includes('$')){
                 `$${amount.toString().replace(/\$/g, "")}`;
            }else {
               return `$${amount}`;
            }
        }
    }
}