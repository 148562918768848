import { HttpParams } from '@angular/common/http';

export class Page {
    page = 1;
    pageSize = 10;
    sortBy = "created";
    sortOrder = "DESC";
    
    constructor(){
        
    }
    
    toParams():HttpParams{
        return new HttpParams()
            .set('page', this.page + '')
            .set('pageSize', this.pageSize + '')
            .set('sortBy', this.sortBy)
            .set('sortOrder', this.sortOrder);
    }
    
    addParams(params:HttpParams):HttpParams{
        return params.set('page', this.page + '')
            .set('pageSize', this.pageSize + '')
            .set('sortBy', this.sortBy)
            .set('sortOrder', this.sortOrder);
    }
}