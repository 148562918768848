import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { String } from 'typescript-string-operations';
import { Subject } from 'rxjs';
import { takeUntil, take ,  debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProductModel } from '../../models/CmdProduct';
import { OrganizationService } from '../../services/organization.service';
import { ProductsService } from '../../services/products.service';
import { SearchModel } from '../../models/SearchModel';
import { SpinService } from '../../services/spin.service';
import { CmdOrganization } from '../../models';
import { Page } from '../../models/page';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UploadProductComponent } from '../../shared/uploadproduct/uploadproduct.component';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';

@Component({
    selector: 'app-products',
    templateUrl: 'products.component.html',
    styleUrls: ['products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {

    destroy$: Subject<boolean> = new Subject<boolean>();
    uploadModalRef: BsModalRef;
    cofirmModalRef: BsModalRef;
    productsSearch: SearchModel;
    searchTerm$ = new Subject<string>();
    searchTermTags$ = new Subject<string>();
    selProduct: string = '1';
    currentOrganization: CmdOrganization;


    constructor(private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private organizationService: OrganizationService,
        private spinService: SpinService,
        private productsService: ProductsService,
        private alertService: StickyPopUpService) {
        this.resetPagination();

        route.params
            .pipe(take(1))
            .subscribe(params => {
                if (params['page'] && params['pageSize']) {
                    this.productsSearch.page.page = +params['page'];
                    this.productsSearch.page.pageSize = +params['pageSize'];
                }
            });
    }


    ngOnInit() {

        this.searchTerm$
            .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchText => {
                this.productsSearch.searchText = searchText;
                this.productsSearch.page.page = 1;
                this.doSearch();
            });
        this.searchTermTags$
            .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchTag => {
                this.productsSearch.searchTags = searchTag;
                this.productsSearch.page.page = 1;
                this.doSearch();
            });


        this.currentOrganization = this.organizationService.getCurrentOrganization();
        this.doSearch();
        this.organizationService.getCurrentOrganizationObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.doSearch();
                this.currentOrganization = this.organizationService.getCurrentOrganization();
            });

    }

    public addProduct() {
        this.router.navigate(['/product', 0]);
    }

    public addProductZIP() {
        this.uploadModalRef = this.modalService.show(UploadProductComponent, { ignoreBackdropClick: true, keyboard: false });
        this.uploadModalRef.content.title = "Upload Product via ZIP";
        this.uploadModalRef.content.allFileTypes = [".zip"];
        this.uploadModalRef.content.uploadType = "zip";
        this.uploadModalRef.content.onClose.subscribe(result => {
            if (result === true) {
                this.doSearch();
            }
        });
    }

    public addCompetitorsCSV() {
        this.uploadModalRef = this.modalService.show(UploadProductComponent, { ignoreBackdropClick: true, keyboard: false });
        this.uploadModalRef.content.title = "Upload Competitors via CSV";
        this.uploadModalRef.content.uploadTo = 'competitors';
        this.uploadModalRef.content.allFileTypes = [".csv"];
        this.uploadModalRef.content.uploadType = "csv";
        this.uploadModalRef.content.onClose.subscribe(result => {
            if (result === true) {
                this.doSearch();
            }
        });
    }



    public editProduct(product: ProductModel) {
        const returnUrl = String.Format("/products/{0}/{1}", this.productsSearch.page.page, this.productsSearch.page.pageSize);
        this.router.navigate(['/product', product.id, returnUrl]);
    }

    public delete(product: ProductModel) {
        this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = "Delete a Product";
        this.cofirmModalRef.content.message = 'Are you sure you want to delete ' + product.title + '?';
        this.cofirmModalRef.content.onClose.subscribe(result => {
            if (result === true) {
                this.spinService.show();
                this.productsService.deleteProduct(product.id)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                        this.alertService.success('The product has been deleted.');
                        this.spinService.hide();
                        this.doSearch();
                    }, error => {
                        this.alertService.error(error.error.message);
                        this.spinService.hide();
                        this.doSearch();
                    });
            }
        });
    }

    resetPagination() {
        this.productsSearch = new SearchModel();
        this.productsSearch.page = new Page();
    }

    clearSearch() {
        if (this.productsSearch && this.productsSearch.searchText) {
            this.resetPagination();
            this.doSearch()
        }
    }

    doSearch() {
        this.spinService.show();
        this.productsService.getProductsForOrganization(this.organizationService.getCurrentOrganizationId(), this.productsSearch)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.spinService.hide();
                this.productsSearch.results = data;
            },
                error => {
                    this.spinService.hide();
                });
    }

    pageChanged($event) {
        this.productsSearch.page.page = $event.page;
        this.productsSearch.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }

    changePageSize() {
        this.doSearch();
    }

    sortProducts(sortBy) {
        this.productsSearch.page.page = 1;
        if (this.productsSearch.page.sortBy == sortBy) {
            if (this.productsSearch.page.sortOrder == "ASC") {
                this.productsSearch.page.sortOrder = "DESC";
            } else {
                this.productsSearch.page.sortOrder = "ASC";
            }
        } else {
            this.productsSearch.page.sortBy = sortBy;
            this.productsSearch.page.sortOrder = "ASC"
        }
        this.doSearch();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}


