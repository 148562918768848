import { CmdWidget } from './CmdWidget';
import { CmdAsset } from './CmdAsset';
import { CmdSlideDeck } from './CmdSlideDeck';
export class CmdCollectionItem extends CmdWidget {
    thumbnail: CmdAsset;
    slideDeck:CmdSlideDeck = new CmdSlideDeck('');

    productId?: string;
    productSku?: string;
    productTitle?: string;

    constructor(id:string){
        super(id);
        this.type = "CollectionItem";
    }
}
