import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Cmd3DCarousel, CmdScene, Cmd3DCarouselItem } from '../../models';
import { CmdEmailTemplate } from '../../models/CmdEmailTemplate';
import { OrganizationService } from '../../services/organization.service';
import { WidgetService, ItemModel } from '../../services/widget.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { MedialibraryComponent } from '../../shared/medialibrary/medialibrary.component';
import { MedialibraryService } from '../../shared/medialibrary/medialibrary.service';
import { WidgetEditor } from '../widgeteditor';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';


@Component({
    selector: 'app-carousel-editor',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselEditorComponent extends WidgetEditor implements OnInit {

    widgetData: Cmd3DCarousel;
    private confirmModalRef: BsModalRef;
    private mediaModalRef: BsModalRef;
    private selectedItem: Cmd3DCarouselItem;
    emailTemplates: Array<CmdEmailTemplate>;
    private widgetCount: number = 0;
    private widgetCarouselValidation: Array<any>;
    private enableSaveButton: boolean = false;
   
    @ViewChild('carouselWidgetForm', { static: false }) carouselWidgetForm: NgForm;

    constructor(private modalService: BsModalService, private changeDetector: ChangeDetectorRef,private widgetService: WidgetService, private orgService: OrganizationService, private mediaLibraryService: MedialibraryService) {
        super();
        this.orgService.getEmailTemplates().subscribe(temps => { this.emailTemplates = temps });
    }

    ngOnInit() {
        if (this.widgetData.items.length == 0) {
            this.addEmptyRow();
        }
    }
    @Input() set widget(data: Cmd3DCarousel) {
        this.widgetData = data;
        if (!this.widgetData.items || !this.widgetData.items.length) {
            this.addEmptyRow();
        } else {
            for (let i = 0; i < this.widgetData.items.length; i++) {
                if (this.widgetData.items[i].asset && this.widgetData.items[i].asset.thumbnailUrl) {
                    this.widgetData.items[i].thumbnail = this.widgetData.items[i].asset;
                }
            }
        }
    }

    removeItem(item: Cmd3DCarouselItem) {
        let self = this;
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this carousel item?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.widgetData.items.splice(this.widgetData.items.indexOf(item), 1);
                    this.notifyChange();
                }
            });
    }

    addEmptyRow() {
        this.widgetData.items.push(new Cmd3DCarouselItem(this.widgetData.id + '-' + this.widgetCount));
        this.widgetCount++;
    }

    selectImage(item: Cmd3DCarouselItem) {
        this.selectedItem = item;
        this.mediaLibraryService.assetType = 'image';
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result.length == 1) {
                    this.selectedItem.asset = result[0];
                    item.thumbnail = result[0];
                    this.notifyChange();
                }
            });
    }

    removeImage(item: Cmd3DCarouselItem) {
        item.thumbnail = null;
        item.asset = undefined;
        this.notifyChange();
    }
    
    clearImage(size) {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this image?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.selectedItem.asset = undefined;
                    this.notifyChange();
                }
            });
    }

    notifyChange() {
        this.checkValues();
        super.notifyChange();
    }
    private checkValues() {
        if (this.widgetData.scaleX > 2) this.widgetData.scaleX = 2;
        if (this.widgetData.scaleX < 0) this.widgetData.scaleX = 0;
        if (this.widgetData.scaleY > 2) this.widgetData.scaleY = 2;
        if (this.widgetData.scaleY < 0) this.widgetData.scaleY = 0;
        if (this.widgetData.scaleZ > 2) this.widgetData.scaleZ = 2;
        if (this.widgetData.scaleZ < 0) this.widgetData.scaleZ = 0;
    }
}
