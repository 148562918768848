import { Component, OnInit } from '@angular/core';
import { AzureAuthholderService  } from '../../services/azureauthholder.service'

@Component({
  selector: 'app-auth-token',
  templateUrl: './auth-token.component.html',
  styleUrls: ['./auth-token.component.css']
})
export class AuthTokenComponent implements OnInit {

  constructor(private auth: AzureAuthholderService) {
     auth.handleAuthentication();
     auth.scheduleRenewal();
  }

  ngOnInit() {
  }

}
