import { CmdBaseModel, CmdAsset, CmdWidget } from './';
/**
 * A scene is a dynamically created page that has a specific layout 
 * that determines how it looks to the end user and what types of controls 
 * it can contain and in what locations on the screen.
 */

export class CmdScene extends CmdBaseModel {
    applicationId: string;
    title: string;
    description: string;
    template: CmdAsset;
    themeClassName: string;
    customCss: string;
    cssClasses: string;
    slug: string;
    modal: boolean;
    showHeader: boolean;
    showRightDrawer: boolean;
    backgroundLarge: CmdAsset;
    backgroundLargeLandscape: CmdAsset;
    backgroundMedium: CmdAsset;
    backgroundMediumLandscape: CmdAsset;
    backgroundSmall: CmdAsset;
    backgroundSmallLandscape: CmdAsset;
    widgets: Array<CmdWidget> = new Array<CmdWidget>();
    scrollbarContainer?: Array<CmdWidget> = new Array<CmdWidget>();
    tags: Array<string> = [];
}
