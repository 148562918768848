import { EnvironmentSpecificService } from "./environmentspecific.service";
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AzureAuthholderService } from "../services/azureauthholder.service";

@Injectable()
export class AnalyticsService {

    constructor(  private azureholder: AzureAuthholderService, private http: HttpClient, private envService: EnvironmentSpecificService) { }

    getActivityByPerson(orgId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = new HttpParams().set("orgId", orgId);
        const requestOptions = {params:params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/' + orgId + '/activityByPerson', requestOptions);
    }

    getDevicePercentage(orgId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = new HttpParams().set("orgId", orgId);
        const requestOptions = {params:params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/' + orgId + '/devicePercentage', requestOptions);
    }

    getPageViewsByDate(orgId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = new HttpParams().set("orgId", orgId);
        const requestOptions = {params:params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/' + orgId + '/pageViewsByDate', requestOptions);
    }

    getScreenCount(orgId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = new HttpParams().set("orgId", orgId);
        const requestOptions = {params:params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/' + orgId + '/screenCount', requestOptions);
    }

     //new create API
    getScenePercentageForOrg(orgId: string,startDate:string,endDate:string): Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
            httpParams =httpParams.append("startDate",startDate.toString());
            httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/scenes', requestOptions);

    }

    getScenePercentageForOrgAndApp(orgId:string,appId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        // httpParams =httpParams.append("appId",appId.toString());
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/scenes/'+appId, requestOptions);

    }

    getScenePercentageForOrgAndUser(orgId:String, userId:string, startDate:string, endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        // httpParams =httpParams.append("userId",userId.toString());
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/scenes/users/'+userId, requestOptions);

    }

    getDeviceCountForOrg(orgId:string, startDate:string, endDate:string):Observable<any>{
       
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams =new HttpParams();
        httpParams = httpParams.append("startDate",startDate.toString());
        httpParams = httpParams.append("endDate",endDate.toString());
        const requestOptions={ params:httpParams,headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/deviceCount',requestOptions);

    }

    getDeviceCountForOrgAndApp(orgId:string,appId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/deviceCount/'+appId, requestOptions);
    }

    getDeviceCountForOrgAndUser(orgId:string,userId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        // httpParams =httpParams.append("userId",userId.toString());
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers}; 
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/deviceCount/users/'+userId, requestOptions);

    }
    
    getDeviceCount(orgId:string,appId:string,userId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        // httpParams =httpParams.append("appId",appId.toString());
        // httpParams =httpParams.append("userId",userId.toString());
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers}; 
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/deviceCount/'+appId+'/users/'+userId, requestOptions);
    }

    getCollectionsShownForOrg(orgId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/collectionsShown', requestOptions);

    }

    getCollectionsShownForOrgAndApp(orgId:string,appId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        // httpParams=httpParams.append("appId",appId.toString());
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers}; 
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/collectionsShown/'+appId, requestOptions);

    }

    getCollectionsShownForOrgAndUser(orgId:string,userId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        // httpParams=httpParams.append("userId",userId.toString());
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers}; 
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/collectionsShown/users/'+userId, requestOptions);

    }

    getCollectionsShown(orgId:string,appId:string,userId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        // httpParams=httpParams.append("userId",userId.toString());
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/collectionsShown/'+appId+'/users/'+userId, requestOptions);
 
    }

    getSharedContentForOrg(orgId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/sharedContents', requestOptions);

    }
    
    getSharedContentForOrgAndApp(orgId:string,appId:string,startDate:string,endDate:string):Observable<any>{
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams=new HttpParams();
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={params:httpParams, headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/sharedContents/'+appId, requestOptions);

    }


    getUserListForOrg(orgId:string):Observable<any>{
       
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams =new HttpParams();
        const requestOptions={ params:httpParams,headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/users',requestOptions);

    }

    getSessionForOrg(orgId:string,startDate:string,endDate:string):Observable<any>{
       
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams =new HttpParams();
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={ params:httpParams,headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/sessions',requestOptions);

    }

    getShareCountForOrg(orgId:string,startDate:string,endDate:string):Observable<any>{
       
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams =new HttpParams();
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={ params:httpParams,headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/shareCount',requestOptions);

    }
    
    getShareCountForOrgAndApp(orgId:string,appId:string,startDate:string,endDate:string):Observable<any>{
       
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams =new HttpParams();
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={ params:httpParams,headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/shareCount/'+appId,requestOptions);

    }

    getContentOverviewForOrg(orgId:string,startDate:string,endDate:string):Observable<any>{
       
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams =new HttpParams();
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={ params:httpParams,headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/contentOverview',requestOptions);

    }

    getContentOverviewForOrgAndApp(orgId:string,appId:string,startDate:string,endDate:string):Observable<any>{
       
        const headers=new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:"Bearer "+this.azureholder.getAccessToken(),
        });
        let httpParams =new HttpParams();
        httpParams =httpParams.append("startDate",startDate.toString());
        httpParams =httpParams.append("endDate",endDate.toString());
        const requestOptions={ params:httpParams,headers: headers};
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/analytics/dashboard/' + orgId + '/contentOverview/'+appId,requestOptions);

    }
}
