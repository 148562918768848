import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'toolName' })

export class ToolsetNamePipe implements PipeTransform {

    transform(toolType: string, args?: any): any {
        if (toolType !== undefined) {
            switch(toolType){                
                case 'COST_CALCULATOR':
                    return 'Cost Calculator';
                case 'TAB_DATA_SEARCH':
                    return 'Content Search & Lookup'; // changed name as requested 'Tab Data Search';
                case 'QUOTE_BUILDER':
                    return 'Quote Builder';
                case 'TCO_CALCULATOR':
                    return 'TCO Calculator';
                default:
                    return '';
            }
        }
    };
}