import { Component, OnInit } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { SearchModel } from '../../../models/SearchModel';
import { ToolsetsService } from '../../../services/toolsets.service';
import { OrganizationService } from '../../../services/organization.service';
import { BaseActionTypeSelectorComponent } from '../baseactiontypeselector';

@Component({
    selector: 'app-tabdatasearch',
    templateUrl: './tabdatasearch.component.html',
    styleUrls: ['./tabdatasearch.component.scss']
})
export class TabdatasearchSelectorComponent extends BaseActionTypeSelectorComponent implements OnInit {
    constructor(public widgetService: WidgetService,
        public toolsetsService: ToolsetsService,
        public orgService: OrganizationService) {
        super(widgetService);
        this.orgId = this.orgService.getCurrentOrganizationId();
    }
    ngOnInit() {
        let tdsSearch = new SearchModel();
        tdsSearch.page.pageSize = 300;
        tdsSearch.page.sortBy = 'title';
        tdsSearch.page.sortOrder = 'ASC';
        this.toolsetsService.getToolsetsForOrganization(this.orgId, tdsSearch, 'TAB_DATA_SEARCH').then(resp => {
            this.rows = resp.contents.filter(tds => !tds.includeInQuotebuilder);
        });
    }
}
