import { OrganizationService } from '../../services/organization.service';
import {Component, OnInit, Output, Input, forwardRef, EventEmitter} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-tagselector',
    templateUrl: './tagselector.component.html',
    styleUrls: ['./tagselector.component.scss'],
    providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TagselectorComponent), multi: true }
  ]
})
export class TagselectorComponent implements OnInit, ControlValueAccessor {

    dropdownList: Array<string> = [];
    _selectedItems: Array<any> = [];
    _tracks:Array<string> = [];
    
    @Output() change:EventEmitter<any> = new EventEmitter();

    constructor(private orgService:OrganizationService) {}

    ngOnInit() {
        this.orgService.getTags().subscribe(theTags => {this._tracks = theTags});
    }

    propagateChange = (_: any) => {};

    get selectedItems() {
        return this._selectedItems;
    }

    set selectedItems(val) {
        var its:Array<string> = new Array<string>();
        if(val){
            val.forEach(it => {
                its.push(it["value"]);
            });
        }
        
        this._selectedItems = val;
        this.propagateChange(its);
    }

    @Input() selectedTracks: Array<any> = [];

    writeValue(data: Array<any>): void {
        this.selectedItems = [];
        if(data){
            data.forEach(item => {
                this.selectedItems.push({
                    display: item.value,
                    value: item.value
                });
            });
        }
    }
    registerOnChange(fn: (val: any) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        
    }
    
     
    onSelected(item){
        var items:Array<string> = new Array<string>();
        if(this.selectedItems){
            this.selectedItems.forEach(theItem => {
                items.push(theItem["value"]);
            });
        }
        this.propagateChange(items);
        this.change.emit("change");
    }
}
