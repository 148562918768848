import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductCompetitor } from '../../models/CmdProduct';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { ProductsService } from '../../services/products.service';
import { SpinService } from '../../services/spin.service';
import { finalize } from "rxjs/operators";

@Component({
    selector: 'app-competitor-edit',
    templateUrl: 'competitor-edit.component.html',
    styleUrls: ['competitor-edit.component.scss']
})
export class CompetitorEditComponent implements OnInit {

    public onClose: Subject<any>;
    public prodId: string;
    public editCompetitor: ProductCompetitor = new ProductCompetitor();

    constructor(public bsModalRef: BsModalRef,
        private alertService: StickyPopUpService,
        private spinService: SpinService,
        private productsService: ProductsService) {
    }

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public onConfirm(): void {

        // new
        this.spinService.show();
        if (!this.editCompetitor.id) {
            this.productsService.createCompetitors(this.prodId, this.editCompetitor)
                .pipe(take(1))
                .subscribe(resp => {
                    this.alertService.success('Product competitor was successfully saved.');
                    this.onClose.next(this.editCompetitor);
                    this.bsModalRef.hide();
                    this.spinService.hide();
                }, error => {
                    this.spinService.hide();
                    console.log(error);
                    this.alertService.error(error);
                });

            // update
        } else {
            this.productsService.updateCompetitors(this.prodId, this.editCompetitor)
                .pipe(take(1))
                .subscribe(resp => {
                    this.alertService.success('Product competitor was successfully saved.');
                    this.onClose.next(this.editCompetitor);
                    this.spinService.hide();
                    this.bsModalRef.hide();
                }, error => {
                    this.spinService.hide();
                    console.log(error);
                    this.alertService.error(error);
                });
        }
    }

    public onCancel(): void {
        this.bsModalRef.hide();
        this.onClose.next(undefined);
    }

}

