import {CmdEmailTemplate} from "../../../models/CmdEmailTemplate";
import {EmailsService} from "../../../services/emails.service";
import { OrganizationService } from "../../../services/organization.service";
import { StickyPopUpService } from "../../../services/toast/stickyPopUp.service";
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { SpinService } from "../../../services/spin.service";
import { finalize } from "rxjs/operators";

@Component({
    selector: 'app-emailtemplateedit',
    templateUrl: './emailtemplateedit.component.html',
    styleUrls: ['./emailtemplateedit.component.scss']
})
export class EmailtemplateeditComponent implements OnInit {

    public emailTemplate: CmdEmailTemplate = new CmdEmailTemplate();
    private emailTemplateId: string;
    public hasChanges: boolean = false;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private emailService: EmailsService,
        private spinService: SpinService,
        private alertService: StickyPopUpService,
        private orgService:OrganizationService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.emailTemplateId = params['templateId'];
            if(this.emailTemplateId == 'NEW'){
              this.hasChanges = true;
              this.emailTemplate = new CmdEmailTemplate();
              this.emailTemplate.organizationId = this.orgService.getCurrentOrganizationId();
            }
            else{
                this.emailService.getById(this.emailTemplateId).subscribe(template => {
                    this.emailTemplate = template;
                });
            }
        });
    }

    cancel() {
        this.router.navigate(['/emailtemplates']);
    }

    save(){
        this.spinService.show();
        if (this.emailTemplate.id) {
            this.emailService.update(this.emailTemplate).pipe(finalize(() => this.spinService.hide())).subscribe(result => {
              this.hasChanges = false;
              this.alertService.success('Email template updated successfully!');
              this.router.navigate(['/emailtemplates']);
            }, error => {
                this.spinService.hide();
                this.alertService.error('Failed to save email template: ' + error.error.message);
            });
        }
        else {
            this.emailService.create(this.emailTemplate).pipe(finalize(() => this.spinService.hide())).subscribe(result => {
              this.hasChanges = false;
              this.alertService.success('Email template created successfully!');
              this.router.navigate(['/emailtemplates']);
            }, error => {
                this.spinService.hide();
                this.alertService.error('Failed to save email template: ' + error.error.message);
            });
        }
    }

    public notifyChange(){
      this.hasChanges = true;
    }
}
