import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinService } from '../../services/spin.service';

@Component({
  selector: 'spin-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  displayLoader = false;

  private subscription: Subscription;
  constructor(private spinService: SpinService) { }

  ngOnInit() {
    this.subscription = this.spinService.loaderState
      .subscribe((state) => {
        this.displayLoader = state;
      });
  }

}
