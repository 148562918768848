import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../../services/organization.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { EditQuoteBuilderService } from '../../../../services/editquotebuilder';

@Component({
  selector: 'app-create-product-csv',
  templateUrl: './create-product-csv.component.html',
  styleUrls: ['./create-product-csv.component.css']
})
export class CreateProductCSVComponent implements OnInit {

  fileData: any;
  public title: string;
  public quoteId: string;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private bsModalRef: BsModalRef, private apiService: EditQuoteBuilderService,
    public organizationService: OrganizationService,
    private stickyPopUp: StickyPopUpService
  ) { }
  ngOnInit() {

  }
  uploadCSVFile(event) {
    console.log(event);
    this.fileData = event.target.files[0];
  }
  uploadCSVData = () => {
    if (this.fileData == undefined) {
      this.stickyPopUp.error('Please choose file to upload.');
      return;
    }
    let formData = new FormData();
    formData.append('file', this.fileData);
    const org = this.organizationService.getCurrentOrganization();
    this.apiService.postUploadCsv(org.id, formData, this.quoteId)
      .subscribe(
        data => {
          this.event.emit({ message: "updated" });
          this.stickyPopUp.success('Uploaded successully.');
          this.bsModalRef.hide();
        }, err => {
          this.stickyPopUp.error('Failed to upload.');
        }
      )
  }
  onCancel() {
    // this.onClose.next(true)
    this.bsModalRef.hide();
  }
}
