import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CmdEmailTemplate } from '../../models/CmdEmailTemplate';
import { WidgetService } from '../../services/widget.service';
import { OrganizationService } from '../../services/organization.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { MedialibraryComponent } from '../../shared/medialibrary/medialibrary.component';
import { WidgetEditor } from '../widgeteditor';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { MedialibraryService } from '../../shared/medialibrary/medialibrary.service';
import { CmdSingleCardItem } from '../../models/cmdSingleCarditem';
import { CmdSingleCard } from '../../models/cmdSingleCard';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-single-card',
  templateUrl: './single-card.component.html',
  styleUrls: ['./single-card.component.css']
})
export class SingleCardComponent extends WidgetEditor implements OnInit {

  private confirmModalRef: BsModalRef;
  private mediaModalRef: BsModalRef;
  private selectedItem: CmdSingleCardItem;
  private widgetCount: number = 0;

  widgetData: CmdSingleCard;
  emailTemplates: Array<CmdEmailTemplate> = [];
  @ViewChild('SingleCardForm', { static: false }) collectionWidgetForm: NgForm;
  constructor(private changeDetector: ChangeDetectorRef,
    private modalService: BsModalService,
    private orgService: OrganizationService,
    private widgetService: WidgetService,
    private mediaLibraryService: MedialibraryService) { super() }
    editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '7rem',
      maxHeight: '10rem',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '4',
      fonts: [
          { class: 'Scania', name: 'Scania' },
          { class: 'ScaniaItalic', name: 'ScaniaItalic' },
          { class: 'ScaniaRegular', name: 'ScaniaRegular' },
          { class: 'ScaniaHeadlineBold', name: 'ScaniaHeadlineBold' },
          { class: 'ScaniaHeadlineRegular', name: 'ScaniaHeadlineRegular' },
          { class: 'arial', name: 'Arial' },
          { class: 'times-new-roman', name: 'Times New Roman' },
          { class: 'calibri', name: 'Calibri' },
          { class: 'comic-sans-ms', name: 'Comic Sans MS' },
          { class: 'GT-Eesti-Text-Regular', name: 'GT Eesti Text Regular' },
          { class: 'GT-Eesti-Text-Light', name: 'GT Eesti Text Light' },
          { class: 'EBGaramond-Regular', name: 'Garamond Regular' },
          { class: 'FranklinGothic', name: 'Franklin Gothic' },
          { class: 'Helvetica', name: 'Helvetica' },
          { class: 'OpenSans-Regular', name: 'Open Sans Regular' },
          //{ class: 'Rockwell', name: 'Rockwell' },
          { class: 'Verdana', name: 'Verdana' }
      ],
      customClasses: [
        {
          name: 'h1-text-left',
          class: 'text-left',
          tag: 'h1',
        },
        {
          name: 'h1-text-center',
          class: 'text-center',
          tag: 'h1',
        },
        {
          name: 'h1-text-right',
          class: 'text-right',
          tag: 'h1',
        },
        {
          name: 'h2-text-left',
          class: 'text-left',
          tag: 'h2',
        },
        {
          name: 'h2-text-center',
          class: 'text-center',
          tag: 'h2',
        },
        {
          name: 'h2-text-right',
          class: 'text-right',
          tag: 'h2',
        },
        {
          name: 'h3-text-left',
          class: 'text-left',
          tag: 'h3',
        },
        {
          name: 'h3-text-center',
          class: 'text-center',
          tag: 'h3',
        },
        {
          name: 'h3-text-right',
          class: 'text-right',
          tag: 'h3',
        },
        {
          name: 'h4-text-left',
          class: 'text-left',
          tag: 'h4',
        },
        {
          name: 'h4-text-center',
          class: 'text-center',
          tag: 'h4',
        },
        {
          name: 'h4-text-right',
          class: 'text-right',
          tag: 'h4',
        },
        {
          name: 'h5-text-left',
          class: 'text-left',
          tag: 'h5',
        },
        {
          name: 'h5-text-center',
          class: 'text-center',
          tag: 'h5',
        },
        {
          name: 'h5-text-right',
          class: 'text-right',
          tag: 'h5',
        },
        {
          name: 'p-text-left',
          class: 'text-left',
          tag: 'p',
        },
        {
          name: 'p-text-center',
          class: 'text-center',
          tag: 'p',
        },
        {
          name: 'p-text-right',
          class: 'text-right',
          tag: 'p',
        }
      ],
      sanitize:false
    };

  public isShowText: boolean = false;
  public isShowImage: boolean = false;
  ngOnInit() {
    if (this.widgetData.items.length == 0) {
      this.addEmptyRow();
    }
  }

  @Input() set widget(data: CmdSingleCard) {
    this.widgetData = data;
    if (this.widgetData.useImage || this.widgetData.useText) {
      this.isShowText = this.widgetData.useText
      this.isShowImage = this.widgetData.useImage
    }
  }

  addEmptyRow() {
    this.widgetData.items.push(new CmdSingleCardItem(this.widgetData.id + '-' + this.widgetCount));
    this.widgetCount++;
  }

  useText() {
    this.isShowText = !this.isShowText;
  }

  useImage() {
    this.isShowImage = !this.isShowImage;
  }

  addOpacity(opacityValue: number): void {
    const opacity = Math.round(opacityValue * 100) / 100;
    var alpha = Math.round(opacity * 255);
    var hex = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
    this.widgetData.cardColor = this.widgetData.cardBaseColor + hex;
  }

  changeCornerValue(cornerCode: string): string {
    if (cornerCode.includes('px')) {
      return this.widgetData.corners = cornerCode;
    }
    this.widgetData.corners = cornerCode + 'px';
  }


  selectImage(item: CmdSingleCardItem) {
    this.selectedItem = item;
    this.mediaLibraryService.assetType = 'image';
    this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
    this.mediaModalRef.content.onClose
      .subscribe(result => {
        if (result.length == 1) {
          this.selectedItem.image = result[0];
          this.notifyChange();
        }
      });
  }

  removeImage(item: CmdSingleCardItem) {
    let self = this;
    this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
    this.confirmModalRef.content.message = 'Are you sure you want remove this image?';
    this.confirmModalRef.content.onClose
      .subscribe(result => {
        if (result) {
          item.image = null;
        }
        this.notifyChange();
      });
  }
}
