import { CmdAdBlueConsumption } from "./CmdAdBlueConsumption";
import { CmdContractedServices } from "./CmdContractedServices";
import { CmdFinancingCost } from "./CmdFinancingCost";
import { CmdFormComparison } from "./CmdFormComparison";
import { CmdFuelConsumption } from "./CmdFuelConsumption";
import { CmdProjectInfo } from "./CmdProjectInfo";
import { CmdSteps } from "./CmdSteps";
import { CmdUsedPriceResale } from "./CmdUsedPriceResale";
import { CmdUsedVehicleLiquidity } from "./CmdUsedVehicleLiquidity";
import { CmdVehiclePurchase } from "./CmdVehiclePurchase";

export class CmdTCO{
    steps?: Array<CmdSteps>;
    step1?: Array<CmdProjectInfo>;
    step2?: Array<CmdVehiclePurchase>;
    step3?: Array<CmdFinancingCost>;
    step4?: Array<CmdFuelConsumption>;
    step5?: Array<CmdAdBlueConsumption>;
    step6?: Array<CmdContractedServices>;
    step7?: Array<CmdUsedPriceResale>;
    step8?: Array<CmdUsedVehicleLiquidity>;
    comparison?:Array<CmdFormComparison>
}