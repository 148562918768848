export const GlobalConstants = {
  // All global constants such as connection name, client IDs or client secrets will go here.
  assetsTypes: {
    images: {
      jpeg: 'image/jpeg',
      png: 'image/png'
    },
    css: 'text/css',
    videos: {
      ogv: 'video/ogg',
      mp4: 'video/mp4',
      mov: 'video/quicktime'
    },
    documents: {
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      pdf: 'application/pdf'
    }
  },
  fileSizeConstants: {
    OneKB: 1024,
    OneMB: 1048576,
    OneGB: 1073741824
  },
  folderNamesForUploadingAssets: {
    images: 'images',
    videos: 'videos',
    themes: 'themes',
    documents: 'document',
    pdf: 'document'
  },
  typeNamesForUploadingAssets: {
    images: 'image',
    videos: 'video',
    themes: 'theme',
    documents: 'document',
    pdf: 'document'
  },
  userRoles: {
    superAdmin: "SUPERADMIN",
    organizationAdmin: "ORGADMIN",
    user: "USER"
  }
}
