import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CacheBusterPipe } from './bustcache.pipe';
import { SortByPipe } from './sortby.pipe';
import { ToolsetNamePipe } from './toolsetName.pipe';
import { TruncatePipe } from './truncate.pipe';
import { UserappPipe } from './userapp.pipe';
import { BytesPipe } from './formatbytes.pipe';
import { FilterPipe } from './filter.pipe';
import { FormatAmountPipe } from './formatAmount.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CacheBusterPipe,
    SortByPipe,
    ToolsetNamePipe,
    TruncatePipe,
    UserappPipe,
    BytesPipe,
    FilterPipe,
    FormatAmountPipe
  ],
  exports: [
    CacheBusterPipe,
    SortByPipe,
    ToolsetNamePipe,
    TruncatePipe,
    UserappPipe,
    BytesPipe,
    FormatAmountPipe
  ]
})
export class CustompipeModule { }
