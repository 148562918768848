import {CmdCollectionItem} from './CmdCollectionItem';
import {CmdWidget} from './CmdWidget';
export class CmdCollection extends CmdWidget {
    applicationId:string;
    title: string;
    slug: string;
    items: Array<CmdCollectionItem>;
    tags: Array<string>;
    constructor(id: string) {
        super(id);
        this.type = "Collection";
        this.items = new Array<CmdCollectionItem>();
    }
}