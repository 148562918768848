import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { ProductModel, ProductCompetitor, SearchCompetitorModel } from '../models/CmdProduct';
import { CmdEmailTemplate } from '../models/CmdEmailTemplate';
import { CmdOrganization, Toolsets } from '../models';
import { SearchModel } from '../models/SearchModel';
import { AppSettings } from '../app-settings';
import { BindProdAssetsModel } from '../models/BindProdAssetsModel';
import { AzureAuthholderService } from "../services/azureauthholder.service";


@Injectable()
export class ProductsService {

    private currentOrganization = new Subject<any>();
    private accessToken: String;

    constructor(
        private azureholder: AzureAuthholderService,
        private httpClient: HttpClient, 
        private envService: EnvironmentSpecificService) {
    }

 
    public createProduct(prod:ProductModel) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .post<ProductModel>(this.envService.envSpecific.baseServerUrl + '/api/products', prod, requestOptions);
    }

    public updateProduct(prod:ProductModel) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .put<ProductModel>(this.envService.envSpecific.baseServerUrl + '/api/products', prod, requestOptions);
    }

    public uploadProductCSV(orgId, file): Observable<any> {  
        const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });   
        const formData = new FormData();
        formData.append("file", file);
        const requestOptions = {headers: headers };   
        return this.httpClient
            .post<any>(this.envService.envSpecific.baseServerUrl + '/api/products/' + orgId + '/upload', formData, requestOptions);
    }

    public uploadProductZIP(orgId, file): Observable<any> {
        const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const formData = new FormData();
        formData.append("file", file);
        const requestOptions = {headers: headers };
        return this.httpClient
            .post<any>(this.envService.envSpecific.baseServerUrl + '/api/products/' + orgId + '/uploadZip', formData, requestOptions);
    }

    public uploadCompetitorsCSV(prodId, file): Observable<any> {
        const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const formData = new FormData();
        formData.append("file", file);
        const requestOptions = {headers: headers };
        return this.httpClient
            .post<any>(this.envService.envSpecific.baseServerUrl + '/api/products/' + prodId + '/competitors/upload', formData, requestOptions);
    }

    // This function will format bytes into KB,MB or GB depending upon size of file.
    formatBytes(bytes): string {
        if (bytes < AppSettings.global.fileSizeConstants.OneKB) return bytes.toFixed(2) + " Bytes";
        else if (bytes < AppSettings.global.fileSizeConstants.OneMB) return (bytes / AppSettings.global.fileSizeConstants.OneKB).toFixed(2) + " KB";
        else if (bytes < AppSettings.global.fileSizeConstants.OneGB) return (bytes / AppSettings.global.fileSizeConstants.OneMB).toFixed(2) + " MB";
        else return (bytes / AppSettings.global.fileSizeConstants.OneGB).toFixed(2) + " GB";
    }

    public getProductById(prodId: string): Observable<ProductModel> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .get<any>(this.envService.envSpecific.baseServerUrl + '/api/products/' + prodId, requestOptions)
    }

    public getProductsForOrganization(orgId: string, fillter: SearchModel = new SearchModel(), isActive?: boolean): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let httpParams = new HttpParams();
        httpParams = httpParams.append('orgId', orgId.toString());
        httpParams = httpParams.append('sortBy', fillter.page.sortBy.toString());
        httpParams = httpParams.append('sortOrder', fillter.page.sortOrder.toString());
        httpParams = httpParams.append('pageSize', fillter.page.pageSize.toString());
        httpParams = httpParams.append('page', fillter.page.page.toString());
        httpParams = fillter.searchText.length > 0 ?  httpParams.append('searchText', fillter.searchText) : httpParams;
        httpParams = fillter.searchTags.length > 0 ?  httpParams.append('tags', fillter.searchTags) : httpParams;
        httpParams = isActive ?  httpParams.append('isActive', 'true') : httpParams;
        
        const requestOptions = { params: httpParams, headers: headers };
        return this.httpClient
            .get<any>(this.envService.envSpecific.baseServerUrl + '/api/products/search', requestOptions );
    }

    public deleteProduct(prodId: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .delete<any>(this.envService.envSpecific.baseServerUrl + '/api/products/' + prodId, requestOptions)
    }

    public setAssets(updateModel: BindProdAssetsModel): Observable<any>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.put<any>(this.envService.envSpecific.baseServerUrl + '/api/products/assets', updateModel, requestOptions);
    }

    public removeAssets(updateModel: BindProdAssetsModel): Observable<any>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.put<any>(this.envService.envSpecific.baseServerUrl + '/api/products/remove/assets', updateModel, requestOptions);
    }

// #region Product Competitors

    public searchCompetitors(prodId: string, fillter: SearchCompetitorModel = new SearchCompetitorModel()): Observable<SearchCompetitorModel> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let httpParams = new HttpParams();      
        httpParams = httpParams.append('pageSize', fillter.pageSize.toString());
        httpParams = httpParams.append('page', fillter.page.toString());
        const requestOptions = {params: httpParams, headers: headers };  
        return this.httpClient
            .get<SearchCompetitorModel>(this.envService.envSpecific.baseServerUrl + '/api/products/'+ prodId +'/competitors/search', requestOptions );
    }

    public getCompetitorById(compId: string): Observable<ProductCompetitor> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .get<ProductCompetitor>(this.envService.envSpecific.baseServerUrl + '/api/products/competitor/' + compId, requestOptions);
    }

    public createCompetitors(prodId: string, comp: ProductCompetitor): Observable<ProductCompetitor> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .post<ProductCompetitor>(this.envService.envSpecific.baseServerUrl + '/api/products/' + prodId + '/competitor', comp, requestOptions);
    }

    public updateCompetitors(prodId: string, comp: ProductCompetitor): Observable<ProductCompetitor> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .put<ProductCompetitor>(this.envService.envSpecific.baseServerUrl + '/api/products/' + prodId + '/competitor', comp, requestOptions);
    }

    public deleteCompetitors(compId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .put<any>(this.envService.envSpecific.baseServerUrl + '/api/products/competitor/' + compId, requestOptions, {});
    }

// #endregion
}
