import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, take, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { String } from 'typescript-string-operations';
import { CmdOrganization } from '../../models';
import { CmdToolsetAuthorization } from "../../models/CmdToolsetAuthorization";
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../../services/organization.service';
import { SpinService } from '../../services/spin.service';
import { SearchModel, ResponseModel } from '../../models/SearchModel';
import { Page } from '../../models/page';
import { ToolsetsService } from '../../services/toolsets.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { ConfirmModalComponent } from "../../shared/confirm-modal/confirm-modal.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CMDToolsetsModel } from '../../models/CmdToolsets';

// testing for AWS build
@Component({
    selector: 'app-toolsets',
    templateUrl: 'toolsets.component.html',
    styleUrls: ['toolsets.component.scss']
})
export class ToolsetsComponent implements OnInit, OnDestroy {

    destroy$: Subject<boolean> = new Subject<boolean>();
    searchTerm$ = new Subject<string>();
    searchTermTags$ = new Subject<string>();
    currentOrganization: CmdOrganization;
    toolsetsSearch: SearchModel;
    toolsets = [];
    cofirmModalRef: BsModalRef;
    selToolset: string = '1';
    authorizedToolsets: Array<CmdToolsetAuthorization> = [];

    constructor(private router: Router,
        private route: ActivatedRoute,
        private organizationService: OrganizationService,
        private toolsetsService: ToolsetsService,
        private alertService: StickyPopUpService,
        private spinService: SpinService,
        private modalService: BsModalService) {
        this.resetPagination();

        route.params
            .pipe(take(1))
            .subscribe(params => {
                if (params['page'] && params['pageSize']) {
                    this.toolsetsSearch.page.page = +params['page'];
                    this.toolsetsSearch.page.pageSize = +params['pageSize'];
                }
            });
    }


    ngOnInit() {
        // init search
        this.searchTerm$
            .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchText => {
                this.toolsetsSearch.searchText = searchText;
                this.doSearch();
            });

        // get current Org
        this.currentOrganization = this.organizationService.getCurrentOrganization();

        this.checkListToolsets();

        // get all toolsets
        this.doSearch();
    }

    public addToolset(tool: CmdToolsetAuthorization) {
        if (tool.authorized && this.checkToolsetAccess(tool).length === 0) {

            switch (tool.type) {
                case "COST_CALCULATOR":
                    this.router.navigate(['/toolset/costcalc', 'NEW']);
                    break;
                case "TAB_DATA_SEARCH":
                    this.router.navigate(['/toolset/tabdata', 'NEW']);
                    break;
                case "QUOTE_BUILDER":
                    this.router.navigate(['/toolset/quotebuilder', 'NEW']);
                    break;
                case "TCO_CALCULATOR":
                    this.router.navigate(['/toolset/tco-calculator', ]);
                    break;
            }
        }
    }

    public editToolset(tool: CMDToolsetsModel) {
        const returnUrl = String.Format("/toolsets/{0}/{1}", this.toolsetsSearch.page.page, this.toolsetsSearch.page.pageSize);
        switch (tool.type) {
            case "COST_CALCULATOR":
                this.router.navigate(['/toolset/costcalc', tool.id, returnUrl]);
                break;
            case "TAB_DATA_SEARCH":
                this.router.navigate(['/toolset/tabdata', tool.id, returnUrl]);
                break;
            case "QUOTE_BUILDER":
                this.router.navigate(['/toolset/quotebuilder', tool.id, returnUrl]);
                break;
            case "TCO_CALCULATOR":
                    this.router.navigate(['/toolset/tco-calculator',  ]);
                    break;
        }


    }

    public deleteToolset(tool: any) {
        this.cofirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.cofirmModalRef.content.title = "Delete a Toolset";
        this.cofirmModalRef.content.message = 'Are you sure you want to delete ' + tool.title + '?';
        this.cofirmModalRef.content.onClose.subscribe(result => {
            if (result === true) {
                this.spinService.show();
                this.toolsetsService.deleteToolset(tool.id)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                        this.alertService.success('The toolset has been deleted.');
                        this.spinService.hide();
                        this.doSearch();
                        this.checkListToolsets();
                    }, error => {
                        this.alertService.error(error.error.message);
                        this.spinService.hide();
                        this.doSearch();
                    });
            }
        });
    }



    doSearch() {
        this.spinService.show();
        this.toolsetsService.getToolsetsForOrganization(this.organizationService.getCurrentOrganizationId(), this.toolsetsSearch)
            .then(data => {
                this.spinService.hide();
                this.toolsetsSearch.results = data;
            }).catch(error => {
                this.spinService.hide();
            });
    }

    // #region Paginations&Search
    clearSearch() {
        if (this.toolsetsSearch && this.toolsetsSearch.searchText) {
            this.resetPagination();
            this.doSearch()
        }
    }

    resetPagination() {
        this.toolsetsSearch = new SearchModel();
        this.toolsetsSearch.page = new Page();
    }

    pageChanged($event) {
        this.toolsetsSearch.page.page = $event.page;
        this.toolsetsSearch.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }

    changePageSize() {
        this.doSearch();
    }

    sortToolsets(sortBy) {
        this.toolsetsSearch.page.page = 1;
        if (this.toolsetsSearch.page.sortBy == sortBy) {
            if (this.toolsetsSearch.page.sortOrder == "ASC") {
                this.toolsetsSearch.page.sortOrder = "DESC";
            } else {
                this.toolsetsSearch.page.sortOrder = "ASC";
            }
        } else {
            this.toolsetsSearch.page.sortBy = sortBy;
            this.toolsetsSearch.page.sortOrder = "ASC"
        }
        this.doSearch();
    }

    // #endregion

    public status: { isopen: boolean } = { isopen: false };

    public toggleDropdown($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.status.isopen = !this.status.isopen;
    }

    public change(value: boolean): void {
        this.status.isopen = value;
    }

    public getColor(toolsetType): string {
        switch (toolsetType) {
            case 'COST_CALCULATOR': {
                return '#857cb7';
            }
            case 'TAB_DATA_SEARCH': {
                return '#7cb78b';
            }
            case 'QUOTE_BUILDER': {
                return '#b77c7c';
            }
            case 'TCO_CALCULATOR': {
                return '#1e1633'
            }
            default: {
                return '#777777';
            }
        }
    }

    public checkToolsetAccess(tool: CmdToolsetAuthorization): string {
        if (!tool.authorized) {
            return "Not authorized for ";
        }
        if (tool.numberExisting >= tool.numberAllowed) {
            return "The limit is reached for ";
        }
        return "";
    }

    private checkListToolsets() {
        this.organizationService.getAuthorizedToolsets(this.currentOrganization.id).subscribe(authedToolsets => {
            this.authorizedToolsets = authedToolsets;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
