import { Component, } from '@angular/core';
import { MeasureList, Measure } from '../../../../models/CmdToolsets';
import { ToolsetBaseComponent } from '../toolset-base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolsetsService } from '../../../../services/toolsets.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { takeUntil } from 'rxjs/operators';
import { OrganizationService } from '../../../../services/organization.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SpinService } from '../../../../services/spin.service';
import { CacheService } from '../../../../services/cache.service';

@Component({
    selector: 'app-costcalc',
    templateUrl: './costcalc.component.html',
    styleUrls: ['../toolset-edit.component.scss']
})
export class CostcalcComponent extends ToolsetBaseComponent {

    measure_count_limit = 4;
    setting = {
        live: false,
        more: false
    };

    constructor(public route: ActivatedRoute,
        public router: Router,
        public organizationService: OrganizationService,
        public toolsetsService: ToolsetsService,
        public modalService: BsModalService,
        public spinService: SpinService,
        public alertService: StickyPopUpService,
        public cacheService: CacheService) {
        super(route,
            router,
            organizationService,
            toolsetsService,
            modalService,
            spinService,
            alertService,
            cacheService);
        route.params.subscribe(params => {
            if (params['toolId'] === 'NEW') {
                this.hasChanges = true;
                this.editToolset.id = '';
                this.editToolset.type = 'COST_CALCULATOR';
                this.editToolset.properties = new MeasureList();
            } else
                this.toolsetsService.getToolsetById(params['toolId'])
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(resp => {
                        this.editToolset = resp;
                        if (!this.editToolset.properties || !this.editToolset.properties.measures_list) {
                            this.editToolset.properties = new MeasureList();
                        }
                    }, error => {
                        console.error(error);
                        this.alertService.error('Failed to load toolset: ' + error.error.message);
                        this.isError = true;
                    });

        });
    }

    validData() { }

    addSet() {
        if (this.editToolset.properties.measures_list.length < this.measure_count_limit) {
            let m = new Measure();
            m.value = 0;
            m.type = '';
            m.result = '';
            this.editToolset.properties.measures_list.push(m);
        }
    }
    removeSet(prop: any) {
        this.editToolset.properties.measures_list = this.editToolset.properties.measures_list.filter(p => p !== prop);
    }

}
