import { CmdListItem } from './CmdListItem';
import { CmdWidget } from './CmdWidget';
export class CmdList extends CmdWidget{
    items:Array<CmdListItem>;
    itemsToDisplay:number;
    
    constructor(id:string){
        super(id);
        this.type = "List";
        this.items = new Array<CmdListItem>();
    }
  
}