import { CmdAsset } from './';
export class CmdSlide {
    asset:CmdAsset;
	assetBehavior: string = 'END';
    canDraw:boolean;
    requestInformation:boolean;
    
    validate():boolean{
        return this.asset != undefined;
    }
}