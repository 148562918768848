import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ToolsetsComponent } from './toolsets.component';
import { CostcalcComponent } from './toolset-edit/costcalc/costcalc.component';
import { TabdataComponent } from './toolset-edit/tabdata/tabdata.component';
import { QuotebuilderComponent } from './toolset-edit/quotebuilder/quotebuilder.component';
import { ReactiveFormsModule } from '@angular/forms'
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustompipeModule } from '../../pipes/custompipe.module';
import { DragulaModule } from 'ng2-dragula';
import { LightboxModalComponent } from '../assets';
import { SharedcomponentModule } from '../../shared/sharedcomponent.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ContentquotebuilderComponent } from './contentquotebuilder/contentquotebuilder.component';
import { AddQuoteBuilderModalComponent } from './contentquotebuilder/add-quote-builder-modal/add-quote-builder-modal.component';
import { QuetebuilderReportComponent } from './contentquotebuilder/quetebuilder-report/quetebuilder-report.component';
import { QuetebuilderEditComponent } from './contentquotebuilder/quetebuilder-edit/quetebuilder-edit.component';
import { RouterModule } from '@angular/router';
import { AddDuplicateModalComponent } from './contentquotebuilder/add-quote-builder-modal/add-duplicate-modal.component';
import { CreateProductBuilderModelComponent } from './contentquotebuilder/create-product-builder-model/create-product-builder-model.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CreateProductCSVComponent } from './contentquotebuilder/create-product-csv/create-product-csv.component';
import { AddProductQuoteMdlComponent } from './contentquotebuilder/add-product-quote-mdl/add-product-quote-mdl.component';
import { ChangeColNameMdlComponent } from './contentquotebuilder/change-col-name-mdl/change-col-name-mdl.component';
import { SelectFieldNameModalComponent } from './contentquotebuilder/select-field-name-modal/select-field-name-modal.component';
import { AddColumnModalComponent } from './contentquotebuilder/add-column-modal/add-column-modal.component';
import { EditProductQuoteModalComponent } from './contentquotebuilder/edit-product-quote-modal/edit-product-quote-modal.component';
import { SendQuoteInfoModalComponent } from './contentquotebuilder/send-quote-info-modal/send-quote-info-modal.component';
import { AddOnsModalComponent } from './contentquotebuilder/add-ons-modal/add-ons-modal.component';
import { AddSendInfoColComponent } from './contentquotebuilder/add-send-info-col/add-send-info-col.component';
import { AddEmailccaddressesQuoteBuilderComponent } from './contentquotebuilder/add-emailccaddresses-quote-builder/add-emailccaddresses-quote-builder.component';
import { TcoCalculatorComponent } from './tco-calculator/tco-calculator.component';
import { TcoDevaluationAnalysisComponent } from './tco-calculator/tco-devaluation-analysis/tco-devaluation-analysis.component';
import { TcoAmoritizationInvestmentComponent } from './tco-calculator/tco-amoritization-investment/tco-amoritization-investment.component';
import { Directives } from '../../directives/directives.module';
import { TcoHistoryComponent } from './tco-calculator/tco-history/tco-history.component';
// import { DeletePopUpComponent } from './contentquotebuilder/add-quote-builder-modal/DeletePopUp.component';

// const routes: Routes=[
// {path:'quetebuilder-report',component:QuetebuilderReportComponent},
// {path:'quetebuilder-edit',component:QuetebuilderEditComponent}

// ];

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    CustompipeModule,
    Directives,
    DragulaModule,
    SharedcomponentModule,
    DragDropModule,
    RouterModule
  ],
  declarations: [
    ToolsetsComponent,
    CostcalcComponent,
    QuotebuilderComponent,
    TabdataComponent,
    ContentquotebuilderComponent,
    AddQuoteBuilderModalComponent,
    QuetebuilderReportComponent,
    QuetebuilderEditComponent,
    AddDuplicateModalComponent,
    CreateProductBuilderModelComponent,
    CreateProductCSVComponent,
    AddProductQuoteMdlComponent,
    ChangeColNameMdlComponent,
    SelectFieldNameModalComponent,
    AddColumnModalComponent,
    EditProductQuoteModalComponent,
    SendQuoteInfoModalComponent,
    AddOnsModalComponent,
    AddSendInfoColComponent,
    AddEmailccaddressesQuoteBuilderComponent,
    TcoCalculatorComponent,
    TcoDevaluationAnalysisComponent,
    TcoAmoritizationInvestmentComponent,
    TcoHistoryComponent,
    // DeletePopUpComponent
  ],
  exports: [
    ToolsetsComponent,
    CostcalcComponent,
    QuotebuilderComponent,
    TabdataComponent,
    TcoCalculatorComponent,
    TcoDevaluationAnalysisComponent

  ],
  entryComponents: [
    LightboxModalComponent,
    AddQuoteBuilderModalComponent,
    AddDuplicateModalComponent,
    CreateProductCSVComponent,
    AddProductQuoteMdlComponent,
    AddEmailccaddressesQuoteBuilderComponent,
    ChangeColNameMdlComponent,
    SelectFieldNameModalComponent,
    AddColumnModalComponent,
    EditProductQuoteModalComponent,
    SendQuoteInfoModalComponent,
    AddOnsModalComponent,
    AddSendInfoColComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe]
})
export class ToolsetModule { }
