import { EnvSpecific } from '../models/envspecific';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable()
export class EnvironmentSpecificService {

    public envSpecific: EnvSpecific;
    public envSpecificNull: EnvSpecific = null;
    private envSpecificSubject: BehaviorSubject<EnvSpecific> = new BehaviorSubject<EnvSpecific>(null);

    constructor(private http: HttpClient) {
    }

    public loadEnvironment() {
        // Only want to do this once - if root page is revisited, it calls this again.
        if (this.envSpecific === null || this.envSpecific === undefined) {

            var path: string = './assets/env/env-prod.json';
            if (window.location.hostname.indexOf('admindev.reactorcommand.com') != -1) {
                path = './assets/env/env-dev.json';
            }
            else if (window.location.hostname.indexOf('adminsup.reactorcommand.com') != -1) {
                path = './assets/env/env-sup.json';
            }
            else if (window.location.hostname.indexOf('bigdata.si') != -1) {
                path = './assets/env/env-bigdata-si-dev.json';
            }
            else if (window.location.hostname.indexOf('localhost') != -1 ||
                window.location.hostname.indexOf('127.0.0.1') != -1) {
                path = './assets/env/env-local.json';
            }
            return new Promise((resolve, reject) => {
                this.http.get<EnvSpecific>(path).toPromise().then(data => {
                    this.envSpecific = data;
                    resolve(this.envSpecific);
                });
            });
        }

        return Promise.resolve(this.envSpecificNull);
    }

    
}
