import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-i-frame',
  templateUrl: './i-frame.component.html',
  styleUrls: ['./i-frame.component.css']
})
export class IFrameComponent implements OnInit {

  width:any = '100%';
  height:any = '100%';
  src:string = 'https://uat-scania.command-app.com/';
  constructor() { }

  ngOnInit() {
  }

}
