import {Injectable} from '@angular/core';

@Injectable()
export class MedialibraryService {

    allTypes:Array<string> = ['all'];
    constructor() {}

    set assetType(type:string){
        this.allTypes = [];
        this.allTypes.push(type);
    }
    get assetType():string{
        return this.allTypes && this.allTypes.length >= 1 ? this.allTypes[0] : undefined;  
    }
    set assetTypes(types:Array<string>){
        this.allTypes = types;
    }
    get assetTypes():Array<string>{
        return this.allTypes;  
    }
    
}
