import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../../../services/organization.service';
import { SpinService } from '../../../services/spin.service';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { FormService } from '../../../services/form.service';
import { FormUserService } from '../../../services/form.user.service';
import { FormApiService } from '../../../services/form.api.service';
import { BaseModelFormIO, CmdFormModel } from '../../../models/CmdFormModel';
import { take } from 'rxjs/operators';
import { CmdOrganization } from '../../../models';
import { CmdEmailTemplate } from "../../../models/CmdEmailTemplate";
import { GuidService } from '../../../services/guid.service';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-form-edit',
    templateUrl: 'form-edit.component.html',
    styleUrls: ['form-edit.component.scss']
})
export class FormEditComponent implements OnInit {

    public isHide = true;
    public formModel: CmdFormModel;
    public editForm: BaseModelFormIO;
    private organization: CmdOrganization;
    private roles: Object;
    public url: string;
    public triggerRefresh = new EventEmitter();
    hasChanges: boolean = false;
    public emailTemplates: Array<CmdEmailTemplate>;
    public emailAddresses: Array<any> = [];
    constructor(private router: Router,
        private route: ActivatedRoute,
        private organizationService: OrganizationService,
        private spinService: SpinService,
        private guidService: GuidService,
        private alertService: StickyPopUpService,
        private formApiService: FormApiService,
        private formService: FormService,
        private userService: FormUserService) {
        route.params
            .pipe(take(1))
            .subscribe(params => {
                if (params['formId'] !== '0') {
                    this.formApiService.getFormById(params['formId'])
                        .pipe(take(1))
                        .subscribe(resp => {
                            this.formModel = resp;
                            this.emailAddresses = this.formModel.emailAddresses.map(em => { return { value: em, display: em } });
                            this.formService.getFormById(this.formModel.formId)
                                .pipe(take(1))
                                .subscribe(resp => {
                                    this.editForm = resp;
                                    this.url = '/api/form/' + this.editForm.path;
                                    this.isHide = false;
                                }, error => {
                                    this.alertService.error('Failed to load Form(Form): ' + error.error.message);
                                });

                        }, error => {
                            this.alertService.error('Failed to load Form(API): ' + error.error.message);
                        });

                    // NEW Form
                } else {
                    this.editForm = new BaseModelFormIO();
                    this.formModel = new CmdFormModel();
                    this.isHide = false;
                    this.hasChanges = true;
                }
            });
    }

    ngOnInit(): void {
        this.organization = this.organizationService.getCurrentOrganization();
        this.getRoles();
    }

    public save() {
        this.spinService.show();

        // if component has only number in 'key' field, we must add any chars (https://twistedpairlabs.atlassian.net/browse/CA-1676)
        if (this.editForm && this.editForm.components.length > 0) {
            this.editForm.components.forEach(comp => {
                if (comp.key.match(/^[0-9]+$/)) {
                    comp.key = 'key' + comp.key;
                }
            });
        }

        // add Access to forms
        //        if (this.editForm && this.editForm.submissionAccess.length === 0) {            
        //            this.editForm.submissionAccess = [];
        //            this.editForm.submissionAccess.push({ type: 'create_all', roles: [] });
        //            this.editForm.submissionAccess.push({ type: 'read_all', roles: [] });
        //            this.editForm.submissionAccess.push({ type: 'update_all', roles: [] });
        //            this.editForm.submissionAccess.push({ type: 'delete_all', roles: [] });
        //            this.editForm.submissionAccess.push({ type: 'create_own',
        //                roles: [this.roles['authenticated']['_id'], this.roles['anonymous']['_id']] });
        //            this.editForm.submissionAccess.push({ type: 'read_own', roles: [this.roles['authenticated']['_id']] });
        //            this.editForm.submissionAccess.push({ type: 'update_own', roles: [this.roles['authenticated']['_id']] });
        //            this.editForm.submissionAccess.push({ type: 'delete_own', roles: [this.roles['authenticated']['_id']] });
        //        }

        // add guid to form id
        if (!this.editForm._id) {
            const randomName = this.organization.id + '-' + this.guidService.guid();
            this.editForm.name = randomName;
            this.editForm.path = randomName;
        }




        if (this.editForm && 'components' in this.editForm) {

            // update form
            if (this.editForm._id && this.formModel.id) {

                let formModel = this.formModel;
                formModel.title = this.editForm.title;
                formModel.emailAddresses = this.emailAddresses.map(m => { return m.value });
                formModel.data = this.editForm as Object;

                // update Db
                this.formApiService.updateForm(formModel)
                    .pipe(take(1)).subscribe(resp => {

                        // update Form
                        this.formService.saveForm(this.editForm).pipe(take(1))
                            .subscribe(resp => {
                                this.hasChanges = false;
                                this.spinService.hide();
                                this.alertService.success('Form has been saved');
                                //this.close();
                            }, error => {
                                console.log(error);
                                this.spinService.hide();
                                this.alertService.error('Failed to Save Form(Form.Server): ' + error.error);
                            });
                    }, error => {
                        this.spinService.hide();
                        this.alertService.error('Failed to Save Form(API): ' + error.error.message);
                    });
            } else {

                // new form
                // create on Form server
                this.formService.saveForm(this.editForm).pipe(take(1)).subscribe(resp => {

                    // create on Db
                    let formModel = new CmdFormModel();
                    formModel.organizationId = this.organization.id;
                    formModel.title = this.editForm.title;
                    formModel.emailAddresses = this.emailAddresses.map(m => { return m.value });
                    formModel.formId = resp._id;
                    formModel.data = this.editForm as Object;
                    this.formApiService.createForm(formModel)
                        .pipe(take(1)).subscribe(resp => {
							this.formModel = resp;
                            this.hasChanges = false;
                            this.spinService.hide();
                            this.alertService.success('Form has been saved');
							this.router.navigate(['/form-builder', resp.id]);
                            //this.close();
                        }, error => {
                            this.alertService.error('Failed to Save Form(API): ' + error.error.message);

                            // if error - remove from Form server
                            this.deleteForm(resp._id).pipe(take(1))
                                .subscribe(resp => this.spinService.hide(), error => this.spinService.hide());
                        });

                }, error => {

                    console.log(error);
                    this.spinService.hide();
                    this.alertService.error('Failed to Save Form(Form.Server): ' + error.error);
                });
            }
        }
    }


    public close() {
        this.router.navigate(['/forms']);
    }

    public getJson() {
        return JSON.stringify(this.editForm, null, 4);
    }


    private getRoles(): void {
        this.userService.getRoles()
            .then(roles => this.roles = roles);
    }

    private deleteForm(formId: string): Observable<any> {
        return this.formService.deleteForm(formId);
    }


    public refreshClient() {

        this.triggerRefresh.emit({
            property: 'form',
            value: this.editForm
        });
    }

    public notifyChange() {
        this.hasChanges = true;
    }
}

