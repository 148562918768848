import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { CmdDevaluationAnalysisModel } from '../models/CmdDevaluationAnalysisModel';
import { AzureAuthholderService } from './azureauthholder.service';

@Injectable()
export class TcoCalculatorService {

  constructor(
        private httpClient:HttpClient, 
        private envService: EnvironmentSpecificService,
        private azureholder: AzureAuthholderService,
              ) { }


    public updateDevaluationAnalysis(devalCompetitor,orgId): Observable<any> {
      const headers = new HttpHeaders({
        //"Content-Type": "application/json",
        Authorization: "Bearer " + this.azureholder.getAccessToken(),
    });
  
    const requestOptions = {headers: headers };
      return this.httpClient.put(this.envService.envSpecific.baseServerUrl+"/api/toolsets/tco/"+orgId+"/devaluation",devalCompetitor,requestOptions);
    }

    public getDevaluationForOrgId(orgId:any): Observable<any> {
      const headers = new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });

      let httpParams=new HttpParams();
        // httpParams =httpParams.append("userId",userId.toString());
        httpParams =httpParams.append("orgId",orgId.toString());
        const requestOptions={params:httpParams, headers: headers};

      return this.httpClient
          .get<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets/tco/devaluation',requestOptions)
   }


   public updateTcoDefaultCurrencyInDevaluation(devaluationId:string, currencyKeyword:string): Observable<any> {
  
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.azureholder.getAccessToken(),
      });

    let httpParams=new HttpParams();
      httpParams =httpParams.append("defaultCurrency",currencyKeyword);
      const requestOptions={params:httpParams, headers: headers};

    return this.httpClient
        .put<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets/tco/devaluation/'+devaluationId+'?defaultCurrency='+currencyKeyword,requestOptions)
 }


//   public setDevaluationTemplate(): Observable<any> {
    
//     const headers = new HttpHeaders({
//         // "Content-Type": "application/json",
//         Authorization: "Bearer " + this.azureholder.getAccessToken(),
//     });
//     const requestOptions = {headers: headers };
//     return this.httpClient
//         .get<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets/tco/devaluation/template', requestOptions)
// }

  public getToolsetById(toolId: string): Observable<any> {
    const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.azureholder.getAccessToken(),
    });
    const requestOptions = {headers: headers };
    return this.httpClient
        .get<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets/' + toolId, requestOptions)
}

public getTCOSharingsById(toolId: string): Observable<any> {
  const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.azureholder.getAccessToken(),
  });
  const requestOptions = {headers: headers };
  return this.httpClient
      .get<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets/tco/sharings/' + toolId, requestOptions)
}

    public createToolset(toolsetObj: any): Observable<any> {
      const headers = new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.azureholder.getAccessToken(),
      });
      const requestOptions = {headers: headers };
      return this.httpClient
          .post<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets', toolsetObj, requestOptions);
  }
  
  public updateToolset(toolsetObj: any): Observable<any> {
    const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.azureholder.getAccessToken(),
    });
    const requestOptions = {headers: headers };
    return this.httpClient
        .put<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets', toolsetObj, requestOptions);
}
  
  public getTCOTemplate(): Observable<any> {
    const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.azureholder.getAccessToken(),
    });
    const requestOptions = {headers: headers };
    return this.httpClient
        .get<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets/tco/template' , requestOptions)
}


}
