import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { SearchModel } from "../models/SearchModel";
import { EnvironmentSpecificService } from "./environmentspecific.service";
import { AzureAuthholderService } from './azureauthholder.service';

@Injectable()
export class EditQuoteBuilderService {
    public isProductCreated = new Subject<any>();

    constructor(
        private azureholder: AzureAuthholderService,
        private httpClient: HttpClient,
        private envService: EnvironmentSpecificService
    ) { }
    getEditQouteList(orgId: string, fillter: SearchModel = new SearchModel()): Observable<any> {
        
        let httpParams = new HttpParams().set('id', "1");
        httpParams = httpParams.append('sortBy', fillter.page.sortBy.toString());
        httpParams = httpParams.append('sortOrder', fillter.page.sortOrder.toString());
        httpParams = httpParams.append('pageSize', fillter.page.pageSize.toString());
        httpParams = httpParams.append('page', fillter.page.page.toString());
        return this.httpClient
            .get<any>(this.envService.envSpecific.baseServerUrl + '/api/quotebuilder/' + orgId + '/search/products?sortBy=created&sortOrder=DESC&pageSize=10&page=1', {});
    }
    getMethod(url): Observable<any> {
        
        return this.httpClient
            .get<any>(this.envService.envSpecific.baseServerUrl + url, {});
    }
    getAllEditQouteList(qouteId, orgId): Observable<any> {
        return this.httpClient
            .get<any>(this.envService.envSpecific.baseServerUrl + '/api/quotebuilder/' + orgId + '/search/products?sortBy=created&sortOrder=DESC&pageSize=10&page=1&id=' + qouteId, {});
    }
    deleteQuoteData(orgId: string, quoteId: string, pId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .delete<any>(this.envService.envSpecific.baseServerUrl + '/api/quotebuilder/' + orgId + '/' + pId + '/product/' + quoteId, requestOptions);
    }

    postUploadCsv(orgId: string, body, pid): Observable<any> {
        return this.httpClient
            .post<any>(this.envService.envSpecific.baseServerUrl + '/api/quotebuilder/' + orgId + '/update/' + pid, body);
    }
    postMethod(url, body): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .post<any>(this.envService.envSpecific.baseServerUrl + url, body, requestOptions);
    }
    putMethod(url, body): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .put<any>(this.envService.envSpecific.baseServerUrl + url, body, requestOptions);
    }
    setStatus(id: string, status: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        let httpParams = new HttpParams();
        httpParams = httpParams.append('status', status);
        return this.httpClient.put<any>(this.envService.envSpecific.baseServerUrl + '/api/salesquotes/' + id, httpParams, requestOptions);
    }
}