import { CmdAsset } from './CmdAsset';
import { CmdHorizontalImageSliderCardsItem } from './CmdHorizontalImageSliderCardsitems';
import { CmdWidget } from './CmdWidget';

export class CmdHorizontalImageSliderCards extends CmdWidget {
    items: Array<CmdHorizontalImageSliderCardsItem>;
    thumbnail: CmdAsset;
    cardColor: string;
    itemsToDisplay: string;
    opacityValue: number
    cardBaseColor: string;
    constructor(id: string) {
        super(id);
        this.cardColor = '#00000080'
        this.cardBaseColor = "#000000";
        this.type = "HorizontalImageSliderCards";
        this.opacityValue = 0.5;
        this.items = new Array<CmdHorizontalImageSliderCardsItem>();
         }
}