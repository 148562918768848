import { CmdAsset } from '../../models';
import { LightboxModalComponent } from '../lightbox/lightbox-modal.component';
import { Directive, Input, ElementRef, Renderer } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Directive({
    selector: '[appOpenLightbox]'
})
export class LightboxopenerDirective {

    private lightboxModalRef: BsModalRef;

    constructor(private modalService: BsModalService, elementRef: ElementRef, renderer: Renderer) {
        renderer.listen(elementRef.nativeElement, 'click', (event) => {
            this.open(this.lightboxAsset);
        });
    }

    @Input() lightboxAsset: CmdAsset;

    public open(theAsset) {
         // added video play support. 03 Nov 2023
        if (theAsset.type == 'image' || 'profile_picture' || 'org_logo' || 'video') {
            this.lightboxModalRef = this.modalService.show(LightboxModalComponent, { class: 'modal-lg asset-modal' });
            // fixed collection item click popup image not coming issue. 12 jan 2022
            this.lightboxModalRef.content.url = theAsset.url || theAsset.asset.url; // fixed code for image not coming in popup.
            this.lightboxModalRef.content.title = theAsset.title || theAsset.asset.title; // fixed code for title not coming in popup.
            this.lightboxModalRef.content.originalFileName = theAsset.originalFileName || theAsset.asset.originalFileName; // fixed code for original file name.
            this.lightboxModalRef.content.sizeInBytes = theAsset.sizeInBytes;
            this.lightboxModalRef.content.type = theAsset.type;
            this.lightboxModalRef.content.mimeType = theAsset.mimeType;
            this.lightboxModalRef.content.onClose.subscribe(result => {});
        }
    }
}
