import { CmdBaseModel, CmdScene, CmdAsset } from './';
import { CmdRightMenuItem } from "./CmdRightMenuItem";
export class CmdApplication extends CmdBaseModel {
    title?: string;
    created?: Date;
    description?: string;
    defaultSceneSlug?: string;
    defaultScene?: CmdScene;
    scenes?: Array<CmdScene>;
    rightMenuItems?: Array<CmdRightMenuItem>;
    customCss?: string;
    themeId?: string;
    kioskVideo?: CmdAsset;
    kioskVideoTimeoutSec: number;
    version?: string;
    hasUnpublishedChanges?: boolean;
    hasBeenPublished?: boolean;
    desktopLayoutMode?: string = 'none';
    tabletLayoutMode?: string = 'none';
    phoneLayoutMode?: string = 'none';
    analyticsId?: string = '';
    quoteBuilderId?: string = 'none';
    whitelabelApp?: boolean;
    contentSize?: number;
    isOld:boolean;
    targetLanguage: string;
    appThumbnail?: CmdAsset;
}