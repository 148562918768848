import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { Subject ,  Observable } from 'rxjs';
import { SearchModel } from '../models/SearchModel';
import { CacheService } from './cache.service';
import { AzureAuthholderService } from "../services/azureauthholder.service";



@Injectable()
export class ToolsetsService {
    private currentOrganization = new Subject<any>();

    constructor(
        private azureholder: AzureAuthholderService,
        private httpClient: HttpClient,
        private envService: EnvironmentSpecificService,
        private cacheService: CacheService) {
    }


    public getToolsetsForOrganization(orgId: string, fillter: SearchModel, type_name: string = ''): Promise<any> {
        const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let httpParams = new HttpParams();
        httpParams = httpParams.append('orgId', orgId.toString());
        httpParams = httpParams.append('sortBy', fillter.page.sortBy.toString());
        httpParams = httpParams.append('sortOrder', fillter.page.sortOrder.toString());
        httpParams = httpParams.append('pageSize', fillter.page.pageSize.toString());
        httpParams = httpParams.append('page', fillter.page.page.toString());
        httpParams = fillter.searchText.length > 0 ? httpParams.append('searchText', fillter.searchText) : httpParams;
        httpParams = fillter.searchTags.length > 0 ? httpParams.append('tags', fillter.searchTags) : httpParams;
        httpParams = type_name.length > 0 ? httpParams.append('type', type_name) : httpParams;
        let key = httpParams.toString();
        const requestOptions = {params: httpParams, headers: headers };
        return new Promise((resolve, reject) => {
            this.httpClient
                .get<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets/search', requestOptions).toPromise().then(rows => {
                    resolve(rows);
                });
        });
    }

    public getToolsetById(toolId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .get<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets/' + toolId, requestOptions)
    }

    public createToolset(toolset: any): Observable<any> {
        const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .post<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets', toolset, requestOptions);
    }

    public updateCcEmailAddresses(orgId: String, toolsetId: String): Observable<any> {
        const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .put<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets', orgId + 'updateCcEmail' + toolsetId, requestOptions);
    }

    public updateToolset(toolset: any): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .put<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets', toolset, requestOptions);
    }

    public deleteToolset(toolId: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .delete<any>(this.envService.envSpecific.baseServerUrl + '/api/toolsets/' + toolId, requestOptions)
    }

    // Get Email templates for toolset 'QUOTE_BUILDER'
    public getEmailsTemplates(orgId: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient
            .get<any>(this.envService.envSpecific.baseServerUrl + '/api/emails/templates?orgId=' + orgId + '&type=QUOTE_BUILDER&page=1&pageSize=100', requestOptions)
    }
}
