import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Page } from '../../models/page';
import { OrganizationService } from '../../services/organization.service';
import { SpinService } from '../../services/spin.service';
import { ProductsService } from '../../services/products.service';
import { SearchModel } from '../../models/SearchModel';
import { CmdOrganization } from '../../models';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { ProductModel } from '../../models/CmdProduct';


@Component({
    selector: 'app-products-library',
    templateUrl: 'productslibrary.component.html',
    styleUrls: ['productslibrary.component.scss']
})
export class ProductslibraryComponent implements OnInit, OnDestroy {

    public onClose: Subject<Array<any>>;
    public sel_products: Array<ProductModel> = [];
    public allowProductsWithoutCompetitors: boolean = false;
    public allowProductsWithoutPrice: boolean = true;
    destroy$: Subject<boolean> = new Subject<boolean>();
    uploadModalRef: BsModalRef;
    productsSearch: SearchModel;
    searchTerm$ = new Subject<string>();
    searchTermTags$ = new Subject<string>();
    selProduct: string = '1';
    currentOrganization: CmdOrganization;
    selectAll: boolean = false;

    constructor(public bsModalRef: BsModalRef,
        private organizationService: OrganizationService,
        private spinService: SpinService,
        private alertService: StickyPopUpService,
        private productsService: ProductsService) {
        this.resetPagination();
    }
    public doSelectAll() {
        this.selectAll = !this.selectAll;
        this.productsSearch.results.contents.forEach(prod => {
            prod.selected = this.selectAll;
            if (prod.selected && this.isInList(prod.id, this.sel_products) == -1) {
                this.sel_products.push(prod);
            }
            else if (!prod.selected && this.isInList(prod.id, this.sel_products) != -1) {
                this.sel_products.splice(this.isInList(prod.id, this.sel_products), 1);
            }
        });

    }

    isInList(id: string, list: Array<ProductModel>) {
        for (var i = 0; i < list.length; i++) {
            if (list[i].id == id) {
                return i;
            }
        }
        return -1;
    }

    public ngOnInit(): void {
        this.sel_products = [];
        this.onClose = new Subject();
        this.searchTerm$.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchText => {
                this.productsSearch.searchText = searchText;
                this.productsSearch.page.page = 1;
                this.doSearch();
            });
        this.searchTermTags$
            .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchTag => {
                this.productsSearch.searchTags = searchTag;
                this.productsSearch.page.page = 1;
                this.doSearch();
            });

        this.currentOrganization = this.organizationService.getCurrentOrganization();
        this.doSearch();
    }

    public onConfirm(): void {


        if (!this.allowProductsWithoutPrice && !this.checkPriceInProduct()) {

            this.alertService.error('Some of products does not have price!');

        } else if (!this.allowProductsWithoutCompetitors && !this.checkCompetitorsCount()) {

            this.alertService.error('Some of products does not have competitors!');

        } else {
            this.onClose.next(this.sel_products);
            this.bsModalRef.hide();
        }
    }

    public onCancel(): void {
        this.bsModalRef.hide();
        this.onClose.next(undefined);
    }

    private doSearch() {
        this.spinService.show();
        this.productsService.getProductsForOrganization(
            this.organizationService.getCurrentOrganizationId(), this.productsSearch, true)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.productsSearch.results = data;
                this.setCheckProducts();
                this.spinService.hide();
            },
                error => {
                    this.spinService.hide();
                });
    }

    private setCheckProducts() {
        if (this.productsSearch.results.contents.length) {
            this.productsSearch.results.contents.forEach(prod => {
                prod.selected = this.getProd(prod) || false;
            });
        }
    }

    public selProd(product: ProductModel) {
        if (this.getProd(product)) {
            this.sel_products = this.sel_products.filter(p => p.id !== product.id)
            product.selected = false;
        } else { // if not found - add it
            this.sel_products.push(product);
            product.selected = true;
            if (!this.allowProductsWithoutPrice && product.unitPrice === null && product.rentPrice === null)
                this.alertService.error('This product doesn\'t have price!');

            if (!this.allowProductsWithoutCompetitors && product.competitorsCount === 0)
                this.alertService.error('This product doesn\'t have competitors!');
        }
    }
    private getProd(product) {
        let result = null;
        this.sel_products.some(function (p) {
            if (p.id === product.id) {
                result = product
                return true;
            }
        });
        return result;
    }

    //#region pagination
    public pageChanged($event) {
        this.productsSearch.page.page = $event.page;
        this.productsSearch.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }

    public changePageSize() {
        this.doSearch();
    }

    public sortProducts(sortBy) {
        this.productsSearch.page.page = 1;
        if (this.productsSearch.page.sortBy == sortBy) {
            if (this.productsSearch.page.sortOrder == "ASC") {
                this.productsSearch.page.sortOrder = "DESC";
            } else {
                this.productsSearch.page.sortOrder = "ASC";
            }
        } else {
            this.productsSearch.page.sortBy = sortBy;
            this.productsSearch.page.sortOrder = "ASC"
        }
        this.doSearch();
    }

    private resetPagination() {
        this.productsSearch = new SearchModel();
        this.productsSearch.page = new Page();
    }
    //#endregion pagination

    checkCompetitorsCount(): boolean {
        let result = true;
        if (!this.allowProductsWithoutCompetitors) {
            this.sel_products.forEach(p => {
                if (p.competitorsCount === 0)
                    result = false;
            });
        }
        return result;
    }

    checkPriceInProduct(): boolean {
        let result = true;
        if (!this.allowProductsWithoutPrice) {
            this.sel_products.forEach(p => {

                if (p.unitPrice === null && p.rentPrice === null)
                    result = false;
            });
        }
        return result;
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
