import {ViewChild, Component, OnInit, Input, Output, EventEmitter, ElementRef, Renderer} from '@angular/core';
import { NgModel } from '@angular/forms';
import {Subject} from 'rxjs';
import {TagInputDropdown} from 'ngx-chips'; 

@Component({
    selector: 'app-trackselector',
    templateUrl: './trackselector.component.html',
    styleUrls: ['./trackselector.component.css']
})
export class TrackselectorComponent implements OnInit {
    dropdownList:Array<string> = [];
    selectedItems:Array<any> = [];
    dropdownSettings = {};
    @Output()
    handleChange:EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    constructor(public renderer: Renderer) {}

    @Input() set selectedTracks(data:Array<string>){
        this.selectedItems = [];
        data.forEach(item => {
            this.selectedItems.push({
                display:item,
                value:item
            });
        });
    }
    @Input() set tracks(data: Array<string>) {
        this.dropdownList = data;
    }
    
    ngOnInit() {
        this.dropdownSettings = { 
            singleSelection: false,
            text: "Select tracks",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true
        };      
    }
    
    onSelected(item){
        var items:Array<string> = new Array<string>();
        this.selectedItems.forEach(theItem => {
            items.push(theItem["value"]);
        });
     this.handleChange.emit(items);
    }
}
