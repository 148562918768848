import { CmdSlide } from '../../models';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-slidedisplay',
	templateUrl: './slide-display.component.html',
	styleUrls: ['./slide-display.component.css']
})
export class SlidedisplayComponent implements OnInit {

	theSlide: CmdSlide;
	theLabel: string = "Remove";
	theIcon: string = "trash";
	@Output() onRemove: EventEmitter<CmdSlide> = new EventEmitter<CmdSlide>();
	@Output() onClick: EventEmitter<CmdSlide> = new EventEmitter<CmdSlide>();
	constructor() { }

	ngOnInit() {
	}

	@Input() set slide(ast: CmdSlide) {
		this.theSlide = ast;
	}

	handleClick() {
		this.onClick.emit(this.theSlide);
	}

	handleRemove() {
		this.onRemove.emit(this.theSlide);
	}
	openPdf(assets: any) {
		window.open(assets);
	}
}
