import { Input } from "@angular/core";
import { Subject } from "rxjs";
import { WidgetService } from "../../services/widget.service";

export class BaseActionTypeSelectorComponent {

    @Input() public widgetData: any = {
        actionData: {}
    }
    @Input() public form: any;
    @Input() public orgId: string;
    @Input() public appId: string;
    public isValid: Subject<boolean> = new Subject<boolean>();
    public rows: Array<any> = [];

    constructor(public widgetService: WidgetService){
        
    }

    validateInput() {
        let data = {
            name: 'buttonWidgetPartialForm',
            formData: this.form
        }
        this.widgetService.widgetsToValidate$.next([{
            id: this.widgetData.id,
            data: [data]
        }]);
    }

    setSlugTitle(type, slug) {
        let theVal = this.rows.filter(s => s.id == this.widgetData.actionData.slug)[0];
        this.widgetData.actionData.displayTitle = theVal.title;
    }
}
