import { CmdBaseModel } from './CmdBaseModel';
export class CmdWidget extends CmdBaseModel {
    themeClassName: string;
    cssClasses: string;
    actionType: string;
    actionData: any;
    type: string;
    displayName: string;
    cardColor?: string;
    widgetId: string;
    useImage: boolean;
    corners?: string
    headerText?: string;
    description?: string;
    constructor(id: string) {
        super(id);
        this.actionType = "NONE";
        this.actionData = {};
    }
   
    validate(): boolean {
        return (this.id != undefined && this.type != undefined);
    }
}
