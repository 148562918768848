import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-product-quote-modal',
  templateUrl: './edit-product-quote-modal.component.html',
  styleUrls: ['./edit-product-quote-modal.component.css']
})
export class EditProductQuoteModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
