import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AddQuoteBuilderModalComponent } from './add-quote-builder-modal/add-quote-builder-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../services/organization.service';
import { QuoteBuilderService } from '../../../services/quotebuilder.service';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { AddDuplicateModalComponent } from './add-quote-builder-modal/add-duplicate-modal.component';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { EditQuoteBuilderService } from '../../../services/editquotebuilder';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-contentquotebuilder',
  templateUrl: './contentquotebuilder.component.html',
  styleUrls: ['./contentquotebuilder.component.css']
})
export class ContentquotebuilderComponent implements OnInit {
  @ViewChild('content', { static: false }) content: ElementRef;
  addQuoteBuildeModalRef: BsModalRef;
  cofirmModalRef: BsModalRef;
  addDuplicateModalRef: BsModalRef;
  bsModalRef: BsModalRef;


  DeletePopUpModalRef: BsModalRef;

  salesquote = [{
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",

  },
  {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  },
  {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }, {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }, {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }
    , {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }
    , {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }
    , {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }
    , {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }

  ];
  public originalData: any;
  public quoteTableBool: boolean = false;
  message: string;
  config: any;

  title1 = 'pricelistsearchFilter';
  searchPriceList = '';
  pricelist = [
    'ZA  USpricelist',
    'Test pricelist',
  ]
  body: any;
  orgId: string;
  vId: string;
  title: string;
  toolsetTitle: string;
  recipientName: string;
  searchText: any;
  cont
  public p: number = 1;
  public quotePageSize: number = 10;
  public salesPageSize: number = 10;
  public c: number = 1;
  public loaderBool = false;
  contentTitle: string;
  deleteConfirm: string;
  public salesData: any;
  public salesListData: any = [];
  public personDd: string = "";
  public dateRangeBool: boolean = false;
  public dateRangeString: string = 'Select Date Range';
  public startDate: string = "";
  public endDate: string = "";
  public dateErrBool: boolean = false;
  public salesErrBool: boolean = false;
  public recipientVal: string = "";
  public checkBoxArr: any = [];
  public cvsfileurl: any = 'assets/template.csv';
  currenctPageNumber:number = 1;
  constructor(
    private modalService: BsModalService,
    public organizationService: OrganizationService,
    private quoteBuilderService: QuoteBuilderService,
    private stickyPopUp: StickyPopUpService,
    private route: Router,
    private apiService: EditQuoteBuilderService, private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.getQuoteList(1, this.quotePageSize);
    let today = new Date();
    let stDate = '01/15/2019';
    let dateFrom = this.organizationService.getDate(today);
    this.dateRangeString = (this.datediff(this.parseDate(stDate), this.parseDate(dateFrom)) + 1) + " days (" + stDate + " - " + dateFrom + ")";
    this.getSalesData(stDate, dateFrom, 1, this.salesPageSize);
    // this.quoteBuilderService.getIsCreated()
    //   .subscribe(
    //     data => {
    //       if (data) {
    //         this.getQuoteList();
    //       }
    //     }
    //   )

  }

  getQuoteList(pageNo, totalSize) {
    const org = this.organizationService.getCurrentOrganization();
    this.quoteBuilderService.getQouteList(org.id, pageNo, totalSize)
      .subscribe(
        data => {
          if (data) {
            this.originalData = data;
            if (this.originalData.contents.length) {
              this.quoteTableBool = true;
            } else {
              this.quoteTableBool = false;
            }
            this.originalData.contents.map((item, index) => {
              if (index === 0) {
                this.contentTitle = item.title;
                this.deleteConfirm = this.originalData.contents[index].title;
                localStorage.setItem('title', this.contentTitle)
              }
            })
          }
        }
      )
  }

  deleteInvoice(invId) {
    this.bsModalRef = this.modalService.show(ConfirmModalComponent, { class: 'modal-sm' });

    const org = this.organizationService.getCurrentOrganization();

    this.bsModalRef.content.message = `Are you sure you want to delete ${invId.title} ? `;
    this.bsModalRef.content.onClose

      .subscribe(result => {
        if (result === true) {
          this.quoteBuilderService.deleteQuoteList(org.id, invId.id)


            .subscribe(
              data => {
                if (data) {
                  this.stickyPopUp.success(`${invId.title} has deleted.`);
                  this.getQuoteList(1, this.quotePageSize)
                }
                //  this.message="pricelist deleted successfully";
              }, err => {
                this.stickyPopUp.error('Failed to delete.');
                console.log(err, "error");
              }
            )
        }
      });
  }

  public addQuoteZIP() {
    this.addQuoteBuildeModalRef = this.modalService.show(AddQuoteBuilderModalComponent, { ignoreBackdropClick: true, keyboard: false, class: 'new-quote-builder-modal' });
    this.addQuoteBuildeModalRef.content.event.subscribe(data => {
      this.getQuoteList(1, this.quotePageSize);
    });
  }

  downloadTemplate() {
    this.quoteBuilderService.getTemplate().subscribe((data) => {
      var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      saveAs(blob, 'Template.csv')
    }), err => {
      console.log("error", err)
    }

  }

  public addDuplicate(selectedPriceList: any) {
    this.addDuplicateModalRef = this.modalService.show(AddDuplicateModalComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { invData: selectedPriceList }, class: 'modal-sm' });
    this.addDuplicateModalRef.content.title = selectedPriceList.title;
    this.addDuplicateModalRef.content.event.subscribe(data => {
      this.getQuoteList(1, this.quotePageSize);
    });
  }
  Search() {
    if (this.title == "") {
      this.ngOnInit();
    }
    else {
      this.originalData.contents = this.originalData.contents.filter(res => {
        return res.title.toLocaleLowerCase().match(this.title.toLocaleLowerCase());
      })
    }
  }

  Searchs() {
    if (this.toolsetTitle == "") {
      this.ngOnInit();
    }
    else {
      this.salesData.salesAppData.contents = this.salesData.salesAppData.contents.filter(res => {
        return res.toolsetTitle.toLocaleLowerCase().match(this.toolsetTitle.toLocaleLowerCase());
      })
    }
  }
  public pageChanged($event) {
    this.p = $event.page;
    this.getQuoteList(this.p, this.quotePageSize);
  }
  public changePageSize() {
    this.getQuoteList(this.p, this.quotePageSize);
  }
  public changeSalesPageSize() {
    let today = new Date();
    let stDate = '01/15/2019';
    let dateFrom = this.organizationService.getDate(today);
    this.getSalesData(stDate, dateFrom, this.c, this.salesPageSize);
  }
  public salesPageChanged($event) {
    if(this.currenctPageNumber !== $event.page){
      this.currenctPageNumber = $event.page;
      this.c = $event.page;
      let today = new Date();
      let stDate = '01/15/2019';
      let dateFrom = this.organizationService.getDate(today);
      this.getSalesData(stDate, dateFrom, this.c, this.salesPageSize);
    }
  }
  updateDuplicate(id) {
    this.route.navigate(['contentquotebuilder', id])
  }
  public filterByPerson(val) {
    this.salesListData = this.salesData.salesAppData.contents.filter(res => {
      return res.salesperson.displayName.toLocaleLowerCase().match(val.toLocaleLowerCase());
    })
  }
  public filterByRecipients(val) {
    this.salesListData = this.salesData.salesAppData.contents.filter(res => {
      if (res.recipientName)
        return res.recipientName.toLocaleLowerCase().match(val.toLocaleLowerCase());
    })
    if (!this.salesListData.length) {
      this.salesListData = this.salesData.salesAppData.contents.filter(res => {
        if (res.salesperson.displayName)
          return res.salesperson.displayName.toLocaleLowerCase().match(val.toLocaleLowerCase());
      })
    }
  }
  public filterData() {
    if ((this.startDate == "") || (this.endDate == "")) {
      this.dateErrBool = true;
      return;
    }
    let stDate = this.organizationService.getDate(this.startDate);
    let dateFrom = this.organizationService.getDate(this.endDate);
    this.dateErrBool = false;
    this.dateRangeString = (this.datediff(this.parseDate(stDate), this.parseDate(dateFrom)) + 1) + " days (" + stDate + "-" + dateFrom + ")";
    this.getSalesData(stDate, dateFrom, this.c, this.salesPageSize);
    this.closeDateRange();
  }
  parseDate(str) {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }
  datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  public goToReport(id, title) {
    sessionStorage.setItem("quoteTitle", title);
    this.route.navigate(['/contentquotebuilder/report/' + id]);
  }
  public changeCheckBoxStatus(status) {
    this.checkBoxArr.forEach(element => {
      element.status = status;
    });
  }
  public getSalesData(startDate, endDate, pageNo, pageSize) {
    this.loaderBool = true;
    this.salesListData = [];
    // this.salesData = [];
    this.salesErrBool = false;
    this.apiService.getMethod("/api/quotebuilder/sales?page=" + pageNo + "&pageSize=" + pageSize + "&sortBy=created&sortOrder=DESC&orgId=" + this.organizationService.getCurrentOrganization().id + "&search=&types=theme&userId=" + this.organizationService.getCurrentUser() + "&startDate=" + startDate + "&endDate=" + endDate + "&toolsetId=''").subscribe(
      data => {
        this.salesData = data;
        this.loaderBool = false;
        this.salesListData = this.salesData.salesAppData.contents;
        for (let i = 0; i < this.salesData.salesAppData.totalItems; i++) {
          this.checkBoxArr.push({ status: true });
        }
        if (!this.salesListData.length) {
          this.salesErrBool = true;
        } else {
          this.salesErrBool = false;
        }
      },
      err => {
        this.salesErrBool = false;
        this.loaderBool = false;
        console.log(err);
      }
    )
  }
  public updateSalesStatus(status, obj) {
    this.apiService.setStatus(obj.id, status).subscribe(
      data => {
        this.stickyPopUp.success("Sales Quotes has been updated");
      },
      err => {
        this.stickyPopUp.error("Some problem occured");
        console.log(err);
      }
    )


  }
  public createPdf() {
    let val = this.checkBoxArr.findIndex(x => x.status == true) + 1;
    if (!val) {
      this.stickyPopUp.error("Plese check any record to export");
      return;
    }
    let head = [['SALES PERSON', 'STATUS', 'RECIPIENT', 'DATE SENT', 'PROJECT START', 'TOTAL']]
    let data = [[]];
    let index = 0;
    this.salesListData.forEach(element => {
      if (this.checkBoxArr[index].status) {
        let arr = [element.salesperson.displayName, element.status, element.recipientName || "NA", this.datePipe.transform(element.created, 'short'), element.projectedStartDate || "NA", element.total || 0];
        data.push(arr);
      }
      ++index;
    });
    var doc = new jsPDF();

    doc.setFontSize(18);
    doc.text('Sales Quote Data', 11, 8);
    doc.setFontSize(11);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: head,
      body: data,
      theme: 'plain'
    })
    // doc.output('dataurlnewwindow');
    doc.save('salesData.pdf');
  }
  public createExcel() {
    let val = this.checkBoxArr.findIndex(x => x.status == true) + 1;
    if (!val) {
      this.stickyPopUp.error("Plese check any record to export");
      return;
    }
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('ProductData');
    worksheet.columns = [
      { header: 'SALESPERSON', key: 'displayName' },
      { header: 'STATUS', key: 'status' },
      { header: 'RECIPIENT', key: 'recipientName' },
      { header: 'DATE SENT', key: 'created' },
      { header: 'PROJECT START', key: 'projectedStartDate' },
      { header: 'TOTAL', key: 'total' },
    ];
    let index = 0;
    this.salesListData.forEach(e => {
      if (this.checkBoxArr[index].status)
        worksheet.addRow({ displayName: e.salesperson.displayName, status: e.status, recipientName: e.recipientName || "NA", created: this.datePipe.transform(e.created, 'short'), projectedStartDate: e.projectedStartDate, total: e.total || 0 }, "n");
      ++index;
    });
    worksheet.columns.forEach(column => {
      column.width = column.header.length < 12 ? 12 : column.header.length
    })
    // this.AdjustColumnWidth(worksheet)
    workbook.csv.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'text/csv' });
      fs.saveAs(blob, 'salesData.csv');
    })
  }
  public changeCheckboxSt(index, status) {
    this.checkBoxArr[index].status = status;
  }
  public showDateRange() {
    if (this.dateRangeBool) {
      document.getElementById('date-picker').style.display = 'none';
      this.dateRangeBool = false;
    } else {
      this.dateRangeBool = true;
      document.getElementById('date-picker').style.display = 'block';
    }
  }
  public closeDateRange() {
    document.getElementById('date-picker').style.display = 'none';
    this.dateRangeBool = false;
  }
  public viewAllData() {
    let today = new Date();
    let stDate = '01/15/2019';
    let dateFrom = this.organizationService.getDate(today);
    let dateRangeStringTemp = (this.datediff(this.parseDate(stDate), this.parseDate(dateFrom)) + 1) + " days (" + stDate + " - " + dateFrom + ")";
    if (this.dateRangeString == dateRangeStringTemp) {
      this.personDd = "";
      this.recipientVal = "";
      this.salesListData = this.salesData.salesAppData.contents;
    } else {
      this.dateRangeString = dateRangeStringTemp;
      this.getSalesData(stDate, dateFrom, this.c, this.salesPageSize);
    }
  }
}