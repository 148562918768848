/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
    //local
    // auth: {
    //     clientId: 'a1c04b43-bf0b-42a3-9c40-aefa5db0e46a', // This is the ONLY mandatory field that you need to supply.
    //     authority: 'https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac', // Defaults to "https://login.microsoftonline.com/common"
    //     redirectUri: 'http://localhost:4200/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    //     postLogoutRedirectUri: 'http://localhost:4200/', // Indicates the page to navigate after logout.
    //     navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    // },
    
    //UAT
    // auth: {
    //     clientId: 'a1c04b43-bf0b-42a3-9c40-aefa5db0e46a', // This is the ONLY mandatory field that you need to supply.
    //     authority: 'https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac', // Defaults to "https://login.microsoftonline.com/common"
    //     redirectUri: 'https://uat-scania.command-app.com/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    //     postLogoutRedirectUri: 'https://uat-scania.command-app.com/', // Indicates the page to navigate after logout.
    //     navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    // },

    //Prod
    auth: {  
        clientId: 'a1c04b43-bf0b-42a3-9c40-aefa5db0e46a', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: 'https://scania.command-app.com/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: 'https://scania.command-app.com/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    },
    
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
              //  console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
    
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};