import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-actionbutton',
    templateUrl: './actionbutton.component.html',
    styleUrls: ['./actionbutton.component.css']
})
export class ActionbuttonComponent implements OnInit {

    public theAction: string;
    public theLabel: string;
    public theIcon: string;
    public theType: string = "standard";
    public theDisabled: boolean;

    public click: Subject<any> = new Subject();

    @Input() set label(data: string) {
        this.theLabel = data;
    }
    @Input() set disabled(data: boolean) {
        this.theDisabled = data;
    }
    @Input() set icon(data: string) {
        this.theIcon = data;
    }
    @Input() set type(data: string) {
        this.theType = data;
        switch (this.theType) {
            case "save":
                this.label = "Save";
                this.icon = "floppy-o";
                break;
            case "cancel":
                this.label = "Cancel";
                this.icon = "cancel";
                break;
            case "publish":
                this.label = "Publish";
                this.icon = "rocket";
                break;
            case "remove":
                this.label = "Remove";
                this.icon = "trash";
                break;
            case "archive":
                this.label = "Archive";
                this.icon = "archive";
                break;
            case "replace":
                this.label = "Replace";
                this.icon = "upload";
                break;
            //adding duplicate action button icon, Date: 03 Nov 2023, DeepakRajak
            case "duplicate":
                this.label = "Duplicate";
                this.icon = "clone";
                break;
        }
    }
    constructor() { }

    ngOnInit() {
    }

    doAction() {
        this.click.next("go");
    }
}
