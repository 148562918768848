import { CmdOrganization } from '../../models';
import {OrganizationService} from '../../services/organization.service';
import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    currentOrg:any;
    constructor(private orgService: OrganizationService) {
        
    }

    ngOnInit() {
        this.currentOrg = this.orgService.getCurrentOrganization();
        this.orgService.organizationUpdate.subscribe(newOrg => {
            if(newOrg && this.currentOrg && newOrg.id == this.currentOrg.id){
                this.currentOrg = newOrg;
            }
        });
        this.orgService.currentOrganization.subscribe(newOrg => {
            this.currentOrg = newOrg;
        });
    }
}
