import { CmdWidget } from './CmdWidget';
export class CmdWebView extends CmdWidget {
    
    static expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    
    url: string;
     constructor(id:string){
        super(id);
        this.type = "WebView";
    }
}