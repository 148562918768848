import { Injectable } from '@angular/core';
import { EnvironmentSpecificService } from "./environmentspecific.service";
import { OrganizationService } from "./organization.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AzureAuthholderService } from './azureauthholder.service';

@Injectable()
export class FormUserService {

    private headers = new Headers({
        'Content-Type': 'application/json'
    });

    constructor(private http: HttpClient,
        private azureholder: AzureAuthholderService,
        private envService: EnvironmentSpecificService,
        private orgService: OrganizationService) { }

    getUser(): Promise<Object> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http
            .get(this.envService.envSpecific.baseServerUrl + '/api/form/currentuser?organizationId=' + this.orgService.getCurrentOrganizationId(), requestOptions)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getRoles(): Promise<Object> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        var params: HttpParams = new HttpParams();
        params.append("organizationId", this.orgService.getCurrentOrganizationId())
        const requestOptions = {params: params, headers: headers };
        return this.http
            .get(this.envService.envSpecific.baseServerUrl + '/api/form/access?organizationId=' + this.orgService.getCurrentOrganizationId(), requestOptions)
            .toPromise()
            .then(response => response['roles'] as Object)
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
