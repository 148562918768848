export class CmdRightMenuItem {
  id:string;
  cssClasses: string;
  displayName: string;
  actionType: string;
  actionData: any;
  constructor() {
    this.actionType = "";
    this.displayName = "";
    this.cssClasses = "";
    this.actionData = {
      slug: '',
      templateId: '',
      emailFormTitle: '',
      attachmentId: '',
      attachmentTitle: ''
    }
  }
}