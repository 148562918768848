import {CmdAsset} from './CmdAsset';
import {CmdHorizontalImageSliderItem} from './CmdHorizontalImageSliderItem';
import {CmdWidget} from './CmdWidget';

export class CmdHorizontalImageSlider extends CmdWidget {
    items: Array<CmdHorizontalImageSliderItem>;
    thumbnail: CmdAsset;
    itemsToDisplay:string;

    constructor(id: string) {
        super(id);
        this.type = "HorizontalImageSlider";
        this.items = new Array<CmdHorizontalImageSliderItem>();
    }
}