import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'lightbox-modal',
    templateUrl: './lightbox-modal.component.html',
    styleUrls: ['./lightbox-modal.component.css']
})
export class LightboxModalComponent implements OnInit {

    public theUrl: string;
    public title: string;
    public originalFileName: string;
    public sizeInBytes: number;
    public onClose: Subject<boolean>;
    public backclass: string = 'dark';
    public selection: string = 'dark';
    public type: string;
    public mimeType: string;
    constructor(public bsModalRef: BsModalRef) { }

    set url(url: string) {
        this.theUrl = url + "?cachebuster=" + new Date().getTime();
    }
    get url(): string {
        return this.theUrl;
    }
    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public onConfirm(): void {
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this.bsModalRef.hide();
    }
    public activeButton(selection) {
        this.selection = selection;
        this.backclass = selection;
    }

}
