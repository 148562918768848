import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, Observable, of, timer } from "rxjs";
import { AppSettings } from "../app-settings";
import { EnvironmentSpecificService } from "./environmentspecific.service";
import { flatMap } from "rxjs/operators";
import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpParams } from '@angular/common/http';


import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";

@Injectable()
export class AzureAuthholderService {
  [x: string]: any;
  accessToken: any;
  private refreshSubscription: any;
  private userProfile: any;
  public userProfile$ = new Subject<any>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private httpClient: HttpClient,
    private azureAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public router: Router,
    private envService: EnvironmentSpecificService
  ) { }

  ngOnInit(): void {
    if (localStorage && localStorage.getItem("loginresponse")) {
      const loginresponse = JSON.parse(localStorage.getItem("loginresponse"));
      this.accessToken = loginresponse.payload.accessToken;
    }
  }

  getUserFromAzureLoginResponse(): any {
    if (localStorage && localStorage.getItem("loginresponse")) {
      const loginresponse = JSON.parse(localStorage.getItem("loginresponse"));
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAccessToken(),
      });
      const requestOptions = { headers: headers };
      this.httpClient.get<any>('https://graph.microsoft.com/v1.0/me', requestOptions)
        .subscribe(
          response => {
            let data = response;
            var azureUser: any = {
              businessPhones: data.businessPhones,
              displayName: data.displayName,
              givenName: data.givenName,
              id: data.id,
              jobTitle: data.jobTitle,
              mail: data.mail,
              mobilePhone: data.mobilePhone,
              officeLocation: data.officeLocation,
              preferredLanguage: data.preferredLanguage,
              surname: data.surname,
              userPrincipalName: data.userPrincipalName,
              //roles: ["Marketing_Tool_Super_Global"],
              roles: loginresponse.payload.account.idTokenClaims.roles,
            };
            localStorage.setItem("azureMeUser", JSON.stringify(azureUser));
            return azureUser;
          }, error => {
            return error;
          }
        );
    }

  }

  getAccessToken() {
    if (localStorage && localStorage.getItem("loginresponse")) {
      const loginresponse = JSON.parse(localStorage.getItem("loginresponse"));
      this.accessToken = loginresponse.payload.accessToken;
      localStorage.setItem("access_token", this.accessToken);
      if (this.accessToken) {
        const expiresAt = ((JSON.parse(atob(this.accessToken.split('.')[1]))).exp) + 86400;
        localStorage.setItem("expires_at", expiresAt);
      }
    }
    return this.accessToken;
  }

  // public login(): void {
  //   if (localStorage && localStorage.getItem("org")) {
  //     //localStorage.removeItem("org");
  //   }
  //   //this.msalConfig.authorize();
  // }

  public getProfile(): any {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      throw new Error("Access token must exist to fetch profile");
    }
    if (this.userProfile) {
      return this.userProfile;
    } else {
      this.msalConfig.client.userInfo(accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = profile;
          this.userProfile$.next(profile);
          return this.userProfile;
        }
      });
    }
  }

  public silentTokenRefresh() {
    var self = this;
    this.msalConfig.parseHash(window.location.hash, function (err, response) {
      parent.postMessage(
        err || response,
        self.envService.envSpecific.baseServerUrl
      );
    });
  }

  public handleAuthentication(): void {
    this.msalConfig.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = "";
        this.setSession(authResult);
        this.getProfile();
        setTimeout(() => {
          this.router.navigate(["/"]);
        }, 300);
      } else if (err) {
        // this.router.navigate(["/"]);
        // console.log(err);
        // alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  private setSession(authResult): void {
    // Set the time that the access token will expire at
    if (localStorage && localStorage.getItem("loginresponse")) {
      const loginresponse = JSON.parse(localStorage.getItem("loginresponse"));
      this.accessToken = loginresponse.payload.accessToken;
      if (this.accessToken) {
        const expiresAt = ((JSON.parse(atob(this.accessToken.split('.')[1]))).exp) + 86400;
        localStorage.setItem("expires_at", expiresAt);
      }
    }
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("formio_token", authResult.idTokenPayload["http://form.io/user_metadata"].token
    );
    this.scheduleRenewal();
    this.getProfile();
  }

  public logout(): void {
    //Remove tokens and expiry time from localStorage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("formio_token");
    localStorage.removeItem("org");
    localStorage.removeItem("loginresponse");
    localStorage.removeItem('selectedorg');
    localStorage.removeItem('getCurrentUserResponse')
    localStorage.removeItem('azureMeUser');
    this.azureAuthService.logout();
    this.unscheduleRenewal();
    // Go back to the home route
    this.router.navigate(["/"]);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    if (localStorage && localStorage.getItem("loginresponse")) {
      const loginresponse = JSON.parse(localStorage.getItem("loginresponse"));
      this.accessToken = loginresponse.payload.accessToken;
      if (this.accessToken) {
        const expiresAt = ((JSON.parse(atob(this.accessToken.split('.')[1]))).exp) + 86400;
        return (Date.now() / 1000) < expiresAt;
      }
    }
    return false;
  }

  public renewToken() {
    this.msalConfig.checkSession({}, (err, result) => {
      if (err) {
        console.log(err);
      } else {
        this.setSession(result);
      }
    });
  }

  public refreshToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.msalConfig.renewAuth(
        {
          audience: this.envService.envSpecific.auth0Audience,
          redirectUri: this.envService.envSpecific.auth0SilentAuth,
          usePostMessage: true,
          postMessageOrigin: this.envService.envSpecific.baseServerUrl,
        },
        (err, result) => {
          if (err) {
            Promise.reject(err);
          } else if (result) {
            this.setSession(result);
            Promise.resolve(result.accessToken);
          }
        }
      );
    });
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) return;
    this.unscheduleRenewal();
    const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));
    const source = of(expiresAt)
      .pipe(flatMap(
        expiresAt => {
          const now = Date.now()/1000;
          // Use the delay in a timer to
          // run the refresh at the proper time
          return timer(Math.max(1, expiresAt - now));
        }));

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
      this.scheduleRenewal();
    });
  }

  public unscheduleRenewal() {
    if (!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }
}
