import { CmdEmailFormItem } from "../models";
import { CmdEmailTemplate } from "../models/CmdEmailTemplate";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { Subject ,  Observable } from 'rxjs';
import { SearchModel } from '../models/SearchModel';
import { AzureAuthholderService } from './azureauthholder.service';



@Injectable()
export class EmailsService {
    private currentOrganization = new Subject<any>();
    
    constructor(
        private azureholder: AzureAuthholderService,
        private httpClient: HttpClient, 
        private envService: EnvironmentSpecificService) {
    }

    public getEmailsForOrg(orgId: string, fillter: SearchModel = new SearchModel()): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let httpParams = new HttpParams();
        httpParams = httpParams.append('orgId', orgId.toString());
        httpParams = httpParams.append('sortBy', fillter.page.sortBy.toString());
        httpParams = httpParams.append('sortOrder', fillter.page.sortOrder.toString());
        httpParams = httpParams.append('pageSize', fillter.page.pageSize.toString());
        httpParams = httpParams.append('page', fillter.page.page.toString());
        httpParams = fillter.searchText.length > 0 ?  httpParams.append('searchText', fillter.searchText) : httpParams;
        const requestOptions = {params: httpParams, headers: headers };
        return this.httpClient
             .get<any>(this.envService.envSpecific.baseServerUrl + '/api/emails/logs', requestOptions);
    }
    
    public getEmailTemplates(orgId: string):Observable<Array<CmdEmailTemplate>>{
        const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        let httpParams = new HttpParams();
        httpParams = httpParams.append('orgId', orgId);
        const requestOptions = {params: httpParams, headers: headers };
        return this.httpClient.get<Array<CmdEmailTemplate>>(this.envService.envSpecific.baseServerUrl + '/api/email_templates', requestOptions);
    }
    
    public getById(id: string):Observable<CmdEmailTemplate>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.get<CmdEmailTemplate>(this.envService.envSpecific.baseServerUrl + '/api/email_templates/' + id, requestOptions);
    }
    
    public create(template:CmdEmailTemplate):Observable<CmdEmailTemplate>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.post<CmdEmailTemplate>(this.envService.envSpecific.baseServerUrl + '/api/email_templates/', template, requestOptions);
    }
    public update(template:CmdEmailTemplate):Observable<CmdEmailTemplate>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.put<CmdEmailTemplate>(this.envService.envSpecific.baseServerUrl + '/api/email_templates', template, requestOptions);
    }
    public delete(id:string):Observable<CmdEmailTemplate>{
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.httpClient.delete<CmdEmailTemplate>(this.envService.envSpecific.baseServerUrl + '/api/email_templates/' + id, requestOptions);
    }
}
