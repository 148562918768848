import { Component } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { BaseActionTypeSelectorComponent } from '../baseactiontypeselector';

@Component({
    selector: 'app-websiteurl',
    templateUrl: './websiteurl.component.html',
    styleUrls: ['./websiteurl.component.scss']
})
export class WebsiteUrlSelectorComponent extends BaseActionTypeSelectorComponent{

    constructor(public widgetService: WidgetService) {
        super(widgetService);
    }
}
