import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SpinService {

    shown: boolean = false;

    private loaderSubject = new Subject<boolean>();
    loaderState = this.loaderSubject.asObservable();

    constructor() { }

    show() {
        if (!this.shown) {
            this.shown = true;
            this.loaderSubject.next(true);
        }
    }
    hide() {
        if (this.shown) {
            this.shown = false;
            this.loaderSubject.next(false);
        }
    }
}
