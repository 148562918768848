import { AppComponent } from './app.component';
import { ApplicationbuilderComponent } from './pages/applicationbuilder/applicationbuilder.component';
import { UsersComponent } from './pages/users/users.component';
import { UserEditComponent } from './pages/users';
import { AssetViewComponent } from './pages/assets';
import { ThemesComponent } from './pages/themes/themes.component';
import { HomeComponent } from './pages/home/home.component'
// import { AuthTokenComponent } from './pages/auth-token/auth-token.component'
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard'
import { ChangeGuard } from './guards/changes.guard';
import { AppEditComponent } from './pages/applicationbuilder/app-edit/app-edit.component';
import { CollectionsComponent } from './pages/applicationbuilder/collections/collections.component';
import { ScenesComponent } from './pages/applicationbuilder/scenes/scenes.component';
import { OrganizationEditComponent } from './pages/organizations/organization-edit/organization-edit.component';
import { OrganizationsComponent } from './pages/organizations/organizations.component';
import { ReportsComponent } from './pages/organizations/reports/reports.component';
import { RefreshComponent } from './pages/refresh/refresh.component';
import { ThemeEditComponent } from './pages/themes/theme-edit/theme-edit.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductEditComponent } from './pages/products/product-edit/product-edit.component';
import { ToolsetsComponent } from './pages/toolsets/toolsets.component';
import { EmailsLoggingsComponent } from './pages/emails-loggings/emails-loggings.component';
import { EmailtemplateeditComponent } from "./pages/emailtemplates/emailtemplateedit/emailtemplateedit.component";
import { EmailtemplatesComponent } from "./pages/emailtemplates/emailtemplates.component";
import { FormsComponent } from './pages/form-builder/forms.component';
import { FormEditComponent } from './pages/form-builder/form-edit/form-edit.component';
import { FormDataComponent } from './pages/form-builder/form-data/form-data.component';
import { SalesQuoteComponent } from './pages/salesquotes/salesquotes.component';
import { CostcalcComponent } from './pages/toolsets/toolset-edit/costcalc/costcalc.component';
import { TabdataComponent } from './pages/toolsets/toolset-edit/tabdata/tabdata.component';
import { QuotebuilderComponent } from './pages/toolsets/toolset-edit/quotebuilder/quotebuilder.component';
import { ContentquotebuilderComponent } from './pages/toolsets/contentquotebuilder/contentquotebuilder.component';
import { QuetebuilderEditComponent } from './pages/toolsets/contentquotebuilder/quetebuilder-edit/quetebuilder-edit.component';
import { QuetebuilderReportComponent } from './pages/toolsets/contentquotebuilder/quetebuilder-report/quetebuilder-report.component';
import { QuoteBuilderReportComponent } from './contentquotebuilder/quote-builder-report/quote-builder-report.component';
import { MsalGuard } from '@azure/msal-angular';
import { TcoCalculatorComponent } from './pages/toolsets/tco-calculator/tco-calculator.component';
import { TcoDevaluationAnalysisComponent } from './pages/toolsets/tco-calculator/tco-devaluation-analysis/tco-devaluation-analysis.component';
import { TcoAmoritizationInvestmentComponent } from './pages/toolsets/tco-calculator/tco-amoritization-investment/tco-amoritization-investment.component';
import { TcoHistoryComponent } from './pages/toolsets/tco-calculator/tco-history/tco-history.component';

const appRoutes: Routes = [
    { path: 'silent', component: RefreshComponent, canActivate: [MsalGuard]},
    { path: '', component: HomeComponent, canActivate: [MsalGuard]},
    { path: 'home', component: HomeComponent, canActivate: [MsalGuard]},
    // { path: 'authToken', component: AuthTokenComponent },
    { path: 'applications', component: ApplicationbuilderComponent, canActivate: [MsalGuard] },
    { path: 'applications/:appId', component: AppEditComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'organizations', component: OrganizationsComponent, canActivate: [MsalGuard] },
    { path: 'organizations/:orgId', component: OrganizationEditComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'products/:page/:pageSize', component: ProductsComponent, canActivate: [MsalGuard] },
    { path: 'products', component: ProductsComponent, canActivate: [MsalGuard] },
    { path: 'product/:prodId/:returnUrl', component: ProductEditComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'product/:prodId', component: ProductEditComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'toolsets/:page/:pageSize', component: ToolsetsComponent, canActivate: [MsalGuard] },
    { path: 'toolsets', component: ToolsetsComponent, canActivate: [MsalGuard] },
    // TODO need to create contenttoolset component and  replace with ToolsetsComponent

    { path: 'toolsets/:page/:pageSize', component: ToolsetsComponent, canActivate: [MsalGuard] },
    { path: 'toolsets', component: ToolsetsComponent, canActivate: [MsalGuard] },
    { path: 'contentquotebuilder', component: ContentquotebuilderComponent, canActivate: [MsalGuard] },
    { path: 'contentquotebuilder/:quoteId', component: QuetebuilderEditComponent, canActivate: [MsalGuard] },
    { path: 'contentquotebuilder/report/:quoteId', component: QuoteBuilderReportComponent, canActivate: [MsalGuard] },
    // { path: 'toolsets/:page/:pageSize', component: ToolsetsComponent, canActivate: [MsalGuard] },
    // { path: 'toolsets', component: ToolsetsComponent, canActivate: [MsalGuard] },
    // { path: 'contentquotebuilder', component: ContentquotebuilderComponent, canActivate: [MsalGuard] },
    // { path: 'contentquotebuilder/:quoteId', component: QuetebuilderReportComponent, canActivate: [MsalGuard] },

    { path: 'toolsets/:page/:pageSize', component: ToolsetsComponent, canActivate: [MsalGuard] },
    { path: 'toolsets', component: ToolsetsComponent, canActivate: [MsalGuard] },



    { path: 'toolset/costcalc/:toolId/:returnUrl', component: CostcalcComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'toolset/costcalc/:toolId', component: CostcalcComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'toolset/tabdata/:toolId/:returnUrl', component: TabdataComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'toolset/tabdata/:toolId', component: TabdataComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'toolset/quotebuilder/:toolId/:returnUrl', component: QuotebuilderComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'toolset/quotebuilder/:toolId', component: QuotebuilderComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    //{ path: 'toolset/tcocalculator/:toolId', component: TcoCalculatorComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },

    { path: 'toolset/tco-calculator', component: TcoCalculatorComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    {path: 'toolset/tco-calculator/New/devaluationAnalysis',component:TcoDevaluationAnalysisComponent,canActivate:[MsalGuard],canDeactivate:[ChangeGuard]},
    {path:  'toolset/tco-calculator/New/amoritizationinvestment',component:TcoAmoritizationInvestmentComponent,canActivate:[MsalGuard],canDeactivate:[ChangeGuard]},

    {path: 'toolset/tco-calculator/tco-history',component:TcoHistoryComponent,canActivate:[MsalGuard],canDeactivate:[ChangeGuard]},
   


    {path:  'toolset/tco-calculator/:toolId/amoritizationinvestment',component:TcoAmoritizationInvestmentComponent,canActivate:[MsalGuard],canDeactivate:[ChangeGuard]},
    {path: 'toolset/tco-calculator/:toolId/devaluationAnalysis',component:TcoDevaluationAnalysisComponent,canActivate:[MsalGuard],canDeactivate:[ChangeGuard]},
   

    { path: 'dashboard', component: ReportsComponent, canActivate: [MsalGuard] },
    { path: 'users', component: UsersComponent, canActivate: [MsalGuard] },
    { path: 'users/:userId', component: UserEditComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'assets', redirectTo: 'assets/list', pathMatch: 'full' },
    { path: 'assets/:viewtype', component: AssetViewComponent, canActivate: [MsalGuard] },
    { path: 'themes', component: ThemesComponent, canActivate: [MsalGuard] },
    { path: 'themes/theme-edit/:themeId', component: ThemeEditComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'emails', component: EmailsLoggingsComponent, canActivate: [MsalGuard] },
    { path: 'emailtemplates', component: EmailtemplatesComponent, canActivate: [MsalGuard] },
    { path: 'emailtemplates/:templateId', component: EmailtemplateeditComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'applications/:appId/scenes/:sceneId', component: ScenesComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'applications/:appId/collections/:collectionId', component: CollectionsComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'forms', component: FormsComponent, canActivate: [MsalGuard] },
    { path: 'form-builder/:formId', component: FormEditComponent, canActivate: [MsalGuard], canDeactivate: [ChangeGuard] },
    { path: 'form-data/:formId', component: FormDataComponent, canActivate: [MsalGuard] },
    { path: 'salesquotes', component: SalesQuoteComponent, canActivate: [MsalGuard] }
];
export const appRouting: ModuleWithProviders = RouterModule.forRoot(appRoutes);