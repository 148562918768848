import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditQuoteBuilderService } from '../../../../services/editquotebuilder';
import { OrganizationService } from '../../../../services/organization.service';
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { ConfirmModalComponent } from '../../../../shared/confirm-modal/confirm-modal.component';
import { QuoteBuilderService } from '../../../../services/quotebuilder.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { CreateProductCSVComponent } from '../create-product-csv/create-product-csv.component';
import { AddProductQuoteMdlComponent } from '../add-product-quote-mdl/add-product-quote-mdl.component';
import { ChangeColNameMdlComponent } from '../change-col-name-mdl/change-col-name-mdl.component';
import { AddColumnModalComponent } from '../add-column-modal/add-column-modal.component';
import { SendQuoteInfoModalComponent } from '../send-quote-info-modal/send-quote-info-modal.component';
import { SpinService } from '../../../../services/spin.service';
import { AddOnsModalComponent } from '../add-ons-modal/add-ons-modal.component';
import { AddEmailccaddressesQuoteBuilderComponent } from '../add-emailccaddresses-quote-builder/add-emailccaddresses-quote-builder.component';

@Component({
  selector: 'app-quetebuilder-edit',
  templateUrl: './quetebuilder-edit.component.html',
  styleUrls: ['./quetebuilder-edit.component.css']
})

export class QuetebuilderEditComponent implements OnInit {
  

  addQuoteBuildeModalRef: BsModalRef;
  addProductModalRef: BsModalRef;
  addCSVProduct: BsModalRef;
  bsModalRef: BsModalRef;
  editquotes: any = {};
  quotesTblHeader: any[];
  description: string;
  editquotetitle: string;
  colNameModalRef: BsModalRef;
  pos: any;
  quoteId: string;
  p: number = 1;
  org: any;
  ccEmails: any;
  addOnsData:any=[];
  addCcEmail:any=[];
  allProduct: any = [];
  public tableData: any = [];
  public tableHeader: any = [];
  public remainingHeader: any = [];
  public noValBool: boolean = false;
  quoteArrayComingFromApi=[];
  constructor(private router: Router, private apiService: EditQuoteBuilderService, private quoteService: QuoteBuilderService,
    public organizationService: OrganizationService,
    private stickyPopUp: StickyPopUpService,
    private activeRoute: ActivatedRoute, private modalService: BsModalService, public renderer2: Renderer2,
    private spinService: SpinService) { }

  ngOnInit() {
    this.org = this.organizationService.getCurrentOrganization();
    this.quoteId = this.activeRoute.snapshot.params.quoteId;
    this.getBuilderData(this.activeRoute.snapshot.params.quoteId, 'new');
    this.getAddOns();
    // this.getCcEmail();
  }
  getBuilderData(quoteId, type) {
    this.spinService.show();
    this.apiService.getAllEditQouteList(quoteId, this.org.id).subscribe(
      data => {
        this.spinService.hide();
        this.ccEmails = data.contents[0].ccEmails;
        this.quoteArrayComingFromApi = data.contents[0].ccEmails;
        this.editquotes = data.contents[0];
        this.quotesTblHeader = data.contents[0].productHeaders.quoteBuilderProductHeader;
        if(this.editquotes.quoteBuilderProductsTo[0].sellPrice === null) {

        }else {
          this.editquotes.quoteBuilderProductsTo.map((item,index)=>{
            if(item.sellPrice === null){
              this.editquotes.quoteBuilderProductsTo[index].sellPrice = 0
            }else{
              this.editquotes.quoteBuilderProductsTo[index].sellPrice = (parseFloat(item.sellPrice)).toLocaleString();
              if(!(this.editquotes.quoteBuilderProductsTo[index].sellPrice).includes('.')){
                this.editquotes.quoteBuilderProductsTo[index].sellPrice+=".00";
              }
            }
          })
        }
       
        
        this.allProduct = this.editquotes.quoteBuilderProductsTo;
        this.noValBool = this.editquotes.quoteBuilderProductsTo.length ? false : true;
        this.editquotetitle = data.contents[0].title;
        this.editquotes.quoteBuilderProductsTo.sort((x, y) => x.rowPosition - y.rowPosition);
        this.headerPositioning();
        // if (type == 'update') {
        //   let length = this.editquotes.quoteBuilderProductsTo.length;
        //   this.editquotes.quoteBuilderProductsTo.forEach((object, i) => { object.rowPosition = i + 2 });
        //   this.editquotes.quoteBuilderProductsTo[length - 1].rowPosition = 1;
        //   this.editquotes.quoteBuilderProductsTo.splice(0, 0, this.editquotes.quoteBuilderProductsTo[length - 1]);
        //   this.editquotes.quoteBuilderProductsTo.splice(length - 1, 1);
        // }
      },
      err => {
        this.spinService.hide();
        console.log(err);
      }
    )
  }
  insertAt(array, index, obj) {
    array.splice(index, 0, obj);
  }
  public getDate(date) {
    if (!date) {
      return "NA";
    }
    let d = new Date(date);
    
    return (('0' + (d.getMonth() + 1)).slice(-2) + "/" + ('0' + d.getDate()).slice(-2) + "/" + d.getFullYear());
    // return (('0' + d.getDate()).slice(-2) + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear());
  }
  headerPositioning() {
    this.tableHeader = [];
    this.remainingHeader = [];
    for (let pElement of this.quotesTblHeader) {
      let flag = 0;
      for (let cElement of this.editquotes.quoteBuilderProductsTo) {
        if (cElement[pElement.columnName] || (pElement.columnName=='sellPrice' && cElement[pElement.columnName]!=null)) { 
          flag = 0;
          this.tableHeader.push(pElement);
          break;
        } else {
          flag = 1;
        }
      }
      if (flag) {
        this.remainingHeader.push(pElement);
      }
    }
    this.tableHeader.sort((x, y) => x.position - y.position);
  }
  isNumeric(num) {
    return !isNaN(num)
  }
  Search() {
    if (this.description == "") {
      this.editquotes.quoteBuilderProductsTo = this.allProduct;
    }
    else {
      let flag = 0;
      for (let element of this.tableHeader) {
        this.editquotes.quoteBuilderProductsTo = this.allProduct.filter(res => {
          if ((element.columnName != 'sellPrice') && res[element.columnName] && res[element.columnName].toLocaleLowerCase().match(this.description.toLocaleLowerCase())) {
            flag = 1;
            return res[element.columnName].toLocaleLowerCase().match(this.description.toLocaleLowerCase());
          } else if (res[element.columnName] && element.columnName == 'sellPrice' && this.isNumeric(this.description)) {
            flag = 1;
            return res[element.columnName].toString().match(this.description);
          }
        })
        if (flag) {
          break;
        }
      }
    }
  }
  onDrop(event: any) {
    moveItemInArray(this.editquotes.quoteBuilderProductsTo, event.previousIndex, event.currentIndex);
    this.editquotes.quoteBuilderProductsTo.forEach((object, i) => { object.rowPosition = i + 1 });
    this.updateRow(this.editquotes.quoteBuilderProductsTo);
  }
  updateRow(arr) {
    let body = arr
    this.apiService.putMethod("/api/quotebuilder/" + this.organizationService.getCurrentOrganization().id + "/" + this.quoteId + "/product/position", body).subscribe(
      data => {
        this.stickyPopUp.success('Row Updated');
      },
      err => {
        console.log(err, 'Some problem occured');
      }
    )
  }
  updateRowFrAddNew(arr) {
    let body = arr
    this.apiService.putMethod("/api/quotebuilder/" + this.organizationService.getCurrentOrganization().id + "/" + this.quoteId + "/product/position", body).subscribe(
      data => {
        this.getBuilderData(this.activeRoute.snapshot.params.quoteId, "new")
      }
    )
  }
  onDropcol(event: any) {
    moveItemInArray(this.quotesTblHeader, event.previousIndex, event.currentIndex);
    this.quotesTblHeader.forEach((header: { position: any; }, idx: number) => {
      header.position = idx + 1;
    });
  }
  public uploadProductCSV() {
    this.addCSVProduct = this.modalService.show(CreateProductCSVComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { quoteId: this.quoteId, title: this.editquotetitle }, class: 'update-csv-modal' });
    this.addCSVProduct.content.event.subscribe(data => {
      this.getBuilderData(this.quoteId, 'new');
    });
  }
  public addProduct() {
    this.addProductModalRef = this.modalService.show(AddProductQuoteMdlComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { title: this.editquotetitle, quoteId: this.quoteId, apiType: "add" }, class: 'add-Product-Modal' });
    console.log(this.addProductModalRef)
    this.addProductModalRef.content.event.subscribe(data => {
      this.getBuilderData(this.quoteId, 'update');
    });
  }
  public updateCcAddresses() {
    this.addProductModalRef = this.modalService.show(AddEmailccaddressesQuoteBuilderComponent, { ignoreBackdropClick: true, keyboard: false, initialState: {title: this.editquotetitle, orgId: this.org.id ,quoteId:this.quoteId, 
     quoteArray:this.quoteArrayComingFromApi, apiType: "edit" }, class: 'add-Product-Modal' });
    console.log(this.addProductModalRef)
    this.addProductModalRef.content.event.subscribe(data => {
      this.getBuilderData(this.quoteId, 'update');
    });
  }
  public openAddOnsMdl() {
    this.addProductModalRef = this.modalService.show(AddOnsModalComponent, { ignoreBackdropClick: true,initialState: { addOnData: this.addOnsData,quoteId: this.quoteId,orgId:this.org}, keyboard: false, class: "add-ons-modal" });

  }
  public editProductMdl(data, tableHeader) {
    this.addProductModalRef = this.modalService.show(AddProductQuoteMdlComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { title: this.editquotetitle, quoteId: this.quoteId, apiType: "edit", productData: data, headerData: this.quotesTblHeader, table: tableHeader } });
    this.addProductModalRef.content.event.subscribe(data => {
    this.getBuilderData(this.quoteId, 'new');
    });
  }
  deleteQuote(quoteId, sku) {
    const org = this.organizationService.getCurrentOrganization();
    this.bsModalRef = this.modalService.show(ConfirmModalComponent, { class: 'delete-modal' });
    this.bsModalRef.content.message = `Are you sure you want to delete ${sku}? `;
    this.bsModalRef.content.onClose
      .subscribe(result => {
        if (result === true) {
          this.apiService.deleteQuoteData(org.id, quoteId, this.editquotes.id)
            .subscribe(
              data => {
                if (data) {
                  this.stickyPopUp.success(`${sku} has deleted.`);
                  this.getBuilderData(this.activeRoute.snapshot.params.quoteId, 'new');
                }
                //  this.message="pricelist deleted successfully";
              }, err => {
                this.stickyPopUp.error('Failed to delete.');
                console.log(err, "error");
              }
            )
        }
      });


  }

  dropCol(event) {
    this.quotesTblHeader.forEach((object, i) => {
      let index = this.tableHeader.findIndex(x => x.id == object.id);
      if (!(index + 1)) {
        this.tableHeader.push(object);
      }
    });
    moveItemInArray(this.tableHeader, event.previousIndex, event.currentIndex);
    this.tableHeader.forEach((object, i) => { object.position = i + 1 });
    let body = {
      "quoteBuilderProductHeader": this.tableHeader
    }
    this.apiService.postMethod("/api/quotebuilder/" + this.organizationService.getCurrentOrganization().id + "/update/" + this.quoteId + "/header", body).subscribe(
      data => {
        this.stickyPopUp.success('Header Updated');
        this.quotesTblHeader = data.productHeaders.quoteBuilderProductHeader;
        this.getBuilderData(this.quoteId, 'new');
        // this.headerPositioning();
      },
      err => {
        console.log(err, 'Some problem occured');
        this.headerPositioning();
      }
    )
  }
  mouseDown(event, el: any = null) {
    el = el || event.target
    this.pos = {
      x: el.getBoundingClientRect().left - event.clientX + 'px',
      y: el.getBoundingClientRect().top - event.clientY + 'px',
      width: el.getBoundingClientRect().width + 'px'
    }
  }
  onDragRelease(event) {
    this.renderer2.setStyle(event.source.element.nativeElement, 'margin-left', '0px')
  }
  public chgColNameMdl(colName, pos) {
    let url = "/api/quotebuilder/" + this.organizationService.getCurrentOrganization().id + "/update/" + this.quoteId + "/header";
    this.addQuoteBuildeModalRef = this.modalService.show(ChangeColNameMdlComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { position: pos, apiUrl: url, headerData: this.quotesTblHeader, newName: colName } });
  }
  public changeVisibility(st, position) {
    let index = this.quotesTblHeader.findIndex(x => x.position == position);
    this.quotesTblHeader[index].visibility = st;
    let body = {
      "quoteBuilderProductHeader": this.quotesTblHeader
    }
    this.apiService.postMethod("/api/quotebuilder/" + this.organizationService.getCurrentOrganization().id + "/update/" + this.quoteId + "/header", body).subscribe(
      data => {
        this.stickyPopUp.success('Visibility changed successfully');
      },
      err => {
        console.log(err, 'Some problem occured');
      }
    )
  }
  public showAddClm() {
    if (!this.remainingHeader.length) {
      this.stickyPopUp.error("No Column left");
      return;
    }
    
    this.colNameModalRef = this.modalService.show(AddColumnModalComponent, { ignoreBackdropClick: true, keyboard: false, initialState: { columnData: this.remainingHeader }, class: 'add-Column-Quote-Modal' });
    this.colNameModalRef.content.event.subscribe(data => {
      this.tableHeader.push(data.columnObj);
      let body = {
        "quoteBuilderProductHeader": data.columnObj.columnName
      }
      this.apiService.postMethod("/api/quotebuilder/" + this.organizationService.getCurrentOrganization().id + "/" + this.quoteId + "/addColumn?columnName=" + data.columnObj.columnName, body).subscribe(res => {
        this.getBuilderData(this.quoteId, 'new');
      })
      this.tableHeader.sort((x, y) => x.position - y.position);
      let index = this.remainingHeader.findIndex(x => x.id == data.columnObj.id);
      this.remainingHeader.splice(index, 1);
    });

  }
  public rowVisibility(status, id) {
    let body = {
      "rowVisibility": status
    }
    this.apiService.putMethod("/api/quotebuilder/" + this.org.id + "/" + this.quoteId + "/product/" + id + "/visibility", body).subscribe(
      data => {
        this.stickyPopUp.success("Visibility changed successfully");
      },
      err => {
        console.log(err);
        this.stickyPopUp.error("Some problem occured");
      }
    )
  }
  public sendInfoMdl() {
    // , initialState: { columnData: this.remainingHeader }
    this.modalService.show(SendQuoteInfoModalComponent, { ignoreBackdropClick: true, keyboard: false, class: "modal-width-625",initialState: { orgId: this.org.id ,quoteId:this.quoteId } });
  }
  getAddOns(){
    this.apiService.getMethod("/api/quotebuilder/" + this.org.id + "/" + this.quoteId + "/addons/").subscribe(
      data => {
        this.addOnsData=data.quoteBuilderAddOns;
      },
      err => {
        console.log(err);
        this.stickyPopUp.error("Some problem occured");
      }
    )
  }
  // getCcEmail(){
  //   this.apiService.getAllEditQouteList(this.quoteId,this.org.id).subscribe(
  //       data => {
  //        this.ccEmails = data.contents[0].ccEmailAddressess;
  //       //  localStorage.setItem("QbCcEmails",this.ccEmails);
  //       },
  //       err => {
  //         console.log(err);
  //         this.stickyPopUp.error("Some problem occured");
  //       }
  //     );
  // }
}
