import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as CanvasJS from 'canvasjs/dist/canvasjs.js';
import { Color } from 'ng2-charts';
import 'chart.piecelabel.js';
import { EditQuoteBuilderService } from '../../services/editquotebuilder';
import { OrganizationService } from '../../services/organization.service';
import { SpinService } from '../../services/spin.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-quote-builder-report',
  templateUrl: './quote-builder-report.component.html',
  styleUrls: ['./quote-builder-report.component.css']
})
export class QuoteBuilderReportComponent implements OnInit {
  addQuoteBuildeModalRef: BsModalRef;
  cofirmModalRef: BsModalRef;
  addDuplicateModalRef: BsModalRef;
  bsModalRef: BsModalRef;
  public title: string;

  DeletePopUpModalRef: BsModalRef;

  salesquote = [{
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",

  },
  {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  },
  {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }, {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }, {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }
    , {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }
    , {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }
    , {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }
    , {
    name: 'John Smith',
    recepient: "	Troy Davis",
    Datesent: "9/15/19 10:15am",
    ProjectStart: "9/27/19",
    branch: "Baton Rouge",
    total: "$15,38555",
  }

  ];

  // originalData: any=[];
  originalData: any[]
  message: string;
  config: any;
  //  quotelist=[];
  //private quotelist: any[];
  // let quotelist: Array<any>;

  title1 = 'pricelistsearchFilter';
  searchPriceList = '';
  pricelist = [
    'ZA  USpricelist',
    'Test pricelist',
  ]
  body: any;
  orgId: string;
  vId: string;
  public quoteTitle: string;
  public chartData: any;
  public indTtlData: any = [];
  p: number = 1;
  c: number = 1;

  contentTitle: string;
  deleteConfirm: string;
  public startDate: string = "";
  public endDate: string = "";
  public salesData: any = [];
  public dateRangeBool: boolean = false;
  public dateRangeString: string = 'Select Date Range';
  public doughnutChartLabels: string[] = ['Breanne Daugherly', 'Daniel Torez', 'Jeff Franklin', 'Julie Anderson', 'John Smith'];
  public doughnutChartColors: Color[] = [{
    backgroundColor: ['#80a4ed', '#f45100', '#555555', '#b0b0b0', '#5fbb97']
  }];
  public demodoughnutChartData: number[] = [16, 15, 18, 29, 20];
  public doughnutChartType: string = 'doughnut';
  public dateErrBool: boolean = false;
  public chartOptions: any;
  public salesListData: any = [];
  public personDd: string = "";
  public loaderBool: boolean = true;
  public salesErrBool: boolean = false;
  public recipientVal: string = "";
  public checkBoxArr: any = [];
  toolsetId:string;
  constructor(
    private apiService: EditQuoteBuilderService,
    public organizationService: OrganizationService,
    private spinService: SpinService,
    private stickyPopUp: StickyPopUpService, private datePipe: DatePipe,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    let data = this.doughnutChartLabels;
    this.chartOptions = {
      cutoutPercentage: 60,
      responsive: true,
    };
    this.toolsetId=this.route.snapshot.params.quoteId;
    this.quoteTitle = sessionStorage.quoteTitle;
    this.viewAllData();
  }
  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }
  public changeCheckBoxStatus(status) {
    this.checkBoxArr.forEach(element => {
      element.status = status;
    });
  }
  public createPdf() {
    let val = this.checkBoxArr.findIndex(x => x.status == true) + 1;
    if (!val) {
      this.stickyPopUp.error("Plese check any record to export");
      return;
    }
    let head = [['SALES PERSON', 'STATUS', 'RECIPIENT', 'DATE SENT', 'PROJECT START', 'TOTAL']]
    let data = [[]];
    let index = 0;
    this.salesListData.forEach(element => {
      if (this.checkBoxArr[index].status) {
        let arr = [element.salesperson.displayName, element.status, element.recipientName || "NA", this.datePipe.transform(element.created, 'short'), element.projectedStartDate || "NA", element.total || 0];
        data.push(arr);
      }
      ++index;
    });
    var doc = new jsPDF();

    doc.setFontSize(18);
    doc.text('Sales Quote Data', 11, 8);
    doc.setFontSize(11);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: head,
      body: data,
      theme: 'plain'
    })
    // doc.output('dataurlnewwindow');
    doc.save('salesData.pdf');
  }
  public createExcel() {
    let val = this.checkBoxArr.findIndex(x => x.status == true) + 1;
    if (!val) {
      this.stickyPopUp.error("Plese check any record to export");
      return;
    }
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('ProductData');
    worksheet.columns = [
      { header: 'SALESPERSON', key: 'displayName' },
      { header: 'STATUS', key: 'status' },
      { header: 'RECIPIENT', key: 'recipientName' },
      { header: 'DATE SENT', key: 'created' },
      { header: 'PROJECT START', key: 'projectedStartDate' },
      { header: 'TOTAL', key: 'total' },
    ];
    let index = 0;
    this.salesListData.forEach(e => {
      if (this.checkBoxArr[index].status)
        worksheet.addRow({ displayName: e.salesperson.displayName, status: e.status, recipientName: e.recipientName || "NA", created: this.datePipe.transform(e.created, 'short'), projectedStartDate: e.projectedStartDate, total: e.total || 0 }, "n");
      ++index;
    });
    worksheet.columns.forEach(column => {
      column.width = column.header.length < 12 ? 12 : column.header.length
    })
    // this.AdjustColumnWidth(worksheet)
    workbook.csv.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'text/csv' });
      fs.saveAs(blob, 'salesData.csv');
    })
  }
  public changeCheckboxSt(index, status) {
    this.checkBoxArr[index].status = status;
  }
  public filterByPerson(val) {
    this.salesListData = this.salesData.salesAppData.contents.filter(res => {
      return res.salesperson.displayName.toLocaleLowerCase().match(val.toLocaleLowerCase());
    })
  }
  public filterByRecipients(val) {
    this.salesListData = this.salesData.salesAppData.contents.filter(res => {
      if (res.recipientName)
        return res.recipientName.toLocaleLowerCase().match(val.toLocaleLowerCase());
    })
    if (!this.salesListData.length) {
      this.salesListData = this.salesData.salesAppData.contents.filter(res => {
        if (res.salesperson.displayName)
          return res.salesperson.displayName.toLocaleLowerCase().match(val.toLocaleLowerCase());
      })
    }
  }
  public reset() {
    this.salesListData = this.salesData.salesAppData.contents;
    this.personDd = "";
  }
  public filterData() {
    if ((this.startDate == "") || (this.endDate == "")) {
      this.dateErrBool = true;
      return;
    }
    let stDate = this.organizationService.getDate(this.startDate);
    let dateFrom = this.organizationService.getDate(this.endDate);
    this.dateErrBool = false;
    this.dateRangeString = (this.datediff(this.parseDate(stDate), this.parseDate(dateFrom)) + 1) + " days (" + stDate + "-" + dateFrom + ")";
    this.getChartData(stDate, dateFrom);
    this.getSalesData(stDate, dateFrom);
    this.closeDateRange();
  }
  public viewAllData() {
    let today = new Date();
    let stDate = '01/15/2019';
    this.personDd = "";
    this.recipientVal = "";
    let dateFrom = this.organizationService.getDate(today);
    this.dateRangeString = (this.datediff(this.parseDate(stDate), this.parseDate(dateFrom)) + 1) + " days (" + stDate + " - " + dateFrom + ")";
    this.getChartData(stDate, dateFrom);
    this.getSalesData(stDate, dateFrom);
  }
  parseDate(str) {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }

  datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  public getSalesData(dateFrom, dateTo) {
    // this.spinService.show();
    this.loaderBool = true;
    this.salesErrBool = false;
    this.salesListData = [];
    this.checkBoxArr = [];
    this.apiService.getMethod("/api/quotebuilder/sales?page=1&pageSize=50&sortBy=created&sortOrder=DESC&orgId=" + this.organizationService.getCurrentOrganization().id + "&search=&types=theme&userId=" + this.organizationService.getCurrentUser() + "&startDate=" + dateFrom + "&endDate=" + dateTo+"&toolsetId="+ this.toolsetId).subscribe(
      data => {
        this.loaderBool = false;
        // this.spinService.hide();
        this.salesData = data;
        this.salesListData = this.salesData.salesAppData.contents;
        this.salesListData.forEach(element => {
          this.checkBoxArr.push({ id: element.id, status: true });
        });
        if (!this.salesListData.length) {
          this.salesErrBool = true;
        } else {
          this.salesErrBool = false;
        }
      },
      err => {
        this.loaderBool = false;
        // this.spinService.hide();
        console.log(err);
      }
    )
  }
  public getChartData(dateFrom, dateTo) {
    // this.spinService.show();
    this.apiService.getMethod("/api/quotebuilder/sales/report?page=1&pageSize=50&sortBy=created&sortOrder=DESC&orgId=" + this.organizationService.getCurrentOrganization().id + "&search&types=theme&userId=" + this.organizationService.getCurrentUser() + "&startDate=" + dateFrom + "&endDate=" + dateTo+"&toolsetId="+ this.toolsetId).subscribe(
      data => {
        // this.spinService.hide();
        this.indTtlData = [];
        this.chartData = data;
        for (var property in this.chartData.individualTotals) {
          this.indTtlData.push({ label: property, y: this.chartData.individualTotals[property] });
        }
        let chart = new CanvasJS.Chart("chartContainer", {
          animationEnabled: true,
          title: {
            text: this.chartData.totalresult,
            verticalAlign: "center"
          },
          data: [{
            type: "doughnut",
            startAngle: 100,
            //innerRadius: 60,
            // indexLabelRadius: 5,
            indexLabelFontSize: 12,
            indexLabel: "{label} - #percent%",
            toolTipContent: "<b>{label}:</b> {y} (#percent%)",
            dataPoints: this.indTtlData
          }]
        });
        chart.render();
      },
      err => {
        // this.spinService.hide();
        console.log(err);
      }
    )
  }
  public showDateRange() {
    if (this.dateRangeBool) {
      document.getElementById('date-picker').style.display = 'none';
      this.dateRangeBool = false;
    } else {
      this.dateRangeBool = true;
      document.getElementById('date-picker').style.display = 'block';
    }
  }
  public closeDateRange() {
    document.getElementById('date-picker').style.display = 'none';
    this.dateRangeBool = false;
  }
  public updateSalesStatus(status, obj) {
    this.apiService.setStatus(obj.id, status).subscribe(
      data => {
        this.stickyPopUp.success("Sales Quotes has been updated");
        let today = new Date();
        let stDate = '01/15/2019';
        let dateFrom = this.organizationService.getDate(today);
        this.dateRangeString = (this.datediff(this.parseDate(stDate), this.parseDate(dateFrom)) + 1) + " days (" + stDate + " - " + dateFrom + ")";
        this.getChartData(stDate, dateFrom);
      },
      err => {
        this.stickyPopUp.error("Some problem occured");
      }
    )


  }
}
