import { AssetsService } from '../../services/assets.service';
import { OrganizationService } from '../../services/organization.service';
import { SpinService } from '../../services/spin.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AssetpdfmodifierComponent } from '../assetpdfmodifier/assetpdfmodifier.component';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Component({
  selector: 'app-assetthumbnailupload',
  templateUrl: './assetthumbnailupload.component.html',
  styleUrls: ['./assetthumbnailupload.component.css']
})
export class AssetthumbnailuploadComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput;
  uploadModalRef: BsModalRef;
  public tags: Array<string> = [];
  public selectedTags: Array<any> = [];
  public onClose: Subject<boolean>;
  public completedFiles: Array<any> = [];

  public  : Boolean = false;
  remaining: number = 0;
  totalFiles: number = 0;
  totalSize: number = 0;
  loadedSize: number = 0;
  loadedPercent: number = 0;
  checkImageExist :boolean ;
  uploadType: string = "image";
  buttonIsDisabled: boolean = true;
  checked: boolean = false;
  disableAttachementCheckbox:boolean=true;
  allFileTypes: Array<string> = [".png, .jpg, .jpeg"];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private apiData = new BehaviorSubject<any>(null);
  public apiData$ = this.apiData.asObservable();
  uploadInProgress: boolean;

  constructor(public bsModalRef: BsModalRef,
      private assetService: AssetsService,
      private modalService: BsModalService,
      private orgService: OrganizationService,
      private alertService: StickyPopUpService,
      private spinService: SpinService) { }

  fileTypes(): Array<string> {
      return this.allFileTypes;
  }


  ngOnInit() {
      this.orgService.getTags().subscribe(tags => {
          this.tags = tags;
      });
      this.onClose = new Subject();
  }
  onSelected($event) {
      this.selectedTags = $event;
  }
  onConfirm() {
      let fileBrowser = this.fileInput.nativeElement;
      if (fileBrowser.files) {
          this.totalFiles = fileBrowser.files.length;
          this.remaining = fileBrowser.files.length;
          this.spinService.show();
          this.uploadInProgress = true;
          var uploadFiles = [];
          var uploadObservables = [];
          this.completedFiles = [];
          this.totalSize = 0;
          for (var x = 0; x < fileBrowser.files.length; x++) {
              var aFile = fileBrowser.files[x];
              this.totalSize += aFile.size;
              this.completedFiles.push({
                  file: aFile,
                  status: 'loading'
              });
              this.assetService.uploadAppThumbnail(this.orgService.getCurrentOrganizationId(), this.selectedTags, aFile)
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe(file => {
                          this.remaining--;
                          this.loadedSize += file.size;
                          this.loadedPercent = (this.loadedSize / this.totalSize) * 100;
                          this.setStatus(this.completedFiles, file, 'success');
                          if (this.remaining == 0) {
                              this.spinService.hide();
                              this.alertService.success("Uploaded " + fileBrowser.files.length + " files");
                              this.onClose.next(true);
                              this.bsModalRef.hide();
                      }

                  }, error => {
                      this.remaining--;
                      this.completedFiles.unshift({ status: 'error', error: error.message });
                      if (this.remaining == 0) {
                          this.spinService.hide();
                          this.uploadInProgress = false;
                      }
                  });
          }
      }
  }

  setData(data) {
      this.apiData.next(data)
  }

  private setStatus(list: Array<any>, file, status) {
      list.forEach(item => {
          if (item.file.name == file.originalFileName) {
              item.status = status;
          }
      });
  }

  public onCancel(): void {
      if (this.spinService.shown) {
          this.spinService.hide();
      }
      this.ngUnsubscribe.next(undefined);
      this.onClose.next(undefined);
      this.bsModalRef.hide();
  }
  formatBytes(bytes): string {
      return this.assetService.formatBytes(bytes);
  };

  validateFileInput() {
      let fileBrowser = this.fileInput.nativeElement;
      this.checkImageExist = false;
      this.buttonIsDisabled = true;
      fileBrowser.files.forEach(element => {
          if ((element.type != 'image/jpeg') && (element.type != 'image/jpg') && (element.type != 'image/png')) {
              this.checkImageExist = true;
          }
      });
      if(this.checkImageExist == false){
          this.buttonIsDisabled = false;
      }
  }
  dialogShowHide(status:boolean){
      this.checked=status;
  }
}
