import { CmdAsset } from '../../models';
import { Page } from '../../models/page';
import { AssetsService } from '../../services/assets.service';
import { OrganizationService } from '../../services/organization.service';
import { InputValidationService } from '../../services/inputvalidation.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SpinService } from '../../services/spin.service';

@Component({
    selector: 'app-themes',
    templateUrl: './themes.component.html',
    styleUrls: ['./themes.component.css']
})
export class ThemesComponent implements OnInit {
    bsModalRef: BsModalRef;
    themes: Array<any>;
    model: any = {};
    buttonIsDisabled: boolean = false;
    private orgSubscription: Subscription;
    public currentOrganization: any;

    search: any = {
        searchType: 'theme',
        searchText: '',
        tags: [],
        results: []
    };

    sortBy: string = 'title';
    sortOrder: string = '';

    constructor(private assetService: AssetsService,
        private orgService: OrganizationService,
        private validationService: InputValidationService,
        private toast: StickyPopUpService,
        private modalService: BsModalService,
        private spinService: SpinService) {
        this.search.page = new Page();
        this.search.page.page = 1;
        this.search.page.pageSize = 10;
        this.search.page.sortBy = "title";
        this.search.page.sortOrder = "ASC";
    }

    ngOnInit() {
        this.loadThemes();
        this.currentOrganization = this.orgService.getCurrentOrganization();
        this.orgSubscription = this.orgService.getCurrentOrganizationObservable().subscribe(() => {
            this.loadThemes();
            this.currentOrganization = this.orgService.getCurrentOrganization();
        });
    }

    loadThemes() {
        this.spinService.show();
        this.assetService.getAssets(this.orgService.getCurrentOrganizationId(), [this.search.searchType], this.search.searchText, this.search.tags, this.search.page)
            .subscribe(data => {
                this.themes = data.contents;
                this.search.results = data;
                this.spinService.hide();
            },
                error => {
                    console.log(error);
                    this.spinService.hide();
                    //Error while fetching assets, Handle error here.
                });
    }
    createTheme() {
        this.assetService.createTheme(this.orgService.getCurrentOrganizationId(), this.model.themeName).subscribe(data => {
            this.toast.success("Theme created.");
            this.loadThemes();
            this.model.themeName = '';
        }, error => {
            console.log(error);
            this.toast.error(error.error.message);
        });
    }
    clone(asset) {
        this.assetService.clone(asset.id).subscribe(newAsset => {
            this.toast.success("Theme (" + asset.title + ") cloned to  " + newAsset.title);
            this.loadThemes();
        }, error => {
            this.toast.error(error.error.message);
        });
    }
    delete(asset) {
        this.assetService.archive(asset.id).subscribe(() => {
            this.toast.success("Theme (" + asset.title + ") has been archived.");
            this.loadThemes();
        }, error => {
            this.toast.error(error.error.message);
        });
    }

    /* Confirmation pop up before archiving asset*/
    public showDeleteConfirmationModal(asset) {
        this.bsModalRef = this.modalService.show(ConfirmModalComponent);
        this.bsModalRef.content.title = asset.name;
        this.bsModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.delete(asset);
                }
            });
    }
    /* Confirmation pop up before archiving asset*/
    public showCloneConfirmationModal(asset) {
        this.bsModalRef = this.modalService.show(ConfirmModalComponent);
        this.bsModalRef.content.title = asset.name;
        this.bsModalRef.content.message = "You are about to clone the selected theme.  Do you wish to proceed?";
        this.bsModalRef.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.clone(asset);
                }
            });
    }
    //Below function will sort assets list when user clicks on table header.
    sort(sortBy) {
        if (this.search.page.sortBy == sortBy) {
            if (this.search.page.sortOrder == "ASC") {
                this.search.page.sortOrder = "DESC";
            } else {
                this.search.page.sortOrder = "ASC";
            }
        } else {
            this.search.page.sortBy = sortBy;
            this.search.page.sortOrder = "ASC"
        }
        this.loadThemes();
    }

    pageChanged(event) {
        this.search.page.page = event.page;
        this.search.page.pageSize = event.itemsPerPage;
        this.loadThemes();
    }

    changePageSize() {
        this.loadThemes();
    }

    getApplications(asset: CmdAsset) {
        return asset ? asset.applications.join(', ') : '';
    }

    validateInput() {
        this.buttonIsDisabled = this.validationService.validateEmptyString(this.model.themeName);
    }
}
