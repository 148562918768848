import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../../services/organization.service';
import { CreateProductBuilderModelComponent } from '../create-product-builder-model/create-product-builder-model.component'
import { BsModalService } from 'ngx-bootstrap/modal';
import { QuoteBuilderService } from '../../../../services/quotebuilder.service';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
import { Subject } from 'rxjs';
import { FormControl, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { EditQuoteBuilderService } from '../../../../services/editquotebuilder';

@Component({
  selector: 'app-change-col-name-mdl',
  templateUrl: './change-col-name-mdl.component.html',
  styleUrls: ['./change-col-name-mdl.component.css']
})
export class ChangeColNameMdlComponent implements OnInit {
  onClose = new Subject<any>();
  public newName: string;
  public position: any;
  public apiUrl: any;
  public oldName: any;
  public headerData: any = [];
  constructor(private apiService: EditQuoteBuilderService, private bsModalRef: BsModalRef,
    public organizationService: OrganizationService,
    private stickyPopUp: StickyPopUpService
  ) {
  }

  ngOnInit() {
    const org = this.organizationService.getCurrentOrganization();
    this.onClose = new Subject();
  }

  onCancel() {
    // this.onClose.next(true)
    this.bsModalRef.hide();
  }

  public changeName() {
    let index = this.headerData.findIndex(x => x.position == this.position)
    this.headerData[index].headerName = this.newName;
    let body = {
      "quoteBuilderProductHeader": this.headerData
    }
    this.apiService.postMethod(this.apiUrl, body).subscribe(
      data => {
        this.stickyPopUp.success('Column name changed successfully');
        this.bsModalRef.hide();
      },
      err => {
        console.log(err, 'Some problem occured');
      }
    )
  }
  showCreateProductModal() {
    document.getElementById('createproduct').style.display = 'block';
    document.getElementById('quoteBuilder').style.display = 'none';
  }
}
