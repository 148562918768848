import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'cachebuster' })

export class CacheBusterPipe implements PipeTransform {

    transform(url: any, args?: any): any {
        if(url){
            return url + '?cachebuster=' + new Date().getTime();
        }
        else{
            return url;
        }
    };
}