import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinService } from '../../services/spin.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssetsService } from '../../services/assets.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Subject } from 'rxjs';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';

@Component({
  selector: 'app-assetpdfmodifier',
  templateUrl: './assetpdfmodifier.component.html',
  styleUrls: ['./assetpdfmodifier.component.css']
})
export class AssetpdfmodifierComponent implements OnInit {
  private confirmModalRef: BsModalRef;
  public onClose: Subject<boolean>;
  public images = []
  public isSelected: boolean = true;
  private convertedPdf = [];

  createNew = [
    {
      input: '',
      selectedImages: []
    }
  ]
  sendPdf = [
    {
      pages: [],
      filename: ''

    }
  ]

  constructor(
    public bsModalRef: BsModalRef,
    private spinService: SpinService,
    private assetService: AssetsService,
    private alertService: StickyPopUpService,
    private modalService: BsModalService,) {
    this.assetService.response.subscribe(res => {
      res.forEach(element => {
        this.convertedPdf.push(element);
        this.images.push(element.thumbnailUrl);
      });
    }, error => {
      console.log(error)
    })
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  /**
   * creating new pdf object with name and selectedImage array
   * and pushing the object into createdNewPdf array.
   */
  createNewPdf() {
    this.createNew.push({
      input: '',
      selectedImages: []
    })
  }

  /**
   * Selected image are pushing to the last created pdf
   * @param image 
   */

  selectedImage(image: any) {
    this.createNew[this.createNew.length - 1].selectedImages = [...this.createNew[this.createNew.length - 1].selectedImages, image]
  }

  deleteSelectedImage(image, imageIndex,rowIndex) {
    console.log(this.createNew);
    console.log(image);
    console.log(imageIndex);
    console.log(rowIndex);
    const result = this.createNew[rowIndex].selectedImages.splice(imageIndex, 1);
    if (result) {
      this.alertService.success("Image deleted successfully !!")
    }
  }
  /**
   * closing the pop-up open in the mode
   */
  public onCancel(): void {
    if (this.spinService.shown) {
      this.spinService.hide();
    }
    this.bsModalRef.hide();
  }

  /**
   * With the click selectALL
   * All the image will be push to the selected pdf image section
   * @param checkBoxStatus 
   * @param index 
   */
  selectAllImage(checkBoxStatus: boolean, index: number) {
    if (checkBoxStatus) {
      this.images.forEach((item) => {
        this.createNew[index].selectedImages = [...this.createNew[index].selectedImages, item]
      })
    } else {
      this.images.forEach((item) => {
        this.createNew[index].selectedImages = [];
      })
    }
  }

  /**
   * Deleting the pdf
   * @param pdf 
   */
  removePdf(pdf: any) {
    this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
    this.confirmModalRef.content.message = 'Are you sure you want remove this pdf?';
    this.confirmModalRef.content.onClose
      .subscribe(result => {
        if (result) {
          this.createNew.splice(pdf, 1)
        }
      });
  }

  /**
   * getting the array of new created pdf.
   * with selected image, finding the id
   * making payload of array of ids and filename 
   */
  onSubmit() {
    const isEmpty = element => element.input === '' || element.selectedImages.length === 0;
    let isInvalid = this.createNew.some(isEmpty);
    if (isInvalid) {
      this.alertService.error("Please fill the file Name and select atleast one image")
    } else {
      this.spinService.show()
      this.sendPdf = this.createNew.map((item) => ({
        pages: item.selectedImages.map((element) =>
          this.convertedPdf.find((item) =>
            item.thumbnailUrl == element).id),
        filename: item.input
      }))
      this.assetService.savePdf(this.sendPdf).subscribe((res) => {
        if (res) {
          this.spinService.hide()
          this.alertService.success("PDF successfully created");
          this.bsModalRef.hide();
          this.onClose.next(true);
        } else {
          this.alertService.error("PDF creation failed.please try again.")
        }
      }, error => {
        this.alertService.error(`Error : ${error.error.message}`)
      });
    }

  }

}
