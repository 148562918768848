import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CmdOrganization, CmdAsset, CmdUser } from '../../models';
import { SearchModel } from '../../models/SearchModel';
import { Router } from '@angular/router';
import { OrganizationService } from '../../services/organization.service';
import { SpinService } from '../../services/spin.service';
import { Page } from '../../models/page';
import { AssetsService } from "../../services/assets.service";
import { EmailsService } from '../../services/emails.service';
import { EnvironmentSpecificService } from "../../services/environmentspecific.service";
import { StickyPopUpService } from "../../services/toast/stickyPopUp.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserService } from '../../services/user.service';

@Component({    
    selector: 'app-emails-loggings',
    templateUrl: 'emails-loggings.component.html',
    styleUrls: ['emails-loggings.component.scss']
})
export class EmailsLoggingsComponent implements OnInit, OnDestroy {

    private destroy$: Subject<boolean> = new Subject<boolean>();
    searchTerm$ = new Subject<string>();
    searchTermTags$ = new Subject<string>();
    searchTypes = ['from', 'to', 'subject', 'template', 'date'];
    currentUser: CmdUser;
    currentOrganization: CmdOrganization;
    emailsSearch: SearchModel;
        

    constructor(private router: Router,
                private organizationService: OrganizationService,
                private emailsService: EmailsService,
                private spinService: SpinService,
                private envService:EnvironmentSpecificService,
                private http:HttpClient,
                private assetService:AssetsService,
                private alertService:StickyPopUpService,
                private userService:UserService) {
        this.resetPagination();
    }


    ngOnInit() {
        setTimeout(() => {
        this.userService.getCurrentUser().toPromise().then(user => {
            this.currentUser = user;
        });
    }, 3000)
        // init search
        this.searchTerm$            
            .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchText => {
                this.emailsSearch.page.page = 1;
                this.emailsSearch.searchText = searchText;
                this.doSearch();
            });

        // get current Org
        this.currentOrganization = this.organizationService.getCurrentOrganization();

        // get all emails
        this.doSearch();
    }

    doSearch(): any {
        
        this.spinService.show();
        this.emailsService.getEmailsForOrg(this.organizationService.getCurrentOrganizationId(), this.emailsSearch)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.spinService.hide();                
                this.emailsSearch.results = data;
            },
            error => {
                this.spinService.hide();
            });
    }

    // #region Paginations&Search
    clearSearch() {
        if (this.emailsSearch && this.emailsSearch.searchText) {
            this.resetPagination();
            this.doSearch()
        }
    }

    resetPagination() {
        this.emailsSearch = new SearchModel();        
        this.emailsSearch.page = new Page();
        this.emailsSearch.page.sortOrder = "DESC";
        this.emailsSearch.page.sortBy = "created";
        this.emailsSearch.searchType = this.searchTypes[0];
    }

    pageChanged($event) {
        this.emailsSearch.page.page = $event.page;
        this.emailsSearch.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }

    changePageSize() {
        this.doSearch();
    }

    sortemails(sortBy) {
        this.emailsSearch.page.page = 1;
        if (this.emailsSearch.page.sortBy == sortBy) {
            if (this.emailsSearch.page.sortOrder == "ASC") {
                this.emailsSearch.page.sortOrder = "DESC";
            } else {
                this.emailsSearch.page.sortOrder = "ASC";
            }
        } else {
            this.emailsSearch.page.sortBy = sortBy;
            this.emailsSearch.page.sortOrder = "ASC"
        }
        this.doSearch();
    }

    // #endregion

    
    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
    
    
    downloadAttachment(emailLog:any){
        if(emailLog.assetId){
            this.assetService.getById(emailLog.assetId).subscribe(asset => {
                const headers = new HttpHeaders();
                headers.set('Accept', asset.mimeType);
                headers.set('Content-Type', asset.mimeType);
                this.http.get(this.envService.envSpecific.baseServerUrl + "/api/assets/" + emailLog.assetId + "/file", {headers:headers, responseType:'blob'}).subscribe(result => {
                    // Create a new Blob object using the 
                    //response data of the onload object
                    var blob = new Blob([result], {type: asset.mimeType});
                    //Create a link element, hide it, direct 
                    //it towards the blob, and then 'click' it programatically
                    let a:any = document.createElement("a");
                    a.style = "display: none";
                    document.body.appendChild(a);
                    //Create a DOMString representing the blob 
                    //and point the link element towards it
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = asset.name;
                    //programatically click the link to trigger the download
                    a.click();
                    //release the reference to the file by revoking the Object URL
                    window.URL.revokeObjectURL(url);
                });
            });
        }
    }
}
