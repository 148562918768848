import { CmdList, CmdScene, CmdListItem } from '../../models';
import { CmdEmailTemplate } from '../../models/CmdEmailTemplate';
import { OrganizationService } from '../../services/organization.service';
import { WidgetService } from '../../services/widget.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { LightboxModalComponent } from '../../shared/lightbox/lightbox-modal.component';
import { MedialibraryComponent } from '../../shared/medialibrary/medialibrary.component';
import { WidgetEditor } from '../widgeteditor';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MedialibraryService } from '../../shared/medialibrary/medialibrary.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-list-editor',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListEditorComponent extends WidgetEditor implements OnInit {

    widgetData: CmdList;
    private confirmModalRef: BsModalRef;
    private mediaModalRef: BsModalRef;
    private selectedItem: CmdListItem;
    emailTemplates: Array<CmdEmailTemplate>;
    private widgetListValidation: Array<any>;
    private enableSaveButton: boolean = false;
    private widgetCount: number = 0;
    @ViewChild('listWidgetForm', { static: false }) listWidgetForm: NgForm;

    constructor(private modalService: BsModalService,
        private widgetService: WidgetService,
        private orgService: OrganizationService,
        private mediaLibraryService: MedialibraryService) {
        super();
        this.orgService.getEmailTemplates().subscribe(temps => { this.emailTemplates = temps });
    }

    ngOnInit() {
    }
    @Input() set widget(data: CmdList) {
        this.widgetData = data;
        if (!this.widgetData.items || !this.widgetData.items.length) {
            this.addEmptyRow();
        }
    }
    removeItem(item: CmdListItem) {
        let self = this;
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this list item?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.widgetData.items.splice(this.widgetData.items.indexOf(item), 1);
                    this.notifyChange();
                }
            });
    }

    addEmptyRow() {
        this.widgetData.items.push(new CmdListItem(this.widgetData.id + '-' + this.widgetCount));
        this.widgetCount++;
    }

    selectImage(item: CmdListItem) {
        this.selectedItem = item;
        this.mediaLibraryService.assetType = 'image';
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result.length == 1) {
                    this.selectedItem.asset = result[0];
                    this.notifyChange();
                }
            });
    }
    clearImage(item: CmdListItem) {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this image?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    item.asset = undefined;
                    this.notifyChange();
                }
            });
    }
}
