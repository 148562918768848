import { CmdWidget } from ".";


export class CmdFormIOModel extends CmdWidget {


  constructor(id: string) {
      super(id);
      this.type = "FORMIO";
  }
}
