import { CmdAsset } from './CmdAsset';
import { CmdWidget } from './CmdWidget';
export class Cmd3DCarouselItem extends CmdWidget{
    
    label: string;
    thumbnail: CmdAsset;
    asset: CmdAsset;
    constructor(id:string){
        super(id);
        this.type = "Carousel3DItem";
    }
}