import { Component, OnInit } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { BaseActionTypeSelectorComponent } from '../baseactiontypeselector';
import { OrganizationService } from '../../../services/organization.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CmdEmailTemplate } from '../../../models/CmdEmailTemplate';
import { MedialibraryComponent } from '../../medialibrary/medialibrary.component';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-emailtemplate',
    templateUrl: './emailtemplate.component.html',
    styleUrls: ['./emailtemplate.component.scss']
})
export class EmailTemplateSelectorComponent extends BaseActionTypeSelectorComponent implements OnInit {

    mediaModalRef: BsModalRef;
    confirmModalRef: BsModalRef;
    selectedTemplate: CmdEmailTemplate;
    constructor(public widgetService: WidgetService,
        private orgService: OrganizationService,
        private modalService: BsModalService) {
        super(widgetService);

    }
    ngOnInit() {
        this.orgService.getEmailTemplates().subscribe(temps => {
            this.rows = temps;
        });
    }


    selectImage() {
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.search.searchTypes = ['all'];
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result && result.length == 1) {
                    this.widgetData.actionData.attachmentId = result[0].id;
                    this.widgetData.actionData.attachmentTitle = result[0].title;
                    this.validateInput();
                }
            });
    }
    clearImage(size) {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this image?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    this.widgetData.actionData.attachmentId = undefined;
                    this.widgetData.actionData.attachmentTitle = undefined;
                }
            });
    }

    setSlugTitle(type, slug) {
        var theVal;
        theVal = this.rows.filter(s => s.id == this.widgetData.actionData.templateId)[0];
        this.widgetData.actionData.displayTitle = theVal.label;
    }
}