import { Injectable } from '@angular/core';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AzureAuthholderService } from './azureauthholder.service';

@Injectable()
export class WidgetService {

    widgets: Array<any>;
    actionTypes: Array<ItemModel> = [
        { title: "Do Nothing", value: "NONE" } as ItemModel,
        { title: "Go Back", value: "BACK" } as ItemModel, 
        { title: "Open a Collection", value: "COLLECTION" } as ItemModel, 
        { title: "Go to a Menu", value: "NAVIGATION" } as ItemModel,
        { title: "Go to the Home Menu", value: "HOME" } as ItemModel, 
        { title: "Send an Email", value: "EMAIL" } as ItemModel,
        { title: "Exit the application", value: "EXIT" } as ItemModel, 
        { title: "Open an external website", value: "EXTERNAL_WEBSITE" } as ItemModel,
        { title: "Open an internal website", value: "INTERNAL_WEBSITE" } as ItemModel,
        { title: "Open Content Search & Lookup", value: "TAB_DATA_SEARCH" } as ItemModel,
        { title: "Open a Quote Builder", value: "QUOTE_BUILDER" } as ItemModel,
        { title: "Open a Cost Calculator", value: "COST_CALCULATOR" } as ItemModel,
        { title: "Open a TCO Calculator", value: "TCO_CALCULATOR" } as ItemModel,
        { title: "Open the Form History", value: "FORM_HISTORY" } as ItemModel,
        { title: "Open the Sales Quote History", value: "SALES_QUOTE_HISTORY" } as ItemModel
    ]

    private widgetsToValidate: Array<any> = [];
    public widgetsToValidate$ = new Subject<Array<any>>();

    constructor( private azureholder: AzureAuthholderService,private http: HttpClient, private envService: EnvironmentSpecificService) {
        this.widgetsToValidate$.subscribe((nextValue) => {
            this.widgetsToValidate = nextValue;
        });
    }
    getWidgets() {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/widgets', requestOptions);
    }

    getActionTypes() {
        return this.actionTypes;
    }
}
export class ItemModel {
    
    public title: string;
    public value: string;
}
