export class CmdFormComparison {
   
    vehiclePurchaseComparison: Array<{ header: string; amount:string; }>;

    financingComparison:Array<{ 
        downPaymentAmount:Array<{
            productName: string; amount:string; 
        }>,
        monthlyInstallment:Array<{
            productName: string; amount:string; 
        }>, 
        downPaymentDifference:Array<{
            productName: string; amount:string; 
        }>,
        InterestRateDifference:Array<{
            productName: string; amount:string; 
        }>,   
    }>;

    fuelConsumptionComparison: Array<{ productName: string; amount:string; }>;

    adBlueDieselComparison: Array<{ productName: string; amount:string; }>;
    contractedServicesComparison: Array<{ productName: string; amount:string; }>;

    usedVehicleLiquidityComparison:Array<{ productName: string; amount:string; }>;

    finalResult:Array<{ 
        lifeCycle:Array<{
            productName: string; amount:string; 
        }>,
        financialApplication:Array<{
            productName: string; amount:string; 
        }>, 
        negotiationVolume:Array<{
            productName: string; amount:string; 
        }>,
        investmentPeriod:Array<{
            productName: string; amount:string; 
        }>,   
        returnOnInvestmentPeriod:Array<{
            productName: string; amount:string; 
        }>,   
    }>;

  }