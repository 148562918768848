import { Pipe, PipeTransform } from '@angular/core'; 

@Pipe({
  name: 'truncate',
  pure: false
})
export class TruncatePipe implements PipeTransform {
  constructor() {
  }
  transform(value: string, args?: any): any { 
    let limit = args ? parseInt(args, 10) : 10; 
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit)+trail : value;
  }

}
