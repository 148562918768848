import {CmdAsset} from './CmdAsset';
// import {CmdHorizontalImageSliderItem} from './CmdHorizontalImageSliderItem';
import { CmdScrollableContainerItem } from './CmdScrollableContainerItem';
import {CmdWidget} from './CmdWidget';

export class CmdScrollableContainer extends CmdWidget {
    items: Array<any>;
    thumbnail: CmdAsset;

    //flag for cloning
    cloning?: boolean = false;
    
    constructor(id: string) {
        super(id);
        this.type = "ScrollableContainer";
        this.items = new Array<CmdScrollableContainerItem>();
    }
}