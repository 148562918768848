import { Page } from '../../models/page';
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, ElementRef } from '@angular/core';
import { AssetsService } from '../../services/assets.service';
import { Subject, Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CmdAsset } from '../../models';
import { OrganizationService } from '../../services/organization.service';
import { SpinService } from '../../services/spin.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { AssetUploadComponent } from '../../shared/assetuploader/upload.component';
import { TageditorComponent } from '../../shared/tageditor/tageditor.component';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, map, finalize, take } from 'rxjs/operators';


import { ProductsService } from '../../services/products.service';
import { ProductSelectAssetsComponent } from '../../shared/productselectassets/productselectassets.component';

@Component({
    selector: 'asset-view',
    templateUrl: './asset-view.component.html',
    styleUrls: ['./asset-view.component.scss']
})
export class AssetViewComponent implements OnInit, OnDestroy {
    uploadModalRef: BsModalRef;
    bsModalRef: BsModalRef;
    assetTagEditModalRef: BsModalRef;
    clearTagModalRef: BsModalRef;
    @ViewChild('searchAssets', { static: false }) searchQuery;
    @ViewChild('replaceFile', { static: false }) replaceFile: ElementRef;
    public assetSearch: any;
    public results: any;
    public totalNumberOfAssets: number = 0;
    private orgSubscription: Subscription;
    public currentOrganization: any;
    public viewType: string = "list";
    public tags: Array<string> = [];
    public searchTypes = [
        'image',    //an image
        'video',    //a video
        'theme',    //an application theme that provides the base styles for all widgets
        'template', //a scene template that provides the base layout for a scene
        'pdf', //a pdf thumbnail that provides the base layout for a pdf
        'profile_picture',
        'org_logo',
        // 'css',      //a custom css file associated with the application
        'document', //a document stored in the system and used for display. This might be a pdf file that is displayed in a panel;
    ];

    /* Required to unSubscribe from AssetsService Service */
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    allSelected: boolean = false;

    constructor(private assetsService: AssetsService,
        private modalService: BsModalService,
        private orgService: OrganizationService,
        private assetService: AssetsService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: StickyPopUpService,
        private productsService: ProductsService,
        private spinService: SpinService) {
        this.resetPagination();
    }

    ngOnInit() {
        this.viewType = this.route.snapshot.params['viewtype'];
        this.currentOrganization = this.orgService.getCurrentOrganization();
        this.doSearch();
        this.orgService.getTags().subscribe(tags => {
            this.tags = tags;
        });
        this.orgSubscription = this.orgService.getCurrentOrganizationObservable().subscribe(() => {
            this.resetPagination();
            this.doSearch();
            this.currentOrganization = this.orgService.getCurrentOrganization();
        });

        this.route.paramMap.subscribe(params => {
            this.viewType = params.get('viewtype');
            this.resetPagination();
            this.doSearch();
        });
    }

    formatBytes(bytes): string {
        return this.assetService.formatBytes(bytes);
    };

    handleSelectAllToggle(status) {
        this.assetSearch.results.contents.forEach(item => {
            item.selected = status;
        });
    }

    cacheBuster(url): string {
        if (url && url.indexOf("http") != -1 && url.indexOf("cachebuster") == -1) {
            return url + ("?cachebuster=" + new Date().getTime());
        }
        else {
            return url;
        }
    }

    startUpload() {
        this.uploadModalRef = this.modalService.show(AssetUploadComponent, { ignoreBackdropClick: true, keyboard: false });
        this.uploadModalRef.content.title = "Upload Files";
        this.uploadModalRef.content.onClose.subscribe(result => {
            if (result === true) {
                this.doSearch();
            }
        });
    }
    resetPagination() {
        this.assetSearch = {
            searchText: "",
            searchType: [],
            tags: [],
            results: []
        }
        this.assetSearch.page = new Page();
    }
    onSelected(event) {
        this.assetSearch.tags = event;
        this.doSearch();
    }
    typeChanged(inp: any) {
        this.doSearch();
    }

    doSearch() {
        this.spinService.show();
        this.assetsService.getAssets(this.orgService.getCurrentOrganizationId(),
            [...this.assetSearch.searchType.map(function (a) { return a.value })], this.assetSearch.searchText, this.assetSearch.tags, this.assetSearch.page)
            .pipe(finalize(() => {
                this.spinService.hide();
            }))
            .subscribe(data => {
                this.assetSearch.results = data;
            },
                error => {
                    this.spinService.hide();
                    console.log(error);
                    //Error while fetching assets, Handle error here.
                });
    }
    pageChanged($event) {
        this.allSelected = false;
        this.assetSearch.page.page = $event.page;
        this.assetSearch.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }

    /* Confirmation pop up before archiving asset*/
    public showConfirmationModal(asset) {
        this.bsModalRef = this.modalService.show(ConfirmModalComponent);
        this.bsModalRef.content.title = asset.name;
        this.bsModalRef.content.onClose
            .pipe(take(1))
            .subscribe(result => {
                if (result === true) {
                    this.archive(asset);
                }
            });
    }

    archive(asset) {
        this.assetsService.archive(asset.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                let index = this.assetSearch.results.contents.indexOf(asset);
                if (index > -1) {
                    this.assetSearch.results.contents.splice(index, 1);
                }
                this.alertService.success("The asset has been deleted successfully.");
            },
                error => {
                    this.alertService.error(error.error.message);
                });
    }

    //Below function will sort assets list when user clicks on table header.
    sortAssets(sortBy) {
        if (this.assetSearch.page.sortBy == sortBy) {
            if (this.assetSearch.page.sortOrder == "ASC") {
                this.assetSearch.page.sortOrder = "DESC";
            } else {
                this.assetSearch.page.sortOrder = "ASC";
            }
        } else {
            this.assetSearch.page.sortBy = sortBy;
            this.assetSearch.page.sortOrder = "ASC"
        }
        this.doSearch();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.orgSubscription.unsubscribe();
    }

    replace(asset: CmdAsset, fileBrowser) {
        if (fileBrowser.srcElement && fileBrowser.srcElement.files && fileBrowser.srcElement.files[0]) {
            this.spinService.show();
            this.assetsService.replace(asset.id, fileBrowser.srcElement.files[0])
                .pipe(takeUntil(this.ngUnsubscribe),
                    finalize(() => {
                        this.spinService.hide();
                    })).subscribe(data => {
                        asset = data;
                        this.doSearch();
                    },
                        error => {
                            this.spinService.hide();
                            this.alertService.error(error.error.message);
                        });;
        }
    }

    startEditProducts(type) {
        var theSelectedAssets: Array<any> = this.getSelectedAssets();
        if (theSelectedAssets.length == 0) {
            this.alertService.error('No assets selected.');
        }
        else {
            this.assetTagEditModalRef = this.modalService.show(ProductSelectAssetsComponent, { ignoreBackdropClick: true, keyboard: false });
            this.assetTagEditModalRef.content.setActionType(type, theSelectedAssets);


            this.productsService.getProductsForOrganization(this.orgService.getCurrentOrganizationId())
                .pipe(
                    map(resp => {
                        let products_for_autocomplete = [];
                        products_for_autocomplete.push(...resp.contents.map(function (p) { return { display: p.title, value: p.id } }));
                        products_for_autocomplete.push(...resp.contents.map(function (p) { return { display: p.sku, value: p.id }; }));
                        return products_for_autocomplete;
                    }))
                .subscribe(prods => {
                    this.assetTagEditModalRef.content.all_products = prods;
                });

            this.assetTagEditModalRef.content.onClose.subscribe(result => {
                if (result) {
                    if (type === 'Add') {
                        this.productsService.setAssets(result).subscribe(() => {
                            this.doSearch();
                        }, error => {
                            this.alertService.error(error.error.message);
                            this.doSearch();
                        });
                    } else if (type === 'Remove') {
                        this.productsService.removeAssets(result).subscribe(() => {
                            this.doSearch();
                        }, error => {
                            this.doSearch();
                        });
                    }
                }
            });
        }
    }

    startEditTags(type) {
        var theSelectedTags: Array<any> = this.getSelectedAssets();
        if (theSelectedTags.length == 0) {
            this.alertService.error('No assets selected.');
        }
        else {
            this.assetTagEditModalRef = this.modalService.show(TageditorComponent, { ignoreBackdropClick: true, keyboard: false });
            this.assetTagEditModalRef.content.setActionType(type, theSelectedTags);
            this.assetTagEditModalRef.content.onClose.subscribe(result => {
                if (result) {
                    this.assetsService.setTags(result).subscribe(updatedAssets => {
                        this.doSearch();
                    });
                }
            });
        }
    }

    startDeleteTags(type) {
        this.assetTagEditModalRef = this.modalService.show(TageditorComponent, { ignoreBackdropClick: true, keyboard: false });
        this.assetTagEditModalRef.content.setActionType(type, this.getSelectedAssets());
        this.assetTagEditModalRef.content.onClose.subscribe(result => {
            this.allSelected = false;
            if (result) {
                this.assetsService.setTags(result).subscribe(updatedAssets => {
                    this.doSearch();
                });
            }
        });
    }

    startClearTags() {
        this.clearTagModalRef = this.modalService.show(ConfirmModalComponent, { ignoreBackdropClick: true, keyboard: false });
        this.clearTagModalRef.content.title = "Clear Asset Tags";
        this.clearTagModalRef.content.message = "Are you sure you want to remove all tags from the " + this.getSelectedAssets().length + " selected assets?";
        this.clearTagModalRef.content.onClose.subscribe(result => {
            if (result) {
                this.allSelected = false;
                var theAssets = this.getSelectedAssets();
                var updateAssets: Array<CmdAsset> = [];
                theAssets.forEach(a => {
                    updateAssets.push({
                        id: a.id,
                        tags: []
                    });
                });
                this.assetsService.setTags(updateAssets).subscribe(updatedAssets => {
                    this.doSearch();
                });
            }
        });
    }

    getSelectedAssets(): Array<CmdAsset> {
        var selected: Array<CmdAsset> = [];
        if (this.assetSearch.results && this.assetSearch.results.contents) {
            this.assetSearch.results.contents.forEach(item => {
                if (item.selected) {
                    selected.push(item);
                }
            });
        }
        return selected;
    }

    chagnePageSize() {
        this.doSearch();
    }
    
    openPdf(assets: any) {
        window.open(assets.url,'_blank');
    }
}
