import { CmdHorizontalImageSlider, CmdScene, CmdSlideDeck, CmdAsset } from '../../models';
import { CmdEmailTemplate } from '../../models/CmdEmailTemplate';
import { CmdHorizontalImageSliderItem } from '../../models/CmdHorizontalImageSliderItem';
import { CmdSlide } from '../../models/CmdSlide';
import { WidgetService } from '../../services/widget.service';
import { OrganizationService } from '../../services/organization.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { MedialibraryComponent } from '../../shared/medialibrary/medialibrary.component';
import { WidgetEditor } from '../widgeteditor';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { MedialibraryService } from '../../shared/medialibrary/medialibrary.service';
import { DragulaService } from 'ng2-dragula';

@Component({
    selector: 'app-horizontalimageslidereditor',
    templateUrl: './horizontalimageslidereditor.component.html',
    styleUrls: ['./horizontalimageslidereditor.component.css'],
    providers: [DragulaService]
})
export class HorizontalImageSliderEditorComponent extends WidgetEditor implements OnInit {
    private confirmModalRef: BsModalRef;
    private mediaModalRef: BsModalRef;
    private selectedAsset: CmdAsset;
    private selectedItem: CmdHorizontalImageSliderItem;
    private enableSaveButton: boolean = false;
    private widgetCount: number = 0;

    widgetData: CmdHorizontalImageSlider;
    emailTemplates: Array<CmdEmailTemplate> = [];
    @ViewChild('collectionWidgetForm', { static: false }) collectionWidgetForm: NgForm;

    constructor(private changeDetector: ChangeDetectorRef,
        private modalService: BsModalService,
        private orgService: OrganizationService,
        private widgetService: WidgetService,
        private mediaLibraryService: MedialibraryService,
        private dragulaService: DragulaService) {
        super();
        this.dragulaService.setOptions('widgetDataItems', {
            revertOnSpill: true,
            moves: function (el: any, container: any, handle: any): any {
                if (handle.classList.contains('addbdr')) {
                    return true;
                }
                return false;
            }
        });
    }

    ngOnInit() {
        this.orgService.getEmailTemplates().subscribe(templates => this.emailTemplates = templates);
    }
    @Input() set widget(data: CmdHorizontalImageSlider) {
        this.widgetData = data;
    }

    removeItem(item: CmdHorizontalImageSliderItem) {
        let self = this;
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this item?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    let index = self.widgetData.items.indexOf(item);
                    if (index >= 0) {
                        self.widgetData.items.splice(index, 1);
                    }
                    this.notifyChange();
                }
            });
    }

    removeImage(item: CmdHorizontalImageSliderItem) {
        let self = this;
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this image?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    item.image = null;
                }
                this.notifyChange();
            });
    }

    addEmptyRow() {
        this.widgetData.items.push(new CmdHorizontalImageSliderItem(this.widgetData.id + '-' + this.widgetCount));
        this.widgetCount++;
    }

    removeThumbnailImage(slider: CmdHorizontalImageSlider) {
        slider.thumbnail = null;
        this.notifyChange();
    }

    selectThumbnailImage(slider: CmdHorizontalImageSlider) {
        //        this.selectedItem = slider;
        this.mediaLibraryService.assetType = 'image';
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.multiselect = false;
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result.length == 1) {
                    this.widgetData.thumbnail = result[0];
                    this.notifyChange();
                }
            });
    }

    clearThumbnailImage(slider: CmdHorizontalImageSlider) {
        this.confirmModalRef = this.modalService.show(ConfirmModalComponent);
        this.confirmModalRef.content.message = 'Are you sure you want remove this image?';
        this.confirmModalRef.content.onClose
            .subscribe(result => {
                if (result) {
                    slider.thumbnail = undefined;
                    this.notifyChange();
                }
            });
    }

    selectImage(item: CmdHorizontalImageSliderItem) {
        this.selectedItem = item;
        this.mediaLibraryService.assetType = 'image';
        this.mediaModalRef = this.modalService.show(MedialibraryComponent, { class: 'modal-lg asset-modal' });
        this.mediaModalRef.content.onClose
            .subscribe(result => {
                if (result.length == 1) {
                    this.selectedItem.image = result[0];
                    this.notifyChange();
                }
            });
    }
}
