import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppSettings } from '../app-settings';
import { CmdAsset } from '../models';
import { Page } from '../models/page';
import { EnvironmentSpecificService } from './environmentspecific.service';
import { AzureAuthholderService } from './azureauthholder.service';
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class AssetsService {
    //for testing
    public response = new BehaviorSubject([]);
    constructor(private azureholder: AzureAuthholderService, private http: HttpClient, private envService: EnvironmentSpecificService) { }
    getTemplates(orgId: string, page: Page) {
        const params = page.toParams().set('orgId', orgId);
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {  params: params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/type/template', requestOptions);
    }
    getById(id: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/' + id, requestOptions);
    }

    clone(id: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/' + id + '/clone',{}, requestOptions);
    }

    getByIdWithContents(id: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/' + id + '/contents', requestOptions);
    }

    updateByString(asset: any): Observable<CmdAsset> {
        const headers = new HttpHeaders({
           // "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.put<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/' + asset.id + '/updatetext', asset, requestOptions);
    }

    getImages(orgId: string, page: Page) {
        const params = page.toParams().set('orgId', orgId);
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {params: params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/type/image',requestOptions );
    }
    getThemes(orgId: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = new Page().toParams().set('orgId', orgId);
        const requestOptions = {params: params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/type/theme', requestOptions);
    }
    getVideos(orgId: string, page: Page) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = page.toParams().set('orgId', orgId);
        const requestOptions = {params: params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/type/video', requestOptions);
    }


    createTheme(orgId: string, themeName: string) {
        const headers = new HttpHeaders({
           // "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const formData = new FormData();
        formData.append("orgId", orgId);
        formData.append("type", "theme");
        formData.append("folder", "themes");
        formData.append("content", "");
        formData.append("name", themeName);
        formData.append("content-type", "text/css");
        const requestOptions = { headers: headers };
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/create', formData, requestOptions);
    }

    save(type, folder, file) {
        const headers = new HttpHeaders({
           // "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);
        formData.append("folder", folder);
        const requestOptions = {headers: headers };
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/assets', formData, requestOptions);
    }

    getAssets(orgId: string, assetType: Array<string>, searchText, tags, page): Observable<any> {
        const headers = new HttpHeaders({
            // "Content-Type": "application/json",
             Authorization: "Bearer " + this.azureholder.getAccessToken(),
         });
        let params = page.toParams()
            .set('orgId', orgId)
            .set('search', searchText);

        if (assetType.length && assetType[0] !== 'all') {
            params = params.append('types', assetType);
        }
        if (tags != undefined && tags.length > 0) {
            params = params.append('tags', tags);
        }
        const requestOptions = { params: params, headers: headers };
        return this.http.get(this.envService.envSpecific.baseServerUrl + '/api/assets/search',requestOptions);
    }


    // This function will format bytes into KB,MB or GB depending upon size of file.
    formatBytes(bytes): string {
        if (bytes < AppSettings.global.fileSizeConstants.OneKB) return bytes.toFixed(2) + " Bytes";
        else if (bytes < AppSettings.global.fileSizeConstants.OneMB) return (bytes / AppSettings.global.fileSizeConstants.OneKB).toFixed(2) + " KB";
        else if (bytes < AppSettings.global.fileSizeConstants.OneGB) return (bytes / AppSettings.global.fileSizeConstants.OneMB).toFixed(2) + " MB";
        else return (bytes / AppSettings.global.fileSizeConstants.OneGB).toFixed(2) + " GB";
    }

    // This function will select appropriate folder and file type depending upon file type uploaded by user, and upload the asset. 
    uploadAsset(orgId: string, tags: Array<string>, file,uploadDialogChecked:boolean): Observable<any> {
        const headers = new HttpHeaders({
            // "Content-Type": "application/json",
             Authorization: "Bearer " + this.azureholder.getAccessToken(),
         });
        const formData = new FormData();
        formData.append("orgId", orgId);
        formData.append("tags", tags.join(","));
        switch (file.type) {
            // CSS files

            case AppSettings.global.assetsTypes.css: {
                formData.append("file", file);
                formData.append("type", AppSettings.global.typeNamesForUploadingAssets.themes);
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.themes);
                break;
            }
            // Images
            case AppSettings.global.assetsTypes.images.jpeg: {
                formData.append("file", file);
                formData.append("type", AppSettings.global.typeNamesForUploadingAssets.images);
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.images);
                break;
            }
            case AppSettings.global.assetsTypes.images.png: {
                formData.append("file", file);
                formData.append("type", AppSettings.global.typeNamesForUploadingAssets.images);
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.images);
                break;
            }
            // Videos
            case AppSettings.global.assetsTypes.videos.ogv: {
                formData.append("file", file);
                formData.append("type", AppSettings.global.typeNamesForUploadingAssets.videos);
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.videos);
                break;
            }
            case AppSettings.global.assetsTypes.videos.mp4: {
                formData.append("file", file);
                formData.append("type", AppSettings.global.typeNamesForUploadingAssets.videos);
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.videos);
                break;
            }
            case AppSettings.global.assetsTypes.videos.mov: {
                formData.append("file", file);
                formData.append("type", AppSettings.global.typeNamesForUploadingAssets.videos);
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.videos);
                break;
            }
            // Documents
            case AppSettings.global.assetsTypes.documents.docx: {
                formData.append("file", file);
                formData.append("type", AppSettings.global.typeNamesForUploadingAssets.documents);
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.documents);
                break;
            }
            // PDF
            case AppSettings.global.assetsTypes.documents.pdf: {
                formData.append("file", file);
                formData.append("type", "pdf");
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.pdf);
                break;
            }
            // all else -> to document
            default: {
                formData.append("file", file);
                formData.append("type", AppSettings.global.typeNamesForUploadingAssets.documents);
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.documents);
                break;
            }
        }
        if ((file.type === AppSettings.global.assetsTypes.documents.pdf) && uploadDialogChecked == false) {
            const requestOptions = {headers: headers };
            return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/convertPdf', formData, requestOptions);
        } else {
            const requestOptions = {headers: headers };
            return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/assets', formData, requestOptions);
            // return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/assets', formData);
        }
    }

    uploadAppThumbnail(orgId: string, tags: Array<string>, file): Observable<any> {
        const headers = new HttpHeaders({
            // "Content-Type": "application/json",
             Authorization: "Bearer " + this.azureholder.getAccessToken(),
         });
        const formData = new FormData();
        formData.append("orgId", orgId);
        formData.append("tags", tags.join(","));
        switch (file.type) {
            // Images
            case AppSettings.global.assetsTypes.images.jpeg: {
                formData.append("file", file);
                formData.append("type", 'app_thumbnail');
                formData.append("folder", 'app_thumbnail');
                break;
            }
            case AppSettings.global.assetsTypes.images.png: {
                formData.append("file", file);
                formData.append("type", 'app_thumbnail');
                formData.append("folder", 'app_thumbnail');
                break;
            }
         
        }
            const requestOptions = {headers: headers };
            return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/assets', formData, requestOptions);
    }

    savePdf(createPdf: any) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/savePdf', createPdf, requestOptions)
    }



    // This function will select appropriate folder and file type depending upon file type uploaded by user, and upload the asset. 
    uploadProfilePicture(orgId: string, file): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        const formData = new FormData();
        formData.append("orgId", orgId);
        formData.append("tags", "[]");
        switch (file.type) {
            // Images
            case AppSettings.global.assetsTypes.images.jpeg: {
                formData.append("file", file);
                formData.append("type", 'profile_picture');
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.images);
                break;
            }
            case AppSettings.global.assetsTypes.images.png: {
                formData.append("file", file);
                formData.append("type", 'profile_picture');
                formData.append("folder", AppSettings.global.folderNamesForUploadingAssets.images);
                break;
            }
        }
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/assets', formData, requestOptions);
    }

    // THis function will archive asset depending upon the Id passed.
    archive(assetId: string): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.delete(this.envService.envSpecific.baseServerUrl + '/api/assets/' + assetId, requestOptions);
    }
    download(asset: any): void {
        window.open(asset.url, "_blank");
    }
    update(id: string, file: File): Observable<any> {
        const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const formData = new FormData();
        formData.append("file", file);
        const requestOptions = {headers: headers };
        return this.http.put<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/' + id + '/update', formData, requestOptions);
    }
    replace(id: string, file: File): Observable<any> {
        const headers = new HttpHeaders({
            //"Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const formData = new FormData();
        formData.append("file", file);
        const requestOptions = {headers: headers };
        return this.http.put<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/' + id + '/replace', formData, requestOptions);
    }
    setTags(assets: Array<CmdAsset>) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.put<any>(this.envService.envSpecific.baseServerUrl + '/api/assets/tags', assets, requestOptions);
    }
}
