import { CmdScene } from '../../models';
import { OrganizationService } from '../../services/organization.service';
import { WidgetService, ItemModel } from '../../services/widget.service';
import { Component, OnInit, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { Subject } from "rxjs";

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ActiontypeselectorComponent),
    multi: true
};

@Component({
    selector: 'app-action-type-selector',
    templateUrl: './actiontypeselector.component.html',
    styleUrls: ['./actiontypeselector.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class ActiontypeselectorComponent implements OnInit {

    public isValid: Subject<boolean> = new Subject<boolean>();
    _applicationId: string;
    _organizationId: string;
    widgetData: any = {
        actionData: {}
    };
    actionTypes: Array<ItemModel> = [];
    actionType: string;
    private widgetButtonValidation: Array<any>;
    @ViewChild('buttonWidgetPartialForm', { static: false }) buttonWidgetPartialForm: NgForm;

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;
    constructor(private widgetService: WidgetService,
        private orgService: OrganizationService) {
        this.widgetService.widgetsToValidate$.subscribe((nextValue) => {
            this.widgetButtonValidation = nextValue;
        });
    }

    ngOnInit() {
        this._organizationId = this.orgService.getCurrentOrganizationId();
        this.actionTypes = this.widgetService.getActionTypes();
    }

    @Input() set applicationId(id) {
        this._applicationId = id;
    }
    @Input() set widget(widget: any) {
        this.widgetData = widget;
    }

    validateInput() {
        this.widgetData.actionData.slug = null;
        let data = {
            name: 'buttonWidgetPartialForm',
            formData: this.buttonWidgetPartialForm
        }
        let self = this;
        if (this.widgetButtonValidation && this.widgetButtonValidation.length) {
            let buttonObjectFound: boolean = false;
            this.widgetButtonValidation.filter(function (widgetButtonData) {
                let widgetFound: boolean = false;

                if (widgetButtonData && widgetButtonData.id === self.widgetData.id &&
                    widgetButtonData.data && widgetButtonData.data.length) {
                    buttonObjectFound = true;
                    widgetButtonData.data.filter(function (widget) {
                        if (widget && widget.name == data.name) {
                            widgetFound = true;
                            widget.formData = data.formData;
                            self.widgetService.widgetsToValidate$.next(self.widgetButtonValidation);
                        }
                    });
                    if (!widgetFound) {
                        widgetButtonData.data.push(data);
                        self.widgetService.widgetsToValidate$.next(self.widgetButtonValidation);
                    }
                }
            });
            if (!buttonObjectFound) {
                self.widgetButtonValidation.push({
                    id: self.widgetData.id,
                    data: [data]
                });
                self.widgetService.widgetsToValidate$.next(self.widgetButtonValidation);
            }
        }
        else {
            this.widgetService.widgetsToValidate$.next([{
                id: self.widgetData.id,
                data: [data]
            }]);
        }
    }
}
