import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { TcoCalculatorService } from '../../../../services/tco-calculator.service';
import { CmdAmoritizationInvestment } from '../../../../models/tco/CmdAmoritizationInvestment';
import { CmdFormComparison } from '../../../../models/tco/CmdFormComparison';
import { CmdAmoritizationForms } from '../../../../models/tco/CmdAmoritizationForms';
import { StickyPopUpService } from '../../../../services/toast/stickyPopUp.service';
@Component({
  selector: 'app-tco-amoritization-investment',
  templateUrl: './tco-amoritization-investment.component.html',
  styleUrls: ['./tco-amoritization-investment.component.css']
})
export class TcoAmoritizationInvestmentComponent implements OnInit {

  tcoDataRes: any;
  amoritization: CmdAmoritizationInvestment = new CmdAmoritizationInvestment();
  form: CmdAmoritizationForms;
  comparision: CmdFormComparison = new CmdFormComparison();
  toolsetId: any;

  constructor(

    private route: Router,
    public alertService: StickyPopUpService,
  ) {

  }

  ngOnInit() {
    var forms = localStorage.getItem("Amoritization");
    this.amoritization.forms = JSON.parse(forms);
  }

  onTcoCalculator() {
    localStorage.removeItem('Amoritization');
    this.route.navigate(["toolset/tco-calculator"]);
  }
}
