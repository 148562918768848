import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'userappfilter'
})
export class UserappPipe implements PipeTransform {

    transform(items: Array<any>, args?: any): any {
        
        if (!items) {
            return items;
        }
        
        if(args == 'WHITELABEL'){
            return items.filter(item => {
                return item.whitelabelApp != undefined && item.whitelabelApp;
            });
        }
        else{
            return items;
        }
    }

}
