import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../services/organization.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import * as $ from "jquery";
import { finalize } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { Page } from '../../../models/page';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { EditQuoteBuilderService } from '../../../services/editquotebuilder';
import { StickyPopUpService } from '../../../services/toast/stickyPopUp.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinService } from '../../../services/spin.service';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-notify-modal',
  templateUrl: './notify-modal.component.html',
  styleUrls: ['./notify-modal.component.css']
})
export class NotifyModalComponent implements OnInit {
  onClose = new Subject<any>();
  public userData: any = [];
  public checkBoxArr: any = [];
  public userCheckBoxData: any = [];
  public appId: string;
  pageNum = new Page();
  public heading;
  public title: string = "";
  public message: string = "";
  public errBool: boolean = false;
  public modalTitle: string;
  public type: string;
  public isUserEmpty: boolean = true;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public sendAllCheck: boolean = false;
   userIds = [];
  searchTerm$ = new Subject<string>();
  userSearch: any = {
    searchText: "",
    searchType: "All fields",
    tags: [],
    results: []
  };
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(private bsModalRef: BsModalRef,
    public organizationService: OrganizationService,
    private userService: UserService,
    private orgService: OrganizationService,
    private modalService: BsModalService,
    private spinService: SpinService,
    private apiService: EditQuoteBuilderService,
    private stickyPopUp: StickyPopUpService,
    private spinner: NgxSpinnerService
  ) {
    this.resetPagination();
  }

  ngOnInit() {
    this.searchTerm$
    .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
    .subscribe(searchText => {
        this.userSearch.searchText = searchText;
        this.userSearch.page.page = 1;
        this.getUserData();
    });
    this.getUserData();
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  getUserData() {
    this.spinner.show();
    this.userSearch.page.pageSize = 50;//restricted to 50 records per page
    this.userService.searchNotification(this.appId, "", "All fields", this.userSearch.page)
      .subscribe(data => {
        this.userData = data.contents;
        this.userSearch.results = data;
        this.spinner.hide();
        this.checkBoxArr = [];
        this.userData.forEach(element => {
          this.checkBoxArr.push({ id: element.id, isSelected: false });
        });
        this.sendAll(false);
      },
        error => {
          this.spinService.hide();
          //Error while fetching users, Handle error here.
        });
  }

  public sendAll(status) {
    for (let i = 0; i < this.checkBoxArr.length; i++) {
      var checkBoxArrIndex = this.userCheckBoxData.indexOf(i);
      if ((this.userData[i].role != 'SUPERADMIN') && this.userData[i].id == this.checkBoxArr[i].id) {
        this.checkBoxArr[i].isSelected = status;
        if (status == true) { 
          if (checkBoxArrIndex === -1) {
            this.userCheckBoxData.push(i);
          }
        }
        else{
          this.userCheckBoxData.splice(checkBoxArrIndex,1);
        }
      } 
      else {
        this.checkBoxArr[i].isSelected = true;
        if (checkBoxArrIndex === -1 ) {
          this.userCheckBoxData.push(i);
        }
      }
    }
    if (status == false) {
      this.userCheckBoxData.length = 0;
    }
    this.sendAllCheck = status;
    this.isUserEmpty = (!status);
  }

  public checkboxInput(index, status) {
    var checkBoxArrIndex = this.userCheckBoxData.indexOf(index);
    if (status) {
      this.checkBoxArr[index].isSelected = true;
      this.isUserEmpty = false;
      this.sendAllCheck = false;
      if (checkBoxArrIndex === -1 ) {
        this.userCheckBoxData.push(index);
      }
    }
    else {
      this.checkBoxArr[index].isSelected = false;
      this.userCheckBoxData.splice(checkBoxArrIndex,1);
      if(this.userCheckBoxData.length == 0) this.isUserEmpty = true;
    }
  }


  public sendInfo() {
    this.spinService.show();
    this.errBool = true;
    if (this.title === '' || this.message === '') {
      this.stickyPopUp.error("Please enter all mandatory fields");
      this.spinService.hide();
      return;
    }
    for (let i = 0; i < this.checkBoxArr.length; i++) {
      if (this.checkBoxArr[i].isSelected) {
        this.userIds.push(this.checkBoxArr[i].id)
      }
    }
    if (this.isUserEmpty) {
      this.stickyPopUp.error("Please select atleast one user");
      this.spinService.hide();
      return;
    }
    if (this.modalTitle == "Notify") {
      let body = {
        "title": this.title,
        "body": this.message,
        "sendToList": this.userIds,
        "appID": this.appId,
        "sendToAll":this.sendAllCheck
      }
      this.apiService.postMethod("/api/notification/push", body).pipe(finalize(() => this.spinService.hide())).subscribe(
        data => {
          this.spinService.hide();
          this.stickyPopUp.success(data.message);
          console.log("Successfully sent : " + data.success);
          console.log("Failed to sent : " + data.failed);
        },
        err => {
          this.spinService.hide();
          this.stickyPopUp.error(err.error.message);
        }
      )
    } else {
      let body = {
        "title": this.title,
        "body": this.message,
        "sendToList": this.userIds,
        "appID": this.appId,
        "sendToAll":this.sendAllCheck
      }
      this.apiService.postMethod("/api/notification/rollout", body).pipe(finalize(() => this.spinService.hide())).subscribe(
        data => {
          this.spinService.hide();
          this.stickyPopUp.success(data.message);
          console.log("Successfully sent : " + data.success);
          console.log("Failed to sent : " + data.failed);
        },
        err => {
          this.spinService.hide();
          this.stickyPopUp.error(err.error.message);
        }
      )
    }
    this.userIds = [];
    //this.onCancel();
    // this.modalService.show(SuccessModalComponent, { class: 'modal-trans' });
  }

  //Below function will sort users list when user clicks on table header.
  sortUsers(sortBy) {
    if (this.userSearch.page.sortBy == sortBy) {
      if (this.userSearch.page.sortOrder == "ASC") {
        this.userSearch.page.sortOrder = "DESC";
      } else {
        this.userSearch.page.sortOrder = "ASC";
      }
    } else {
      this.userSearch.page.sortBy = sortBy;
      this.userSearch.page.sortOrder = "ASC"
    }
    this.getUserData();
  }

  resetPagination() {
    this.userSearch = {
      searchText: "",
      searchType: "All fields",
      tags: [],
      results: []
    }
    this.userSearch.page = new Page();
  }

  pageChanged($event) {
    this.userSearch.page.page = $event.page;
    this.userSearch.page.pageSize = $event.itemsPerPage;
    this.getUserData();
  }
}
