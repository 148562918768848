import {OrganizationService} from './organization.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpParams} from '@angular/common/http';
import {CmdApplication} from '../models';
import { EnvironmentSpecificService } from './environmentspecific.service';
import {Observable} from 'rxjs';
import { AzureAuthholderService } from './azureauthholder.service';
import { HttpHeaders } from "@angular/common/http";
@Injectable()
export class ApplicationService {

    constructor(private azureholder: AzureAuthholderService, private http: HttpClient, private envService:EnvironmentSpecificService) {}

    getOrgApplications(orgId:string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = new HttpParams().set("orgId", orgId);
        const requestOptions = {params:params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/applications', requestOptions);
    }
    
    getUserApplications(userId:string, orgId:string){
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = new HttpParams().set("userId", userId).set("orgId", orgId);
        const requestOptions = {params:params, headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/applications/authorized', requestOptions);
    }

    getById(id: string): Observable<CmdApplication> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.get<any>(this.envService.envSpecific.baseServerUrl + '/api/applications/' + id,requestOptions);
    }

    deleteById(id: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.delete<any>(this.envService.envSpecific.baseServerUrl + '/api/applications/' + id,requestOptions);
    }

    create(orgId:string, themeId: string, title: string, description: string) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/applications/', {
            organizationId: orgId,
            themeId: themeId,
            title: title,
            description: description, 
            desktopLayoutMode: 'none',
            tabletLayoutMode: 'none',
            phoneLayoutMode: 'none'
        }, requestOptions);
    }
    updateMetadata(app:CmdApplication): Observable<CmdApplication> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const requestOptions = {headers: headers };
        return this.http.put<CmdApplication>(this.envService.envSpecific.baseServerUrl + '/api/applications/' + app.id + '/metadata', app, requestOptions);
    }
    publish(id:string, userId:string):Observable<CmdApplication> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = new HttpParams().set("userId", userId);
        const requestOptions = {params:params, headers: headers };
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/applications/' + id + '/publish', {}, requestOptions);
    }
    rollout(id:string):Observable<Array<any>> {
    
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.azureholder.getAccessToken(),
        });
        const params = new HttpParams();
        const requestOptions = {params: params, headers: headers };
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/applications/' + id + '/rollout', {}, requestOptions );
    }
    
    clone(id:string):Observable<any> {
        let parms = new HttpParams();
        return this.http.post<any>(this.envService.envSpecific.baseServerUrl + '/api/applications/' + id + '/clone', {}, {
            params: parms
        });
    }
}
