import { CmdUser } from '../../models';
import { Page } from '../../models/page';
import { User } from '../../models/user';
import { OrganizationService } from '../../services/organization.service';
import { StickyPopUpService } from '../../services/toast/stickyPopUp.service';
import { UserService } from '../../services/user.service';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SpinService } from '../../services/spin.service';


@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {

    private destroy$: Subject<boolean> = new Subject<boolean>();
    searchTerm$ = new Subject<string>();
    userSearch: any = {
        searchText: "",
        searchType: "All fields",
        tags: [],
        results: []
    };
    public currentOrganization: any;
    public deleteModal: BsModalRef;
    public searchTypes = [
        'All fields', 'name', 'email', 'role'
    ];

    /* Required to unSubscribe from userService Service */
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private orgSubscription: Subscription;
    constructor(private userService: UserService,
        private orgService: OrganizationService,
        private modalService: BsModalService,
        private alertService: StickyPopUpService,
        private spinService: SpinService) {
        this.resetPagination();
    }
    ngOnInit() {
        this.searchTerm$
            .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe(searchText => {
                this.userSearch.searchText = searchText;
                this.userSearch.page.page = 1;
                this.doSearch();
            });
        this.currentOrganization = this.orgService.getCurrentOrganization();
        this.doSearch();
        this.orgSubscription = this.orgService.getCurrentOrganizationObservable().subscribe(() => {
            this.doSearch();
            this.currentOrganization = this.orgService.getCurrentOrganization();
        });
    }

    resetPagination() {
        this.userSearch = {
            searchText: "",
            searchType: "All fields",
            tags: [],
            results: []
        }
        this.userSearch.page = new Page();
    }

    clearSearch() {
        if (this.userSearch && this.userSearch.searchText) {
            this.resetPagination();
            this.doSearch()
        }
    }

    doSearch() {
        this.spinService.show();
        this.userService.search(this.orgService.getCurrentOrganizationId(),
            this.userSearch.searchText, this.userSearch.searchType, this.userSearch.page)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.spinService.hide();
                this.userSearch.results = data;
            },
                error => {
                    this.spinService.hide();
                    //Error while fetching users, Handle error here.
                });
    }

    typeChanged() {
        if (this.userSearch.searchText.length)
            this.doSearch();
    }

    pageChanged($event) {
        this.userSearch.page.page = $event.page;
        this.userSearch.page.pageSize = $event.itemsPerPage;
        this.doSearch();
    }

    //Below function will sort users list when user clicks on table header.
    sortUsers(sortBy) {
        if (this.userSearch.page.sortBy == sortBy) {
            if (this.userSearch.page.sortOrder == "ASC") {
                this.userSearch.page.sortOrder = "DESC";
            } else {
                this.userSearch.page.sortOrder = "ASC";
            }
        } else {
            this.userSearch.page.sortBy = sortBy;
            this.userSearch.page.sortOrder = "ASC"
        }
        this.doSearch();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    confirmDelete(user: CmdUser) {
        this.deleteModal = this.modalService.show(ConfirmModalComponent);
        this.deleteModal.content.title = "Delete User";
        this.deleteModal.content.message = "Are you sure you want to delete this user?";
        this.deleteModal.content.onClose
            .subscribe(result => {
                if (result === true) {
                    this.userService.delete(user).subscribe(() => {
                        this.alertService.success("User has been delete.");
                        this.doSearch();
                    }, error => {
                        var err = JSON.parse(error.error);
                        this.alertService.error("Failed to delete user: " + err.message);
                    });
                }
            });
    }

    chagnePageSize() {
        this.doSearch();
    }
}
